<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
  {{ serverService.get_title(type_task,lang) }}
  <span class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>

  <div class="video_div1" (click)="open_video()" style="float: left;" *ngIf="type_task != 3">
    <mat-icon class="video">smart_display</mat-icon>
  </div>

</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
  <div mat-dialog-content class="matDialogContent" style="padding:0px !important;padding-bottom:1rem !important">
    <div class="row">
      <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe'
        [ngClass]="{en:lang==2,'width100':ref == 'menu' || type_task == 3}">
        <div class="row" [ngStyle]="{'border-bottom' : code != 219 ? 'solid 1px #ccc' : 'none'}">

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="level > 1">
            <label for="guilds" class='wh-label'>
              <span *ngIf="lang == 1"> اصناف </span>
              <span *ngIf="lang == 2">Guild</span>
              <span (click)="reset_input(258)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='guilds_title' autocomplete="off" readonly
                (click)="load_services(258)" [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='guilds' autocomplete="off" readonly [hidden]="true">
            </label>
          </div>

          <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="sex" class='wh-label'>
              <span *ngIf="lang == 1"> عنوان </span>
              <span *ngIf="lang == 2"> Title </span>
              <span (click)="reset_input(10)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='sex_title' autocomplete="off" [required]="required()" readonly
                (click)="load_services(10)" [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='sex' autocomplete="off" [required]="required()" readonly
                [hidden]="true">
            </label>
          </div>

          <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="title" class='wh-label'>
              <span *ngIf="lang == 1">نام طرف حساب </span>
              <span *ngIf="lang == 2"> Account holder </span>
              <input class='wh-input' formControlName='title' autocomplete="off" [required]="required()"
                [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="store" class='wh-label'>
              <span *ngIf="lang == 1"> نام شرکت / فروشگاه </span>
              <span *ngIf="lang == 2"> Company/ Store name </span>
              <input class='wh-input' formControlName='store' autocomplete="off" [required]="required() && level == 1"
                [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="group" class='wh-label'>
              <span *ngIf="lang == 1"> گروههای کاربری </span>
              <span *ngIf="lang == 2"> User groups </span>
              <span (click)="reset_input(155)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='group_title' autocomplete="off" (click)="load_services(155)"
                [required]="required()" readonly>
              <input class='wh-input' formControlName='group' autocomplete="off" [required]="required()" [hidden]="true"
                [ngClass]="{'en':dir == 'ltr'}" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="level != 1">
            <label for="login_service_title" class='wh-label'>
              <span *ngIf="lang == 1"> سرویس ورود </span>
              <span *ngIf="lang == 2"> Login service </span>
              <span (click)="reset_input(213)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='login_service_title' autocomplete="off"
                (click)="load_services(213)" [required]="required() && level == 1" readonly
                [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='login_service' autocomplete="off"
                [required]="required() && level == 1" [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="status_id" class='wh-label'>
              <span *ngIf="lang == 1"> وضعیت </span>
              <span *ngIf="lang == 2"> Status </span>
              <span (click)="reset_input(26)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='status_title' autocomplete="off" (click)="load_services(26)"
                [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='status_id' autocomplete="off" [required]="required()"
                [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="cellphone" class='wh-label'>
              <span *ngIf="lang == 1"> شماره همراه </span>
              <span *ngIf="lang == 2"> Cellphone number </span>
              <input class='wh-input' formControlName='cellphone' pattern="[0-9]{1,}" (keypress)="get_number($event)"
                minlength=11 maxlength=11 autocomplete="off" [required]="required() && level == 1 "
                [ngClass]="{'en':dir == 'ltr'}" autocomplete="off">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="country" class='wh-label'>
              <span *ngIf="lang == 1"> کشور </span>
              <span *ngIf="lang == 2"> Country </span>
              <span (click)="reset_input(259)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='country_title' autocomplete="off" (click)="load_services(259)"
                readonly [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='country' autocomplete="off" [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="state" class='wh-label'>
              <span *ngIf="lang == 1"> استان </span>
              <span *ngIf="lang == 2"> State / Province </span>
              <span (click)="reset_input(233)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='state_title' autocomplete="off" (click)="load_services(233)"
                readonly [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='state' autocomplete="off" [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="address" class='wh-label'>
              <span *ngIf="lang == 1"> آدرس </span>
              <span *ngIf="lang == 2"> Address </span>
              <input class='wh-input' formControlName='address' autocomplete="off" [ngClass]="{'en':dir == 'ltr'}"
                autocomplete="off">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1 "
            [hidden]="type_task == 3">
            <label for="admin" class='wh-label'>
              <span *ngIf="lang == 1"> ورود به نرم افزار </span>
              <span *ngIf="lang == 2"> Log in to Finty </span>
              <mat-checkbox class='wh-input' formControlName='admin' autocomplete="off" style="border:none"
                [ngClass]="{'en':dir == 'ltr'}">
              </mat-checkbox>
            </label>
          </div>
        </div><!-- row -->

        <div class="row" style="padding-top:1rem">
          <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
            <span class="header-title" *ngIf="lang == 1"> اطلاعات تکمیلی </span>
            <span *ngIf="lang == 2" [ngClass]="{'full-left':dir == 'ltr'}"> Further information </span>
          </div>
          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="level > 1">
            <label for="user" class='wh-label'>
              <span *ngIf="lang == 1"> نام کاربری </span>
              <span *ngIf="lang == 2"> Username </span>
              <input class='wh-input' formControlName='user' autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="type_task == 3 || level > 1">
            <label for="password" class='wh-label'>
              <span *ngIf="lang == 1"> رمز عبور </span>
              <span *ngIf="lang == 2"> Password </span>
              <input type="text" class='wh-input' formControlName='password' [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="phone" class='wh-label'>
              <span *ngIf="lang == 1"> شماره ثابت </span>
              <span *ngIf="lang == 2"> Landline number </span>
              <input class='wh-input' formControlName="phone" autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="email" class='wh-label'>
              <span *ngIf="lang == 1"> پست الکترونیک </span>
              <span *ngIf="lang == 2"> Email </span>
              <input class='wh-input' formControlName='email' autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="code_meli" class='wh-label'>
              <span *ngIf="lang == 1"> کد ملی / شناسه </span>
              <span *ngIf="lang == 2"> National ID number / ID </span>
              <input class='wh-input' formControlName='code_meli' autocomplete="off"
                (keypress)="serverService.get_number($event)" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="code_posti" class='wh-label'>
              <span *ngIf="lang == 1"> کد پستی </span>
              <span *ngIf="lang == 2"> Postal code </span>
              <input class='wh-input' formControlName='code_posti' autocomplete="off"
                (keypress)="serverService.get_number($event)" length="10" minlength="10" maxlength="10"
                [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="economic_code" class='wh-label'>
              <span *ngIf="lang == 1"> شماره اقتصادی </span>
              <span *ngIf="lang == 2"> Economic code </span>
              <input class='wh-input' formControlName='economic_code' autocomplete="off"
                (keypress)="serverService.get_number($event)" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="true">
            <label for="site" class='wh-label'>
              <span *ngIf="lang == 1"> سایت </span>
              <span *ngIf="lang == 2"> Website </span>
              <input class='wh-input' formControlName='site' autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1 && type_task !== 3">
            <label for="sms_charge" class='wh-label'>
              <span *ngIf="lang == 1">شارژ اولیه پیامک به ریال </span>
              <span *ngIf="lang == 2"> Initial SMS credit in Rials </span>
              <input class='wh-input' formControlName='sms_charge' autocomplete="off" currencyMask
                [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>


          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
            <label for="expire_date" class="wh-label">
              <span *ngIf="lang == 1">تاریخ انقضا </span>
              <span *ngIf="lang == 2"> Expiration Date</span>
              <div style="width:100%">
                <input class="wh-input" matInput [matDatepicker]="picker" formControlName="expire_date"
                  autocomplete="off" [ngClass]="{'en':dir == 'ltr'}"
                  [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                  [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}">
                </mat-datepicker-toggle>
                <mat-datepicker #picker
                  [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker>
              </div>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1">
            <label for="number_access" class='wh-label'>
              <span *ngIf="lang == 1"> تعداد دسترسی مجاز </span>
              <span *ngIf="lang == 2"> Number of authorized access </span>
              <input class='wh-input' formControlName='number_access' autocomplete="off" currencyMask
                [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1">
            <label for="amount" class='wh-label'>
              <span *ngIf="lang == 1"> شارژ ماهیانه </span>
              <span *ngIf="lang == 2"> Monthly charge </span>
              <input class='wh-input' formControlName='amount' autocomplete="off" currencyMask
                [required]="required() && level == 1" [ngClass]="{'en':dir == 'ltr'}">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1">
            <label for="currency" class='wh-label'>
              <span *ngIf="lang == 1"> واحد پولی پرداخت </span>
              <span *ngIf="lang == 2"> Payment currency </span>
              <span (click)="reset_input(156)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='currency_title' autocomplete="off" readonly
                (click)="load_services(156)" [required]="required() && level == 1" [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='currency' autocomplete="off" readonly [hidden]="true"
                [required]="required() && level == 1">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" *ngIf="level == 1">
            <label for="moarf" class='wh-label'>
              <span *ngIf="lang == 1"> معرف </span>
              <span *ngIf="lang == 2"> Referrer </span>
              <span (click)="reset_input(161)">
                <mat-icon class="close" class="input_search input_search_pe" [ngClass]="{input_search_en:lang==2}">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='moarf_title' autocomplete="off" readonly
                (click)="load_services(161)" [ngClass]="{'en':dir == 'ltr'}">
              <input class='wh-input' formControlName='moarf' autocomplete="off" readonly [hidden]="true">
            </label>
          </div>

        </div>

        <div class="row" style="border-bottom:solid 1px #ccc;">
          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="level != 1">
            <label for="setting_menu" class="wh-label">
              <span *ngIf="lang == 1"> تنظیمات منو </span>
              <span *ngIf="lang == 2"> Menu settings </span>
              <div style="width:100%">
                <mat-checkbox formControlName="setting_menu" autocomplete="off"></mat-checkbox>
              </div>
            </label>
          </div>

          <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}">
            <label for="comment" class='wh-label'>
              <span *ngIf="lang == 1"> توضیحات </span>
              <span *ngIf="lang == 2"> Description </span>
              <textarea class="wh-input" formControlName='comment' style="height:80px"
                [ngClass]="{'en':dir == 'ltr'}"></textarea>
            </label>
          </div>

          <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
            [ngClass]="{'full-left':dir == 'ltr',width100:type_task == 3}" [hidden]="level != 1">
            <label for="comment2" class='wh-label'>
              <span *ngIf="lang == 1"> توضیحات - فقط ادمین </span>
              <span *ngIf="lang == 2"> Description - admin only </span>
              <textarea class="wh-input" formControlName='comment2' style="height:80px"
                [ngClass]="{'en':dir == 'ltr'}"></textarea>
            </label>
          </div>
        </div><!-- row -->
      </div><!-- col-md-8 col-lg-8 col-sm-8 col-xs-12  -->

      <div class='col-md-3 col-lg-3 col-sm-3 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}"
        style="padding-top:0.5rem" [hidden]="type_task == 3">
        <span *ngIf="lang == 1"> آواتار من </span>
        <span *ngIf="lang == 2"> My avatar </span>
        <mat-card class="example-card logo cursor backgroundImage"
          style="padding:3px 16px 7px 16px;width:100%;height:10rem"
          [ngStyle]="{'background-image':'url( ' + user_logo + ')'}" (click)="logo.click()" accept="image/*">
          <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
          </mat-card-header>
        </mat-card>
        <span (click)="delete_logo()" *ngIf="user_logo_bin == true"><i class="far fa-trash-alt icon"
            style="margin: 0px 4px;"></i></span>
        <input type="file" (change)="change_avater($event)" style="display: none;" #logo accept="image/*">
      </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->
    </div><!-- row -->
    <hr>
    <!-- ************************************************************************************* -->
    <div *ngIf="level == 1">
      <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12  div_panding'
        style="text-align:right;padding-right:1rem;padding-top:1rem;padding-bottom:1rem" *ngIf="type_task != 3">
        <span class="header-title" *ngIf="lang == 1"> دسترسی ماژول ها</span>
        <span class="header-title" *ngIf="lang == 2">Access modules </span>
      </div>
      <div class="row user_div1" *ngFor="let s of list_sgroup" style="padding-top:1rem">
        <div class="user_div2">--- {{ s.site_software_title }} ---</div>
        <span *ngFor="let x of list_sgroup_id[s.site_software_id]">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-12 pe service" [ngClass]="{en:lang==2}">
            <mat-checkbox class="user-checkbox" [value]="x.site_services_id" (change)="change_select_service()"
              name="service[]" [checked]="x.checked">
            </mat-checkbox>
            {{ x.site_services_title }}
          </div>
        </span>
      </div>
    </div>
    <!--- ماژول ها -->

  </div> <!-- content -->


  <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
    <button type="submit" [disabled]="!form1.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
      *ngIf="type_task == 1 || type_task == 2">
      {{ serverService.get_event_title(type_task,lang) }}
    </button>
    <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
      <{{ serverService.get_event_title(type_task,lang) }} </button>
  </div><!-- row -->
</form>