<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span *ngIf="lang == 1">صندوق</span>
    <span *ngIf="lang == 2">Cash register</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <div class="inventory" [ngClass]="{'en':dir == 'ltr'}">{{ cash | number }}</div>
                <div style="text-align: center;" *ngIf="lang == 1">موجودی صندوق</div>
                <div style="text-align: center;" *ngIf="lang == 2">Cash register balance</div>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="issue_date" class="wh-label">
                    <span *ngIf="lang == 1"> تاریخ از: </span>
                    <span *ngIf="lang == 2"> Date from: </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker3" formControlName="date3" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker3" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="issue_date" class="wh-label">
                    <span *ngIf="lang == 1"> تا تاریخ: </span>
                    <span *ngIf="lang == 2"> Date to: </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker4" formControlName="date4" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker4" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </div>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button [disabled]="!form1_group.valid || loading || !form1_group.get('amount').value > 0" class="btn_pe"
            (click)="save(type_task,1,'close')" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3" (click)="search(1)">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>