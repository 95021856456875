import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { HttpEventType } from '@angular/common/http';
import { UserVideoComponent } from '../user-video/user-video.component';
import { UserContentComponent } from '../user-content/user-content.component';
import { MessageService } from '../../service/message/message.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService, RichTextEditor } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService]
})
export class MyProfileComponent implements OnInit, OnDestroy {
  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|', 'FontSize', 'FontColor', 'BackgroundColor']
  };
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_logo: string = this.serverService.get_default_user_logo();
  public sign_logo: string = this.serverService.get_default_image();
  public user_logo1: string = this.serverService.get_default_image();
  public user_sign_logo2: string = this.serverService.get_default_image();
  public iframe: object = { enable: true };
  public height: number = 500;
  public user_id: number;
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1: FormGroup | any;
  public logo_site: string | undefined;
  public user_logo_bin: boolean = false;
  public logo_info: any;
  public ref: string | undefined
  public result: any | undefined;
  public sign_logo_bin: boolean = false;
  public sign_logo_info: any;
  public gropus: number = 0;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public creator: number | undefined;
  public level: number | undefined;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public list_service: any = [];
  public list_service_select: any = [];
  public list_sgroup: any = [];
  public list_sgroup_id: any = [];
  public video: string | undefined;
  public user_logo1_bin: boolean;
  public logo1_info: any;
  public user_sign_logo2_bin: boolean;
  public sign_logo2_info: any;
  public rule_site_numbers: number = 0;

  public tab1: string = "اطلاعات اولیه";
  public tab2: string = "اطلاعات تکمیلی";
  public tab3: string = "آواتار و امضا";
  public tab4: string = "پیامکها";
  public tab5: string = "قراردادها";

  constructor(
    public serverService: ServerService
    , public messageService: MessageService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<MyProfileComponent>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.level = dialog_data.level;
      this.create_form1();
      this.id = dialog_data.id;
      this.creator = dialog_data.creator;
      this.title = dialog_data.title;
      this.ref = dialog_data.ref;
      this.code = dialog_data.code;
      this.gropus = dialog_data.gropus;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.list_service_select = "";
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (this.lang == 2) {
      this.tab1 = "Basic information";
      this.tab2 = "Further information";
      this.tab3 = "Avatar and signature";
      this.tab4 = "Text messages";
      this.tab5 = "Contracts";
    }
  }

  create_form1() {
    this.form1 = new FormGroup({
      'title': new FormControl(null),
      'wharehouse': new FormControl(null),
      'store': new FormControl(null),
      'state_title': new FormControl(null),
      'state': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'currency_title': new FormControl(null),
      'currency': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user': new FormControl(null),
      'email': new FormControl(null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      'cellphone': new FormControl(null),
      'code_meli': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'economic_code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'price_dollar': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'code_posti': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'phone': new FormControl(null, [Validators.pattern('[0-9-]{1,}')]),
      'address': new FormControl(''),
      'password': new FormControl(null),
      'profit_sale': new FormControl(0, [Validators.pattern('[0-9]{1,}'), Validators.maxLength(3), Validators.max(100)]),
      'profit_repairman': new FormControl(0, [Validators.pattern('[0-9]{1,}'), Validators.maxLength(3), Validators.max(100)]),
      'repairman': new FormControl(null),
      'show_cellphone': new FormControl(null),
      'show_cellphone_in_bill': new FormControl(null),
      'login_with_sms': new FormControl(null),
      'document_finance': new FormControl(null),
      'rule_site': new FormControl(null),
      'rule_delivery': new FormControl(null),
      'rule_invoice_buy': new FormControl(null),
      'rule_invoice_sale': new FormControl(null),
      'width_logo': new FormControl(null),
      'height_logo': new FormControl(null),
      'bg_logo': new FormControl(null),
      'sms': new FormControl(null),
      'sms2': new FormControl(null),
      'sms_invoice_buy': new FormControl(null),
      'sms_invoice_sale': new FormControl(null),
      'invoice_title': new FormControl(null),
      'invoice': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 1 || this.type_task == 2) {
      this.form1.controls['title'].setValidators([Validators.required]);
      this.form1.controls['tax'].setValidators([Validators.required]);
      this.form1.controls['profit_sale'].setValidators([Validators.required]);
      this.form1.controls['profit_repairman'].setValidators([Validators.required]);
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;

          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.check_for_access();
            }
          }
          if (type_task == 2) { this.check_for_access(); }
          if (type_task == 4) { this.get_data(); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_number(event) {
    this.serverService.get_number(event)
  }


  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6590, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1.patchValue({
            'title': res['result'][0].user_title,
            'store': res['result'][0].user_store,
            'state_title': res['result'][0].site_state_title,
            'state': res['result'][0].site_state_id,
            'currency_title': res['result'][0].site_currency_title,
            'currency': res['result'][0].site_currency_id,
            'user': res['result'][0].user_userName,
            'cate': res['result'][0].user_cate,
            'email': res['result'][0].user_email,
            'code_meli': res['result'][0].user_code_meli,
            'code_posti': res['result'][0].user_code_posti,
            'economic_code': res['result'][0].user_economic_code,
            'tax': res['result'][0].user_tax,
            'price_dollar': res['result'][0].user_price_dollar,
            'sms': res['result'][0].user_sms,
            'sms2': res['result'][0].user_sms2,
            'sms_invoice_buy': res['result'][0].user_sms_invoice_buy,
            'sms_invoice_sale': res['result'][0].user_sms_invoice_sale,
            'cellphone': res['result'][0].user_cellPhone,
            'phone': res['result'][0].user_phone,
            'address': res['result'][0].user_address,
            'password': res['result'][0].user_repassword,
            'profit_sale': res['result'][0].user_profit_sale,
            'profit_repairman': res['result'][0].user_profit_repairman,
            'repairman': res['result'][0].user_repairman,
            'show_cellphone': res['result'][0].user_show_cellphone,
            'show_cellphone_in_bill': res['result'][0].user_show_cellphone_in_bill,
            'login_with_sms': res['result'][0].user_login_with_sms,
            'document_finance': res['result'][0].user_document_finance,
            'rule_site': res['result'][0].user_rule_site,
            'rule_delivery': res['result'][0].user_rule_delivery,
            'rule_invoice_buy': res['result'][0].user_rule_invoice_buy,
            'rule_invoice_sale': res['result'][0].user_rule_invoice_sale,
            'width_logo': res['result'][0].user_width_logo,
            'height_logo': res['result'][0].user_height_logo,
            'bg_logo': res['result'][0].user_bg_logo,
            'invoice_title': res['result'][0].wharehouse_invoice_type_title,
            'invoice': res['result'][0].wharehouse_invoice_type_id,
            'wharehouse': res['result'][0].user_wharehouse,

          }
          );
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.logo_info = {
            site: res['result'][0].user_logo_site,
            path: res['result'][0].user_logo
          }
          if (res['result'][0].user_logo) {
            this.user_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo;
            this.user_logo_bin = true;
          }
          else {
            this.user_logo = this.serverService.get_default_user_logo();
            this.user_logo_bin = false;
          }

          this.logo1_info = {
            site: res['result'][0].user_logo1_site,
            path: res['result'][0].user_logo1
          }
          if (res['result'][0].user_logo1) {
            this.user_logo1 = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo1;
            this.user_logo1_bin = true;
          }
          else {
            this.user_logo1 = this.serverService.get_default_user_logo();
            this.user_logo1_bin = false;
          }

          this.sign_logo2_info = {
            site: res['result'][0].user_sign_logo2_site,
            path: res['result'][0].user_sign_logo2
          }
          if (res['result'][0].user_sign_logo2) {
            this.user_sign_logo2 = res['result'][0].user_logo_site + "/" + res['result'][0].user_sign_logo2;
            this.user_sign_logo2_bin = true;
          }
          else {
            this.user_sign_logo2 = this.serverService.get_default_image();
            this.user_sign_logo2_bin = false;
          }


          this.sign_logo_info = {
            site: res['result'][0].user_logo_site,
            path: res['result'][0].user_sign_logo
          }
          if (res['result'][0].user_sign_logo) {
            this.sign_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_sign_logo;
            this.sign_logo_bin = true;
          }
          else {
            this.sign_logo = this.serverService.get_default_image();
            this.sign_logo_bin = false;
          }

        }//end if
        else {
          this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  save() {
    this.check_for_access();
  }

  check_for_access() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6468, creator: this.creator, id: this.id, level: this.level,
      group: this.form1.value.group
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      'address': 6589, "user_id": this.user_id
      , 'title': this.form1.value.title
      , 'store': this.form1.value.store
      , 'state': this.form1.value.state
      , 'currency': this.form1.value.currency
      , 'tax': this.form1.value.tax
      , 'price_dollar': this.form1.value.price_dollar
      , 'phone': this.form1.value.phone
      , 'sms': this.form1.value.sms
      , 'sms2': this.form1.value.sms2
      , 'sms_invoice_sale': this.form1.value.sms_invoice_sale
      , 'sms_invoice_buy': this.form1.value.sms_invoice_buy
      , 'sort': this.form1.value.sort
      , 'user': this.form1.value.user
      , 'email': this.form1.value.email
      , 'code_meli': this.form1.value.code_meli
      , 'economic_code': this.form1.value.economic_code
      , 'code_posti': this.form1.value.code_posti
      , 'password': this.form1.value.password
      , 'address1': this.form1.value.address
      , 'profit_sale': this.form1.value.profit_sale
      , 'profit_repairman': this.form1.value.profit_repairman
      , 'logo_info': this.logo_info
      , 'logo1_info': this.logo1_info
      , 'sign_logo2_info': this.sign_logo2_info
      , 'sign_info': this.sign_logo_info
      , 'logo_site': this.serverService.get_site_upload_image()
      , 'id': this.id
      , 'creator': this.creator
      , 'repairman': this.form1.value.repairman
      , 'show_cellphone': this.form1.value.show_cellphone
      , 'show_cellphone_in_bill': this.form1.value.show_cellphone_in_bill
      , 'login_with_sms': this.form1.value.login_with_sms
      , 'document_finance': this.form1.value.document_finance
      , 'rule_site': this.form1.value.rule_site
      , 'rule_delivery': this.form1.value.rule_delivery
      , 'rule_invoice_buy': this.form1.value.rule_invoice_buy
      , 'rule_invoice_sale': this.form1.value.rule_invoice_sale
      , 'width_logo': this.form1.value.width_logo
      , 'height_logo': this.form1.value.height_logo
      , 'bg_logo': this.form1.value.bg_logo
      , 'invoice': this.form1.value.invoice
      , 'wharehouse': this.form1.value.wharehouse
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].user_id == this.user_id) {
            this.serverService.send_user_title({
              title: res['result'][0].user_title,
              user_logo_site: res['result'][0].user_logo_site,
              user_logo: res['result'][0].user_logo,
            });
            //this.serverService.send_menu2();
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.matDialogRef.close();
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "نام کاربری باید منحصر به فرد باشد";
          var en_message = "Username must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update


  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  open_content(id: number) {
    this.dialog.open(UserContentComponent, {
      width: '35rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code }
    })
  }


  change_avater(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.user_logo = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.user_logo_bin = true;
          this.logo_info = {
            'site': this.serverService.get_site_upload_image(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }

  delete_logo() {
    this.user_logo = this.serverService.get_default_user_logo();
    this.user_logo_bin = false;
    this.logo_info = {
      path: '',
      site: ''
    }
  }
  //************************************************************************************************ */
  change_logo(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.user_logo1 = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.user_logo1_bin = true;
          this.logo1_info = {
            'site': this.serverService.get_site_upload_image(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }

  delete_logo1() {
    this.user_logo1 = this.serverService.get_default_image();
    this.user_logo1_bin = false;
    this.logo1_info = {
      path: '',
      site: ''
    }
  }
  //************************************************************************************************ */
  change_sign_logo2(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.user_sign_logo2 = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.user_sign_logo2_bin = true;
          this.sign_logo2_info = {
            'site': this.serverService.get_site_upload_image(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }

  delete_sign_logo2() {
    this.user_sign_logo2 = this.serverService.get_default_image();
    this.user_sign_logo2_bin = false;
    this.sign_logo2_info = {
      path: '',
      site: ''
    }
  }
  //************************************************************************************************ */
  change_sign_logo(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.sign_logo = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.sign_logo_bin = true;
          this.sign_logo_info = {
            'site': this.serverService.get_site_upload_image(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }

  delete_sign_logo() {
    this.sign_logo = this.serverService.get_default_image();
    this.sign_logo_bin = false;
    this.sign_logo_info = {
      path: '',
      site: ''
    }
  }
  //************************************************************************************************ */
  clear_token() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    var pe_message = " آیا از تغییر توکن اطمینان دارید ؟ بعد از تغییر دوباره باید وارد شوید.";
    var en_message = "Are you sure about the token change?";
    confirm_delete = window.confirm(this.messageService.message(this.lang, pe_message, en_message));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6705, id: this.user_id }).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.matDialogRef.close();
            this.serverService.signout();
            this.message(true, this.messageService.change(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }
  //************************************************************************************************ */
  load_services(id: number): any {
    if (this.code == 219 && id == 155) {
      return false;
    }
    var where;
    var code_id;
    if (id == 155) {
      code_id = 6470;
    }
    if (id == 213) {
      code_id = 6568;
    }
    if (id == 156) {
      code_id = 6703;
    }
    if (id == 189) {
      code_id = 6596;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      data: {
        id: id, where: where, creator: this.creator, code_id: code_id, lang: this.lang
        , obj: { level: this.level, lang: this.lang }
      }
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (id == 233) {
            this.form1.patchValue({
              'state_title': result.title,
              'state': result.id
            })
          }
          if (id == 156) {
            this.form1.patchValue({
              'currency_title': result.title,
              'currency': result.id
            })
          }
          if (id == 189) {
            this.form1.patchValue({
              'invoice_title': result.title,
              'invoice': result.id
            })
          }
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 233) {
      this.form1.patchValue({
        'state_title': null,
        'state': null,
      })
    }
    if (id == 156) {
      this.form1.patchValue({
        'currency_title': null,
        'currency': null,
      })
    }
    if (id == 189) {
      this.form1.patchValue({
        'invoice_title': null,
        'invoice': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}