<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='load_data2("all")' [ngClass]="{active1:show == 'all'}">
                    <span>{{ messageService.get_all(lang) }}</span>
                </button>
                <button class="new1" (click)='load_data2("today")' [ngClass]="{active1:show == 'today'}">
                    <span>{{ messageService.get_today(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span *ngIf="lang == 1">ردیف</span>
                <span *ngIf="lang == 2">Row</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">نام کاربر</span>
                <span *ngIf="lang == 2">Username</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}" [ngClass]="{'en':dir == 'ltr'}">
                <div class="right" [ngClass]="{'fleft':dir == 'ltr','left':dir == 'ltr'}">{{ x.user_title }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">تاریخ ورود</span>
                <span *ngIf="lang == 2">Login date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_log_date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">ساعت ورود</span>
                <span *ngIf="lang == 2">Login time</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_log_time }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ip">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">آی پی</span>
                <span *ngIf="lang == 2">IP</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span [ngClass]="{'en':dir == 'ltr'}">{{ x.user_log_ip }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span *ngIf="lang == 1">شناسه</span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:70px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.user_log_id }}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{alarm:row.user_log_username1 != row.user_log_username2,'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>