<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    جستجوی کالا ها
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div style="text-align: right;">
        <button type="button" class="search_button" (click)="add()">درج جدید</button>
    </div>
    <input class="search" (keyup)="get_material(2)" [(ngModel)]="search">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>
        <mat-table [dataSource]="dataSource" style='min-width:100%'
            class='table-scroll mat_table_fa_without_scroll mat_table_fa_without_scroll_bg not- pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style="max-width:30px">
                    #
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width:30px">{{ i+1 }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="device">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span *ngIf="lang == 1"> دستگاه</span>
                    <span *ngIf="lang == 2">ِDevice</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_material_id,x.wharehouse_material_title)"
                    class="cursor" style="text-align: center;max-width:120px">
                    {{ x.wharehouse_device_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cate">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span *ngIf="lang == 1">شاخه</span>
                    <span *ngIf="lang == 2">Category</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_material_id,x.wharehouse_material_title)"
                    class="cursor" style="text-align: center;max-width:120px">
                    {{ x.wharehouse_material_cate_title }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="material">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان قطعه</span>
                    <span *ngIf="lang == 2">Component name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_material_id,x.wharehouse_material_title)"
                    class="cursor" style="text-align: center;">
                    {{ x.wharehouse_material_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="inventory">
                <mat-header-cell *matHeaderCellDef style="max-width:80px">
                    <span *ngIf="lang == 1">موجودی</span>
                    <span *ngIf="lang == 2">Stock</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_material_id,x.wharehouse_material_title)"
                    class="cursor" style="text-align: center;" style="max-width:80px">
                    {{ x.inventory }}</mat-cell>
            </ng-container>



            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let x;let i = index;columns:displayedColumns"
                (keyup.enter)="select(x.wharehouse_material_id,x.wharehouse_material_title)"></mat-row>
        </mat-table>
    </div>
    <!-- **************************************************************************************************** -->
</div>