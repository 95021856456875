<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span *ngIf="type_task == 2 && i == -1"> {{ serverService.get_title(1,lang) }} </span>
    <span *ngIf="type_task == 2 && i >= 0"> {{ serverService.get_title(2,lang) }} </span>
    <span *ngIf="type_task == 3"> <span>{{ serverService.get_title(type_task,lang) }}</span></span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video && ref == 'menu'" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row">
            <mat-radio-group aria-label="Select an option" formControlName="document" (change)="get_document()"
                [disabled]="readonly">
                <div class='pe div_panding div1' *ngFor="let x of list_document" [ngClass]="{'full-left':dir == 'ltr'}">
                    <mat-radio-button [required]="required()" [value]="x.finance_document_id" [disabled]=" 1 == 2 && x.finance_document_status == 2 || (x.finance_document_recieve == true && count_check_pay > 0) 
                        || (x.finance_document_pay == true && count_check_recieve > 0) ">
                        {{ x.finance_document_title }}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>


        <div class="row pe1">
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}" [hidden]="type_task == 3 ">
                <label for="date1" class='wh-label'>
                    <span *ngIf="lang == 1"> تاریخ سند : </span>
                    <span *ngIf="lang == 2"> Document date : </span>
                    <input class="wh-input" matInput [matDatepicker]="picker" formControlName="document_date"
                        autocomplete="off" [required]="required()" [ngClass]="{'en':dir == 'ltr'}"
                        [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}" [readonly]="readonly">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                        [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}" [hidden]="type_task != 3">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع سند : </span>
                    <span *ngIf="lang == 2"> Document type : </span>
                    <span (click)="reset_input(191)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="document_title" (click)="load_services(191)" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="document_id" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}"
                [hidden]="document_user == false || transfer == 1">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> نام طرف حساب : </span>
                    <span *ngIf="lang == 2"> Account holder name : </span>
                    <span (click)="add_user()" [hidden]="type_task == 3" [hidden]="readonly == true || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(161)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="open_user() "
                        [required]="required() && document != 16 && document != 18 && transfer == 0" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="user" [hidden]="true"
                        [required]="required() && document != 16 && document != 18 && transfer == 0" readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}"
                [hidden]="type_task != 3 || document_cost == false || transfer == 1">
                <label for="cost_group" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان گروه هزینه : </span>
                    <span *ngIf="lang == 2"> Expense group title : </span>
                    <span (click)="reset_input(254)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_group_title" (click)="load_services(254)" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cost_group" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}"
                [hidden]="document_cost == false || transfer == 1">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان هزینه : </span>
                    <span *ngIf="lang == 2"> Expense title : </span>
                    <span (click)="add_cost()" class="add_icon" [hidden]="type_task == 3"
                        [hidden]="readonly == true || type_task == 3" [ngClass]="{fright:dir == 'ltr'}">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(221)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_title" (click)="load_services(221)"
                        [required]="required() && document == 16" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cost" [hidden]="true"
                        [required]="required() && document == 16 " readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}"
                [hidden]="document_income == false || transfer == 1">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان درآمد : </span>
                    <span *ngIf="lang == 2"> Income title : </span>
                    <span (click)="add_income()" class="add_icon" [hidden]="type_task == 3"
                        [hidden]="readonly == true || type_task == 3" [ngClass]="{fright:dir == 'ltr'}">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(242)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="income_title" (click)="load_services(242) "
                        [required]="required() && document == 18" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="income" [hidden]="true"
                        [required]="required() && document == 18 " readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}" [hidden]="transfer == 1">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات : </span>
                    <span *ngIf="lang == 2"> Description : </span>
                    <input class="wh-input" formControlName="comment" [ngClass]="{'en':dir == 'ltr'}"
                        [readonly]="readonly">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}" [hidden]="type_task != 3">
                <label for="issue_date" class="wh-label">
                    <span *ngIf="lang == 1"> تاریخ از : </span>
                    <span *ngIf="lang == 2"> Date from : </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker3" formControlName="date3" autocomplete="off"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}" [ngClass]="{en:dir == 'ltr'}">
                        <mat-datepicker-toggle matSuffix [for]="picker3" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':lang==2,width100:type_task == 3}" [hidden]="type_task != 3">
                <label for="issue_date" class="wh-label">
                    <span *ngIf="lang == 1"> تا تازیخ : </span>
                    <span *ngIf="lang == 2"> Date to : </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker4" formControlName="date4" autocomplete="off"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}" [ngClass]="{en:dir == 'ltr'}">
                        <mat-datepicker-toggle matSuffix [for]="picker4" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </div>
                </label>
            </div>


            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off">
                </label>
            </div>

        </div><!-- row -->

        <div class="row" *ngIf="type_task != 3">
            <div style="text-align:right;padding:0px;margin-bottom: 5px;" [ngClass]="{'left':dir == 'ltr'}">
                <button type="button" class="button_default" (click)='open_detaile(0,1,0,1,0,false,false)'
                    [hidden]="readonly">
                    <span *ngIf="lang == 1">ثبت تراکنش مالی</span>
                    <span *ngIf="lang == 2">Record financial transaction</span>
                </button>
                <button type="button" class="button_default" (click)='select_cheque(1)'
                    *ngIf="document_type == 2 && transfer == 0" [hidden]="readonly" [hidden]="true">
                    <span *ngIf="lang == 1">لیست چک های دریافتی</span>
                    <span *ngIf="lang == 2">List of received checks</span>
                </button>
                <ng-container *ngIf="transfer == 0">
                    <button type="button" class="button_default" (click)='open_cheque_recieve_detaile(0,1,0)'
                        [hidden]="readonly" *ngIf="document_type == 1">
                        <span>
                            <span *ngIf="lang == 1">دریافت چک</span>
                            <span *ngIf="lang == 2">Receive check</span>
                        </span>
                    </button>
                    <button type="button" class="button_default" (click)='open_cheque_pay_detaile(0,1 ,0)'
                        [hidden]="readonly" *ngIf="document_type == 2">
                        <span>
                            <span *ngIf="lang == 1">صدور چک</span>
                            <span *ngIf="lang == 2">Write a check</span>
                        </span>
                    </button>
                </ng-container>
            </div>

            <div class="content-body1" class="content-body control-content-body"
                [ngClass]="{content_body_en:dir=='ltr'}">
                <mat-table [dataSource]="dataSource" style='min-width:100%'
                    class='table-scroll mat_table_fa_without_scroll pe1'
                    [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
                    [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                    <ng-container matColumnDef="row">
                        <mat-header-cell *matHeaderCellDef style='max-width:30px'>
                            <span>#</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:30px'
                            [ngClass]="{'en':dir == 'ltr'}">{{ i + 1 }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="payment_type">
                        <mat-header-cell *matHeaderCellDef [hidden]="transfer == 1" style="max-width:130px">
                            <span *ngIf="lang == 1">نوع تراکنش</span>
                            <span *ngIf="lang == 2">Type of transaction</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            [hidden]="transfer == 1" style="max-width:130px" [ngClass]="{'en':dir == 'ltr'}">{{
                            x.finance_payment_type_title }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef>
                            <span *ngIf="lang == 1">مبلغ</span>
                            <span *ngIf="lang == 2">Amount</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            [ngClass]="{'en':dir == 'ltr'}">
                            {{
                            x.finance_financial2_amount | number }}</mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="transfer == 1">
                            <span *ngIf="lang == 1">نوع چک</span>
                            <span *ngIf="lang == 2">Type of check</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            style="max-width:100px" [hidden]="transfer == 1" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.site_cheque_type_title }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bank">
                        <mat-header-cell *matHeaderCellDef>
                            <span *ngIf="lang == 1"> بانک</span>
                            <span *ngIf="lang == 2"> Bank </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.site_bank_title | substr:0:15}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef>
                            <span *ngIf="lang == 1">شماره حساب</span>
                            <span *ngIf="lang == 2"> Account number </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.accounts_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cheque">
                        <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="transfer == 1">
                            <span *ngIf="lang == 1"> شماره چک </span>
                            <span *ngIf="lang == 2"> Check number </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            style="max-width:100px" [hidden]="transfer == 1" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.finance_financial2_cheque_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="due">
                        <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="transfer == 1">
                            <span *ngIf="lang == 1">تاریخ سررسید</span>
                            <span *ngIf="lang == 2">Due date</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            style="max-width:100px" [hidden]="transfer == 1" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.due_date }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef [hidden]="transfer == 1">
                            <span *ngIf="lang == 1">وضعیت چک</span>
                            <span *ngIf="lang == 2">Check status</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_financial2_id,x.site_cheque_id,x.site_cheque_type,x.footer,x.finance_financial2_cheque_myself)"
                            [hidden]="transfer == 1" [ngClass]="{'en':dir == 'ltr'}">
                            {{
                            x.site_cheque_status_title }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <mat-header-cell *matHeaderCellDef style='max-width:65px' [hidden]="readonly">
                            <span *ngIf="lang == 1"> عملیات </span>
                            <span *ngIf="lang == 2">Operation</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:65px' [hidden]="readonly">
                            <span [hidden]="x.footer == true">
                                <span (click)="check_access2(i,3,0,x.finance_financial2_id,0,1,x.site_cheque_id)"><i
                                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                    </mat-header-row>
                    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                        (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                        [ngStyle]="{backgroundColor: 1==1 ? row.color : 'transparent' }"
                        [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,'mat_table_hover': row === mat_table_hoverRow}"
                        class="cursor">
                    </mat-row>

                </mat-table>
            </div>

            <div style="text-align: right;padding-top:1rem;" [ngClass]="{'full-left':dir == 'ltr'}">
                <span *ngIf="lang == 1"> جمع کل به عدد : </span>
                <span *ngIf="lang == 2"> Sum total in numbers : </span>
                <span [ngClass]="{'en':dir == 'ltr'}">{{ amount | number }} </span>
                <span>{{ currency_title }}</span>
                <span *ngIf="lang == 1"> به حروف </span>
                <span *ngIf="lang == 1"> {{ sum_all_letter }} </span>
                <span *ngIf="lang == 1"> {{ currency_title }} </span>
            </div>
        </div>

    </div>

    <div mat-dialog-actions class="matDialogActions">

        <div class="row" style="width: 100%;">

            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 hidden-xs" [hidden]="type_task == 3"
                style="text-align: right;" [ngClass]="{'full-left':dir == 'ltr'}">
                <div *ngIf="document_user == true">
                    <div class="remain" *ngIf="lang == 1"> مانده حساب از قبل به عدد </div>
                    <div class="remain" *ngIf="lang == 2"> Old debit/ credit </div>
                    <div class="remain">
                        <div style="display: inline-block;direction: ltr;" [ngClass]="{'en':dir == 'ltr'}"> {{ remain |
                            number }} </div>
                    </div>
                    <div class="remain"> {{ currency_title }} </div>
                    <div class="remain" *ngIf="lang == 1"> به حروف </div>
                    <div class="remain" *ngIf="lang == 1">{{ remain_letter }}</div>
                    <div class="remain" *ngIf="lang == 1"> {{ currency_title }} </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12" [ngClass]="{width100:type_task == 3}"
                style="text-align: right;padding-right:0px" [hidden]="readonly">
                <button type="submit" [disabled]="!form1_group.valid || (i == -1 && list_record.length == 0)"
                    class="btn_pe" [ngClass]="{btn_en:lang == 2}" [hidden]="readonly"
                    *ngIf="type_task == 1 || type_task == 2">
                    <span *ngIf="lang == 2">Save</span>
                    <span *ngIf="lang == 1">ذخیره</span>
                </button>
                <button type="button" (click)="search(1)" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
                    *ngIf="type_task == 3">
                    <span *ngIf="lang == 2">Search</span>
                    <span *ngIf="lang == 1">جستجو</span>
                </button>
            </div>

        </div>
    </div>
</form>