<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                -
                <span class="filter" *ngIf="level == 1 || similar_admin">
                    <button (click)='load_data2("active",0)' [ngClass]="{active1:tsearch == 'active' && free == 0}">
                        <span *ngIf="lang == 1">فعال رایگان</span>
                        <span *ngIf="lang == 2">Active (free)</span>
                    </button>
                    <button (click)='load_data2("active",1)' [ngClass]="{active1:tsearch == 'active' && free == 1}">
                        <span *ngIf="lang == 1">فعال شارژی</span>
                        <span *ngIf="lang == 2">Active (paid)</span>
                    </button>
                    <button (click)='load_data2("expire",0)' [ngClass]="{active1:tsearch == 'expire' && free == 0}">
                        <span *ngIf="lang == 1">منقضی رایگان</span>
                        <span *ngIf="lang == 2">Expired (free)</span>
                    </button>
                    <button (click)='load_data2("expire",1)' [ngClass]="{active1:tsearch == 'expire' && free == 1}">
                        <span *ngIf="lang == 1">منقضی شارژی</span>
                        <span *ngIf="lang == 2">Expired (paid)</span>
                    </button>
                </span>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="show_hiddens()">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 "
                (click)="save_id2(x.user_id)" [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="similar_admin">
            <mat-header-cell *matHeaderCellDef style='max-width:100px'
                [hidden]="level != 1 || show_status_similar_admin == false">
                <span *ngIf="lang == 1">شبیه ادمین</span>
                <span *ngIf="lang == 2">Pseudo admin</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px'
                [hidden]="level != 1 || show_status_similar_admin == false">
                <span (click)='change_similar_admin(i,x.user_similar_admin,x.user_id)'>
                    <span *ngIf="x.user_similar_admin == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.user_similar_admin == 1"><i class="fas fa-star icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'
                [hidden]="level != 1 || show_status_similar_admin == false"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="clear_token">
            <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="level > 1">
                <span *ngIf="lang == 1">تغییر دادن توکن</span>
                <span *ngIf="lang == 2">Change token</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px;padding:0.2rem !important'
                [hidden]="level > 1">
                <span (click)='clear_token(i,x.user_id)'>
                    <mat-icon>delete_sweep</mat-icon>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px' [hidden]="level > 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="guilds">
            <mat-header-cell *matHeaderCellDef style='max-width:250px;min-width:250px;' [hidden]="level > 1">
                <span *ngIf="lang == 1">اصناف</span>
                <span *ngIf="lang == 2">Guild</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:250px;min-width:250px;' class="cell1"
                (click)='open_detaile(i,2,x.user_id)' [hidden]="level > 1">
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ x.site_guilds_title |
                    substr:0:30 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:250px;min-width:250px;'
                [hidden]="level > 1"></mat-footer-cell>
        </ng-container>


        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 350px;">
                <span *ngIf="lang == 1">نام کاربر</span>
                <span *ngIf="lang == 2">Name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 350px;">
                <div class="div2 right" (click)='open_detaile(i,2,x.user_id)' style="float: right;"
                    [ngClass]="{'fleft':dir == 'ltr' , 'left' : dir == 'ltr','en':dir == 'ltr' }">
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <span (click)="go_to_user2(x.user_id,x.user_token_login)" *ngIf="level == 1" class="cursor"
                    [ngClass]="{'fright':dir == 'ltr' , 'fleft' : dir == 'rtl' }">
                    <i class="fa fa-eye icon"></i>
                </span>
                <span *ngIf="level == 1" [ngClass]="{'fright':dir == 'ltr' , 'fleft' : dir == 'rtl' }"
                    (click)="send_sms(x.user_cellPhone,x.user_username,x.user_repassword)">
                    <i class="far fa-comments"></i>
                </span>
                <span (click)="open_Menu(x.user_id)" [ngClass]="{'fright':dir == 'ltr' , 'fleft' : dir == 'rtl' }"
                    [matMenuTriggerFor]="menu" [hidden]="level == 1">
                    <i class="fas fa-info-circle icon"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 350px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="store">
            <mat-header-cell *matHeaderCellDef style="min-width: 200px;" [hidden]="code == 219">
                <span *ngIf="lang == 1">نام شرکت / فروشگاه</span>
                <span *ngIf="lang == 2">Company/ Store name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 200px;"
                (click)='open_detaile(i,2,x.user_id)' [hidden]="code == 219">
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ x.user_store }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 200px;" [hidden]="code == 219"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">شماره همراه</span>
                <span *ngIf="lang == 2">Cellphone number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                class="center">
                <div *ngIf="show_cellphone == true" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="copy">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">مقادیر پیش فرض</span>
                <span *ngIf="lang == 2">Default values</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="cell1" [hidden]="level != 1">
                <div *ngIf="x.user_copy == false">
                    <span (click)="accept(i,6140,x.user_id,x.site_guilds_user)" *ngIf="!loading_copy">
                        <mat-icon style="font-size: 1rem;">content_copy</mat-icon>
                    </span>
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading_copy">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="x.user_copy == true"> <i class="fas fa-check icon" style="color:green;"></i> </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="last_login">
            <mat-header-cell *matHeaderCellDef style="max-width: 140px;min-width: 140px;"
                [hidden]="code == 161 && level != 1">
                <span *ngIf="lang == 1">آخرین تاریخ ورود</span>
                <span *ngIf="lang == 2">Last login date</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="max-width: 140px;min-width: 140px;"
                [ngStyle]="{background:date == x.user_last_login ? '#a7e9a7' : 'transparent' }"
                (click)='open_detaile(i,2,x.user_id)' [hidden]="code == 161 && level != 1"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.user_last_time }} - {{ x.user_last_login }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 140px;min-width: 140px;"
                [hidden]="code == 161 && level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef style="padding-top:0.3rem !important"
                [hidden]="code == 219 || level != 1">
                <span *ngIf="lang == 1">شارژ ماهیانه</span>
                <span *ngIf="lang == 2">Monthly fee</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="code == 219 || level != 1" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.user_amount | number}} {{ x.site_currency_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ref">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">محل درج</span>
                <span *ngIf="lang == 2">Place insert</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1">
                <span *ngIf="x.user_ref == 1 && lang == 1">سایت</span>
                <span *ngIf="x.user_ref == 1 && lang == 2">Site</span>
                <span *ngIf="x.user_ref == 2  && lang == 1">اکسل</span>
                <span *ngIf="x.user_ref == 2  && lang == 2">Excel</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="moarf_id">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1 || code != 161 || moarf != 'moarf_id'">
                <span *ngIf="lang == 1">معرف</span>
                <span *ngIf="lang == 2">Referrer</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1 || code != 161 || moarf != 'moarf_id'">
                <div class="right">{{ x.moarf_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef
                [hidden]="level != 1 || code != 161 || moarf != 'moarf_id'"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1 && code != 219">
                <span *ngIf="lang == 1">نام کاربری</span>
                <span *ngIf="lang == 2">Username</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1 && code != 219">
                <div class="left" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_username }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1 && code != 219"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">کشور</span>
                <span *ngIf="lang == 2">country</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1">
                <div>{{ x.user_country_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1 && code != 219"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">استان</span>
                <span *ngIf="lang == 2">State/ Province</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1">
                <div>{{ x.site_state_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1 && code != 219"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sms">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">شارژ پیامک</span>
                <span *ngIf="lang == 2">SMS credit</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="level != 1" [ngClass]="{'red':x.user_sms_bought < 0}" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.user_sms_bought | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{ sharj_all |
                number }}</mat-footer-cell>
        </ng-container>

        <mat-menu #menu="matMenu" class="users-mat-menu-panel"
            style="max-width:42rem !important;min-width:42rem !important" xPosition="before">
            <div class="row" (click)="$event.stopPropagation()">
                <table class="table table-bordered">
                    <tr>
                        <td (click)="open_first_balance()" class="cursor">
                            <span *ngIf="lang == 1">مانده اول دوره</span>
                            <span *ngIf="lang == 2">Old debit/ credit</span>
                        </td>
                    </tr>
                    <tr>
                        <td (click)="open_user_cardex()" class="cursor">
                            <span *ngIf="lang == 1">کاردکس اشخاص</span>
                            <span *ngIf="lang == 2">Client card index</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor">
                            <span *ngIf="lang == 1">درج مالی</span>
                            <span *ngIf="lang == 2">Financial entry</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor">
                            <span *ngIf="lang == 1">صدور فاکتور</span>
                            <span *ngIf="lang == 2">Invoice issuance</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor">
                            <span *ngIf="lang == 1">پذیرش تعمیرگاه</span>
                            <span *ngIf="lang == 2">Repair shop reception</span>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-menu>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">تاریخ ثبت نام</span>
                <span *ngIf="lang == 2">register date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="expire">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">تاریخ انقضا</span>
                <span *ngIf="lang == 2">Expiration date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_expire_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="access">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">دسترسی</span>
                <span *ngIf="lang == 2">Access</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_count_access }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="reception">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">پذیرش</span>
                <span *ngIf="lang == 2">Reception</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.count_reception }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">فاکتورها</span>
                <span *ngIf="lang == 2">Invoices</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.count_invoice }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="finance">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">امور مالی</span>
                <span *ngIf="lang == 2">Financial affairs</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.count_finance }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">گروههای کاربری</span>
                <span *ngIf="lang == 2">User groups</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_groups_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="profit_sale">
            <mat-header-cell *matHeaderCellDef [hidden]="level == 1 || code == 161">
                <span *ngIf="lang == 1">سود فروش</span>
                <span *ngIf="lang == 2">Sales profit</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level == 1 || code == 161" [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_profit_sale }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level == 1 || code == 161"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="profit_repairman">
            <mat-header-cell *matHeaderCellDef [hidden]="level == 1 || code == 161">
                <span *ngIf="lang == 1">سود تعمیرات</span>
                <span *ngIf="lang == 2">Repair profit</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="level == 1 || code == 161" [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_profit_repairman
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level == 1 || code == 161"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">شناسه</span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:50px'>
                <span *ngIf="lang == 1">عملیات</span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:50px' class="cell1">
                <span (click)="check_access(3,0,x.user_id,0,1)" style="color:red"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:50px'></mat-footer-cell>
        </ng-container>
        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow  || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>