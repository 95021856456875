<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
        (window:resize)="change_resize()">
        <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'
            [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 "
                    [ngClass]="{'en':dir == 'ltr'}">{{ i +
                    page_number_first
                    }}</mat-cell>
                <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef style="min-width: 180px;">
                    <span *ngIf="lang == 1">نام طرف حساب</span>
                    <span *ngIf="lang == 2">Client name</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 180px;">
                    <div class="div2 right" (click)='open_detaile(i,2,x.user_id)' style="float: right;"
                        [ngClass]="{'fleft':dir == 'ltr' , 'left' : dir == 'ltr' , 'en' : dir == 'ltr'  }">
                        {{ x.user_title }}
                    </div>
                    <span (click)="show_financial2(i,x.user_id)" class="cursor"
                        [ngClass]="{'fright':dir == 'ltr' , 'fleft' : dir == 'rtl' }">
                        <i class="fa fa-eye icon"></i>
                    </span>
                    <span *ngIf="code == 209" [ngClass]="{'fright':dir == 'ltr' , 'fleft' : dir == 'rtl' }"
                        (click)="check_charge_sms(x.balance,x.user_cellPhone, x.user_sex_title + ' ' + x.user_title , x.user_store)">
                        <i class=" far fa-comments"></i>
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef style="min-width: 180px;"></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="cellphone">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره همراه</span>
                    <span *ngIf="lang == 2">Cellphone number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" class="cell1">
                    <div *ngIf="show_cellphone == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_cellPhone }}</div>
                    <div *ngIf="show_cellphone == false" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.user_cellPhone | hide_cellphone
                        }}</div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="bed">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">بدهکار</span>
                    <span *ngIf="lang == 2">Debtor</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.bed | number }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef [ngClass]="{'en':dir == 'ltr'}">{{ bed | number }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="best">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">بستانکار</span>
                    <span *ngIf="lang == 2">Creditor</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.best | number }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef [ngClass]="{'en':dir == 'ltr'}">{{ best | number }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="remain">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">مانده حساب</span>
                    <span *ngIf="lang == 2">Account balance</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index">
                    <div class="right money" style="display: inline;float: right;" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.balance | number }}</div>
                    <div class="icons" style="margin-top:-4px">
                        <span (click)="new(i,x.user_id,x.balance)" [ngClass]="{'en':dir == 'ltr'}">
                            <mat-icon>paid</mat-icon>
                        </span>
                    </div>
                </mat-cell>

                <mat-footer-cell *matFooterCellDef>
                    <div class="right">
                        <div style="display:inline-block" [ngClass]="{'en':dir == 'ltr'}">{{ balance | number }}</div>
                    </div>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
            <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
                *matFooterRowDef="displayedColumns;sticky : true">
            </mat-footer-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>

    </div>