<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>


<div class="row" style="direction: rtl;display: flex !important;">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="جستجو..." autocomplete="off" style="width:100%">
    </div>

    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button (click)='change_task(1,1,"",1,"","")' [disabled]='new_status' *ngIf="lang == 1">جديد</button>
                <button (click)='change_task(1,1,"",1,"","")' [disabled]='new_status' *ngIf="lang == 2"
                    [ngClass]="{en_navigation_number:lang==2}">New</button>
                <button (click)='change_task(3,3,"",1,"","")' *ngIf="lang == 1">فیلتر</button>
                <button (click)='change_task(3,3,"",1,"","")' *ngIf="lang == 2"
                    [ngClass]="{en_navigation_number:lang==2}">Search</button>
                <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                        class="fas fa-fast-forward"></i></button>
                <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                        class="fas fa-forward"></i></button>
                <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                        class="fas fa-backward"></i></button>
                <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                        class="fas fa-fast-backward"></i></button>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="material-icons pager_icons cursor" (click)="check_access(3,0,selected_record,1,1)">
                delete
            </div>
            <div style="display: inline-block;">
                <mat-checkbox (change)="get_select($event.checked)" [(indeterminate)]="indeterminate"></mat-checkbox>
            </div>
        </div>
    </div>
</div>
<div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
    style='overflow-x:auto;direction:rtl;' (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
        [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}">
        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span *ngIf="lang == 1">ردیف</span>
                <span *ngIf="lang == 2">Row</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">عنوان</span>
                <span *ngIf="lang == 2">Title</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.finance_income_type_id,x.finance_income_type_title,x.status_id,x.finance_income_type_sort)'>
                <div class="right">{{ x.finance_income_type_title }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef [hidden]="true">
                <span *ngIf="lang == 1">ترتیب</span>
                <span *ngIf="lang == 2">Sort</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="true">{{ x.finance_income_type_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1"> وضعیت </span>
                <span *ngIf="lang == 2"> Status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.finance_income_type_id,x.finance_income_type_title,x.status_id,x.finance_income_type_sort)'>
                <span>{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:60px">
                <span *ngIf="lang == 1"> شناسه </span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.finance_income_type_id,x.finance_income_type_title,x.status_id,x.finance_income_type_sort)'
                style="max-width:60px">
                {{ x.finance_income_type_id }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span *ngIf="lang == 1"> عملیات </span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>
                <span
                    (click)='change_task(2,x.finance_income_type_id,x.finance_income_type_title,x.status_id,x.finance_income_type_sort)'><i
                        class="fas fa-desktop icon"></i></span>
                <mat-checkbox class="operation-icon" (change)="get_select_records(i)" name="selete_records[]"
                    [value]="x.finance_income_type_id" [checked]="x.checked"></mat-checkbox>
                <span class="operation-icon" (click)="check_access(3,0,x.finance_income_type_id,0,1)"><i
                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ no_record }}</div>
        </div>
    </div>

</div>


<ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_group">
        <div style='text-align:center;padding:12px !important'>
            <span>{{ title }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان : </span>
                    <span *ngIf="lang == 2"> Title : </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت : </span>
                    <span *ngIf="lang == 2"> Status : </span>
                    <select class='wh-input' formControlName="status_id" required>
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>
        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'
                *ngIf="lang == 1 ">
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 1'
                    (click)="check_access(1,0,0,0,1)" [disabled]="!form1_group.valid">ارسال و ذخيره</button>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 2'
                    (click)="check_access(2,0,0,0,1)" [disabled]="!form1_group.valid">ويرايش</button>
            </div>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'
                *ngIf="lang == 2 ">
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 1'
                    (click)="check_access(1,0,0,0,1)" [disabled]="!form1_group.valid"> Save </button>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 2'
                    (click)="check_access(2,0,0,0,1)" [disabled]="!form1_group.valid">Update</button>
            </div>
        </div>
    </form>
</ngx-smart-modal>

<ngx-smart-modal #myModalSearch identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_search_group">
        <div style='text-align:center;padding:12px !important'>{{ title }}</div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title_search" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان : </span>
                    <span *ngIf="lang == 2"> Title : </span>
                    <input class='wh-input' formControlName='title_search' autocomplete="off">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id_search" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت : </span>
                    <span *ngIf="lang == 2"> Status : </span>
                    <select class='wh-input' formControlName='status_id_search' pattern="[0-9]{1,}">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>

        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12'
                style='margin-top:5px;text-align:center;font-family:irsans' *ngIf="lang == 1 ">
                <button class="btn_pe" (click)="check_access(4,1,0,1,1)">جستجو</button>
            </div>
            <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center;font-family:en'
                *ngIf="lang == 2 ">
                <button class="btn_en" (click)="check_access(4,1,0,1,1)">Search</button>
            </div>

        </div>
    </form>
</ngx-smart-modal>