<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span *ngIf="lang == 1">لیست کالا</span>
    <span *ngIf="lang == 2">Product list</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent matDialogContent1">
    <div [ngStyle]="{'text-align':dir == 'ltr' ? 'left' : 'right'}">
        <span class="hidden-xs">
            <button (click)="which(2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
            <button (click)="which(3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        </span>
        <button type="button" (click)='get_material3()'
            [ngClass]="{button_active:type_list == 'material','full-left':dir == 'ltr'}" class="invoice_button2">
            <span *ngIf="lang == 1">لیست کالاها</span>
            <span *ngIf="lang == 2">Product list</span>
            {{ count_total_material | number}}
        </button>
        <button type="button" (click)='get_services3()'
            [ngClass]="{button_active:type_list == 'services','full-left':dir == 'ltr'}" class="invoice_button2"
            *ngIf="document_type_text == 'exit'">
            <span *ngIf="lang == 1">لیست خدمات</span>
            <span *ngIf="lang == 2">Service list</span>
            {{ count_total_service | number }}
            <span></span>
        </button>
        <button type="button" class="invoice_button2" [ngClass]="{'full-left':dir == 'ltr'}">
            <span *ngIf="document_type_text == 'exit' && back == 0 " (click)='get_order()'>
                <span *ngIf="lang == 1"> سبد فروش </span>
                <span *ngIf="lang == 2"> Sales cart </span>
            </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ count_order }}</span>
        </button>

        <button type="button" class="search_button hidden-xs" (click)="add()" *ngIf="type_list == 'material'">
            <span *ngIf="lang == 1">تعریف کالای جدید</span>
            <span *ngIf="lang == 2">Definition of new product</span>
        </button>
        <button type="button" class="search_button" (click)="add()" *ngIf="type_list == 'services'">
            <span *ngIf="lang == 1">تعریف خدمات جدید</span>
            <span *ngIf="lang == 2">Add new service</span>
        </button>
    </div>

    <div class="row" *ngIf="type_list == 'material'">
        <input class="search" (keyup)="get_material2()" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">

        <div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}">

            <mat-table [dataSource]="dataSource" style='min-width:100%'
                class='table-scroll mat_table_fa_without_scroll pe1'
                [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef style="text-align: center !important;min-width: 150px;"
                        class="right">
                        <span *ngIf="lang == 1">عنوان کالا</span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor right" style="min-width: 150px;">
                        <span class="paddingRight" [ngClass]="{'full-left':dir == 'ltr'}">
                            {{ x.wharehouse_material_title }} | {{ x.wharehouse_group_title | substr:0:20
                            }} | {{ x.wharehouse_material_cate_title | substr:0:20 }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="seller">
                    <mat-header-cell *matHeaderCellDef style="max-width:150px" [hidden]="document_type_text != 'exit'">
                        <span *ngIf="lang == 1">فروشنده</span>
                        <span *ngIf="lang == 2">Salesman</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor"
                        style="text-align: center;height:2rem;padding:0px !important;max-width:150px"
                        [hidden]="document_type_text != 'exit'">
                        <select class="select" id="repairman{{ x.wharehouse_order_id  }}"
                            [ngClass]="{'full-left':dir == 'ltr'}">
                            <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                                [value]="repairman.user_id">{{
                                repairman.user_title }}</option>
                        </select>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="inventory">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px">
                        <span *ngIf="lang == 1">موجودی</span>
                        <span *ngIf="lang == 2">Stock</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important" [hidden]="document_type_text == 'input'">
                        <input class=" wh-input input1" id="inventory1{{ x.wharehouse_order_id }}" currencyMask
                            [value]="x.inventory | number" autocomplete="off" readonly [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]='true'>
                        <span *ngIf="lang == 1">ش درخواست</span>
                        <span *ngIf="lang == 2">Request no</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important" [hidden]='true'>
                        <input class=" wh-input input1" id="order1{{ x.wharehouse_order_id }}" autocomplete="off"
                            readonly [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:120px"
                        [hidden]="document_type_text == 'request' || access_price_column_buy == false">
                        <span *ngIf="lang == 1">قیمت خرید</span>
                        <span *ngIf="lang == 2">Purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:120px;padding:0px !important"
                        [hidden]="document_type_text == 'request' || access_price_column_buy == false">
                        <input class=" wh-input input1" id="invoice_price1{{ x.wharehouse_order_id }}" currencyMask
                            [ngModel]="x.wharehouse_order_cost" autocomplete="off"
                            [readonly]="document_type_text != 'input'" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:60px">
                        <span *ngIf="lang == 1">تعداد </span>
                        <span *ngIf="lang == 2">Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:60px;padding:0px !important">
                        <input type="number" class=" wh-input input1" id="input1{{ x.wharehouse_order_id }}" [value]="1"
                            autocomplete="off" min="0" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef style="max-width:110px">
                        <span *ngIf="lang == 1"> قیمت فروش</span>
                        <span *ngIf="lang == 2">Selling price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:110px;padding:0px !important">
                        <input class="wh-input input1" id="cost1{{ x.wharehouse_order_id }}" currencyMask
                            autocomplete="off" [ngModel]="x.wharehouse_material_price2" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request' ">
                        <span *ngIf="lang == 1">تخفیف</span>
                        <span *ngIf="lang == 2">Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:80px;padding:0px !important"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request'">
                        <input class="wh-input input1" id="discount1{{ x.wharehouse_order_id }}" currencyMask
                            autocomplete="off" [ngModel]="0" [disabled]="back == true" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:60px">
                        <span *ngIf="lang == 1">اضافه </span>
                        <span *ngIf="lang == 2"> Add </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                        style="max-width:60px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline"
                            (click)="save(i,x.wharehouse_material_id,x.wharehouse_order_id, 1 , 1 , 0)"
                            style="vertical-align: middle;font-size: 20px;" *ngIf="ref == 'invoice' ">
                            add_circle_outline
                        </mat-icon>
                        <mat-icon class="add_circle_outline" (click)="request_material(x.wharehouse_material_id)"
                            style="vertical-align: middle;font-size: 20px;" *ngIf="ref == 'requests' ">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
                <mat-row *matRowDef="let x;let i = index;columns:displayedColumns2"></mat-row>
            </mat-table>

        </div>
    </div>

    <div class="row" *ngIf="type_list == 'services'">
        <input class="search" (keyup)="get_services2(2)" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'full-left':dir == 'ltr'}">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngClass]="{en1:lang == 2}" [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i+
                        page_number_first }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef style="min-width:150px">
                        <span *ngIf="lang == 1">عنوان سرویس</span>
                        <span *ngIf="lang == 2">Service name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;min-width:150px"
                        [ngClass]="{'en':dir == 'ltr'}">
                        <div class="right" [ngClass]="{'left':dir == 'ltr'}">{{ x.repairman_services_title }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="repairman">
                    <mat-header-cell *matHeaderCellDef style="max-width:150px">
                        <span *ngIf="lang == 1">تعمیرکار</span>
                        <span *ngIf="lang == 2">Repairman</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor"
                        style="text-align: center;height:2rem;padding:0px !important;max-width:150px">
                        <select class="select" id="repairman{{ x.repairman_services_id  }}"
                            [ngClass]="{'full-left':dir == 'ltr'}">
                            <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                                [value]="repairman.user_id">{{
                                repairman.user_title }}</option>
                        </select>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span *ngIf="lang == 1">تعداد </span>
                        <span *ngIf="lang == 2">Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        <input type="number" class="wh-input input1" id="input2{{ x.repairman_services_id }}"
                            [value]="1" autocomplete="off" currencyMask>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px">
                        <span *ngIf="lang == 1">قیمت </span>
                        <span *ngIf="lang == 2">Price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important">
                        <input class="wh-input input1" id="cost2{{ x.repairman_services_id }}"
                            [ngModel]="x.repairman_services_cost" currencyMask autocomplete="off" currencyMask
                            [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="comment">
                    <mat-header-cell *matHeaderCellDef style="max-width:400px">
                        <span *ngIf="lang == 1">توضیحات</span>
                        <span *ngIf="lang == 2">Description</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:400px;padding:0px !important">
                        <input class="wh-input input1" id="comment{{ x.repairman_services_id }}" autocomplete="off"
                            style="text-align: right;" [ngClass]="{'full-left':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span *ngIf="lang == 1">اضافه کردن</span>
                        <span *ngIf="lang == 2">َAdd</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline"
                            (click)="add_to_bascket_exit(i,x.repairman_services_id,0,1,2,x.repairman_services_cost,0,0,0)"
                            style="vertical-align: middle;font-size: 20px;">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns3" color="primary"></mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns3;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor"></mat-row>
            </mat-table>
        </div>
    </div>
    <!-- **************************************************************************************************** -->
    <div class="row" *ngIf="type_list != 'material' && type_list != 'services'">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngClass]="{en1:lang == 2}" [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '70px' : 'auto' }">
                        <span *ngIf="lang == 1">نوع</span>
                        <span *ngIf="lang == 2">Type</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '70px' : 'auto' }">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.wharehouse_material_type_title }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }">
                        <span *ngIf="lang == 1">نام کالا
                            <span *ngIf="document_type_text == 'exit'"> و خدمات </span>
                        </span>
                        <span *ngIf="lang == 2"> Product
                            <span *ngIf="document_type_text == 'exit'"> and service name </span>
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;"
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.title }}
                            <span *ngIf="x.wharehouse_order_material_type == 1">| {{ x.wharehouse_material_cate_title
                                }}</span>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef [hidden]="true">
                        <span *ngIf="lang == 1">مدل قطعه</span>
                        <span *ngIf="lang == 2">Component model</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [hidden]="true">
                        {{ x.title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '90px' : 'auto' }">
                        <span *ngIf="lang == 1">تعداد</span>
                        <span *ngIf="lang == 2">Number</span>
                        <span *ngIf="document_type_text == 'request' ">
                            <span *ngIf="lang == 1">درخواستی</span>
                            <span *ngIf="lang == 2">requested</span>
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '90px' : 'auto' }"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span *ngIf="lang == 1">واحد شمارش</span>
                        <span *ngIf="lang == 2">Counting unit</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width:100px" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_weight_title}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef style="max-width: 120px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">قیمت واحد</span>
                        <span *ngIf="lang == 2">Unit price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 120px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_cost | number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width: 120px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">تخفیف</span>
                        <span *ngIf="lang == 2">Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 120px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_discount | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sum">
                    <mat-header-cell *matHeaderCellDef style="max-width: 120px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">جمع</span>
                        <span *ngIf="lang == 2">Total</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 120px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.sum | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="repairman">
                    <mat-header-cell *matHeaderCellDef style="max-width: 140px;">
                        <span *ngIf="lang == 1">تعمیرکار/فروشنده</span>
                        <span *ngIf="lang == 2">Repairman/ Salesman</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 140px;">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.repairman_title }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="searial">
                    <mat-header-cell *matHeaderCellDef style="max-width: 70px;"
                        [hidden]="document_type_text == 'input' || back == 1 ">
                        <span *ngIf="lang == 1">سریالها</span>
                        <span *ngIf="lang == 2">Serial numbers</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="open_serial(i , x.wharehouse_order_id,x.title,x.wharehouse_order_consumed_invoice)"
                        style="max-width: 70px;" [hidden]="document_type_text == 'input' || back == 1 "
                        [ngClass]="{bg2:x.searial == true}">
                        <span *ngIf="x.wharehouse_order_material_type == 1">
                            <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="operation">
                    <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly">
                        <span *ngIf="lang == 1">عملیات</span>
                        <span *ngIf="lang == 2">Operation</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly">
                        <span
                            (click)='check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)'><i
                                class="fas fa-desktop icon"></i></span>
                        <!-- x.wharehouse_order_material,x.wharehouse_order_status_add -->
                        <span
                            (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_material,x.wharehouse_order_trash,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            *ngIf="document_type_text != 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>
                        <span (click)="delete_request(x.wharehouse_order_id)"
                            *ngIf="document_type_text == 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>
                    </mat-cell>
                </ng-container>

                <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                </mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor"></mat-row>

            </mat-table>
        </div>
        <div style="text-align: right;padding-top:1rem;" [hidden]="document_type_text == 'request' "
            [ngClass]="{'full-left':dir == 'ltr'}">
            <span *ngIf="lang == 1"> جمع کل به عدد : </span>
            <span *ngIf="lang == 2"> Sum total in numbers : </span>
            <span [ngClass]="{'en':dir == 'ltr'}"> {{ sum_all | number }} </span>
            <span> {{ currency_title }} </span>
            <span *ngIf="lang == 1"> به حروف </span>
            <span *ngIf="lang == 1"> {{ sum_all_letter }} </span>
            <span *ngIf="lang == 1"> {{ currency_title }} </span>
        </div>
    </div><!-- row -->
    <!-- **************************************************************************************************** -->
</div>