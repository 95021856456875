import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-wharehouse-order-sale-detaile',
  templateUrl: './wharehouse-order-sale-detaile.component.html',
  styleUrls: ['./wharehouse-order-sale-detaile.component.scss']
})
export class WharehouseOrderSaleDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public reception_id: number | undefined;
  public group: number = 0;
  public ref: string | undefined;
  public ref2: string | undefined;
  public invoice_id: number | undefined;
  public token: number | undefined;
  public trash: number | undefined;
  public material_id: number = 0;
  public creator: number | undefined;
  public level: number | undefined;
  public temp_number: number = 0;
  public check_order_id: number | undefined;
  public financial_id: number;
  public result: any;
  public price: number;
  public access_price_column_buy: boolean = false;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<WharehouseOrderSaleDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.ref2 = dialog_data.ref2;
      this.trash = dialog_data.trash;
      this.invoice_id = dialog_data.invoice_id;
      this.group = dialog_data.group;
      this.reception_id = dialog_data.reception_id;
      this.create_form1();
      this.id = dialog_data.id;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      this.check_access(4);
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'cost': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'profit': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
      'material_title': new FormControl({ value: null, disabled: true }),
      'material': new FormControl({ value: null, disabled: true }, [Validators.pattern('[0-9]{1,}')]),
      'group_title': new FormControl({ value: null, disabled: true }),
      'group': new FormControl({ value: null, disabled: true }, [Validators.pattern('[0-9]{1,}')]),
      'cate_title': new FormControl({ value: null, disabled: true }),
      'cate': new FormControl({ value: null, disabled: true }, [Validators.pattern('[0-9]{1,}')]),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'seller_title': new FormControl(null),
      'seller': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'price': new FormControl({ value: null, disabled: true }, [Validators.pattern('[0-9]{1,}')]),
    });
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            this.level = res['level'];
            if (type_task == 2) {
              this.check_for_update1();
            }
            if (type_task == 4) {
              this.get_data();
              if (this.ref == 'reception') this.get_financial2_id_reception();
              if (this.ref == 'invoice') this.get_financial2_id_invoice();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_financial2_id_reception() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6627, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.financial_id = res['result'][0].finance_financial2_id;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_financial2_id_invoice() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6635, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.financial_id = res['result'][0].finance_financial2_id;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6494, id: this.id, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'price': res['result'][0].wharehouse_material_price,
              'cost': res['result'][0].wharehouse_order_cost,
              'discount': res['result'][0].wharehouse_order_discount,
              'profit': res['result'][0].wharehouse_order_profit,
              'comment': res['result'][0].wharehouse_order_comment,
              'material_title': res['result'][0].wharehouse_material_title,
              'material': res['result'][0].wharehouse_material_id,
              'cate_title': res['result'][0].wharehouse_material_cate_title,
              'cate': res['result'][0].wharehouse_material_cate_id,
              'number': res['result'][0].wharehouse_order_number,
              'group': res['result'][0].wharehouse_group_id,
              'group_title': res['result'][0].wharehouse_group_title,
            });

            if (res['access_invoice_buy'] == 1) { this.access_price_column_buy = true; }
            else if (res['price_column_buy'] == 1) { this.access_price_column_buy = true; }

            this.price = res['result'][0].wharehouse_material_price;
            if (this.ref == 'invoice') {
              this.form1_group.patchValue({
                'seller': res['result'][0].seller,
                'seller_title': res['result'][0].seller_title
              })
            }
            else if (this.ref == 'reception') {
              this.form1_group.patchValue({
                'repairman': res['result'][0].repairman_id,
                'repairman_title': res['result'][0].repairman_title,
              })
            }
            this.material_id = res['result'][0].wharehouse_material_id;
            this.check_order_id = res['result'][0].wharehouse_order_consumed_invoice;
            this.temp_number = res['result'][0].wharehouse_order_number;
          }//end if
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }



  check_for_update1() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    //6003 check_order_id
    var obj = {
      address: 6134
      , material: this.material_id
      , id: this.id
      , token: this.token
      , order_id: this.check_order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if ((this.form1_group.value.number <= (res['remain'] + Number(this.temp_number)))
          ) {
            this.update();
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            var en_message = "The number of outputs of a product cannot be greater than the number of inputs";
            this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update(): any {
    if (this.form1_group.value.discount > this.form1_group.value.cost * this.form1_group.value.number) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      var en_message = "The amount of the discount cannot be more than the original price";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6646, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'cost': this.form1_group.value.cost
      , 'profit': this.form1_group.value.profit
      , 'material': this.material_id
      , 'number': this.form1_group.value.number
      , 'discount': this.form1_group.value.discount
      , 'repairman': this.form1_group.value.repairman
      , 'seller': this.form1_group.value.seller
      , 'id': this.id
      , 'creator': this.creator
      , 'ref': this.ref
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          if (this.financial_id > 0) {
            this.update_financial2_id_reception();
          } else {
            this.matDialogRef.close(
              { result: this.result }
            )
          }
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  update_financial2_id_reception() {
    var repairman = this.form1_group.value.repairman;
    if (this.ref == 'invoice') {
      repairman = this.form1_group.value.seller;
    }
    var obj = {
      address: 6647
      , id: this.financial_id
      , repairman: repairman
      , number: this.form1_group.value.number
      , profit: this.form1_group.value.profit
      , cost: this.form1_group.value.cost
      , discount: this.form1_group.value.discount
      , cost_buy: this.price
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_profit(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 6648, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'profit': res['result'][0].user_profit_sale
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_services(id: number, type: number): any {
    if (id == 220 && this.level == 3) {
      return false;
    }
    if (id == 223 || id == 184 || id == 171) {
      return false;
    }
    var where = "";
    var code_id;
    if (id == 223) {
      code_id = 6346;
    }
    if (id == 220) {
      code_id = 6227;
    }
    if (id == 171) {
      where += " and  wharehouse_material_cate = " + this.form1_group.value.cate;
    }
    if (id == 161) {
      code_id = 6196;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 171) {
            this.form1_group.patchValue({
              'material': res.id,
              'material_title': res.title
            })
          }
          if (id == 220 && type == 1) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title
            });
            this.get_profit(res.id);
          }
          if (id == 220 && type == 2) {
            this.form1_group.patchValue({
              'seller': res.id,
              'seller_title': res.title
            });
            this.get_profit(res.id);
          }
          if (id == 223) {
            this.form1_group.patchValue({
              'group': res.id,
              'group_title': res.title,
              'cate_title': null,
              'cate': null,
              'material_title': null,
              'material': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title,
              'material_title': null,
              'material': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title,
              'material_title': null,
              'material': null
            })
          }
        }
      }
    )
  }

  reset_input(id: number, type: number) {
    if (id == 220 && this.level == 3) {
      return false;
    }
    if (id == 171) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
      })
    }
    if (id == 223) {
      this.form1_group.patchValue({
        'group': null,
        'group_title': null,
        'cate': null,
        'cate_title': null,
        'material': null,
        'material_title': null,
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
        'cate': null,
        'cate_title': null,
      })
    }
    if (id == 220 && type == 1) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null
      })
    }
    if (id == 220 && type == 2) {
      this.form1_group.patchValue({
        'seller': null,
        'seller_title': null
      })
    }
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    return true;
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

