import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-newspaper-detaile',
  templateUrl: './newspaper-detaile.component.html',
  styleUrls: ['./newspaper-detaile.component.scss']
})
export class NewspaperDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription;
  public form1: FormGroup;
  public obj: any;
  public creator: number | undefined;
  public id: number | undefined;
  public video: string | undefined;
  public code: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<NewspaperDetaileComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    this.create_form();

    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.id = dialog_data.id;
      this.code = dialog_data.id;
    }
    if (this.dialog_data.search_array != 0) {
      console.log(this.dialog_data.search_array.length)
      this.form1.patchValue({
        'date3': this.dialog_data.search_array.date3,
        'order': this.dialog_data.search_array.order,
      })
    }

  }//end consructor

  search() {
    this.matDialogRef.close({
      'date3': this.form1.value.date3,
      'order': this.form1.value.order,
    })
  }


  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  check_access(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.video = res['result'][0].video;
            this.loading = false;
          }
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  create_form() {
    this.form1 = new FormGroup({
      'date3': new FormControl(new Date(), [Validators.required]),
      'order': new FormControl('desc'),
    });
  }

  close() {
    this.matDialogRef.close();
  }


  load_services(id: number) {
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {

        }
      }
    )
  }


  reset_input(id: number) {

  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}