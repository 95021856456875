import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ReceptionDetaileComponent } from "../reception-detaile/reception-detaile.component";
import { PaymentComponent } from '../../../finance/payment/payment.component';
import { WharehouseExit2Component } from '../../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { HttpClient } from '@angular/common/http';
import { RepairmanShelfLogComponent } from '../../repairman-shelf-log/repairman-shelf-log.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { RepairmanDeliveryComponent } from '../../repairman-delivery/repairman-delivery.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-reception-list',
  templateUrl: './reception-list.component.html',
  styleUrls: ['./reception-list.component.scss']
})
export class ReceptionListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number;
  public user_token: string;
  public user_store: string;
  public loading = false;
  public loading2 = false;
  public subscription: Subscription;
  public subscription2: Subscription;
  public task_type: number | undefined;
  public i: number;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public code_movie: any;
  public sort: string | undefined;
  public show_excel: boolean = false;
  public loading1: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public delivery: number | undefined;
  public x: any;
  public ready_delivery: number | undefined;
  public ready_reparing: number | undefined;
  public payment_dialog: boolean;
  public material_dialog: boolean;
  public back_i: number | undefined;
  public sum_services: number | undefined;
  public sum_material: number | undefined;
  public sum_order: number | undefined;
  public list_services: any = [];
  public level: number | undefined;
  public creator: number | undefined;
  public video: string | undefined;
  public default: number | undefined;
  public access_service: number | undefined;
  public path_export: string | undefined;
  public status_show_id: boolean = false;
  public show_cellphone: boolean = true;
  public query_customer: number;
  public query_repairman: number;
  public seeOwnPanel: boolean = false;
  public list_cellphone: any = [];
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  public token: any = Math.floor(Math.random() * 99999999999999);
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;

  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'user', 'invoice', 'cellphone', 'id2', 'id', 'shelf', 'device', 'brand', 'model', 'searial', 'date', 'time', 'date2', 'time2', 'estimated_time', 'status', 'device_status', 'sum_services', 'sum_material', 'sum', 'operation'];
  //*************
  public rstatus: any | undefined;
  public rstatus_default: any | undefined;
  public repairman: number | undefined;
  public pos: string | undefined;

  see_own_panel: number = 0;
  sendsms: number = -1;

  //************** */
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public http: HttpClient
    , public messageService: MessageService
  ) {
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
    this.subscription2 = this.serverService.get_new_reception().subscribe(
      (res) => {
        this.list_record.push(res);
        this.pages(1);
        if (this.list_record.length == 21) {
          this.list_record.splice(20, 1);
        }
        this.dataSource.data = this.list_record;
        //this.check_charge_sms(res.repairman_reception_id, res.repairman_reception_code, res.user_cellPhone, res.user_title, false, res.user_store);
      }
    );
  }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_store = this.user_info.user_store;
      this.user_id = this.user_info.user_id;
      this.user_token = this.user_info.user_token;
    }

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
        this.id = params['id'];
        this.repairman = params['repairman'];
        this.pos = params['pos'];
        if (!this.page) this.page = 1;
        this.search_array = [];
        this.get_movie();

      }
    )
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.page = Number(params['page']);
        this.search_all = params['all'];
        this.query_customer = params['customer'];
        this.rstatus = params['rstatus'];
        if (params['back_i']) this.back_i = params['back_i'];
        this.query_repairman = params['repairman'];
        this.check_access(4, 1, 0, 1, 1);
      }
    );
    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    var code = this.code;
    if (this.code == 170) { code = 197; }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address_token(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: code }, this.user_token).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.path_export = res['path_export'];
          this.show_cellphone = res['show_cellphone'];

          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    var code = this.code;
    if (this.code == 170) { code = 197; }
    this.subscription = this.serverService.post_address_token(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: code }, this.user_token).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) {
            this.get_reception_with_full_shelf();
            if (this.level == 3) this.check_user_access_only_his_panel_config(page);
            else this.load_data(page, 1, 1, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_user_access_only_his_panel_config(page: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6816,
      id: this.user_id,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.seeOwnPanel = res['result'][0].user_can_only_see_his_own_list;
          this.load_data(page, 1, 1, -1);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6817
      , "page": this.page
      , 'type': this.type
      , 'level': this.level
      , 'excel': excel
      , 'lang': this.lang
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'rstatus': this.rstatus
      , 'repairman': this.repairman
      , 'pos': this.pos
      , 'id': this.id
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'show_cellphone': this.show_cellphone
      , 'query_customer': this.query_customer
      , 'seeOwnPanel': this.seeOwnPanel
      , 'query_repairman': this.query_repairman
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              var x: any;
              for (var i = 0; i < res['num']; i++) {
                res['result'][i].sum = Number(res['result'][i].sum_order) - Number(res['result'][i].sum_payment);
                x = new Date(res['result'][i].repairman_reception_estimated_time).getTime() - new Date(res['date2']).getTime();
                res['result'][i].urgent = Math.round(x / (1000 * 3600 * 24))
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.delivery;
            this.default = res['default1'];
            this.payment_dialog = res['payment_dialog'];
            this.material_dialog = res['material_dialog'];
            this.delivery = res['delivery'];
            this.ready_delivery = res['ready_delivery'];
            this.ready_reparing = res['ready_reparing'];
            this.sum_services = res['sum_services'];
            this.sum_material = res['sum_material'];
            this.sum_order = res['sum_order'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level });
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  //*************************************************************************************** */
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code_movie }
    })
  }
  get_movie(): any {
    this.code_movie = this.code;
    var user_id = this.user_id;
    if (this.code == 170) { this.code_movie = 166; user_id = 2445; }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: user_id, id: this.code_movie }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.video = res['result'][0].video;
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_reception_with_full_shelf() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6620, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.empty_shelf_and_reception(res['result'][i].repairman_reception_id, res['result'][i].repairman_reception_shelf);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_log(reception) {
    this.dialog.open(RepairmanShelfLogComponent, {
      width: '67rem',
      height: 'auto',
      data: { reception: reception }
    })
  }
  get_menu(i: number, x: any) {
    this.x = x;
    this.i = i;
  }
  show_id() {
    this.status_show_id = !this.status_show_id;
  }

  get_sum_order(i: number, id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6113, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_record[i].sum = Number(res['result'][0].sum_order) - Number(res['result'][0].sum_payment);
            this.list_record[i].sum_services = res.result[0].sum_services;
            this.list_record[i].sum_material = res.result[0].sum_material;
            this.list_record[i].sum_order = res.result[0].sum_order;
            this.dataSource.data = this.list_record;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          var en_message = "erorr in $";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************************ */
  show_factor1() {
    if (this.lang == 2) {
      window.open(this.serverService.get_my_domain() + "/tracking/en/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
    } else {
      window.open(this.serverService.get_my_domain() + "/tracking/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
    }
  }

  show_factor2() {
    if (this.lang == 2) {
      window.open(this.serverService.get_my_domain() + "/tracking2/en/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code + "?type=reception", "_blank");
    } else {
      window.open(this.serverService.get_my_domain() + "/tracking2/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code + "?type=reception", "_blank");
    }
  }

  show_factor3() {
    window.open("/receipt/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
  }

  barcode() {
    window.open(this.site + "/barcode/barcode.html?type=1&creator=" + this.creator + "&userId=" + this.user_id + "&id=" + this.x.repairman_reception_id + "&token=" + this.x.repairman_reception_code + "&bottomtext=" + this.x.repairman_shelf_title + "&toptext=" + this.x.user_title + "&lang=" + this.lang, "_blank");
  }

  check_charge_sms(olgu: string, i: number, id: number, id2: number, code: number, cellphone: any, status_title: string, again: boolean, user_store: string, user_phone: string): any {
    if (again == true) {
      if (this.serverService.check_internet() == false) {
        this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
        return;
      }//end if
      else { this.matSnackBar.dismiss(); }
    }
    if (cellphone.match(/^\d{11}$/g) == null) {
      var pe_message = "فرمت شماره برای ارسال پیامک معتبر نمی باشد";
      var en_message = "The number format is not valid for sending SMS";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    if (again == true) this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= 2000) {
              if (again == false) {
                this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
              }
              this.send_sms(olgu, i, id, id2, code, cellphone, status_title, again, user_store, user_phone);
            }
            else {
              if (again == true) {
                var pe_message = "قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید";
                var en_message = "Unable to send SMS, please recharge";
                this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
              } else {
                var pe_message = "رکورد یا موفقیت ذخیره شد ولی قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید";
                var en_message = "The record or success was saved, but you are unable to send SMS, please recharge";
                this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              }

            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms(olgu: string, i: number, id: number, id2: number, code: number, cellphone: any, title: string, again: boolean, user_store: string, user_phone: string): any {
    var template;
    if (this.lang == 1) {
      if (olgu == 'reception1') template = 'tracking';
      else if (olgu == 'reception2') template = 'tracking2';
    } else {
      if (olgu == 'reception1') template = 'tracking';
      else if (olgu == 'reception2') template = 'tracking2';
    }

    var token = this.serverService.get_my_domain() + "/" + template + "/" + id;
    if (again == true) {
      var x = confirm(this.messageService.message_sms_accept(this.lang));
      if (x == false) {
        this.message(false, "", 1, this.messageService.close(this.lang));
        return false;
      }
      this.loading = true;
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6250, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].user_sms2 == 1 || again == true) {
            var obj = {
              address: 6724
              , user_id: this.user_id
              , creator: this.creator
              , cellphone: cellphone
              , token: token
              , code: code
              , id: id
              , id2: id2
              , title: title
              , user_store: user_store
              , user_phone: user_phone
            }
            this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
              (res: any) => {
                if (again == true) {
                  var pe_message = "پیام با موفقیت ارسال شد";
                  var en_message = "Message sent successfully";
                  this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
                }
                this.log_sms(res['response'].body.entries[0].cost);
              })
          } else {
            this.message(false, "", 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          var pe_message = "خطا در ارسال پیامک";
          var en_message = "Error sending SMS";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  log_sms(cost: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6308, user_id: this.user_id, creator: this.creator, cost: cost }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          var en_message = "Error in recording the sent SMS log";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************************ */
  open_delivery(i: number, id: number) {
    const dialogRef = this.dialog.open(RepairmanDeliveryComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.splice(i, 1);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }

  get_code_delivery(id: number) {
    return Math.floor(id * id / 2)
  }
  //************************************************************************************************************ */
  open(i: number, type_task: number, id: number, id2: number) {
    if (this.payment_dialog == true) {
      this.open_payment(i, id, id2);
    }
    else if (this.material_dialog == true) {
      this.open_exit(i, id);
    }
    else {
      this.open_detaile(i, type_task, id);
    }
  }

  open_detaile(i: number, type_task: number, id: number) {
    this.back_i = -1;
    var width = '65rem';
    if (type_task == 3) {
      width = '40rem';
    }
    const dialogRef = this.dialog.open(ReceptionDetaileComponent, {
      width: width,
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: 160, title: this.title, creator: this.creator, default_rstatus: this.default, rstatus: this.rstatus }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          var change_status = 0;
          if (type_task == 1) {
            this.list_record.push(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
            this.update_attachment(id, res.token);
          }
          else if (type_task == 2) {
            this.list_record[i].repairman_shelf_title = res.result.repairman_shelf_title;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_cellPhone = res.result.user_cellPhone;
            this.list_record[i].wharehouse_device_title = res.result.wharehouse_device_title;
            this.list_record[i].repairman_reception_urgent = res.result.repairman_reception_urgent;
            this.list_record[i].repairman_brand_title = res.result.repairman_brand_title;
            this.list_record[i].repairman_model_title = res.result.repairman_model_title;
            this.list_record[i].repairman_reception_searial = res.result.repairman_reception_searial;
            this.list_record[i].repairman_rstatus_title = res.result.repairman_rstatus_title;
            this.list_record[i].repairman_device_status_title = res.result.repairman_device_status_title;
            this.list_record[i].repairman_title = res.result.repairman_title;
            this.list_record[i].repairman_reception_color = res.result.repairman_reception_color;
            this.list_record[i].repairman_reception_approximate = res.result.repairman_reception_approximate;
            this.list_record[i].repairman_reception_editor_date = res.result.repairman_reception_editor_date;
            this.list_record[i].repairman_reception_editor_time = res.result.repairman_reception_editor_time;
            this.list_record[i].repairman_reception_comment3 = res.result.repairman_reception_comment3;
            this.list_record[i].repairman_reception_estimated_time = res.result.repairman_reception_estimated_time;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level, update: true });

            if (this.lang == 1) {
              if (res.isprint == 1) window.open(this.serverService.get_my_domain() + "/tracking/" + id + "/" + res.result.repairman_reception_code, "_blank");
            } else {
              if (res.isprint == 1) window.open(this.serverService.get_my_domain() + "/tracking/en/" + id + "/" + res.result.repairman_reception_code, "_blank");
            }

            if (this.pos == 'right') {
              if (this.rstatus != res.result.repairman_reception_status) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            } else {
              if (this.default == 1) {
                if (res.result.repairman_rstatus_default == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.delivery == 1) {
                if (res.result.repairman_rstatus_empty_shelf == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.ready_delivery == 1) {
                if (res.result.repairman_rstatus_sub_ready_delivery == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.ready_reparing == 1) {
                if (res.result.repairman_rstatus_repairing == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
            }
            if (res.result.confirm_sms == true) {
              this.check_charge_sms('reception2', i, res.result.repairman_reception_id, res.result.repairman_reception_id2, res.result.repairman_reception_code, res.result.user_cellPhone, res.result.repairman_rstatus_title, false, res.result.user_store, res.result.user_phone);
            }
            this.update_financial2(res.result.repairman_reception_id, res.result.repairman_reception_customer);
            if (change_status == 0) this.get_sum_order(i, id);
            this.update_attachment(id, res.token);
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }

  update_attachment(id: number, token: number) {
    var obj = {
      address: 6358,
      id: id,
      user_id: this.user_id,
      creator: this.creator,
      token: token
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ذخیره ضمیمه ها";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  update_financial2(id: number, customer: number) {
    var obj = {
      address: 6108,
      id: id,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2(id, customer);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_Menu(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading1 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6122, id: id }).subscribe(
      (res: any) => {
        this.list_services = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            res['result'][i].logo = res['result'][i].user_logo_site + "/" + res['result'][i].user_logo;
            this.list_services.push(res['result'][i]);
          }//end for
          this.loading1 = false;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.loading1 = false;
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_all_financial2(id: number, customer: number) {
    var obj = {
      address: 6110,
      id: id,
      user_id: this.user_id,
      customer: customer,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_send_sms(id: number, code: number, cellphone: number, title: string, rstatus_title: string) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 2007, id: 4 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].site_setting_sms == 1) {
            this.subscription = this.http.get(this.serverService.sms_token + "?receptor=" + cellphone + "&token=" + id + "&token2=" + code + "&token3=" + rstatus_title + "&token10=" + title + "&template=tracking").subscribe(
              (res: any) => {

              })
          }
        }//end if
        else {
          var pe_message = "خطا در ارسال پیامک";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 1729; } else { address = 1728; }
      var obj = {
        'address': address, "user_id": this.user_id, creator: this.creator
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_reception_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_reception_id == res['result_record'][i].repairman_reception_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.empty_shelf(res['result'][0].repairman_reception_shelf);
            this.serverService.send_reception_repairman();
            this.delete_financial(id);
            this.server_update_count_reception(res['result'][0].count1)
          }//end if
          else if (res['status'] == 4) {
            var pe_message = ".این رکورد یا پرداختی دارد یا قطعات و خدمات برای آن درج شده است . قادر به حذف نیستید";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  server_update_count_reception(count) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6387, creator: this.creator, count: count }).subscribe(
      (res) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_financial(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6109, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var pe_message = "با موفقیت حذف شد";
          this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf(shelf: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf_and_reception(id: number, shelf: number) {
    var obj = { address: 6621, shelf: shelf, creator: this.creator, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_payment(i: number, id: number, id2: number) {
    const dialogRef = this.dialog.open(PaymentComponent, {
      width: '65rem',
      height: 'auto',
      data: { code: this.code, id: id, ref: 3, page: this.page, search_all: this.search_all, search_array: this.search_array, i: i, creator: this.creator, level: this.level, id2: id2 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.splice(i, 1);
          this.dataSource.data = this.list_record;
          this.pages(-1);
          if (res.result.confirm_sms == true) {
            this.check_charge_sms('reception2', i, res.result.repairman_reception_id, res.result.repairman_reception_id2, res.result.repairman_reception_code, res.result.user_cellPhone, res.result.repairman_rstatus_title, false, res.result.user_store, res.result.user_phone);
          }
        }//end if 

        //if (change_status == 0) this.get_sum_order(i, id);
      }
    )
  }

  open_exit(i: number, id: number) {
    this.back_i = -1;
    const dialogRef = this.dialog.open(WharehouseExit2Component, {
      'width': '85rem',
      'height': 'auto',
      data: { 'reception_id': id, token: this.token, ref: 'exit', type: 2, code: 160, creator: this.creator, level: this.level, default_rstatus: this.default }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        var change_status = 0;
        if (res) {
          this.list_record[i].repairman_rstatus_title = res.repairman_rstatus_title;
          if (this.pos == 'right') {
            if (this.rstatus != res.repairman_reception_status) {
              this.list_record.splice(i, 1);
              this.dataSource.data = this.list_record;
              this.pages(-1);
              change_status = 1;
            }
          } else {
            if (this.default == 1) {
              if (res.repairman_rstatus_default == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.delivery == 1) {
              if (res.repairman_rstatus_empty_shelf == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.ready_delivery == 1) {
              if (res.repairman_rstatus_sub_ready_delivery == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.ready_reparing == 1) {
              if (res.repairman_rstatus_repairing == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
          }
          if (res.confirm_sms == true) {
            this.check_charge_sms('reception2', i, res.repairman_reception_id, res.repairman_reception_id2, res.repairman_reception_code, res.user_cellPhone, res.repairman_rstatus_title, false, res.user_store, res.user_phone);
          }
          if (change_status == 0) this.get_sum_order(i, id);
        }
        /*
        this.list_record.splice(i, 1);
        this.dataSource.data = this.list_record;
        */
      }
    )
  }
  //********************************************************************************** */
  check_cellphone_sms2() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var x = window.confirm(this.messageService.message_sms_accept(this.lang));
    if (x == false) {
      this.message(false, "", 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading2 = true;
    var count = 0;
    var obj = {
      address: 6818,
      id: this.list_record[this.i].repairman_reception_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_cellphone = [];
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].user_cellphone.length == 11 && res['result'][i].user_cellphone.match(/^\d{11}$/g)) {
              count++;
              this.list_cellphone.push(res['result'][i].user_cellphone);
            }
          }
          if (this.list_cellphone.length > 0) this.check_charge_sms2(count);
          else {
            var pe_message = "برای ارسال پیامک ، بایستی حداقل یک تعمیرکار با شماره موبایل درست مشخص می باشد";
            var en_message = "To send an SMS, at least one repairman with a correct mobile number must be specified";
            this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )

  }
  check_charge_sms2(count: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= count * 1000) {
              for (var i = 0; i < this.list_cellphone.length; i++) {
                this.send_sms2(this.list_cellphone[i], count, i + 1);
              }
            }
            else {
              var pe_message = "قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید";
              var en_message = "Unable to send SMS, please recharge";
              this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms2(cellphone: number, count1: number, count2: number): any {
    var obj = {
      address: 6819
      , cellphone: cellphone
      , token: this.serverService.domain
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (count1 == count2) {
          var pe_message = "پیام با موفقیت ارسال شد";
          var en_message = "Message sent successfully";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        this.log_sms(res['response'].body.entries[0].cost);
      })
  }
  //********************************************************************************* */
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
  }//end OnDestroy
}
