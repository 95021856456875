<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="customer" class='wh-label'>
                    <span *ngIf="lang == 1"> نام شرکت </span>
                    <span *ngIf="lang == 2"> Company name </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(161)"
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="customer" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="amount" class='wh-label'>
                    <span *ngIf="lang == 1"> تعداد ماه </span>
                    <span *ngIf="lang == 2"> Number of months </span>
                    <input type="number" class='wh-input' formControlName="number" autocomplete="off"
                        [required]="required()" (change)="get_amount()" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع پلن </span>
                    <span *ngIf="lang == 2"> Type of plan </span>
                    <span (click)="reset_input(237)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="plan_title" (click)="load_services(237)"
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="plan" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت </span>
                    <span *ngIf="lang == 2"> Status </span>
                    <span (click)="reset_input(236)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(236)"
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="code == 230">
                <label for="issue_date" class="wh-label">
                    <span *ngIf="lang == 1"> تاریخ انقضا </span>
                    <span *ngIf="lang == 2"> Expire Date </span>
                    <input class="wh-input" [matDatepicker]="picker" formControlName="expire_date" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                        [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="amount" class='wh-label'>
                    <span *ngIf="lang == 1"> مقدار پرداختی </span>
                    <span *ngIf="lang == 2"> Payment amount </span>
                    <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask
                        [readonly]="type_task != 3" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="type_task != 3">
                <label for="Coupun" class='wh-label'>
                    <span *ngIf="lang == 1"> کوپن </span>
                    <span *ngIf="lang == 2"> Coupon </span>
                    <span (click)="reset_input(262)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="coupon_title" (click)="load_services(262)" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="coupon" [hidden]="true" readonly>
                </label>
            </div>


            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="amount" class='wh-label'>
                    <span *ngIf="lang == 1"> کد رهگیری </span>
                    <span *ngIf="lang == 2"> Tracking number </span>
                    <span *ngIf="lang == 1"> کد رهگیری : </span>
                    <span *ngIf="lang == 2"> Transaction ID : </span>
                    <input class='wh-input' formControlName="code" autocomplete="off"
                        (keypress)="serverService.get_number($event)" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="amount" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea style="height:3rem" class='wh-input' formControlName="comment" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2" [hidden]="readonly">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="button" (click)="search(1)" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>
</form>