import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserDetaile3Component } from "../user-detaile3/user-detaile3.component";
import { UserVideoComponent } from '../user-video/user-video.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-list-access',
  templateUrl: './user-list-access.component.html',
  styleUrls: ['./user-list-access.component.scss']
})
export class UserListAccessComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public loading_copy: boolean = false;
  public subscription: Subscription;
  public show_cellphone: boolean = true;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public free: boolean;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined;
  public search_all: string | undefined;
  public show_financial: boolean = false;
  public date: string | undefined;
  public select_user: number;
  public record_id: number = 0;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public back_i: number | undefined;
  public sharj_all: number = 0;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'clear_token', 'username', 'last_login', 'title', 'cellphone', 'group', 'profit_sale', 'profit_repairman', 'operation'];
  //*************
  public creator: number;
  public creator_temp: number;
  public access_service: number | undefined;
  public level: number | undefined;
  public video: string | undefined;
  public search_all_params: boolean = false;
  public groups_id: number = 0;
  public path_export: string | undefined;
  public moarf: string;
  //************** */
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public http: HttpClient
    , public messageService: MessageService
    , public dialogRef: MatDialog) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.check_access(4, 1, 0, 1, 1);

    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.creator_temp = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.show_cellphone = res['show_cellphone'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = Number(this.page) + Number(1); }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6 || page == 1) { this.page = 1; }
    var obj = {
      'address': 6706
      , "page": this.page
      , 'type': this.type
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'code': this.code
      , 'groups_id': this.groups_id
      , 'path_export': this.path_export, level: this.level
      , 'show_cellphone': this.show_cellphone
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.date = res['date'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sharj_all = res['sharj_all'];
            this.pages(0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_group()
            this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    var width = '55rem';
    if (type_task == 3) {
      width = '22rem'
    }
    const dialogRef = this.dialog.open(UserDetaile3Component, {
      width: width,
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, level: this.level, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].user_groups_title = res.result.user_groups_title;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_cellPhone = res.result.user_cellPhone;
            this.list_record[i].user_profit_sale = res.result.user_profit_sale;
            this.list_record[i].user_profit_repairman = res.result.user_profit_repairman;
            this.list_record[i].user_server_site = res.result.user_server_site;
            this.list_record[i].user_username = res.result.user_username;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }

  get_bg(id: number) {
    this.id = id;
  }

  go_to_user2(user_id: number, token: number, site: string, token2: string, services_login: string) {
    if (!site) {
      this.go_to_user(user_id, token2, services_login);
    } else {
      window.open(site + "/home/reception/197?token_login=" + token + "&userId=" + user_id, "_blank");
    }
  }

  go_to_user(id: number, token: string, services_login: string) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6570, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var creator = res['result'][0].user_creator2;
          var user_info = {
            user_id: res['result'][0].user_id,
            user_creator: creator,
            username: res['result'][0].user_userName,
            user_title: res['result'][0].user_title,
            groups_title: res['result'][0].user_groups_title,
            user_token: res['result'][0].user_token,
            lang: this.lang,
          };

          localStorage.setItem("dir", JSON.stringify('rtl'));
          localStorage.setItem("user_info", JSON.stringify(user_info));
          localStorage.setItem('status', '1');
          localStorage.setItem('refrerr', '2');
          localStorage.setItem('lang', this.lang);
          window.open("/home/" + res['result'][0].site_services_link + "/" + res['result'][0].site_services_id + "?token_login=" + token + "&userId=" + id, "_self");
          //window.open("/home/reception/197", "_self");
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  clear_token(i: number, id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    var pe_message = " اگر گمان میکنید که نام کاربری یا کلمه عبور این کاربر در دسترس شخص دیگری قرار گرفته است با تغییر توکن  میتوانید دسترسی شخص مورد نظر را به صورت آنی قطع کنید . آیا از تغییر توکن اطمینان دارید؟.";
    var en_message = "If you think that this user's username or password has been accessed by someone else, by changing the token, you can immediately cut off the access of the desired person. Are you sure about the token change?";
    confirm_delete = window.confirm(this.messageService.message(this.lang, pe_message, en_message));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6705, id: id }).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.message(true, this.messageService.change(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }

  get_bg2(id: number) {
    this.record_id = id;
  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 1659; } else { address = 1658; }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.serverService.send_reception_repairman();
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.user_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.user_id == res['result_record'][i].user_id), 1);
              }
            };
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.serverService.send_group();
            this.delete_financial2(id);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  delete_financial2(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6310, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در تراکنش مالی کاربر";
          var en_message = "Error in the user's financial transaction";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}