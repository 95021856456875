import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../../system/service/server/server.service';
import { InsertComponent } from '../../../service/insert/insert.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-cardex-detaile2',
  templateUrl: './cardex-detaile2.component.html',
  styleUrls: ['./cardex-detaile2.component.scss']
})

export class CardexDetaile2Component implements OnInit, OnDestroy {

  public server_main: string = this.serverService.get_server_main();
  public server: any = this.serverService.get_server();
  public site: any = this.serverService.get_site();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading = false;
  public loading2 = false;
  public subscription: Subscription;
  public loading_file: boolean = false;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i!: number;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public result: any | undefined;
  public temp_inventory: number = 0;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public readonly: boolean = false;
  public access_service: number | undefined;
  public level: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<CardexDetaile2Component>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;

      this.readonly = dialog_data.readonly;
      this.creator = dialog_data.creator;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          group: dialog_data.search_array.group,
          group_title: dialog_data.search_array.group_title,
          cate: dialog_data.search_array.cate,
          cate_title: dialog_data.search_array.cate_title,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'group_title': new FormControl(null),
      'group': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cate_title': new FormControl(null),
      'cate': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.title = res['result'][0].title;
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          if (type_task == 3) { this.search(excel); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {
    this.matDialogRef.close({
      group_title: this.form1_group.value.group_title,
      group: this.form1_group.value.group,
      cate_title: this.form1_group.value.cate_title,
      cate: this.form1_group.value.cate,
    })
  }

  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var creator = this.creator;
    var code_id;

    if (id == 184) {
      if (!this.form1_group.value.group) {
        var pe_message = "در ابتدا باید گروه انتخاب شود";
        var en_messgae = "First, the group must be selected";
        this.message(true, this.messageService.message(this.lang, pe_message, en_messgae), 1, this.messageService.close(this.lang));
        return false;
      }
    }
    if (id == 184) {
      code_id = 6523;
    }
    if (id == 223) {
      code_id = 6346;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, creator: creator, code_id: code_id
        , obj: { group: this.form1_group.value.group }
      }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 223) {
            this.form1_group.patchValue({
              'group': res.id,
              'group_title': res.title,
              'cate': null,
              'cate_title': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title
            })
          }
        }
      }
    )
  }


  reset_input(id: number): any {
    if (this.readonly == true) {
      return false;
    }

    if (id == 223) {
      this.form1_group.patchValue({
        'group_title': null,
        'group': null,
        'cate': null,
        'cate_title': null
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'cate_title': null,
        'cate': null
      })
    }
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy


}


