<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>کد تحویل دستگاه</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row delivery-row1">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 right fright">
            طرف حساب
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 right">
            {{ customer_title }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 right fright">
            دستگاه
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 right">
            {{ device }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 right fright">
            وضعیت
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 right">
            {{ rstatus_title }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center" style="padding:1rem">
            <button class="btn_pe delivery-button1" (click)="check_charge_sms(1)" style="width:15rem">
                ارسال کد تحویل دستگاه
            </button>
        </div>
    </div>


    <div class="row">
        <form [formGroup]="form1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="brand" class='wh-label'>
                    <input class="wh-input" formControlName="code" style="text-align: center"
                        (keypress)="serverService.get_number($event)"
                        placeholder="لطفا کد خوانده شده توسط مشتری را اینجا وارد نمائید...">
                </label>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
                <button class="btn_pe delivery-button1" (click)="check_code()" style="width:15rem">
                    بررسی تاییدیه کد دریافتی
                </button>
            </div>
        </form>
    </div>
    <div class="row" style="padding-top:2rem;font-size: 0.85rem;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 right" style="color:#888;padding:0px">
            در صورت صحت کد دریافتی ، دستگاه به وضعیت تحویل شده منتقل میشود و مشتری از تحویل دستگاه از طریق پیامک مطلع
            میشود.
        </div>
    </div>

</div>