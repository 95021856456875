<div mat-dialog-content class="matDialogContent" style="padding:0px !important;background: #000;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    .
    <span class="close" (click)="close()" style="color:#BDBDBD;opacity: 0.5;">
        <mat-icon>close</mat-icon>
    </span>
    <div class="row">
        <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}" style="padding:0px;margin:0px"
            *ngIf="src">
            <div class="video_box">
                <video controls autoplay style="width: 100%;" #videoRef>
                    <source [src]="src" type="video/mp4" />
                    Browser not supported
                </video>
            </div>
        </div><!-- col-md-12 col-lg-12 col-sm-12 col-xs-12 pe  -->

        <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}" style="padding:0px;margin:0px"
            *ngIf="!src">
            <div class="row" class="preview1" [hidden]="count_total > 0">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <img src="../../../assets/images/preview.png">
                    </div>
                    <div class="preview2" *ngIf="lang == 1">هیچ رکوردی برای نمایش وجود ندارد</div>
                    <div class="preview2" *ngIf="lang == 2">There are no records to display</div>
                </div>
            </div>
        </div><!-- col-md-12 col-lg-12 col-sm-12 col-xs-12 pe  -->

    </div><!-- row -->
</div> <!-- content -->