<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span>{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="material_type == 2">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه : </span>
                    <span *ngIf="lang == 2"> Group : </span>
                    <span (click)="reset_input(223)" [hidden]="material_type == 1">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223)"
                        [required]="required()  && material_type == 1" readonly [attr.disabled]="true">
                    <input class="wh-input" formControlName="group" [hidden]="true"
                        [required]="required()  && material_type == 1" readonly [attr.disabled]="true">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="material_type == 2">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه : </span>
                    <span *ngIf="lang == 2"> Subgroup : </span>
                    <span (click)="reset_input(184)" [hidden]="material_type == 1">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184) "
                        [required]="required() && material_type == 1" readonly [attr.disabled]="true">
                    <input class="wh-input" formControlName="cate" [hidden]="true"
                        [required]="required()  && material_type == 1" readonly [attr.disabled]="true">
                </label>
            </div>


            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1 && material_type == 1"> عنوان قطعه مصرف شده: </span>
                    <span *ngIf="lang == 2 && material_type == 1"> Used component : </span>

                    <span *ngIf="lang == 1 && material_type == 2"> عنوان خدمات </span>
                    <span *ngIf="lang == 2 && material_type == 2"> Service name : </span>
                    <span (click)="reset_input(195)" [hidden]="material_type == 1">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(195)" readonly
                        [required]="required()">
                    <input class="wh-input" formControlName="material" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="true">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> مرکز هزینه : </span>
                    <span *ngIf="lang == 2"> Cost center : </span>
                    <span (click)="reset_input(186)" [hidden]="true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_center_title" (click)="load_services(186) "
                        [required]="required1()" readonly [attr.disabled]="true">
                    <input class="wh-input" formControlName="cost_center" [attr.disabled]="true" [hidden]="true"
                        [required]="required1()" readonly>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> تعداد : </span>
                    <span *ngIf="lang == 2"> Number : </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" [required]="required()"
                        currencyMask>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="pending == 1">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت : </span>
                    <span *ngIf="lang == 2"> Price : </span>
                    <input class='wh-input' formControlName="cost" currencyMask autocomplete="off"
                        [required]="required()">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="pending == 1">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> تخفیف : </span>
                    <span *ngIf="lang == 2"> Discount : </span>
                    <input class='wh-input' formControlName="discount" currencyMask autocomplete="off">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="pending == 1 ">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> نام تعمیرکار : </span>
                    <span *ngIf="lang == 2"> Repairman name : </span>
                    <span (click)="reset_input(220)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220)" readonly
                        [required]="required() && (ref == 'invoice' || ref == 'reception') ">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly
                        [required]="required() && (ref == 'invoice' || ref == 'reception') ">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off">
                </label>
            </div>


            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات : </span>
                    <span *ngIf="lang == 2"> Description : </span>
                    <textarea style="height:10rem" class='wh-input' formControlName="comment" autocomplete="off">
                    </textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
            (click)="check_access(type_task,2)">
            <span *ngIf="lang == 2">Export Excel file</span>
            <span *ngIf="lang == 1">خروجی اکسل</span>
        </button>
    </div>
</form>