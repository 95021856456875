<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">ریز پرداخت ها</span>
        <span *ngIf="lang == 2">Payment history</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>


<div mat-dialog-content class="matDialogContent">
    <div class="row" style="padding-bottom: 1rem;" [ngStyle]="{display:lang == 2 ? 'flex' : 'initial'}">
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <table class="table table-bordered" [ngStyle]="{direction:lang == 2 ? 'ltr' : 'rtl'}">
                <tr>
                    <td>
                        <span *ngIf="lang == 1">هزبنه حمل</span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Shipping cost</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ trans | number }}</td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="lang == 1">تخفیف از خدمات</span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Discount on service</span>
                    </td>
                    <td style="padding:0px !important" [ngClass]="{'en':dir == 'ltr'}">{{ discount_material | number }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="lang == 1">تخفیف از کالا</span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Discount on product</span>
                    </td>
                    <td style="padding:0px !important" [ngClass]="{'en':dir == 'ltr'}">{{ discount_services | number }}
                    </td>
                </tr>
                <tr style="background-color: #F1EFEA">
                    <td>
                        <span *ngIf="lang == 1">مبلغ فاکتور</span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Amount due</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ sum | number }}
                        <span style="float: left;padding-top:1px" (click)="open_exit()"><i
                                class="fas fa-info-circle icon"></i> </span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <table class="table table-bordered" [ngStyle]="{direction:lang == 2 ? 'ltr' : 'rtl'}">
                <tr>
                    <td>
                        <span *ngIf="lang == 1">نام طرف حساب</span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Name and surname</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ user_title }}</td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="lang == 1"> شماره همراه </span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Cellphone number</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ user_cellphone }}</td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="lang == 1"> شناسه پذیرش </span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Reception no</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ id }}</td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="lang == 1"> دستگاه/برند/مدل </span>
                        <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Device/Brand/Model</span>
                    </td>
                    <td [ngClass]="{'en':dir == 'ltr'}">{{ device }} / {{ brand }} / <span class="font-en">{{ model
                            }}</span></td>
                </tr>
            </table>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 remain">
            <div class="remain_text1" (click)="get_account()">
                <span *ngIf="lang == 1">مانده حساب کل</span>
                <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}">Total balance</span>
            </div>
            <div class="remain_text2" [ngClass]="{'en':dir == 'ltr'}">{{ remain | number }}</div>
            <div style="padding-top:0.5rem">{{ currency_title }}</div>
        </div>
    </div>

    <div class="row">
        <div style="text-align: center;margin-top: 6rem;">
            <button class="button_default button_default2" (click)="new()">
                <span *ngIf="lang == 1">میانبر درج امورمالی</span>
                <span *ngIf="lang == 2">Shortcut to financial affairs</span>
            </button>
            <button class="button_default button_default2" (click)="show_financial2()" *ngIf="show_financial == false">
                <span *ngIf="lang == 1"> مشاهده ریز تراکنش </span>
                <span *ngIf="lang == 2"> Payment history </span>
            </button>
            <button class="button_default button_default2" (click)="back()" *ngIf="show_financial == true">
                <span *ngIf="lang == 1">برگشت</span>
                <span *ngIf="lang == 2">Return</span>
            </button>
        </div>
    </div>

</div>

<div mat-dialog-actions class="matDialogActions" *ngIf="!status_empty_shelf">
    <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="update()">
        <span *ngIf="lang == 2">Change to delivered status</span>
        <span *ngIf="lang == 1">انتقال به وضعیت تحویل داده شده</span>
    </button>
    <div style="padding-right:1rem;padding-top:0.2rem">
        <mat-radio-group aria-label="Select an option" name="device_status" [(ngModel)]="device_status">
            <div class="device_status_text">
                <span *ngIf="lang == 1">وضعیت دستگاه :</span>
                <span *ngIf="lang == 2"> Device status :</span>
            </div>
            <mat-radio-button class="status_title_items reception-status" *ngFor="let x of list_device_status"
                [value]="x.repairman_device_status_id" [checked]="device_status == x.repairman_device_status_id">
                <span class="status_span_label">{{ x.repairman_device_status_title }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>