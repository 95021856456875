<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">گزارش بانک</span>
        <span *ngIf="lang == 2">Bank report</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
            style='overflow-x:auto;direction:rtl;'>
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
                [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">

                <ng-container matColumnDef="check">
                    <mat-header-cell *matHeaderCellDef style="max-width:70px">

                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:70px">
                        <mat-radio-group aria-label="Select an option" name="account" formControlName="account"
                            (click)="get_bg(x.id)">
                            <mat-radio-button [value]="x.id"></mat-radio-button>
                        </mat-radio-group>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">شماره حساب</span>
                        <span *ngIf="lang == 2">Account number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;"
                        [ngStyle]="{backgroundColor:x.requests_reception == true ? '#e1b4b4' : 'transparent'}">
                        <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ x.title }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_number">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">نام دارنده حساب</span>
                        <span *ngIf="lang == 2">Account holder name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.site_bank_accounts_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_bank_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">نام بانک</span>
                        <span *ngIf="lang == 2">Bank name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.site_bank_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">نام شعبه</span>
                        <span *ngIf="lang == 2">Branch name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.site_bank_accounts_branch_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_remain">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">مانده حساب</span>
                        <span *ngIf="lang == 2">Account balance</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;direction: ltr;" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.balance | number }}</mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
                <mat-row (click)="mat_table_selectedRow = row" *matRowDef="let x;let i = index;columns:displayedColumns"
                    (keyup.enter)="select(x.id,x.title)" [ngClass]="{mat_table_selected:row_id == x.id}"></mat-row>
            </mat-table>
        </div>

        <div class="row pe1" [ngClass]="{'en1':dir == 'ltr'}">
            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="issue_date" class="wh-label" [ngClass]="{'en1':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تاریخ از: </span>
                    <span *ngIf="lang == 2"> Date from: </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker3" formControlName="date3" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker3" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="issue_date" class="wh-label" [ngClass]="{'en1':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تا تاریخ: </span>
                    <span *ngIf="lang == 2"> Date to: </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker4" formControlName="date4" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker4" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </div>
                </label>
            </div>

        </div>

    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="search()">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>