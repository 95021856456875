<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">دریافت چک</span>
        <span *ngIf="lang == 2">Receive a check</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> بابت</span>
                        <span *ngIf="lang == 2">Description</span>
                        <input class='wh-input' formControlName="title" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="amount" class='wh-label'>
                        <span *ngIf="lang == 1"> مبلغ چک </span>
                        <span *ngIf="lang == 2"> Check amount </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="bank" class='wh-label'>
                        <span *ngIf="lang == 1"> بانک </span>
                        <span *ngIf="lang == 2"> Bank </span>
                        <input class="wh-input" formControlName="bank" [required]="required()"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="account_number" class='wh-label'>
                        <span *ngIf="lang == 1"> شماره حساب </span>
                        <span *ngIf="lang == 2"> Account number </span>
                        <input class="wh-input" formControlName="account_number" required
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="number" class='wh-label'>
                        <span *ngIf="lang == 1"> شماره چک </span>
                        <span *ngIf="lang == 2"> Check number </span>
                        <input class="wh-input" formControlName="number" required [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="due" class="wh-label">
                        <span *ngIf="lang == 1">تاریخ سررسید</span>
                        <span *ngIf="lang == 2"> Due date </span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker2" formControlName="due"
                                [required]="required()" autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker"
                                [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="issue_date" class="wh-label">
                        <span *ngIf="lang == 1">تاریخ دریافت </span>
                        <span *ngIf="lang == 2">Date of reception</span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker" formControlName="issue_date"
                                [required]="required()" autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
                            <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                                [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="branch_name" class='wh-label'>
                        <span *ngIf="lang == 1"> عنوان شعبه </span>
                        <span *ngIf="lang == 2"> Branch name </span>
                        <input class="wh-input" formControlName="branch_name" [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="branch_code" class='wh-label'>
                        <span *ngIf="lang == 1"> کد شعبه </span>
                        <span *ngIf="lang == 2"> Branch name </span>
                        <input class="wh-input" formControlName="branch_code" [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="type_task == 1">
                    <label for="status" class='wh-label'>
                        <span *ngIf="lang == 1"> وضعیت چک </span>
                        <span *ngIf="lang == 2"> Status </span>
                        <span (click)="reset_input(190)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="status_title" (click)="load_services(190) "
                            [required]="type_task == 2 " readonly [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="type_task == 2 "
                            readonly [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
            </div>

            <!-- -------------------------------------------------------------------------------------------------------------- -->
            <div class="row" [hidden]="status_pass == 0">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="status_pass == 0">
                    <label for="bank2" class='wh-label'>
                        <span *ngIf="lang == 1"> واریز به بانک </span>
                        <span *ngIf="lang == 2"> Deposit to bank </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank2_title" (click)="load_services(178) " readonly
                            [required]="required() && status_pass == 1" [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="bank2" [hidden]="true" readonly
                            [required]="required() && status_pass == 1">
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="account2" class='wh-label' [hidden]="status_pass == 0">
                        <span *ngIf="lang == 1"> واریز به حساب </span>
                        <span *ngIf="lang == 2"> Deposit to bank </span>
                        <span (click)="reset_account(2)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account2_title" (click)="select_account2() " readonly
                            [required]="required() && status_pass == 1" [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="account2" readonly [hidden]="true"
                            [required]="required() && status_pass == 1">
                    </label>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------- -->
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || cheque_status == 2" class="btn_pe"
            [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <span *ngIf="lang == 1 && cheque_status == 2">چکهای خرج شده غیر قابل ویرایش می باشد</span>
        <span *ngIf="lang == 2 && cheque_status == 2">Spent checks cannot be edited</span>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>