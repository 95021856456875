import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-wharehouse-order-cost-detaile',
  templateUrl: './wharehouse-order-cost-detaile.component.html',
  styleUrls: ['./wharehouse-order-cost-detaile.component.scss']
})
export class WharehouseOrderCostDetaileComponent implements OnInit, OnDestroy {
  public isOnline: any;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any = this.serverService.get_server();
  lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  type_task: number;
  form1_group: FormGroup | any;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  user_id: number | any;
  id: number | undefined;
  code: number | undefined;
  title: string | undefined;
  creator: number | undefined;
  readonly: boolean = false;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<WharehouseOrderCostDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.create_form1();
      this.id = dialog_data.id;
      this.type_task = dialog_data.type_task;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      this.readonly = dialog_data.readonly;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
      if (this.type_task == 3) {
        this.creator = dialog_data.creator;
        this.form1_group.patchValue({
        })
      }
    }

  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'cost_type_title': new FormControl(null, [Validators.required]),
      'cost_type': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'cost': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),
    });
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 2) {
              this.update();
            }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6488, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'cost': res['result'][0].wharehouse_order_cost,
              'cost_type': res['result'][0].finance_cost_type_id,
              'cost_type_title': res['result'][0].finance_cost_type_title,
              'comment': res['result'][0].wharehouse_order_comment,
            });
          }//end if
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update(): any {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6489, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'cost_type': this.form1_group.value.cost_type
      , 'cost': this.form1_group.value.cost
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var where = "";
    var code_id;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 221) {
            this.form1_group.patchValue({
              'cost_type': res.id,
              'cost_type_title': res.title
            })
          }
        }
      }
    )
  }


  reset_input(id: number) {
    if (id == 221) {
      this.form1_group.patchValue({
        'cost_type': null,
        'cost_type_title': null,
      })
    }
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

