<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    {{ serverService.get_title(type_task,lang) }}
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>
<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-sm-12 col-xs-12 pe div_panding' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="group" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه : </span>
                    <span *ngIf="lang == 2"> Group : </span>
                    <span (click)="reset_input(223)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223)"
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="group" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-sm-12 col-xs-12 pe div_panding' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cate" class=' wh-label'>
                    <span *ngIf="lang == 1"> زیرگروه : </span>
                    <span *ngIf="lang == 2"> Subgroup : </span>
                    <span (click)="reset_input(184)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">
                            close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184)" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cate" [hidden]="true" readonly>
                </label>

            </div>
        </div>

    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>

</form>