<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span [ngClass]="{'en':dir == 'ltr'}">{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row" *ngIf="document_type_text == 'input'">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 right" style="padding:0px"
            *ngIf="readonly != true">
            درج شماره سریال جدید
            <span (click)="insert()" class="add_circle_outline cursor">
                <mat-icon>add_circle_outline</mat-icon>
            </span>
        </div>

        <ng-container *ngIf="list_record.length > 0">
            <div class='col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' style='margin-top:5px'
                *ngFor="let x of list_record;let i = index">
                <label for="title" class='wh-label'>
                    <span class='trash' (click)="delete(i,x.wharehouse_order_sale_searial_id)"
                        *ngIf="readonly == false"><i class="far fa-trash-alt icon" style="margin: 0px 4px;"></i>
                    </span>
                    <span *ngIf="lang == 1"> شماره سریال :</span>
                    <span *ngIf="lang == 2"> Serial number :</span>
                    <input class='wh-input' autocomplete="off" [value]="x.wharehouse_order_sale_searial_title"
                        (change)="update(i,x.wharehouse_order_sale_searial_id,$event)" [ngClass]="{bg1: i == index}"
                        [readonly]="readonly">
                </label>
            </div>
        </ng-container>

        <ng-container *ngIf="list_record.length == 0">
            <div class="row" class="preview1" [hidden]="count_total > 0">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <img src="../../../assets//images/preview.png">
                    </div>
                    <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
                </div>
            </div>
        </ng-container>

    </div>


    <div class="row" *ngIf="document_type_text == 'exit'">
        <div class="row" *ngIf="list_record.length > 0">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 right" style="padding:0px"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <span *ngIf="lang == 1">در قسمت پایین می توانید شماره سریال کالای فروخته شده را انتخاب کنید</span>
                <span *ngIf="lang == 2">Please select serial number of the sold item</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 right pe padding0"
                *ngFor="let x of list_record;let i = index" [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                <span>
                    <mat-checkbox name="searial[]" value="{{ x.wharehouse_order_sale_searial_id }}"
                        (change)="select_items(1)" [checked]="x.checked"
                        [disabled]="searial_used.indexOf(x.wharehouse_order_sale_searial_id) != -1 || readonly == true">
                    </mat-checkbox>
                </span>
                <span style="vertical-align: top;">
                    <span *ngIf="searial_used.indexOf(x.wharehouse_order_sale_searial_id) != -1"><del>{{
                            x.wharehouse_order_sale_searial_title }}</del></span>
                    <span *ngIf="searial_used.indexOf(x.wharehouse_order_sale_searial_id) == -1">{{
                        x.wharehouse_order_sale_searial_title }}</span>
                </span>
            </div>
        </div>
        <ng-container *ngIf="list_record.length == 0">
            <div class="row" class="preview1" [hidden]="count_total > 0">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <img src="../../../assets//images/preview.png">
                    </div>
                    <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions align="start" class="pe1 matDialogActions" style="padding-right:25px" *ngIf="readonly != true">
    <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}"
        *ngIf="document_type_text == 'exit' && list_record.length > 0" (click)="select_items(2)">
        <span *ngIf="lang == 1">ویرایش</span>
        <span *ngIf="lang == 2">Edit</span>
    </button>
</div><!-- row -->