<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ title }}
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="mat-dialog-content">
        <div class="row pe1" [ngClass]="{en1:lang == 2}">
            <div class="col-lg-4 col-col-4 col-sm-4 col-md-4 col-xs-12 pe">
                <label class="wh-label">
                    <span *ngIf="lang == 1 ">عنوان منو</span>
                    <span *ngIf="lang == 2 ">Title menu</span>
                    <input class="wh-input" autocomplete="off" formControlName="title" required>
                </label>
            </div>
        </div>


        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12" style="float: right;">
                <mat-card class="example-card background-image  cursor"
                    [ngStyle]="{'background-image':'url( ' + logo_src + ')'}" (click)="logo.click()">
                    <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                    </mat-card-header>
                </mat-card>
                <span (click)="delete_logo()" *ngIf="logo_bin == true"><i class="far fa-trash-alt icon"
                        style="margin: 0px 4px;"></i></span>
                <input type="file" (change)="change_logo($event)" accept="image/*" style="display: none;" #logo>
            </div>
        </div>


    </div><!-- content -->

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe">ذخیره</button>
        <button type="button" class="btn_pe" (click)="open_gaccess()">دسترسی گروهها</button>
        <button type="button" class="btn_pe" (click)="open_paccess()">دسترسی افراد</button>
        <button type="button" class="btn_pe" (click)="open_video()">فیلم آموزشی</button>
    </div>
</form>