<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>

    <span>
        <span *ngIf="lang == 1">درج جدید
            <span *ngIf="service == 'brand' ">برند</span>
            <span *ngIf="service == 'device' ">دستگاه</span>
            <span *ngIf="service == 'cate' ">زیرگروه</span>
            <span *ngIf="service == 'group' ">گروه</span>
            <span *ngIf="service == 'cost' ">هزینه</span>
            <span *ngIf="service == 'income' ">درآمد</span>
            <span *ngIf="service == 'device_status' ">وضعیت دستگاه</span>
        </span>
        <span *ngIf="lang == 2">New insert</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="save()">
    <div mat-dialog-content class="matDialogContent" style="padding:0.5rem 0rem !important">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان </span>
                    <span *ngIf="lang == 2"> Title </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [hidden]="service != 'cost'"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cost_group" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان گروه هزینه </span>
                    <span *ngIf="lang == 2"> Cost group title </span>
                    <span (click)="reset_input(254)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_group_title" (click)="load_services(254)" readonly
                        [required]="service == 'cost'">
                    <input class="wh-input" formControlName="cost_group" [hidden]="true" readonly
                        [required]="service == 'cost'">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}"
                [hidden]="service != 'brand' ">
                <label for="title2" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان انگلیسی </span>
                    <span *ngIf="lang == 2"> English title </span>
                    <input class='wh-input' formControlName="title2" autocomplete="off">
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 1">ذخیره</span>
            <span *ngIf="lang == 2">Save</span>
        </button>
    </div>
</form>