
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-first-balance',
  templateUrl: './user-first-balance.component.html',
  styleUrls: ['./user-first-balance.component.scss']
})
export class UserFirstBalanceComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server_main();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public code: any;
  public id: number;
  public user_id: number;
  public form1: FormGroup;
  public type_task: number;
  public creator: number;
  public access: boolean = false;
  public level: number;
  public access_service: number;
  public list_status: any = [];
  public finance_id: number | undefined;
  public count_finance: number | undefined;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<UserFirstBalanceComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = 161;
      this.check_access(4);
    }
  }//end consructor

  ngOnInit() {

  }

  create_form1() {
    this.form1 = new FormGroup({
      'finance_nature_title': new FormControl(null),
      'finance_nature': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
      'balance': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
    });
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          //this.loading = false;
          if (type_task == 1) { }
          if (type_task == 2) { this.update_user(); }
          if (type_task == 3) { }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6652, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1.patchValue({
              'finance_nature_title': res['result'][0].finance_nature_title,
              'finance_nature': res['result'][0].finance_nature_id,
              'balance': res['result'][0].user_balance,
            });
            this.finance_id = res['result'][0].finance_financial2_id;
            this.count_finance = res['result'][0].count_finance;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_user() {
    var obj = {
      address: 6653,
      user_id: this.user_id
      , id: this.id
      , finance_nature: this.form1.value.finance_nature
      , balance: this.form1.value.balance
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.check();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check() {
    if (this.count_finance == 0) {
      this.insert_new_finnace(this.id);
    } else {
      this.update_finance();
    }
  }

  insert_new_finnace(user) {
    var obj = {
      address: 6224, user: user, creator: this.creator, user_id: this.user_id
      , amount: this.form1.value.balance
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close();
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_finance() {
    var obj = {
      address: 6225, finance_id: this.finance_id, user_id: this.user_id
      , finance_nature: this.form1.value.finance_nature
      , amount: this.form1.value.balance
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close()
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  //**************************************************************** */
  load_services(id: number) {
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, creator: this.creator
        , obj: { level: this.level, lang: this.lang }
      }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 218) {
            this.form1.patchValue({
              'finance_nature_title': res.title,
              'finance_nature': res.id
            })
          }
        }
      }
    )
  }


  reset_input(id: number) {
    if (id == 218) {
      this.form1.patchValue({
        'finance_nature_title': null,
        'finance_nature': null,
        'balance': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
