import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewFastMaterialComponent } from '../../wharehouse/new-fast-material/new-fast-material.component';
import { WharehouseOrderServiceDetaileComponent } from '../wharehouse-order-service-detaile/wharehouse-order-service-detaile.component';
import { OrderDetaileComponent } from '../../wharehouse/wharehouse-order/order-detaile/order-detaile.component';
import { WharehouseOrderSaleDetaileComponent } from '../wharehouse-order-sale-detaile/wharehouse-order-sale-detaile.component';
import { MessageService } from '../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';
import Num2persian from 'num2persian';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-wharehouse-exit2',
  templateUrl: './wharehouse-exit2.component.html',
  styleUrls: ['./wharehouse-exit2.component.scss'],
  providers: [DecimalPipe]
})
export class WharehouseExit2Component implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public list_record: any = [];
  public list_record_exit: any = [];
  public search: string | undefined;
  public page: number = 1;
  public invoice_id: number | undefined;
  public group: number | undefined;
  public cate: number | undefined;
  public reception_id: number | undefined;
  public exit_id: number | undefined;
  public list_material_type: any = [];
  public list_device_status: any = [];
  public material_type: number = 0;
  public ref: string | undefined;
  public type: number | undefined;
  public list_invoice: any = [];
  public new_invoice_id: number;
  public sum_all: number = 0;
  public sum_all_services: number;
  public sum_all_material: number;
  public code: number | undefined;
  public id: number | undefined;
  public list_rstatus: any = [];
  public list_rstatus2: any = [];
  public status_id: number | undefined;
  public shelf_temp: number | undefined;
  public level: number = 0;
  public invoice_price1: number = 0;
  public creator: number | undefined;
  public discount_all: number = 0;
  public sum_all_without_discount: number = 0;
  public readonly: boolean = false;
  public currency_title: string | undefined;
  public sum_all_letter: string | undefined;
  public customer_cellphone: string | undefined;
  public customer_title: string | undefined;
  public rstatus_temp: number | undefined;
  public default_rstatus: number | undefined;
  public repairing: boolean = false;
  public repairman: any | undefined;
  public delivery: number | undefined;
  public count_exit: number = 0;
  public count_order: number = 0;
  public device_status: number | undefined;
  public count_total_service: number | undefined;
  public count_total_material: number | undefined;
  public list_repairman: any = [];
  public order_id: number = 0;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public show_cellphone: boolean = true;
  public device_title: string;
  public brand_title: string;
  public model_title: string;
  public displayedColumns = ['row', 'material', 'seller', 'inventory', 'invoice_price', 'number', 'cost', 'add'];
  public displayedColumns2 = ['row', 'title', 'repairman', 'comment', 'number', 'cost', 'add'];
  public displayedColumns3 = ['row', 'type', 'material', 'number', 'cost', 'discount', 'sum', 'repairman', 'searial', 'comment', 'operation'];
  public displayedColumns4 = ['row', 'material', 'group', 'cate', 'brand', 'number', 'operation'];

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<WharehouseExit2Component>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public essageService: MessageService
    , public _decimalPipe: DecimalPipe) {

    this.subscription2 = this.serverService.get_material().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
    if (dialog_data) {
      this.default_rstatus = dialog_data.default_rstatus;
      this.invoice_id = dialog_data.invoice_id;
      this.ref = dialog_data.ref;
      this.group = dialog_data.group;
      this.exit_id = dialog_data.exit_id;
      this.cate = dialog_data.cate;
      this.type = dialog_data.type;
      this.reception_id = dialog_data.reception_id;
      this.code = dialog_data.code;
      this.id = dialog_data.reception_id;
      this.creator = dialog_data.creator;
      this.order_id = dialog_data.order_id;
      this.level = dialog_data.level;
      if (dialog_data.readonly) this.readonly = dialog_data.readonly;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_rstatus();
    this.get_material_type();
    this.get_currnecy();
    this.get_customer();
    this.get_device_status();
  }

  get_customer() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6216, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.customer_title = res['result'][0].user_title;
            this.customer_cellphone = res['result'][0].user_cellphone;
            this.device_status = res['result'][0].repairman_reception_device_status;
            this.device_title = res['result'][0].wharehouse_device_title;
            this.brand_title = res['result'][0].repairman_brand_title;
            this.model_title = res['result'][0].repairman_model_title;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_currnecy() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title = res['result'][0].site_currency_title;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  change_status(delivery: number, repairing: boolean) {
    this.repairing = repairing;
    this.delivery = delivery;

  }

  save_status(type: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6104
      , id: this.id
      , device_status: this.device_status
      , status_id: this.status_id
      , creator: this.creator, type: type
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var confirm_sms = false;
          if ((this.rstatus_temp != res['result'][0].repairman_reception_status || type == 2) && res['result'][0].user_sms2 == 1) {
            confirm_sms = window.confirm("آیا میخواهید " + res['result'][0].user_sex_title + " " + res['result'][0].user_title + " از تغییر وضعیت مطلع نمائید؟");
          }
          res['result'][0].confirm_sms = confirm_sms;

          if (res['result'][0].repairman_rstatus_empty_shelf == 1) {
            this.empty_shelf(this.shelf_temp, 0, 'empty_reception');
          } else {
            this.serverService.send_reception_repairman();
          }
          this.matDialogRef.close(res['result'][0]);
          this.serverService.send_payments(1);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  empty_shelf(shelf: any, new_shelf: number, type: string) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, type: type, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_reception_repairman();
          this.serverService.send_payments(1);
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_repairman() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6458, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_repairman = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_repairman.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_reception() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6103, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.status_id = parseInt(res['result'][0].repairman_reception_status);
            this.rstatus_temp = res['result'][0].repairman_reception_status;
            this.shelf_temp = res['result'][0].repairman_reception_shelf;
            this.repairman = res['result'][0].repairman_reception_repairman;
            this.delivery = res['result'][0].repairman_rstatus_deliverd;
            this.repairing = res['result'][0].repairman_rstatus_repairing;
          }
          this.get(1, 1, 0);
          this.serverService.send_payments(1);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_rstatus() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 1993, creator: this.creator, lang: this.lang }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_show_menu == 1) {
              this.list_rstatus.push(res['result'][i]);
            } else {
              this.list_rstatus2.push(res['result'][i]);
            }
          }//end for

          this.get_reception();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_material_type() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1986 }).subscribe(
      (res: any) => {
        this.list_material_type = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_material_type.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  which(page) {
    if (page == 2) { this.page = this.page + 1; }
    if (page == 3) { this.page = this.page - 1; }
    this.get(1, this.page, this.material_type);
  }

  get_search() {
    if (this.material_type > 0) {
      this.get(2, 1, this.material_type);
    } else {
      this.get_exit(2, 1);
    }
  }

  get(loading: number, page: number, id: number) {
    this.page = page;
    this.material_type = id;

    if (id == 1) {
      this.get_order_material(loading);
    }

    else if (id == 2) {
      this.get_services(loading);
    }
    else {
      this.get_exit(1, 1);
      this.get_order_count_material();
      this.get_order_count_service();
    }
  }

  get_order_count_material() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6475, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_total_material = res['count'];
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_order_count_service() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6474, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_total_service = res['count'];
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_order_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }

    var obj = {
      address: 6435, search: this.search, page: this.page
      , group: this.group, cate: this.cate, creator: this.creator, ref: 'reception', 'document_type_text': 'exit'
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            //res['result'][i].inventory = res['result'][i].wharehouse_order_number - res['result'][i].order_consumed;
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total_material = res['count'];
          this.pages(0);
          this.get_repairman();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
      this.search = '';
    }

    var obj = {
      address: 6261, search: this.search, page: this.page
      , group: this.group, cate: this.cate, creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total = res['count'];
          this.pages(0);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }



  delete_material(i: number, id: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6039, "user_id": this.user_id, 'id': id, page: this.page, search: []
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(i, 1);
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true      
  }//end delete 
  //******************************************************* */
  get_services(loading) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
      this.search = '';
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1987, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource.data = this.list_record;
          this.count_total = res['count'];
          this.pages(0);
          this.get_repairman();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_services(i: number, id: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 1981, "user_id": this.user_id, 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(i, 1);
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true       
  }//end delete 

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 10 - 9;
    this.page_number_second = this.page * 10;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 10) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  select(id: number, title: string) {
    this.matDialogRef.close({ title: title, id: id });
  }
  //************************************************************************************************  */
  add() {
    const dialogRef = this.dialog.open(NewFastMaterialComponent, {
      width: '22rem',
      height: 'auto',
      data: { group: this.group, cate: this.cate, material_type: this.material_type, creator: this.creator, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }

  //*************************************************************************************************************************************** */
  check_reception_for_material(i: number, material_id: number, order_id: number, number1: number, material_type: number, cost: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6567, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.save_material(i, material_id, order_id, number1, material_type, cost);
        }//end if
        else {
          var pe_message = "این پذیرش قبلا حذف شده است.";
          var en_message = "This admission has already been removed.";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  save_material(i: number, material_id: number, order_id: number, number1: number, material_type: number, cost: any) {
    if (this.ref == 'exit') {
      //فروشنده
      var repairman_id = <any>document.getElementById("repairman" + order_id);
      repairman_id = repairman_id.value;
      //قیمت فروش
      var cost1 = <any>document.getElementById("cost1" + order_id);
      var price_sale = parseInt(cost1.value.match(/\d/g).join(""));
      //تعداد فروش
      var input1 = <any>document.getElementById("input1" + order_id);

      var number_sale = input1.value;
      this.check_inventory2(i, material_id, order_id, repairman_id, material_type, price_sale, number_sale, cost, order_id);

    }
    else {
      var input1 = <any>document.getElementById("input1" + order_id);
      var number = input1.value;
      this.add_to_bascket_order(material_id, number);
    }
  }
  //************************************************************************************************** */
  check_inventory2(i: number, material_id: number, order_id: number, repairman_id: number, material_type: number, price_sale: number, number_sale: number, price_buy: number, number_invoice_buy: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!number_sale || number_sale == 0 || price_sale < 0) {
      var pe_message = "لطفا تعداد و هزینه درخواستی را مشخص نمائید";
      var en_message = "Please specify the requested quantity and cost";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6134, material_id: material_id, exit_id: this.exit_id, order_id: number_invoice_buy }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (number_sale <= res['remain']) {
            this.add_to_bascket_exit(i, material_id, repairman_id, number_sale, material_type, price_sale, price_buy, number_invoice_buy);
            this.message(false, "", 1, this.messageService.close(this.lang));
          } else {
            var pe_message = "تعدادی درخواستی بیشتر ار موجودی انبار می باشد .لطفا اصلاح نمائید.";
            var en_message = "The number of requests is more than the stock. Please correct.";
            this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_reception_for_services(i: number, material_id: number, repairman: number, number_sale: number, material_type: number, price_sale: any, price_buy: any, number_invoice_buy: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6567, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.add_to_bascket_exit(i, material_id, repairman, number_sale, material_type, price_sale, price_buy, number_invoice_buy);
        }//end if
        else {
          var pe_message = "این پذیرش قبلا حذف شده است.";
          var en_message = "This reception has already been removed.";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_to_bascket_exit(i: number, material_id: number, repairman: number, number_sale: number, material_type: number, price_sale: any, price_buy: any, number_invoice_buy: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    var repairman_id;
    if (material_type == 2) {
      repairman_id = <any>document.getElementById("repairman" + material_id);
      repairman_id = repairman_id.value;
      var cost2 = <any>document.getElementById("cost2" + material_id);
      price_sale = parseInt(cost2.value.match(/\d/g).join(""));
      var input2 = <any>document.getElementById("input2" + material_id);
      var number = input2.value;
      var comment2 = <any>document.getElementById("comment2" + material_id);
      var comment = comment2.value;
    }
    else {
      repairman_id = repairman;

      if (price_buy > price_sale) {
        var x = "قیمت فروش کمتر از قیمت خرید شده است. آیا می خواهید ادامه دهید؟";
        if (this.lang == 2) x = "The selling price is lower than the purchase price. Do you want to continue?";
        var confirm = window.confirm(x);
        if (confirm == false) {
          this.message(false, "", 1, this.messageService.close(this.lang));
          return false;
        }
      }
    }
    this.loading = true;
    var obj = {
      address: 6480, reception_id: this.reception_id, id: material_id, number: number_sale, order_id: number_invoice_buy,
      type: this.type, user_id: this.user_id, creator: this.creator, repairman_id: repairman_id
      , material_type: material_type, cost: price_sale, comment: comment
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record[i].inventory = this.list_record[i].inventory - number_sale;

          this.dataSource.data = this.list_record;
          this.update_financial2();

          if (this.material_type == 1) {
            this.get_update_material_remain(res['result'][0].wharehouse_order_material);
            this.get_update_order_consumed(res['result'][0].wharehouse_order_consumed_invoice);
            this.check_financial2_for_repairman(1, number_sale, price_buy, price_sale, repairman_id, material_id, res['result'][0].wharehouse_order_id);
          } else {
            this.check_financial2_for_repairman(2, number_sale, price_buy, price_sale, repairman_id, material_id, res['result'][0].wharehouse_order_id);
          }
          this.serverService.send_bascket(res['result'][0]);
          this.count_exit++;
          var pe_message = "با موفقیت اضافه شد";
          var en_message = "Added successfully";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_material_remain(material_id) {
    var obj = {
      address: 6445,
      material_id: material_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_order_consumed(order_id: number) {
    var obj = {
      address: 6446,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***********************************************************************************************  */
  add_to_bascket_order(id: number, number: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 1983, reception_id: this.reception_id, id: id, number: number,
      type: this.type
      , material_type: 1
      , ref: this.ref
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.update_financial2();

          this.serverService.send_bascket(res['result'][0]);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_financial2_for_repairman(type_ref2: number, number: Number, price_buy: number, amount: number, repairman_id: number, material_id: number, order_id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6637, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].user_document_finance == 1) {
            this.insert_financial2_for_repairman(type_ref2, number, price_buy, amount, repairman_id, material_id, order_id);
          }
        }//end if 
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert_financial2_for_repairman(type_ref2: number, number: Number, price_buy: number, amount: number, repairman_id: number, material_id: number, order_id: number) {
    var obj = {
      address: 6624
      , user_id: this.user_id
      , creator: this.creator
      , reception_id: this.reception_id
      , repairman_id: repairman_id
      , number: number
      , amount: amount
      , type_ref2: type_ref2
      , material_id: material_id
      , order_id: order_id
      , price_buy: price_buy
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_financial2() {
    var obj = {
      address: 6108,
      id: this.reception_id,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_payments(1);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************* */
  check_access3(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: 160 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.show_cellphone = res['show_cellphone'];
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            if (type_task == 3) {
              //this.delete_exit(id);
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_exit(loading: number, page: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.page = page;
    this.material_type = 0;
    if (loading == 1) {
      this.loading = true;
      this.search = '';
    }
    this.material_type = 0;
    var obj = {
      address: 1862
      , reception_id: this.id
      , page: this.page
      , user_id: this.user_id
      , search: this.search
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record = [];
          this.sum_all = 0;
          this.sum_all_material = 0;
          this.sum_all_services = 0;
          this.discount_all = 0;
          this.sum_all_without_discount = 0;
          this.count_order = res['num'];
          for (var i = 0; i < res['num']; i++) {
            //res['result'][i].sum = res['result'][i].wharehouse_order_number * res['result'][i].cost;
            this.sum_all += res['result'][i].sum;
            this.sum_all_letter = Num2persian(this.sum_all);
            this.sum_all_without_discount += res['result'][i].cost;
            this.discount_all += res['result'][i].wharehouse_order_discount;

            if (res['result'][i].wharehouse_order_material_type == 1) {
              this.sum_all_material += res['result'][i].sum;
            }
            if (res['result'][i].wharehouse_order_material_type == 2) {
              this.sum_all_services += res['result'][i].sum;
            }
            this.list_record.push(res['result'][i]);
          }//end for
          this.count_exit = res['num'];
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total = res['count'];
          this.pages(0);
          this.serverService.send_payments(1);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data

  get_device_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6465, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_device_status = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_device_status.push(res['result'][i]);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_for_access(i: number, type_task: number, id: number, title: string, footer: boolean, material_type: number, creator: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_repairman == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.open_order_sale(i, id)
              } else {
                this.open_services(i, id);
              }
              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              var en_message = "You do not have access to make changes";
              this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_order_sale(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderSaleDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, code: 160, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].sum = res.result.sum;
          this.get_update_material_remain(res.result.wharehouse_material_id);
          this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          this.get_amount_exit();
          this.update_financial2();
          this.serverService.send_payments(1);
        }
      }
    )
  }


  open_detaile_exit(i: number, type_task: number, id: number, title: string, footer: boolean, material_type: number, repairman: number): any {
    const dialogRef = this.dialog.open(OrderDetaileComponent, {
      width: '45rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, material_type: material_type, document_type_text: 'exit', i: i, code: 160, title: title, reception_id: this.id, group: this.group, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].repairman_title = res.result.repairman_title;
            this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
            this.list_record[i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
            this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
            this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
            this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
            this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
            this.list_record[i].sum = res.result.sum;
            this.list_record[i].title = res.result.title;
            this.list_record[i].cost = res.result.cost;
            this.list_record[i].sum = res.result.sum;
            this.list_record[i].sum_without_discount = res.result.sum_without_discount;
          }
          if (material_type == 1) {
            this.get_update_material_remain(res.result.wharehouse_material_id);
            this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          }
          this.get_amount_exit();
          this.update_financial2();
        }
        this.serverService.send_payments(1);
      }
    )
  }

  open_services(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderServiceDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: 160, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].title = res.result.title;
          this.list_record[i].cost = res.result.cost;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].sum_without_discount = res.result.sum_without_discount;
          this.get_amount_exit();
          this.update_financial2();
          this.serverService.send_payments(1);
        }
      }
    )
  }


  access_for_delete(id: number, repairman: number, material_type: number, creator: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_repairman == 1 || this.level == 2 || creator == this.user_id) {
              this.delete_exit(id, repairman, material_type);
            } else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              var en_message = "You do not have access to delete";
              this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          var en_message = "Error checking access";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_exit(id: number, repairman: number, material_type: number) {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 1863, "user_id": this.user_id
        , 'id': id, "page": 1,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (material_type == 1) {
              for (var i = 0; i < this.list_record.length; i++) {
                if (this.list_record[i].wharehouse_order_id == id) {
                  this.get_update_material_remain(this.list_record[i].wharehouse_order_material);
                  this.get_update_order_consumed(this.list_record[i].wharehouse_order_consumed_invoice);
                }
              }
            }
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
            this.get_amount_exit();
            this.update_financial2();
            if (material_type == 1) this.delete_financial2_id_for_repairman(id, 6627);
            if (material_type == 2) this.delete_financial2_id_for_repairman(id, 6629);
            this.serverService.send_payments(1);
            this.count_exit--;
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    

  }//end delete

  update_order_material_delete(number: number, consumed_invoice: number, material_id: number) {
    var obj = {
      address: 6442,
      number: number,
      consumed_invoice: consumed_invoice,
      material_id: material_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_financial2_id_for_repairman(id: number, address: number) {
    var obj = {
      address: address
      , id: id
      , user_id: this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].finance_financial2_id > 0) {
            this.delete_financial2_id_for_repairman2(res['result'][0].finance_financial2_id);
          }
        }//end if
        else {
          //this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_financial2_id_for_repairman2(id: number) {
    var obj = {
      address: 6631
      , id: id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_amount_exit() {
    this.sum_all = 0;
    this.sum_all_material = 0;
    this.sum_all_services = 0;
    this.discount_all = 0;
    this.sum_all_without_discount = 0;

    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all = this.sum_all + this.list_record[i].sum;
      this.sum_all_without_discount = this.sum_all_without_discount + this.list_record[i].cost;

      if (this.list_record[i].wharehouse_order_material_type == 1) {
        this.sum_all_material += this.list_record[i].sum;
      }
      if (this.list_record[i].wharehouse_order_material_type == 2) {
        this.sum_all_services += this.list_record[i].sum;
      }
      this.discount_all += this.list_record[i].wharehouse_order_discount;
    }

  }

  open_serial(i: number, id: number, title: string, order_buy_id: number) {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, order_buy_id: order_buy_id, document_type_text: 'exit' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.list_record[i].searial = res.result.searial;
      }
    )
  }
  //************************************************************************************************  */

  close() {
    this.matSnackBar.dismiss();
    this.matDialogRef.close();

  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}