import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-insert-belong',
  templateUrl: './insert-belong.component.html',
  styleUrls: ['./insert-belong.component.scss']
})
export class InsertBelongComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number;
  public loading = false;
  public subscription: Subscription | any;
  public device: number | undefined;
  public brand: number | undefined;
  public form1_group: FormGroup | any;
  public model: number | undefined;
  public creator: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<InsertBelongComponent>) {

    if (dialog_data) {
      this.device = dialog_data.device;
      this.creator = dialog_data.creator;
    }

  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
  }

  create_form() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, Validators.required),
      'comment': new FormControl(null),
    })
  }

  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    if (!this.device) {
      var pe_message = "برای ثبت متعقات باید دستگاه مشخص باشد";
      var pe_action = "To register the belongings, the device must be specified";
      this.message(true, this.messageService.message(this.lang, pe_message, pe_action), 1, this.messageService.close(this.lang));
      return false;
    }
    var obj = {
      address: 1975, user_id: this.user_id, status_id: 1,
      device: this.device,
      title: this.form1_group.value.title,
      comment: this.form1_group.value.comment,
      creator: this.creator
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.insert_model(res['result'][0].repairman_belong_id);
          } else {
            this.insert_belong();
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان مدل برای این دستگاه قبلا در سیستم ثبت شده است";
          var en_action = "This model title for this device has already been registered in the system";
          this.message(true, this.messageService.message(this.lang, pe_message, en_action), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert_model(id: number) {
    var obj = {
      address: 1710, user_id: this.user_id, status_id: 1,
      id: id,
      title: this.form1_group.value.title,
      comment: this.form1_group.value.comment,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(res['result'][0])
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان مدل برای این دستگاه قبلا در سیستم ثبت شده است";
          var en_action = "This model title for this device has already been registered in the system";
          this.message(true, this.messageService.message(this.lang, pe_message, en_action), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }


  insert_belong() {
    var obj = {
      address: 1976, user_id: this.user_id, status_id: 1,
      device: this.device,
      title: this.form1_group.value.title,
      comment: this.form1_group.value.comment,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.insert_model(res['result'][0].repairman_belong_id);
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) { this.loading = false; }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
