<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">حساب ها</span>
        <span *ngIf="lang == 2">Accounts</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div class="matDialogContent" mat-dialog-content style="overflow-y: hidden !important;">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;' (window:resize)="change_resize()">

        <div class="dialog_div_buttons">
            <button type="button" (click)="open_detaile(0,1,0,0,'','')" [disabled]='new_status' *ngIf="lang == 1"
                [hidden]="ref == 'cheque'">جديد</button>
            <button type="button" (click)="open_detaile(0,1,0,0,'','')" [disabled]='new_status' *ngIf="lang == 2"
                [ngClass]="{en_navigation_number:lang==2}" [hidden]="ref == 'cheque'">New</button>
        </div>

        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + page_number_first }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">نام دارنده حساب</span>
                    <span *ngIf="lang == 2">Account holder</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.site_bank_accounts_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="branch_title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">نام شعبه</span>
                    <span *ngIf="lang == 2">Branch name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.site_bank_accounts_branch_title }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="cart_number">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره حساب</span>
                    <span *ngIf="lang == 2">Account number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.site_bank_accounts_number }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="account_number">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره کارت</span>
                    <span *ngIf="lang == 2">Card number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.site_bank_accounts_cart }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="default">
                <mat-header-cell *matHeaderCellDef [hidden]="ref == 'reception' || ref == 'cheque'">
                    <span *ngIf="lang == 1">پیش فرض</span>
                    <span *ngIf="lang == 2">Default</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [hidden]="ref == 'reception' || ref == 'cheque'">
                    <span (click)='change_default(i,x.site_bank_accounts_default,x.site_bank_accounts_id)'>
                        <span *ngIf="x.site_bank_accounts_default == 0"><i class="far fa-star icon"></i> </span>
                        <span *ngIf="x.site_bank_accounts_default == 1"><i class="fas fa-star icon"
                                style="color: blue;"></i> </span>
                    </span>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:70px'
                    [hidden]="ref == 'cheque' || ref == 'reception'">
                    <span *ngIf="lang == 1"> عملیات </span>
                    <span *ngIf="lang == 2">Operation</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'
                    [hidden]="ref == 'cheque' || ref == 'reception'">
                    <span (click)="check_access(3,0,x.site_bank_accounts_id,0,1)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0 || ref == 'cheque'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview2.png">
                </div>
                <div class="preview2" *ngIf="lang == 1">هیچ رکوردی برای نمایش وجود ندارد</div>
                <div class="preview2" *ngIf="lang == 2">There are no records to display</div>
            </div>
        </div>

    </div>
</div>