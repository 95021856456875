import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OrderDetaileComponent } from '../wharehouse-order/order-detaile/order-detaile.component';
import { ExitDetaile2Component } from '../wharehouse-exit/exit-detaile2/exit-detaile2.component';
import { WharehouseOrderServiceDetaileComponent } from '../wharehouse-order-service-detaile/wharehouse-order-service-detaile.component';
import { WharehouseOrderSaleDetaileComponent } from '../wharehouse-order-sale-detaile/wharehouse-order-sale-detaile.component';
import { NewFastMaterialComponent } from '../new-fast-material/new-fast-material.component';
import { MessageService } from '../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';


import { DecimalPipe } from '@angular/common';
import Num2persian from 'num2persian';

@Component({
  selector: 'app-wharehouse-invoice-exit',
  templateUrl: './wharehouse-invoice-exit.component.html',
  styleUrls: ['./wharehouse-invoice-exit.component.scss'],
  providers: [DecimalPipe]
})
export class WharehouseInvoiceExitComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server_main();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public subscription1: Subscription | any;
  public list_record: any = [];
  public search: string | undefined;
  public page: number = 1;
  public invoice_id: number | undefined;
  public device: number | undefined;
  public cate: number | undefined;
  public reception_id: number | undefined;
  public exit_id: number | undefined;
  public list_material_type: any = [];
  public material_type: number = 1;
  public ref: string | undefined;
  public type: number | undefined;
  public list_invoice: any = [];
  public new_invoice_id: number;
  public sum_all: number = 0;
  public remain: number = 0;
  public sum_all_letter: string | undefined;
  public remain_letter: string | undefined;
  public code: number = 0;
  public id: number | undefined;
  public token: number | undefined;;
  public creator: any | undefined;
  public type_list: string = 'material';
  public invoice_price1: number = 0;
  public sum_all_real: number = 0;
  public document_type: number | undefined;
  public date3: number | undefined;
  public x: number = 0;
  public x2: number = 0;
  public x3: number = 0;
  public list_repairman: any = [];
  public requests_id: number = 0;
  public count_order: number = 0;
  public level: number | undefined;
  public back: number = 0;//این فیلد برای زمانی هستش که می خواهید بگوئییم نوع فاکتور برگشت هستش یا خیر
  public user: number | undefined;//این برای موقعی است که می خواهیم نام ظرف حساب در فاکتور ها چه کسی هستش
  public count_total: number = 1;
  public count_total_service: number = 0;
  public count_total_material: number = 0;
  public page_number_first: number | undefined; public page_number_second: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public document_type_text: string | undefined;
  public invoice1_id: number | undefined;
  public access_price_column_buy: boolean = false;
  public displayedColumns3 = ['row', 'title', 'repairman', 'number', 'cost', 'comment', 'add'];
  public displayedColumns2 = ['row', 'material', 'seller', 'inventory', 'order', 'invoice_price', 'number', 'cost', 'discount', 'add'];
  public displayedColumns = ['row', 'type', 'material', 'title', 'number', 'weight', 'price', 'discount', 'sum', 'repairman', 'searial', 'operation'];



  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<WharehouseInvoiceExitComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public _decimalPipe: DecimalPipe) {

    this.subscription2 = this.serverService.get_material().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )

    if (dialog_data) {
      this.invoice_id = dialog_data.invoice_id;
      this.requests_id = dialog_data.requests_id;
      this.date3 = dialog_data.date3;
      this.token = dialog_data.token;
      this.ref = dialog_data.ref;
      this.document_type_text = dialog_data.document_type_text;
      this.document_type = dialog_data.document_type;
      this.device = dialog_data.device;
      this.exit_id = dialog_data.exit_id;
      this.cate = dialog_data.cate;
      this.type = dialog_data.type;
      this.reception_id = dialog_data.reception_id;
      this.code = dialog_data.code;
      this.id = dialog_data.reception_id;
      this.back = dialog_data.back;
      this.user = dialog_data.user;
      this.count_order = dialog_data.count_order;
      this.level = dialog_data.level;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
  }

  check_access(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 4) {
              this.get_material(1);
              this.get_services_count();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_repairman() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6458, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_repairman = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_repairman.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_material2() {
    this.page = 1;
    this.get_material(2);
  }

  get_material3() {
    this.page = 1;
    this.search = '';
    this.get_material(1);
  }


  get_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 1;
    this.type_list = "material";
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    var address;
    if (this.document_type_text == 'input' || this.document_type_text == 'request') address = 6407;
    else address = 6682;

    var obj = {
      address: address, search: this.search, page: this.page
      , device: this.device, cate: this.cate, creator: this.creator, token: this.token, user: this.user
      , back: this.back, document_type_text: this.document_type_text, user_id: this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            //if (this.document_type_text == 'exit') res['result'][i].inventory = res['result'][i].wharehouse_order_number - res['result'][i].order_consumed;
            if (this.document_type_text == 'input' || this.document_type_text == 'request') res['result'][i].wharehouse_order_cost = res['result'][i].wharehouse_material_price;
            this.list_record.push(res['result'][i]);
          }//end for
          if (res['access_invoice_buy'] == 1) { this.access_price_column_buy = true; }
          else if (res['price_column_buy'] == 1) { this.access_price_column_buy = true; }

          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total_material = res['count'];
          this.get_repairman();
          this.pages(0);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 10 - 9;
    this.page_number_second = this.page * 10;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 10) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  get_services_count() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6474, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_total_service = res['count'];
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_services2() {
    this.page = 1;
    this.get_services(2);
  }

  get_services3() {
    this.page = 1;
    this.search = '';
    this.get_services(1);
  }


  get_services(loading) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 2;
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    this.type_list = 'services';
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6454, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource.data = this.list_record;
          this.pages(0);
          this.get_repairman();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  which(page) {
    if (page == 2) { this.page = this.page + 1; }
    if (page == 3) { this.page = this.page - 1; }
    if (this.type_list == 'material') {
      this.get_material(1);
    }
    else if (this.type_list == 'services') {
      this.get_services(1);
    }
    else {
      this.get_order();
    }
  }

  save(i: number, material_id: number, order_id: number, number1: number, material_type: number, cost: any, title: string): any {
    var repairman_id;
    //فروشنده
    repairman_id = <any>document.getElementById("repairman" + order_id);
    repairman_id = repairman_id.value;
    //قیمت فروش
    var cost1 = <any>document.getElementById("cost1" + order_id);
    var price_sale = parseInt(cost1.value.match(/\d/g).join(""));

    //تعداد فروش
    var input1 = <any>document.getElementById("input1" + order_id);
    var number_sale = parseInt(input1.value.match(/\d/g).join(""));

    //شماره فاکتور خرید
    var inventory1 = <any>document.getElementById("inventory1" + order_id);
    var inventory = parseInt(inventory1.value.match(/\d/g).join(""));

    var invoice_price1 = <any>document.getElementById("invoice_price1" + order_id);
    var price_buy = parseInt(invoice_price1.value.match(/\d/g).join(""));
    //*********************************************************************  */
    var discount1 = <any>document.getElementById("discount1" + order_id);
    var discount = parseInt(discount1.value.match(/\d/g).join(""));
    //*********************************************************************  */

    if (number_sale < 1) {
      var pe_message = "تعداد درخواستی باید بیشتر از عدد 0 باشد";
      var en_message = "The requested number must be greater than 0";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    else if (price_sale < price_buy) {
      var message = "قیمت فروش کمتر از قیمت خرید میباشد آیا میخواهید ادامه بدهید؟";
      if (this.lang == 2) {
        message = "The sale price is lower than the purchase price. Do you want to continue?";
      }
      var confirm_delete = window.confirm(message);
      if (confirm_delete == true) {
        this.check_inventory2(i, material_id, repairman_id, material_type, price_sale, number_sale, price_buy, order_id, discount);
      }
    } else {
      this.check_inventory2(i, material_id, repairman_id, material_type, price_sale, number_sale, price_buy, order_id, discount);
    }
  }

  check_inventory2(i: number, material_id: number, repairman_id: number, material_type: number, price_sale: number, number_sale: number, price_buy: number, number_invoice_buy: number, discount: number): any {


    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6134, material_id: material_id, exit_id: this.exit_id, order_id: number_invoice_buy, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (number_sale <= res['remain']) {
            this.add_to_bascket_exit(i, material_id, repairman_id, number_sale, material_type, price_sale, price_buy, number_invoice_buy, discount);
            this.message(false, "", 1, this.messageService.close(this.lang));
          } else {
            var pe_message = "تعدادی درخواستی بیشتر ار موجودی انبار می باشد .لطفا اصلاح نمائید.";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add() {
    const dialogRef = this.dialog.open(NewFastMaterialComponent, {
      width: '22rem',
      height: 'auto',
      data: { cate: this.cate, material_type: this.material_type, creator: this.creator, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }

  add_to_bascket_exit(i, material_id: number, repairman: number, number_sale: number, material_type: number, price_sale: any, price_buy: any, number_invoice_buy: number, discount: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var repairman_id;
    var comment;
    if (material_type == 2) {
      repairman_id = <any>document.getElementById("repairman" + material_id);
      repairman_id = repairman_id.value;
      comment = <any>document.getElementById("comment" + material_id);
      comment = comment.value;
      var cost2 = <any>document.getElementById("cost2" + material_id);
      price_sale = cost2.value.replace(/[^0-9]/g, '');
    } else {
      repairman_id = repairman;
    }

    this.loading = true;
    var obj = {
      address: 6481, reception_id: this.reception_id, id: material_id, number: number_sale, order_id: number_invoice_buy,
      type: this.type, user_id: this.user_id, creator: this.creator
      , material_type: material_type, cost: price_sale, repairman_id: repairman_id, comment: comment,
      trash: 3, invoice_id: this.invoice_id, token: this.token, document_type_text: this.document_type_text, discount: discount
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.list_record[i].inventory = this.list_record[i].inventory - number_sale;
          if (this.list_record[i].inventory == 0) {
            this.list_record.splice(i, 1);
            this.dataSource.data = this.list_record;
          }
          this.count_order++;
          this.send_invoice_material(res['result'][0]);
          if (this.material_type == 1) {
            this.check_financial2_for_repairman(1, number_sale, price_buy, price_sale, repairman_id, material_id, res['result'][0].wharehouse_order_id, discount);
          } else {
            this.check_financial2_for_repairman(2, number_sale, price_buy, price_sale, repairman_id, material_id, res['result'][0].wharehouse_order_id, discount);
          }
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_financial2_for_repairman(type_ref2: number, number: Number, price_buy: number, amount: number, repairman_id: number, material_id: number, order_id: number, discount: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6637, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].user_document_finance == 1) {
            this.insert_financial2_for_repairman(type_ref2, number, price_buy, amount, repairman_id, material_id, order_id, discount);
          }
        }//end if 
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  insert_financial2_for_repairman(type_ref2: number, number: Number, price_buy: number, amount: number, repairman_id: number, material_id: number, order_id: number, discount: number) {
    var obj = {
      address: 6632
      , user_id: this.user_id
      , creator: this.creator
      , invoice_id: this.invoice_id
      , repairman_id: repairman_id
      , number: number
      , amount: amount
      , type_ref2: type_ref2
      , material_id: material_id
      , order_id: order_id
      , token: this.token
      , discount: discount
      , price_buy: price_buy

    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_account(user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = 0;
          this.remain = res['result'][0].balance;
          this.x = 0;
          for (var j = 0; j < this.list_record.length; j++) {
            if (this.list_record[j].wharehouse_order_trash == 3) {
              this.x = this.x + Number(this.list_record[j].wharehouse_order_cost * this.list_record[j].wharehouse_order_number) - this.list_record[j].wharehouse_order_discount;
            }
          }
          this.x3 = 0;
          if (this.document_type == 1) this.remain = Number(this.remain) + Number(this.x) - Number(this.x3);
          if (this.document_type == 2) this.remain = Number(this.remain) - Number(this.x) + Number(this.x3);
          this.remain_letter = Num2persian(this.remain);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***********************************************************************************************  */
  request_material(material_id) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    var input1 = <any>document.getElementById("input1" + material_id);
    var number = input1.value;

    var obj = {
      address: 6258, user_id: this.user_id, creator: this.creator, material_type: 1,
      material_id: material_id, number: number, token: this.token,
      reception_id: this.reception_id, cost: 0, requests_id: this.requests_id
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_order++;
          this.serverService.send_order();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  send_invoice_material(result) {
    this.serverService.send_invoice_material(result);
  }


  //***********************************************************************************************  */
  update_order(order_id: number, number_sale: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6365, order_id: order_id, number_sale: number_sale
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_update_invoice_material();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_to_invoice(id: number, number: number, cost: number, discount): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 6069
      , reception_id: this.reception_id
      , material_id: id, number: number, cost: cost, discount: discount
      , invoice_id: this.invoice_id
      , token: this.token
      , type: 1
      , material_type: 1
      , ref: this.ref
      , user_id: this.user_id
      , creator: this.creator
      , back: this.back
    }

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.count_order++;
          this.serverService.send_invoice_material(res['result'][0]);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_amount_exit() {
    this.sum_all = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all = this.sum_all + this.list_record[i].sum;
    }
  }
  //************************************************************************************************* */
  check_for_access(i: number, type_task: number, id: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 2) {
                this.open_services(i, id);
              }
              else if (material_type == 1 && this.document_type_text == 'exit' && this.back == 0) {
                this.open_order_sale(i, id)
              }
              else {
                this.open_order(i, type_task, id, material_type);
              }
              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_order_sale(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderSaleDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, code: this.code, ref: 'invoice', token: this.token }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_record[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_record[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].sum = res.result.sum;
          if (res.result.wharehouse_order_material_type == 1) {
            this.get_update_material_remain(res.result.wharehouse_material_id);
            this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          }
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.user) this.get_account(this.user);
          this.serverService.send_order();
        }
      }
    )
  }


  open_order(i, type_task, id, material_type: number): any {
    var dialog; var title;
    if (material_type == 1) {
      dialog = OrderDetaileComponent;
      title = '';
    } else {
      dialog = ExitDetaile2Component;
      title = 'خدمات';
    }
    var ref = 'invoice';
    if (this.document_type_text == 'request') ref = 'requests';
    const dialogRef = this.dialog.open(dialog, {
      width: '45rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, material_type: material_type, title: title, trash: 3, code: this.code, invoice_id: this.id, ref: ref, token: this.token, document_type_text: this.document_type_text }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
          this.list_record[i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
          this.list_record[i].wharehouse_order_title = res.result.wharehouse_order_title;
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_record[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_record[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
          this.list_record[i].wharehouse_order_sort = res.result.wharehouse_order_sort;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].status_title = res.result.status_title;
          this.list_record[i].site_currency_title = res.result.site_currency_title;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].repairman_title = res.result.repairman_title;
          if (res.result.wharehouse_order_material_type == 1) {
            this.get_update_material_remain(res.result.wharehouse_material_id);
            this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          }
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.user) this.get_account(this.user);
          this.serverService.send_order();
        }
      }
    )
  }

  open_services(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderServiceDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code, ref: 'invoice' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].title = res.result.title;
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_record[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].sum = res.result.sum;
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.user) this.get_account(this.user);
          this.serverService.send_order();
        }
      }
    )
  }


  get_order() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.type_list = 'order';
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6455, invoice: this.invoice_id, token: this.token, lang: this.lang }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.count_order = this.list_record.length;
          this.dataSource = new MatTableDataSource(this.list_record);
          this.get_sum_all();
          this.pages(0)
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************************* */
  get_request() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.type_list = 'request';

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6285, requests_id: this.requests_id, token: this.token }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_request(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_order--;
            this.serverService.send_order();
            this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  open_serial(i: number, id: number, title: string, order_buy_id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, document_type_text: 'exit', order_buy_id: order_buy_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.list_record[i].searial = res.result.searial;
      }
    )
  }

  //******************************************************************************************************************* */
  get_sum_all() {
    this.x2 = 0;
    for (var j = 0; j < this.list_record.length; j++) {
      if (this.list_record[j].wharehouse_order_trash == 3) {
        this.x2 = Number(this.x2) + Number(this.list_record[j].wharehouse_order_cost * this.list_record[j].wharehouse_order_number - this.list_record[j].wharehouse_order_discount);
      }
    }
    this.sum_all = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all += this.list_record[i].sum;
    }
    this.sum_all_letter = Num2persian(this.sum_all);
    if (this.document_type == 1) this.sum_all_real = Number(this.sum_all) + Number(this.x2);
    if (this.document_type == 2) this.sum_all_real = Number(this.sum_all) - Number(this.x2);
  }


  access_for_delete(id: number, material: number, trash: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.delete_order(id, trash, material_type);
              } else {
                this.delete_order(id, trash, material_type);
              }
            } else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  delete_order(id: number, trash: number, material_type: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (trash == 2) {
              for (var i = 0; i < this.list_record.length; i++) {
                if (this.list_record[i].wharehouse_order_id == id && this.list_record[i].wharehouse_order_material_type == 1) {
                  this.get_update_material_remain(this.list_record[i].wharehouse_order_material);
                  this.get_update_order_consumed(this.list_record[i].wharehouse_order_consumed_invoice);
                }
              }
            }
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            if (material_type == 1) this.delete_financial2_id_for_repairman(id, 6636);
            if (material_type == 2) this.delete_financial2_id_for_repairman(id, 6634);
            this.get_sum_all();
            if (trash == 2) {
              this.update_financial2();
            }
            this.serverService.send_order();
            this.count_order = this.list_record.length;
            this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  delete_financial2_id_for_repairman(id: number, address: number) {
    var obj = {
      address: address
      , id: id
      , user_id: this.user_id
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].finance_financial2_id > 0) {
            this.delete_financial2_id_for_repairman2(res['result'][0].finance_financial2_id);
          }
        }//end if
        else {
          //this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_financial2_id_for_repairman2(id: number) {
    var obj = {
      address: 6631
      , id: id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************* */
  get_update_material_remain(material_id) {
    var obj = {
      address: 6445,
      material_id: material_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_order_consumed(order_id: number) {
    var obj = {
      address: 6446,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_financial2() {
    var obj = {
      address: 6106,
      id: this.invoice_id,
      amount: this.sum_all_real,
      date3: this.date3,
      document_type: this.document_type,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_all_financial2() {
    var obj = {
      address: 6111,
      id: this.invoice_id,
      user: this.user,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.user) this.get_account(this.user);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************  */
  close() {
    this.matDialogRef.close();
  }
  //************************************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }//end if

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
  }//end OnDestroy
}
