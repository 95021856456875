<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        {{ serverService.get_title(type_task,lang) }}
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان : </span>
                    <span *ngIf="lang == 2"> Title : </span>
                    <input class="wh-input" formControlName="title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title2" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان انگلیسی : </span>
                    <span *ngIf="lang == 2"> English title : </span>
                    <input class="wh-input" formControlName="title2" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> نمونه ی کپی دیتا : </span>
                    <span *ngIf="lang == 2"> Data copy sample : </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="load_services(161) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="user" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        [ngClass]="{'en':dir == 'ltr'}" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش : </span>
                    <span *ngIf="lang == 2"> Display status : </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>
</form>