<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="جستجو..." autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='change_task(1,1,"","",1,"","")' [disabled]='new_status'>
                    <span>جدید</span>
                </button>
                <button class="search1" (click)='change_task(3,3,"","",1,"","")'>
                    <span>فیلتر</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
                <div style="display: inline-block;">
                    <mat-checkbox (change)="get_select($event.checked)" [(indeterminate)]="indeterminate">
                    </mat-checkbox>
                </div>
                <div class="cursor" (click)="check_access(3,0,selected_record,1,1)">
                    <i class="far fa-trash-alt trash icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>ردیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span>عنوان</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_invoice_type_id,x.wharehouse_invoice_type_title,x.wharehouse_invoice_type_title2,x.status_id,x.wharehouse_invoice_type_sort,x.wharehouse_invoice_type_alias)'>
                <div class="right">{{ x.wharehouse_invoice_type_title }} </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="recieve">
            <mat-header-cell *matHeaderCellDef>
                <span>دریافت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_recieve(i,x.wharehouse_invoice_type_recieve,x.wharehouse_invoice_type_id)'>
                    <span *ngIf="x.wharehouse_invoice_type_recieve == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.wharehouse_invoice_type_recieve == 1"><i class="fas fa-star icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pay">
            <mat-header-cell *matHeaderCellDef>
                <span>پرداخت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_pay(i,x.wharehouse_invoice_type_pay,x.wharehouse_invoice_type_id)'>
                    <span *ngIf="x.wharehouse_invoice_type_pay == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.wharehouse_invoice_type_pay == 1"><i class="fas fa-star icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="back">
            <mat-header-cell *matHeaderCellDef>
                <span>وضعیت برگشت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_back(i,x.wharehouse_invoice_type_back,x.wharehouse_invoice_type_id)'>
                    <span *ngIf="x.wharehouse_invoice_type_back == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.wharehouse_invoice_type_back == 1"><i class="fas fa-star icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="alias">
            <mat-header-cell *matHeaderCellDef>
                <span>عنوان نمایشی</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_invoice_type_id,x.wharehouse_invoice_type_title,x.wharehouse_invoice_type_title2,x.status_id,x.wharehouse_invoice_type_sort,x.wharehouse_invoice_type_alias)'>
                {{ x.wharehouse_invoice_type_alias }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef>
                <span>ترتیب</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">{{ x.wharehouse_invoice_type_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span>وضعیت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_invoice_type_id,x.wharehouse_invoice_type_title,x.wharehouse_invoice_type_title2,x.status_id,x.wharehouse_invoice_type_sort,x.wharehouse_invoice_type_alias)'>
                <span>{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span>شناسه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_invoice_type_id,x.wharehouse_invoice_type_title,x.wharehouse_invoice_type_title2,x.status_id,x.wharehouse_invoice_type_sort,x.wharehouse_invoice_type_alias)'
                style="max-width:70px">
                {{ x.wharehouse_invoice_type_id }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:50px'>
                <span>عملیات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:50px'>
                <mat-checkbox (change)="get_select_records(i)" name="selete_records[]"
                    [value]="x.wharehouse_invoice_type_id" [checked]="x.checked"></mat-checkbox>
                <span (click)="check_access(3,0,x.wharehouse_invoice_type_id,0,1)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>


<ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_group">
        <div class="dialog_title1">
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title" class='wh-label'>
                    <span> عنوان : </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title2" class='wh-label'>
                    <span> عنوان انگلیسی : </span>
                    <input class='wh-input' formControlName="title2" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="alias" class='wh-label'>
                    <span> عنوان نمایشی : </span>
                    <input class='wh-input' formControlName="alias" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="sort" class='wh-label'>
                    <span> ترتیب : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span> وضعیت نمایش : </span>
                    <select class='wh-input' formControlName="status_id" required>
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>
        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="check_access(type_task,0,0,0,1)"
                    [disabled]="!form1_group.valid">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>

<ngx-smart-modal #myModalSearch identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_search_group">
        <div class="dialog_title1">
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title_search" class='wh-label'>
                    <span> عنوان : </span>
                    <input class='wh-input' formControlName='title_search' autocomplete="off">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="alias_search" class='wh-label'>
                    <span> عنوان نمایشی : </span>
                    <input class='wh-input' formControlName='alias_search' autocomplete="off">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span> وضعيت نمايش : </span>
                    <select class='wh-input' formControlName='status_id_search' pattern="[0-9]{1,}">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>

        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12'
                style='margin-top:5px;text-align:center;font-family:irsans'>
                <button class="btn_pe" (click)="check_access(4,1,0,1,1)">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>