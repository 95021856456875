import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FinancialDetaileComponent } from '../financial/financial-detaile/financial-detaile.component';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public loading_copy: boolean = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public bed: number | undefined;
  public best: number | undefined;
  public balance: number | undefined;
  public show_financial: boolean = false;
  public path_export: string | undefined;
  public show_cellphone: boolean = true;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public ccess_service: number | undefined;
  public selected_record: any = [];
  public level: number | undefined;
  public access_service: any;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'title', 'cellphone', 'bed', 'best', 'remain'];
  //*************
  public document_type_text: string | undefined;

  public creator: number;
  public back_i: number | undefined;
  public search_all_params: boolean = false;
  public video: string | undefined;
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public dialogRef: MatDialog
    , public messageService: MessageService
    , public http: HttpClient) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params['back_i']) this.back_i = params['back_i'];
        if (params['page']) this.page = params['page'];
        if (params['search_all']) {
          this.search_all = params['search_all'];
          this.search_all_params = true;
        }
      }
    )

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (this.search_all_params == false) this.search_all = '';
        this.code = params['id'];
        if (this.code == 209) this.document_type_text = "exit";
        if (this.code == 210) this.document_type_text = "input";
        this.check_access(4, this.page, 0, 1, 1);
      }
    )
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.show_cellphone = res['show_cellphone'];
          this.path_export = res['path_export'];
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) {
            this.load_data(page, excel, loading, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6) { this.page = 1; }
    //************** خیلی خیلی مهم - 6181 و 6182 با هم هستند */
    //6181 - 6182
    var obj = {
      'address': 6797, "page": this.page, 'type': this.type, code: this.code
      , 'excel': excel
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , changedRows: changedRows
      , creator: this.creator
      , path_export: this.path_export
      , show_cellphone: this.show_cellphone
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                if (res['result'][i].user_logo) res['result'][i].logo_site1 = res['result'][i].user_logo_site + '/' + res['result'][i].user_logo;
                else res['result'][i].logo_site1 = this.serverService.get_default_user_logo();
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.get_final_account();
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data3

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }


  show_financial2(i, user) {
    this.show_financial = true;
    this.router.navigate(['/home/financial2/202'], { queryParams: { user: user, user_cost: 'user', ref: this.code, page: this.page, i: i, search_all: this.search_all, link: 'bill' } })
  }

  //******************************************************************************************************  */
  new(j: number, user: number, balance: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = { address: 1927, user: user, user_id: this.user_id, creator: this.creator, document_type_text: this.document_type_text }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial(j, -1, 2, res['id'], balance, user);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_financial(j: number, i: number, type_task: number, id: number, balance: number, user: number) {
    const dialogRef = this.dialog.open(FinancialDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: type_task, id: id, i: i, code: 192, document: 1, sum: balance }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.get_account(j, user, balance);
        }
      }
    )
  }

  get_account(i: number, user: number, balance: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].balance <= 0) {
            this.list_record.splice(i, 1);
          } else {
            this.list_record[i].balance = res['result'][0].balance;
          }
          this.dataSource.data = this.list_record;

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          this.message(true, this.messageService.message(this.lang, pe_message, 'Erorr in $'), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************  */
  get_final_account() {
    var obj = {
      'address': 6798, code: this.code
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.bed = res['bed'];
          this.best = res['best'];
          this.balance = res['balance'];
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  check_charge_sms(balance: number, cellphone: any, title: string): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (cellphone.match(/^\d{11}$/g) == null) {
      this.message(true, this.messageService.error_format_cellphone(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;

    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= 2000) {
              this.send_sms(balance, cellphone, title);
            }
            else {
              this.message(true, this.messageService.error_sharj_sms(this.lang), 1, this.messageService.close(this.lang));
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  send_sms(balance: number, cellphone: any, title: string): any {
    var x = confirm(this.messageService.message_sms_accept(this.lang));
    if (x == false) {
      this.message(false, "", 1, this.messageService.close(this.lang));
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6302, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.send_sms2(balance, cellphone, title, res['result'][0].user_store, res['result'][0].currency_title)
          } else {
            this.message(false, "", 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end if

  send_sms2(balance: number, cellphone: any, title: string, user_store: string, currency_title: string): any {
    var obj = {
      address: 6511, user_id: this.user_id, creator: this.creator
      , balance: balance.toLocaleString()
      , cellphone: cellphone
      , title: title
      , user_store: user_store
      , currency_title: currency_title
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.log_sms(res['response'].body.entries[0].cost);
          this.message(true, this.messageService.send_message(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          this.message(true, this.messageService.message(this.lang, pe_message, 'Error in recording the sent SMS log'), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  log_sms(cost: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6308, user_id: this.user_id, creator: this.creator, cost: cost }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          this.message(true, this.messageService.message(this.lang, pe_message, 'Error in recording the sent SMS log'), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_detaile(i: number, type_task: number, id: number) {
    /*
    const dialogRef = this.dialog.open(InvoiceOfPersonComponent, {
      width: '60rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
    */
  }
  //***************************************************************************
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}