<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">خدمات</span>
        <span *ngIf="lang == 2">Services</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> عنوان خدمات </span>
                    <span *ngIf="lang == 2"> Service name</span>
                    <span (click)="reset_input(195)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(195)" readonly
                        required>
                    <input class="wh-input" formControlName="material" [hidden]="true" readonly required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="number" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تعداد </span>
                    <span *ngIf="lang == 2"> Number </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="cost" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> قیمت واحد </span>
                    <span *ngIf="lang == 2"> Unit Price </span>
                    <input class='wh-input' formControlName="cost" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="discount" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تخفیف </span>
                    <span *ngIf="lang == 2"> Discount </span>
                    <input class='wh-input' formControlName="discount" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}"
                [hidden]="level == 3">
                <label for="repairman" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> نام تعمیرکار </span>
                    <span *ngIf="lang == 2"> Repairman name </span>
                    <span (click)="reset_input(220)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220)" readonly
                        required [attr.disabled]="level == 3 ? true : null">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly required
                        [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}"
                [hidden]="level == 3">
                <label for="profit" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> درصد سود </span>
                    <span *ngIf="lang == 2"> Profit commission </span>
                    <input class='wh-input' formControlName="profit" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="sort" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea style="height:10rem" class='wh-input' formControlName="comment" autocomplete="off">
                    </textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 1">ذخیره</span>
            <span *ngIf="lang == 2">Save</span>
        </button>
    </div>
</form>