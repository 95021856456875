<div mat-dialog-title class="matDialogTitle">
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>انتخاب کالا</span>
    <mat-icon class="close" (click)="close()">
        close
    </mat-icon>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>
        <mat-table [dataSource]="dataSource" style='min-width:100%'
            class='table-scroll mat_table_fa_without_scroll_bg pe1'
            [ngClass]="{mat_table_en_without_scroll_bg:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span *ngIf="lang == 1">ردیف</span>
                    <span *ngIf="lang == 2">Row</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice_number">
                <mat-header-cell *matHeaderCellDef [hidden]='true'>
                    <span *ngIf="lang == 1">شماره فاکتور خرید</span>
                    <span *ngIf="lang == 2">Purchase invoice no</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [hidden]='true'>
                    {{ x.wharehouse_invoice_id }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice_date">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تاریخ خریداری شده</span>
                    <span *ngIf="lang == 2">Purchase date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index">
                    {{ x.wharehouse_invoice_date3 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice_user">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">نام طرف حساب</span>
                    <span *ngIf="lang == 2">Account holder</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index">
                    {{ x.supplier_title }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تعداد خریداری شده</span>
                    <span *ngIf="lang == 2">Number of purchased items</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index">
                    {{ x.wharehouse_order_number }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number1">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تعداد مصرف شده</span>
                    <span *ngIf="lang == 2">Number of used items</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index">
                    {{ x.order_consumed }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef style="background:#F1F11A !important">
                    <span *ngIf="lang == 1">قیمت</span>
                    <span *ngIf="lang == 2">Price</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index">
                    {{ x.wharehouse_order_cost | number }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (click)="get_material(row.wharehouse_order_id)" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview2.png">
                </div>
                <div class="preview2">{{ no_record }}</div>
            </div>
        </div>

    </div>
</div><!-- content -->