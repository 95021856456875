<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1"> لیست کالا </span>
        <span *ngIf="lang == 2"> Product list </span>
    </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent matDialogContent1">
    <div [ngStyle]="{'text-align':dir == 'ltr' ? 'left' : 'right'}">
        <span>
            <button (click)="which(2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
            <button (click)="which(3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        </span>
        <button type="button" (click)='get_material3()'
            [ngClass]="{button_active:type_list == 'material','full-left':dir == 'ltr'}" class="invoice_button2">
            <span *ngIf="lang == 1">لیست کالاها</span>
            <span *ngIf="lang == 2">Product list</span>
            {{ count_total_material | number}}
        </button>
        <button type="button" class="invoice_button2" [ngClass]="{'full-left':dir == 'ltr'}">
            <span (click)='get_order()'>
                <span *ngIf="lang == 1"> سبد برگشت از خرید </span>
                <span *ngIf="lang == 2"> Purchase returns cart </span>
            </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ count_order }}</span>
        </button>
    </div>

    <div class="row" *ngIf="type_list == 'material'">
        <input class="search" (keyup)="get_material2()" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'full-left':dir == 'ltr'}">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngClass]="{en1:lang == 2}" [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef style="text-align: center !important;" class="right">
                        <span *ngIf="lang == 1">عنوان کالا</span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor right">
                        <span class="paddingRight" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.wharehouse_material_title
                            | substr:0:30 }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef style="max-width:180px">
                        <span *ngIf="lang == 1">گروه</span>
                        <span *ngIf="lang == 2">Group</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;max-width:180px">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.wharehouse_group_title |
                            substr:0:20 }} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cate">
                    <mat-header-cell *matHeaderCellDef style="max-width:150px">
                        <span *ngIf="lang == 1">زیرگروه</span>
                        <span *ngIf="lang == 2">Subgroup</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;max-width:150px">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.wharehouse_material_cate_title |
                            substr:0:20 }} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="inventory">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px" [hidden]="document_type_text == 'input'">
                        <span *ngIf="lang == 1">موجودی</span>
                        <span *ngIf="lang == 2">Stock</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="text-align: center;"
                        style="max-width:80px" [hidden]="document_type_text == 'input'">
                        <input class=" wh-input input1" id="inventory1{{ x.wharehouse_order_id }}" currencyMask
                            [value]="x.inventory | number" autocomplete="off" readonly [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order">
                    <mat-header-cell *matHeaderCellDef style="max-width:90px" [hidden]='true'>
                        <span *ngIf="lang == 1">ش درخواست</span>
                        <span *ngIf="lang == 2">Request no</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:90px;" [hidden]='true'>
                        <input class=" wh-input input1" id="order1{{ x.wharehouse_order_id }}" autocomplete="off"
                            readonly [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:110px"
                        [hidden]="document_type_text == 'request'">
                        <span *ngIf="lang == 1">قیمت خرید</span>
                        <span *ngIf="lang == 2">Purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:110px;"
                        [hidden]="document_type_text == 'request'">
                        <input class=" wh-input input1" id="invoice_price1{{ x.wharehouse_order_id }}" currencyMask
                            [ngModel]="x.wharehouse_order_cost" autocomplete="off"
                            [readonly]="document_type_text != 'input'" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span *ngIf="lang == 1">تعداد </span>
                        <span *ngIf="lang == 2">Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="text-align: center;"
                        style="max-width:80px;">
                        <input type="number" class=" wh-input input1" id="input1{{ x.wharehouse_order_id }}" [value]="1"
                            autocomplete="off" min="0" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span *ngIf="lang == 1">اضافه کردن</span>
                        <span *ngIf="lang == 2">Add</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline"
                            (click)="save(i,x.wharehouse_material_id,x.wharehouse_order_id, 1 , 1 , 0)"
                            style="vertical-align: middle;font-size: 20px;" *ngIf="ref == 'invoice' ">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
                <mat-row *matRowDef="let x;let i = index;columns:displayedColumns2"></mat-row>
            </mat-table>

        </div>
    </div>

    <!-- **************************************************************************************************** -->
    <div class="row" *ngIf="type_list != 'material'">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll pe1'
                [ngClass]="{en1:lang == 2}" [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                        <span>#</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + 1 }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '70px' : 'auto' }">
                        <span *ngIf="lang == 1">نوع</span>
                        <span *ngIf="lang == 2">Type</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '70px' : 'auto' }">
                        <div [ngClass]="{'en':dir == 'ltr'}">{{ x.wharehouse_material_type_title}}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }">
                        <span *ngIf="lang == 1">نام کالا
                        </span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;"
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.title | substr:0:30 }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '150px' : 'auto' }">
                        <span *ngIf="lang == 1">گروه</span>
                        <span *ngIf="lang == 2">Group</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '150px' : 'auto' }">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.wharehouse_material_cate_title |
                            substr:0:20 }} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef [hidden]="true">
                        <span *ngIf="lang == 1">مدل قطعه</span>
                        <span *ngIf="lang == 2">Component model</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '90px' : 'auto' }">
                        <span *ngIf="lang == 1">تعداد</span>
                        <span *ngIf="lang == 2">Number</span>
                        <span *ngIf="document_type_text == 'request' ">
                            <span *ngIf="lang == 1">درخواستی</span>
                            <span *ngIf="lang == 2">Requested</span>
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '90px' : 'auto' }"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span *ngIf="lang == 1">واحد شمارش</span>
                        <span *ngIf="lang == 2">Counting unit</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width:100px" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_weight_title}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef style="max-width: 90px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">قیمت واحد</span>
                        <span *ngIf="lang == 2">Unit price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 90px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_cost | number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width: 90px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">تخفیف</span>
                        <span *ngIf="lang == 2">Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 90px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_discount | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sum">
                    <mat-header-cell *matHeaderCellDef style="max-width: 90px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">جمع</span>
                        <span *ngIf="lang == 2">Total</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 90px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.sum | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="operation">
                    <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly">
                        <span *ngIf="lang == 1"> عملیات </span>
                        <span *ngIf="lang == 2">Operation</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly">
                        <span
                            (click)='check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)'><i
                                class="fas fa-desktop icon"></i></span>
                        <!-- x.wharehouse_order_material,x.wharehouse_order_status_add -->

                        <span
                            (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_material,x.wharehouse_order_trash,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            *ngIf="document_type_text != 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>

                        <span (click)="delete_request(x.wharehouse_order_id)"
                            *ngIf="document_type_text == 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>

                    </mat-cell>
                </ng-container>

                <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                </mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor" [ngStyle]="{'backgroundColor':row.wharehouse_order_status_color}"></mat-row>

            </mat-table>
        </div>
        <div style="text-align: right;padding-top:1rem;" [hidden]="document_type_text == 'request' "
            [ngClass]="{'full-left':dir == 'ltr'}">
            <span *ngIf="lang == 1"> جمع کل به عدد : </span>
            <span *ngIf="lang == 2">Sum total in numbers : </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ sum_all | number }} </span>
            <span>{{ currency_title }}</span>
            <span *ngIf="lang == 1"> به حروف </span>
            <span *ngIf="lang == 1"> {{ sum_all_letter }} </span>
            <span *ngIf="lang == 1"> {{ currency_title }} </span>
        </div>
    </div><!-- row -->
    <!-- **************************************************************************************************** -->
</div>