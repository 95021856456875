import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-cheque-list-recieve',
  templateUrl: './cheque-list-recieve.component.html',
  styleUrls: ['./cheque-list-recieve.component.scss']
})
export class ChequeListRecieveComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public isOnline: any | undefined;
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public list_cheque: any = [];
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public user: number | undefined;
  public document_type: number | undefined;
  public token: any | undefined;
  public type: any | undefined;
  public creator: any | undefined;
  public displayedColumns = ['row', 'title', 'user', 'bank', 'cheque_number', 'date3', 'price'];

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<ChequeListRecieveComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.user = dialog_data.user;
      this.document_type = dialog_data.document_type;
      this.token = dialog_data.token;
      this.type = dialog_data.type;
      this.creator = dialog_data.creator;
    }
  }//end consructor

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6795
      , creator: this.creator
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_cheque = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_cheque.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_cheque);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  select(i: number) {
    this.matDialogRef.close({ result: this.list_cheque[i] });
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
