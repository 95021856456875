<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1,false)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span *ngIf="lang == 1">ردیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="how_to_charge">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">نحوه شارژ</span>
                <span *ngIf="lang == 2"> Payment method </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.how_to_charge_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef style="min-width:250px">
                <span *ngIf="lang == 1">شرح پرداختی</span>
                <span *ngIf="lang == 2"> Payment description </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="min-width:250px">
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
                    {{ x.title }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:250px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user_title">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1" style="min-width: 180px;">
                <span *ngIf="lang == 1">نام کاربر</span>
                <span *ngIf="lang == 2"> Client name </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                [hidden]="level != 1" style="min-width: 180px;">
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">{{ x.user_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1" style="min-width: 180px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="account">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1" style="min-width:200px">
                <span *ngIf="lang == 1">عنوان فروشگاه</span>
                <span *ngIf="lang == 2"> Company name </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                [hidden]="level != 1" style="min-width:200px">
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">{{ x.user_store | substr:0:30 }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1" style="min-width:200px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="expire">
            <mat-header-cell *matHeaderCellDef [hidden]="code == 230 || level != 1" style="max-width: 120px;">
                <span *ngIf="lang == 1">تاریخ انقضا</span>
                <span *ngIf="lang == 2"> Expiration date </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                [hidden]="code == 230 || level != 1" style="max-width: 120px;">
                <div [ngClass]="{'en':dir == 'ltr'}">{{ x.site_bascket_expire_date }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width: 120px'
                [hidden]="code == 230 || level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef style="max-width: 180px;min-width: 180px;">
                <span *ngIf="lang == 1">مقدار شارژ</span>
                <span *ngIf="lang == 2">Membership fee in Rials</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="max-width: 180px;min-width: 180px;" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bascket_amount | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width: 180px;min-width: 180px;'
                [ngClass]="{'en':dir == 'ltr'}">{{ sum | number
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="coupon">
            <mat-header-cell *matHeaderCellDef style="max-width: 180px;">
                <span *ngIf="lang == 1">کوپن</span>
                <span *ngIf="lang == 2">Coupon</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="max-width: 180px;" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_coupon_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width: 180px;min-width: 180px;'
                [ngClass]="{'en':dir == 'ltr'}"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef style="min-width:150px" [hidden]="level != 1">
                <span *ngIf="lang == 1">کد رهگیری</span>
                <span *ngIf="lang == 2">Transaction ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="min-width:150px" [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bascket_code }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:150px' [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span *ngIf="lang == 1">تاریخ ثبت</span>
                <span *ngIf="lang == 2">Registration date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bascket_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span *ngIf="lang == 1">ساعت ثبت</span>
                <span *ngIf="lang == 2">Registration time</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bascket_time }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span *ngIf="lang == 1">شناسه</span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_bascket_id)"
                style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bascket_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="level != 1">
                <span *ngIf="lang == 1">عملیات</span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' [hidden]="level != 1">
                <span class="operation-icon" (click)="delete(x.site_bascket_id,x.user_id)"><i
                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:70px' [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true"></mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>