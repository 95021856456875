<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>

    <span>
        <span>{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div_panding' [ngClass]="{en:lang==2}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> نام وضعیت : </span>
                    <span *ngIf="lang == 2"> Status name : </span>
                    <input class='wh-input' formControlName="title" (keyup.enter)="check_access(type_task,1)"
                        autocomplete="off" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div_panding' [ngClass]="{en:lang==2}">
                <label for="title2" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان انگلیسی : </span>
                    <span *ngIf="lang == 2"> English title : </span>
                    <input class='wh-input' formControlName="title2" (keyup.enter)="check_access(type_task,1)"
                        autocomplete="off" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div_panding' [ngClass]="{en:lang==2}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        autocomplete="off" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div_panding' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش : </span>
                    <span *ngIf="lang == 2"> Display status : </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly [required]="required()">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()" readonly
                        [required]="required()">
                </label>
            </div>
        </div><!-- row -->


        <div class="row" *ngIf="type_task == 1 || type_task == 2">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                style="float: right;">
                <div class="wh-label right">تصویر وضعیت</div>
                <mat-card class="example-card logo cursor" class="backgroundImage"
                    style="padding:3px 16px 7px 16px;height:10rem"
                    [ngStyle]="{'background-image':'url( ' + status_logo + ')'}" (click)="logo.click()">
                    <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                    </mat-card-header>
                </mat-card>
                <span (click)="delete_logo()" *ngIf="logo_bin == true"><i class="far fa-trash-alt icon"
                        style="margin: 0px 4px;"></i></span>
                <input type="file" (change)="change_logo($event)" accept="image/*" style="display: none;" #logo>
            </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->
        </div>
    </div>

    <div mat-dialog-actions align="start" class="pe1 matDialogActions" [ngClass]="{en1:lang==2}"
        style="padding-right:25px">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1_group.valid"
            *ngIf="type_task != 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div><!-- row -->
</form>