import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { appRoutingModule } from './app.routing.module';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCoreModule } from "./system/service/mat-core/mat-core.module";
import { MatRadioModule } from '@angular/material/radio';
import { HighchartsChartModule } from 'highcharts-angular';
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { MatTabsModule } from '@angular/material/tabs';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 25,
  acceptedFiles: "image/*,video/*"
};

import { NgxCurrencyModule } from "ngx-currency";
export const customCurrencyMaskConfig = {
  align: "auto",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true
};

import 'hammerjs';
import * as $ from 'jquery';

import { ServerService } from './system/service/server/server.service';
import { MessageService } from './system/service/message/message.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './system/general/login/login.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { HomeComponent } from './system/general/menu/home.component';
import { PaccessComponent } from './system/service/menu/paccess/paccess.component';
import { GaccessComponent } from './system/service/menu/gaccess/gaccess.component';
import { MenuItemsComponent } from './system/service/menu/menu-items/menu-items.component';
import { TabComponent } from './system/service/menu/tab/tab.component';
import { ModulesComponent } from './system/service/modules/modules.component';
import { SubstrPipe } from './system/service/pipe/substr.pipe';
import { DeviceComponent } from './system/repairman/device/device.component';
import { UserGroupsComponent } from './system/user/user-groups/user-groups.component';
import { UserListComponent } from './system/user/user-list/user-list.component';
import { UserDetaileComponent } from './system/user/user-detaile/user-detaile.component';
import { ModelListComponent } from './system/repairman/model/model-list/model-list.component';
import { ModelDetaileComponent } from './system/repairman/model/model-detaile/model-detaile.component';
import { RstatusListComponent } from './system/repairman/rstatus/rstatus-list/rstatus-list.component';
import { RstatusDetaileComponent } from './system/repairman/rstatus/rstatus-detaile/rstatus-detaile.component';
import { ReceptionListComponent } from './system/repairman/reception/reception-list/reception-list.component';
import { ReceptionDetaileComponent } from './system/repairman/reception/reception-detaile/reception-detaile.component';
import { UserSexComponent } from './system/user/user-sex/user-sex.component';
import { ModelList2Component } from './system/repairman/model/model-list2/model-list2.component';
import { BelongListComponent } from './system/repairman/belong/belong-list/belong-list.component';
import { BelongDetaileComponent } from './system/repairman/belong/belong-detaile/belong-detaile.component';
import { BelongList2Component } from './system/repairman/belong/belong-list2/belong-list2.component';
import { ObjectionsListComponent } from './system/repairman/objections/objections-list/objections-list.component';
import { ObjectionsList2Component } from './system/repairman/objections/objections-list2/objections-list2.component';
import { ObjectionsDetaileComponent } from './system/repairman/objections/objections-detaile/objections-detaile.component';
import { UserSearchComponent } from './system/user/user-search/user-search.component';
import { UserDetaile2Component } from './system/user/user-detaile2/user-detaile2.component';
import { InsertModelComponent } from './system/repairman/insert-model/insert-model.component';
import { RepairmanShelfStatusComponent } from './system/repairman/repairman-shelf-status/repairman-shelf-status.component';
import { ShelfListComponent } from './system/repairman/repairman-shelf/shelf-list/shelf-list.component';
import { PaymentTypeComponent } from './system/finance/payment-type/payment-type.component';
import { BankComponent } from './system/finance/bank/bank-list/bank.component';
import { AccountingTypeComponent } from './system/finance/accounting-type/accounting-type.component';
import { BankAccountsComponent } from './system/finance/bank/bank-accounts/accounts-list/bank-accounts.component';
import { NewFastMaterialComponent } from './system/wharehouse/new-fast-material/new-fast-material.component';
import { ChequeStatusComponent } from './system/finance/cheque-status/cheque-status.component';
import { DocumentComponent } from './system/finance/document/document.component';
import { FinancialListComponent } from './system/finance/financial/financial-list/financial-list.component';
import { FinancialDetaileComponent } from './system/finance/financial/financial-detaile/financial-detaile.component';
import { Financial2ListComponent } from './system/finance/financial2/financial2-list/financial2-list.component';
import { Financial2DetaileComponent } from './system/finance/financial2/financial2-detaile/financial2-detaile.component';
import { ChequeSelectComponent } from './system/finance/cheque/cheque-select/cheque-select.component';
import { AccountsDetaileComponent } from './system/finance/bank/bank-accounts/accounts-detaile/accounts-detaile.component';
import { InsertBelongComponent } from './system/repairman/insert-belong/insert-belong.component';
import { InsertObjectionsComponent } from './system/repairman/insert-objections/insert-objections.component';
import { ServicesComponent } from './system/repairman/services/services.component';
import { PaymentComponent } from './system/finance/payment/payment.component';
import { InvoiceDetaileComponent } from './system/wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { InvoiceListComponent } from './system/wharehouse/wharehouse-invoice/invoice-list/invoice-list.component';
import { NewFastMaterial2Component } from './system/wharehouse/new-fast-material2/new-fast-material2.component';
import { DashboardComponent } from './system/general/dashboard/dashboard.component';
import { WharehouseMaterialCateDetaileComponent } from './system/wharehouse/wharehouse-material-cate-detaile/wharehouse-material-cate-detaile.component';
import { WharehouseOrderStatusComponent } from './system/wharehouse/wharehouse-order-status/wharehouse-order-status.component';
import { WharehouseInvoiceTypeComponent } from './system/wharehouse/wharehouse-invoice-type/wharehouse-invoice-type.component';
import { WharehouseCostCenterComponent } from './system/wharehouse/wharehouse-cost-center/wharehouse-cost-center.component';
import { SearchMaterialComponent } from './system/wharehouse/search-material/search-material.component';
import { OrderDetaileComponent } from './system/wharehouse/wharehouse-order/order-detaile/order-detaile.component';
import { WharehouseExit2Component } from './system/wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { ExitDetaile2Component } from './system/wharehouse/wharehouse-exit/exit-detaile2/exit-detaile2.component';
import { MaterialListComponent } from './system/wharehouse/wharehouse-material/material-list/material-list.component';
import { MaterialDetaileComponent } from './system/wharehouse/wharehouse-material/material-detaile/material-detaile.component';
import { InvoiceOfPersonComponent } from './system/finance/invoice-of-person/invoice-of-person.component';
import { CurrencyComponent } from './system/finance/currency/currency.component';
import { RepairmanShelfLogComponent } from './system/repairman/repairman-shelf-log/repairman-shelf-log.component';
import { CardexListComponent } from './system/wharehouse/wharehouse-cardex/cardex-list/cardex-list.component';
import { CardexDetaileComponent } from './system/wharehouse/wharehouse-cardex/cardex-detaile/cardex-detaile.component';
import { WharehouseSelectComponent } from './system/wharehouse/wharehouse-select/wharehouse-select.component';
import { BillComponent } from './system/finance/bill/bill.component';
import { WharehouseWeightComponent } from './system/wharehouse/wharehouse-weight/wharehouse-weight.component';
import { ProfitListComponent } from './system/finance/profit-list/profit-list.component';
import { ProfitDetaileComponent } from './system/finance/profit-detaile/profit-detaile.component';
import { UserServiceListComponent } from './system/user/user-service-list/user-service-list.component';
import { UserServiceDetaileComponent } from './system/user/user-service-detaile/user-service-detaile.component';
import { UserVideoComponent } from './system/user/user-video/user-video.component';
import { FinanceCardexComponent } from './system/finance/finance-cardex/finance-cardex.component';
import { InsertComponent } from './system/service/insert/insert.component';
import { WharehousePlaceComponent } from './system/wharehouse/wharehouse-place/wharehouse-place.component';
import { WharehouseGroupComponent } from './system/wharehouse/wharehouse-group/wharehouse-group.component';
import { Profit2ListComponent } from './system/finance/profit2-list/profit2-list.component';
import { RepairmanAttachDetaileComponent } from './system/repairman/repairman-attach-detaile/repairman-attach-detaile.component';
import { BascketDetaileComponent } from './system/finance/bascket-detaile/bascket-detaile.component';
import { BascketListComponent } from './system/finance/bascket-list/bascket-list.component';

import { IncomeTypeComponent } from './system/finance/income-type/income-type.component';
import { RepairmanDeliveryComponent } from './system/repairman/repairman-delivery/repairman-delivery.component';
import { OpenBelongComponent } from './system/repairman/open-belong/open-belong.component';
import { DeviceStatusComponent } from './system/repairman/device-status/device-status.component';
import { WharehouseInvoiceInputComponent } from './system/wharehouse/wharehouse-invoice-input/wharehouse-invoice-input.component';
import { WharehouseInvoiceExitComponent } from './system/wharehouse/wharehouse-invoice-exit/wharehouse-invoice-exit.component';
import { FinanaceCardexDetaileComponent } from './system/finance/finanace-cardex-detaile/finanace-cardex-detaile.component';
import { WharehouseOrderCostDetaileComponent } from './system/wharehouse/wharehouse-order-cost-detaile/wharehouse-order-cost-detaile.component';
import { HomeRightComponent } from './system/general/home-right/home-right.component';
import { WharehouseOrderServiceDetaileComponent } from './system/wharehouse/wharehouse-order-service-detaile/wharehouse-order-service-detaile.component';
import { WharehouseInvoiceReturnBuyComponent } from './system/wharehouse/wharehouse-invoice-return-buy/wharehouse-invoice-return-buy.component';
import { WharehouseOrderBuyDetaileComponent } from './system/wharehouse/wharehouse-order-buy-detaile/wharehouse-order-buy-detaile.component';
import { WharehouseOrderReturnBuyDetaileComponent } from './system/wharehouse/wharehouse-order-return-buy-detaile/wharehouse-order-return-buy-detaile.component';
import { WharehouseOrderSaleDetaileComponent } from './system/wharehouse/wharehouse-order-sale-detaile/wharehouse-order-sale-detaile.component';
import { WharehouseInvoiceReturnSaleComponent } from './system/wharehouse/wharehouse-invoice-return-sale/wharehouse-invoice-return-sale.component';
import { MaterialList2Component } from './system/wharehouse/wharehouse-material2/material-list2/material-list2.component';
import { MaterialDetaile2Component } from './system/wharehouse/wharehouse-material2/material-detaile2/material-detaile2.component';
import { MaterialDetaile3Component } from './system/wharehouse/wharehouse-material2/material-detaile3/material-detaile3.component';
import { UserContentComponent } from './system/user/user-content/user-content.component';
import { ContentListComponent } from './system/general/content/content-list/content-list.component';
import { ContentDetaileComponent } from './system/general/content/content-detaile/content-detaile.component';
import { CodeListComponent } from './system/service/code-list/code-list.component';
import { CodeDetaileComponent } from './system/service/code-detaile/code-detaile.component';
import { UserLogComponent } from './system/user/user-log/user-log.component';
import { UserMyNetworkComponent } from './system/user/user-my-network/user-my-network.component';
import { UserMyNetworkDetaileComponent } from './system/user/user-my-network-detaile/user-my-network-detaile.component';
import { MyProfileComponent } from './system/user/my-profile/my-profile.component';
import { MyReceiptComponent } from './system/repairman/my-receipt/my-receipt.component';
import { UserDetaile3Component } from './system/user/user-detaile3/user-detaile3.component';
import { CostTypeGroupComponent } from './system/finance/cost-type-group/cost-type-group.component';
import { CostTypeListComponent } from './system/finance/cost-type2/cost-type-list/cost-type-list.component';
import { CostTypeDetaileComponent } from './system/finance/cost-type2/cost-type-detaile/cost-type-detaile.component';
import { CellphonePipe } from './system/service/pipe/cellphone.pipe';
import { FinanceCardexPersonnelComponent } from './system/finance/finance-cardex-personnel/finance-cardex-personnel.component';
import { UserFirstBalanceComponent } from './system/user/user-first-balance/user-first-balance.component';
import { UserStateComponent } from './system/user/user-state/user-state.component';
import { UserHowComponent } from './system/user/user-how/user-how.component';
import { FinanceCardexPersonnelDetaileComponent } from './system/finance/finance-cardex-personnel-detaile/finance-cardex-personnel-detaile.component';
import { WharehouseOrderSaleSearialComponent } from './system/wharehouse/wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';
import { CountryListComponent } from './system/user/user-country/country-list/country-list.component';
import { CountryDetaileComponent } from './system/user/user-country/country-detaile/country-detaile.component';
import { BrandListComponent } from './system/repairman/brand/brand-list/brand-list.component';
import { BrandDetaileComponent } from './system/repairman/brand/brand-detaile/brand-detaile.component';
import { CashListComponent } from './system/finance/cash/cash-list/cash-list.component';
import { CashDetaileComponent } from './system/finance/cash/cash-detaile/cash-detaile.component';
import { FinanceBankListComponent } from './system/finance/finance-bank/finance-bank-list/finance-bank-list.component';
import { FinanceBankDetaileComponent } from './system/finance/finance-bank/finance-bank-detaile/finance-bank-detaile.component';
import { UserListAccessComponent } from './system/user/user-list-access/user-list-access.component';
import { GuilsListComponent } from './system/user/guils/guils-list/guils-list.component';
import { GuilsDetaileComponent } from './system/user/guils/guils-detaile/guils-detaile.component';
import { NewspaperListComponent } from './system/finance/newspaper/newspaper-list/newspaper-list.component';
import { NewspaperDetaileComponent } from './system/finance/newspaper/newspaper-detaile/newspaper-detaile.component';
import { ReportDeviceComponent } from './system/report/report-device/report-device.component';
import { AccountRenewalComponent } from './system/general/account-renewal/account-renewal.component';
import { UserListAccountsComponent } from './system/user/user-list-accounts/user-list-accounts.component';
import { UserListAccountsDetaileComponent } from './system/user/user-list-accounts-detaile/user-list-accounts-detaile.component';
import { CouponListComponent } from './system/user/coupon/coupon-list/coupon-list.component';
import { CouponDetaileComponent } from './system/user/coupon/coupon-detaile/coupon-detaile.component';
import { FinancialDetaile2Component } from './system/finance/finance-new/financial-detaile2/financial-detaile2.component';
import { FinancialList2Component } from './system/finance/finance-new/financial-list2/financial-list2.component';
import { ChequeListComponent } from './system/finance/cheque/cheque-list/cheque-list.component';
import { FinancialDetaile3Component } from './system/finance/finance-new/financial-detaile3/financial-detaile3.component';
import { ChequeFilterComponent } from './system/finance/cheque/cheque-filter/cheque-filter.component';
import { ChequePayDetaileComponent } from './system/finance/cheque/cheque-pay-detaile/cheque-pay-detaile.component';
import { ChequeRecieveDetaileComponent } from './system/finance/cheque/cheque-recieve-detaile/cheque-recieve-detaile.component';
import { ChequeListRecieveComponent } from './system/finance/cheque/cheque-list-recieve/cheque-list-recieve.component';
import { UserSelectRepirmanComponent } from './system/user/user-select-repirman/user-select-repirman.component';
import { CardexDetaile2Component } from './system/wharehouse/wharehouse-cardex/cardex-detaile2/cardex-detaile2.component';

@NgModule({
  declarations: [
    AppComponent, HomeComponent
    , LoginComponent
    , PaccessComponent
    , GaccessComponent
    , ReportDeviceComponent
    , MenuItemsComponent, TabComponent, ModulesComponent, SubstrPipe, CellphonePipe, DeviceComponent, UserGroupsComponent
    , UserListComponent, UserDetaileComponent, ModelListComponent
    , ModelDetaileComponent, RstatusListComponent, RstatusDetaileComponent
    , ReceptionListComponent, ReceptionDetaileComponent, UserSexComponent
    , ModelList2Component, BelongListComponent, BelongDetaileComponent, BelongList2Component
    , ObjectionsListComponent, ObjectionsList2Component, ObjectionsDetaileComponent
    , UserSearchComponent, UserDetaile2Component, InsertModelComponent
    , RepairmanShelfStatusComponent
    , ShelfListComponent, PaymentTypeComponent, BankComponent, AccountingTypeComponent
    , BankAccountsComponent
    , NewFastMaterialComponent, ChequeStatusComponent, DocumentComponent, FinancialListComponent
    , FinancialDetaileComponent, Financial2ListComponent, Financial2DetaileComponent, ChequeSelectComponent
    , AccountsDetaileComponent, InsertBelongComponent, InsertObjectionsComponent
    , ServicesComponent, PaymentComponent
    , InvoiceDetaileComponent, InvoiceListComponent, NewFastMaterial2Component, DashboardComponent
    , WharehouseMaterialCateDetaileComponent, WharehouseOrderStatusComponent
    , WharehouseInvoiceTypeComponent, WharehouseCostCenterComponent, SearchMaterialComponent, OrderDetaileComponent
    , WharehouseExit2Component, ExitDetaile2Component
    , MaterialListComponent, MaterialDetaileComponent, InvoiceOfPersonComponent
    , CurrencyComponent, RepairmanShelfLogComponent, CardexListComponent, CardexDetaileComponent
    , WharehouseSelectComponent, BillComponent, WharehouseWeightComponent, ProfitListComponent
    , ProfitDetaileComponent, UserServiceListComponent, UserServiceDetaileComponent, UserVideoComponent
    , FinanceCardexComponent, InsertComponent
    , WharehousePlaceComponent
    , WharehouseGroupComponent, Profit2ListComponent, RepairmanAttachDetaileComponent
    , BascketDetaileComponent, BascketListComponent
    , IncomeTypeComponent, RepairmanDeliveryComponent, OpenBelongComponent
    , DeviceStatusComponent, WharehouseInvoiceInputComponent, WharehouseInvoiceExitComponent, FinanaceCardexDetaileComponent
    , WharehouseOrderCostDetaileComponent, HomeRightComponent, WharehouseOrderServiceDetaileComponent, WharehouseInvoiceReturnBuyComponent
    , WharehouseOrderBuyDetaileComponent, WharehouseOrderReturnBuyDetaileComponent, WharehouseOrderSaleDetaileComponent
    , WharehouseInvoiceReturnSaleComponent, MaterialList2Component, MaterialDetaile2Component
    , MaterialDetaile3Component, UserContentComponent, ContentListComponent, ContentDetaileComponent
    , CodeListComponent, CodeDetaileComponent, UserLogComponent
    , UserMyNetworkComponent, UserMyNetworkDetaileComponent
    , MyProfileComponent, MyReceiptComponent, UserDetaile3Component, CostTypeGroupComponent
    , CostTypeListComponent, CostTypeDetaileComponent, CellphonePipe, FinanceCardexPersonnelComponent
    , UserFirstBalanceComponent, UserStateComponent, UserHowComponent, FinanceCardexPersonnelDetaileComponent
    , WharehouseOrderSaleSearialComponent, CountryListComponent, CountryDetaileComponent, BrandListComponent
    , BrandDetaileComponent, CashListComponent, CashDetaileComponent, FinanceBankListComponent, FinanceBankDetaileComponent
    , UserListAccessComponent, GuilsListComponent, GuilsDetaileComponent, NewspaperListComponent, NewspaperDetaileComponent
    , UserListAccountsComponent, UserListAccountsDetaileComponent
    , CouponListComponent, CouponDetaileComponent, FinancialDetaile2Component
    , FinancialList2Component, ChequeListComponent, FinancialDetaile3Component, ChequeFilterComponent
    , ChequePayDetaileComponent, ChequeRecieveDetaileComponent, ChequeListRecieveComponent
    , AccountRenewalComponent, UserSelectRepirmanComponent, CardexDetaile2Component
  ],
  imports: [
    appRoutingModule, BrowserAnimationsModule, BrowserModule, MatDialogModule, ReactiveFormsModule, FormsModule, MatProgressBarModule
    , MatSnackBarModule, HttpClientModule, MatTableModule
    , MatSortModule, MatCheckboxModule, MatIconModule, DragDropModule, MatSidenavModule
    , NgxSmartModalModule.forRoot(), NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
    , MatCardModule, MatMenuModule, MatCoreModule, MatDatepickerModule, MatFormFieldModule
    , MatRadioModule, HighchartsChartModule, MatBottomSheetModule
    , DropzoneModule
    , RichTextEditorModule
    , MatTabsModule
  ],
  providers: [
    MessageService, ServerService, { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
