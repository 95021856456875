
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { UserMyNetworkDetaileComponent } from '../user-my-network-detaile/user-my-network-detaile.component';
import { MessageService } from '../../service/message/message.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-user-my-network',
  templateUrl: './user-my-network.component.html',
  styleUrls: ['./user-my-network.component.scss']
})
export class UserMyNetworkComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public lang_text: any = [];
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public export_code: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  @ViewChild('myModal', { static: true }) myModal: any;
  @ViewChild('myModalSearch', { static: true }) myModalSearch: any;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  public search_all: string | undefined;
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public creator: number | undefined;
  public video: string | undefined;
  public expire: boolean | undefined;
  public path_export: string | undefined;
  public moarf_code: string;
  public profit: number = 0;
  public search_array: any = [];
  public base: string = this.serverService.domain + "/register";
  public my_moarf: string;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'moarf', 'title', 'date', 'month', 'amount', 'profit', 'id'];
  //*************
  public form1_group: FormGroup | any;
  public form1_search_group: FormGroup | any;
  //************** */
  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public _clipboardService: ClipboardService
    , public dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form1();
    this.create_search_form1();
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.required]),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_id': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')])
    })
  }

  create_search_form1() {
    this.form1_search_group = new FormGroup({
      'title_search': new FormControl(null),
      'status_id_search': new FormControl(null)
    })
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.title = res['result'][0].title;
            this.creator = res['creator'];
            this.video = res['result'][0].video;
            this.expire = res['expire'];
            this.path_export = res['path_export'];
            this.loading = false;
            if (type_task == 1) {
              if (this.expire == true) {
                this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
                return false;
              } else {
              }
            }
            if (type_task == 2) { }
            if (type_task == 3) {
            }
            if (type_task == 4) {
              this.get_my_moarf_code();
              this.load_data(page, excel, loading, -1);
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
    }
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    var obj = {
      'address': 6579, "page": this.page
      , 'type': this.type, 'export_excel': excel, user_id: this.user_id
      , 'title': this.form1_search_group.value.title_search
      , 'status_id': this.form1_search_group.value.status_id_search
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'path_export': this.path_export
    }

    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.profit = res['profit'];
            this.pages(0);
            this.myModal.close();
            this.myModalSearch.close();
            this.serverService.send_my_network();
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.serverService.change_window_resize(2, '', 0);
          this.serverService.send_services_id(this.code);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  copy() {
    var pe_message = "کپی شد";
    var en_message = "Copied";
    this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
    this._clipboardService.copyFromContent(this.my_moarf);
  }


  get_my_moarf_code() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6584, creator: this.creator }).subscribe(
      (res) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['num'] == 1) {
            this.my_moarf = this.base + "?moarf=" + res['result'][0].user_my_moarf;
          }//end if num
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(UserMyNetworkDetaileComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 2) {
            this.my_moarf = this.base + "?moarf=" + res.user_my_moarf;
          }
          if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }


  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    this.serverService.change_window_resize(1, '', 0);
  }//end change_resize

  set_title(header: string, event: any) {
    var obj = {
      address: 6546,
      user_id: this.user_id,
      code: this.code,
      header: header,
      title: event.target.value,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  ModalClose() {
    this.message(false, "", 2, this.messageService.close(this.lang));
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
