import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import * as Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isOnline: any;
  public loading = false;
  public subscription: Subscription;
  public err: string; public err_validation: boolean = false;
  public err_internet_text: string; public err_internet_validation: boolean;
  public server: any = this.serverService.get_server();
  public username: any;
  user_info: any;
  user_id: number;
  site: any = this.serverService.get_site();
  job: number;
  group: number;
  fname: string;
  store: string;
  lname: string;
  user: number;
  user1: number;
  user2: number;
  user3: number;
  user_store: string = ".....";
  branch_id: number;
  user_logo_ranking: string;
  logo: string;
  user_adver_link_ranking: string;
  list_year: any = [];
  year_id: number;
  list_month: any = [];
  month_id: any;
  day_id: number;
  chart_ylabel: string;
  list1: any = [];
  list2: any = [];
  list_idea: any = [];
  current_day: number;
  current_day2: number;
  remain_days: any;
  list_goods: any = [];
  list_goods2: any = [];
  list_goods2_data: any = [];
  list_score_cities: any = [];
  list_score_cities_yearly: any = [];
  sum_amount_sale: number = 0;
  sum_price: number = 0;
  creator: number | undefined;
  level: number | undefined;

  date1: any | undefined;
  date2: any | undefined;


  month_title: string;
  count_invoice: number = 0;
  count_customer: number = 0;

  tooltip2: any = {
    headerFormat: '<span></span><table><tr>',
    pointFormat: '<td><div style="direction:ltr;float:left"> <div style="display:inline;padding:0 5px">عدد</div>  </td><td> {point.y:,.0f} </td></div>',

    footerFormat: '</tr></table>',
    shared: true,
    useHTML: true
  }

  tooltip3: any = {
    headerFormat: '<span></span><table><tr>',
    pointFormat: '<td><div style="direction:ltr;float:left">  <div style="display:inline;padding:0 5px">عدد</div>  </td><td> {point.y} </td></div>',
    footerFormat: '</tr></table>',
    shared: true,
    useHTML: true
  }

  tooltip4: any = {
    headerFormat: '<span></span><table><tr>',
    pointFormat: '<td><div style="direction:ltr;float:left"> <div style="display:inline;padding:0 5px">تومان</div>  </td><td> {point.y} </td></div>',
    footerFormat: '</tr></table>',
    shared: true,
    useHTML: true
  }

  years_users: any = [];
  years_count_users: any = [];

  list_score_month: any = [];
  list_score_month_text: any = [];
  list_score_branch_data: any = [];
  list_score_branch_porsant: any = [];
  list_score_branch_monthly: any = [];
  list_score_branch_monthly2: any = [10, 20];

  idea: number;

  content_comment: string;
  @ViewChild('myModal') myModal: any;
  list_users: any = [];
  left_list_users2: any = [];
  list_users3: any = [];
  list_users4: any = [];
  list_users_saco: any = [];
  user_logo1: string;
  user_logo2: string;
  user_logo3: string;

  list_users2: any = [];
  list_color: any = ['#6d9077', '#3919ea', '#35ec1f', '#ecad1f', '#ec1fdc', '#1fecec', '#dfec1f', '#1d8c08', '#ec380fd9', '#f10bdfd9',
    '#6d9077', '#3919ea', '#35ec1f', '#ecad1f', '#ec1fdc', '#1fecec', '#dfec1f', '#1d8c08', '#ec380fd9', '#f10bdfd9',
    '#6d9077', '#3919ea', '#35ec1f', '#ecad1f', '#ec1fdc', '#1fecec', '#dfec1f', '#1d8c08', '#ec380fd9', '#f10bdfd9',
    '#6d9077', '#3919ea', '#35ec1f', '#ecad1f', '#ec1fdc', '#1fecec', '#dfec1f', '#1d8c08', '#ec380fd9', '#f10bdfd9',];

  Highcharts: typeof Highcharts = Highcharts; // required
  chartOptions: Highcharts.Options = {};



  constructor(public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , private dialog: MatDialog
    , private activatedRoute: ActivatedRoute
  ) {
    this.Highcharts.setOptions({
      colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
        '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
      lang: {
        thousandsSep: ','
      }
    });

    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.user_info = JSON.parse(<any>localStorage.getItem("user_info"));
        if (this.user_info) {
          this.user_id = this.user_info.user_id;
          this.username = this.user_info.username;
          this.job = this.user_info.job;
          this.group = this.user_info.group;
          this.fname = this.user_info.fname;
          this.lname = this.user_info.lname;
        }
        this.get_user();
        //this.get_year();

      }
    )
  }//end consructor


  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
      this.group = this.user_info.group;
    }
    for (var i = 1; i <= 12; i++) {
      if (i < 10) this.list_score_month.push("0" + i);
      if (i >= 10) this.list_score_month.push(i);
    }
    this.change_month_to_text(this.list_score_month);
    if (this.group == 1) {
      this.serverService.signout();
    }


  }

  date_change() {
    this.year_id = 0;
    this.month_id = 0;
    this.get_users('second', 0, 0);
    this.get_users2('second', 0, 0);
  }


  get_ranking_year(year_id) {
    this.year_id = year_id;
    this.get_users('second', year_id, 0);
    this.get_users2('second', year_id, 0);
    this.get_count_sale_goods_monthly(year_id);
    this.get_count_invoice(year_id);
    this.get_count_customer(year_id);
    this.get_cities_sales_yearly(year_id);
    this.get_score_all_branch_montly(year_id);
  }

  select_month(year_id, month) {
    this.date1 = null;
    this.date2 = null;
    this.get_users('second', year_id, month);
    this.get_users2('second', year_id, month);
    //this.get_branch(year_id, month, 'none');
    //this.get_cities_sales(year_id, month);
    //this.get_goods(year_id, month);
  }

  get_user() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6139, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.get_year();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_year() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2010 }).subscribe(
      (res) => {
        this.list_year = [];
        if (res['status'] == 1) {
          this.year_id = res['current_year'];
          for (var i = 0; i < res['num']; i++) {
            this.list_year.push(res['result'][i]);
          }//end for
          this.get_score_all_branch_montly(this.year_id);
          this.get_month(this.year_id);
          this.get_count_sale_goods_monthly(this.year_id);

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_month(year_id) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2011 }).subscribe(
      (res) => {
        this.list_month = [];
        if (res['status'] == 1) {
          this.month_id = res['current_month'];
          for (var i = 0; i < res['num']; i++) {
            this.list_month.push(res['result'][i]);
          }//end for
          this.get_users('first', this.year_id, this.month_id);
          this.get_users2('first', this.year_id, this.month_id);
          //this.get_goods(this.year_id, this.month_id);
          //this.get_one_month(this.month_id);
          //this.get_cities_sales(this.year_id, this.month_id);
          //this.get_branch(this.year_id, this.month_id, 'none');
          //this.get_ranking_year(this.year_id);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_users(start, year, month) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (month > 0) this.month_id = month;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6120, year: year, month: month, date1: this.date1, date2: this.date2, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_users = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].user_logo) {
              res['result'][i].user_logo = res['result'][i].user_logo_site + "/" + res['result'][i].user_logo;
            }
            else {
              res['result'][i].user_logo = this.serverService.get_default_user_logo();
            }
            this.list_users.push(res['result'][i]);

          }//end for
          //this.list_users.push({ footer: true });
          if (this.list_users.length > 0 && start == 'first') this.get_porsant_user(this.list_users[0].user_id);

          $(".content").css('height', 'calc(100vh - 135px' + ')');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  open_detaile(user, user_title) {
    this.router.navigate(['/home/profit/212'], { queryParams: { year: this.year_id, month: this.month_id, seller: user, search: true } });
  }

  open_detaile2(user, user_title) {
    this.router.navigate(['/home/profit2/235'], { queryParams: { year: this.year_id, month: this.month_id, seller: user, search: true } });
  }

  get_users2(start, year, month) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (month > 0) this.month_id = month;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6135, year: year, month: month, date1: this.date1, date2: this.date2, creator: this.creator, level: this.level, user_id: this.user_id }).subscribe(
      (res: any) => {
        this.left_list_users2 = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].user_logo) {
              res['result'][i].user_logo = res['result'][i].user_logo_site + "/" + res['result'][i].user_logo;
            }
            else {
              res['result'][i].user_logo = this.serverService.get_default_user_logo();
            }
            this.left_list_users2.push(res['result'][i]);

          }//end for
          //this.left_list_users2.push({ footer: true });
          if (this.list_users.length > 0 && start == 'first') this.get_porsant_user(this.list_users[0].user_id);

          $(".content").css('height', 'calc(100vh - 135px' + ')');
          this.recieve_message(false, "", "", 1, "", "");
          console.log(this.left_list_users2)
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_porsant_user(user_id) {
    if (this.serverService.check_internet()['status'] == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.branch_id = user_id;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 2016, user_id: user_id, month: this.month_id, year: this.year_id, idea: this.idea }).subscribe(
      (res) => {
        if (res['status'] == 1) {
          if (res['num'] > 0) this.user_store = res['result'][0].user_title;
          this.list_score_branch_data = [];
          this.list_score_branch_porsant = [];
          for (var i = 1; i <= 12; i++) {
            this.list_score_branch_data.push(0);
            this.list_score_branch_porsant.push(0);
          }

          for (var i = 0; i < res['num']; i++) {
            for (var j = 0; j < this.list_score_month.length; j++) {
              if (res['result'][i].repairman_reception_exit_month == this.list_score_month[j]) {
                if (res['result'][i].porsant == null) res['result'][i].porsant = 0;
                this.list_score_branch_data[j] = res['result'][i].porsant;
                this.list_score_branch_porsant[j] = res['result'][i].porsant;
              }
            }
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_one_month(month: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.month_id = month;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2015, month: month }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.month_title = " ماه " + res['result'][0].site_month_title;
          } else {
            this.month_title = " سال " + this.year_id;
          }

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }



  get_goods(year_id: number, month_id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2017, user_id: this.user_id, month: month_id, year: year_id }).subscribe(
      (res: any) => {
        this.list_goods = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_goods.push(res['result'][i]);
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }



  change_month_to_text(list) {
    for (var i = 0; i <= list.length; i++) {
      if (list[i] == '01') { this.list_score_month_text.push('فروردین'); }
      if (list[i] == '02') { this.list_score_month_text.push('اردیبهشت'); }
      if (list[i] == '03') { this.list_score_month_text.push('خرداد'); }
      if (list[i] == '04') { this.list_score_month_text.push('تیر'); }
      if (list[i] == '05') { this.list_score_month_text.push('مرداد'); }
      if (list[i] == '06') { this.list_score_month_text.push('شهریور'); }
      if (list[i] == '07') { this.list_score_month_text.push('مهر'); }
      if (list[i] == '08') { this.list_score_month_text.push('آبان'); }
      if (list[i] == '09') { this.list_score_month_text.push('آذر'); }
      if (list[i] == '10') { this.list_score_month_text.push('دی'); }
      if (list[i] == '11') { this.list_score_month_text.push('بهمن'); }
      if (list[i] == '12') { this.list_score_month_text.push('اسفند'); }
    }
  }







  get_branch(year, month, day) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.month_id = month;
    this.year_id = year;
    this.day_id = day;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1746, user_id: this.user_id, day: day, month: month, year: year, idea: this.idea }).subscribe(
      (res: any) => {
        this.list_users = [];
        this.list_users2 = [];
        this.sum_price = 0;
        this.sum_amount_sale = 0;
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].user_logo) {
              res['result'][i].user_logo = res['result'][i].user_logo_site + "/" + res['result'][i].user_logo;
            }
            else {
              res['result'][i].user_logo = this.serverService.get_default_user_logo();
            }
            if (res['result'][i].count > 0) {
              this.sum_price += res['result'][i].price;
              this.sum_amount_sale += res['result'][i].amount_sale;
              res['result'][i].color = "#F5F6F7";
              this.list_users.push(res['result'][i]);
            } else {
              this.list_users2.push(res['result'][i]);
            }
          }//end for
          //this.list_users.push({ price: this.sum_price, amount_sale: this.sum_amount_sale, footer: true, color: '#fffead', user_branch_title: 'جمع کل' })
          if (this.job == 1 && this.list_users.length > 0) this.get_porsant_user(this.list_users[0].user_id);
          $(".content").css('height', 'calc(100vh - 135px' + ')');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }



  get_count_invoice(year_id) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1684, year: year_id, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_invoice = res['count'];
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_count_customer(year_id) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1685, year: year_id, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_customer = res['count'];
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_count_sale_goods_monthly(year_id) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2017, user_id: this.user_id, year: year_id, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_goods2_data = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_goods2.push(res['result'][i].name);
            this.list_goods2_data.push(res['result'][i].y);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }



  get_score_all_branch_montly(year_id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 2013, user_id: this.user_id, year: year_id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          this.list_score_branch_monthly = [];
          for (var i = 1; i <= 12; i++) {
            this.list_score_branch_monthly.push();
          }

          for (var i = 1; i <= 12; i++) {
            this.list_score_branch_monthly.push(0);
          }

          for (var i = 0; i < res['num']; i++) {
            for (var j = 0; j < this.list_score_month.length; j++) {
              if (res['result'][i] && res['result'][i].repairman_reception_month == this.list_score_month[j]) {
                this.list_score_branch_monthly[j] = res['result'][i].count;
              }
            }
          }

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_cities_sales(year_id: number, month_id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1680, user_id: this.user_id, month: this.month_id, year: this.year_id }).subscribe(
      (res: any) => {
        this.list_score_cities = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_score_cities.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_cities_sales_yearly(year_id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1680, user_id: this.user_id, year: this.year_id }).subscribe(
      (res: any) => {
        this.list_score_cities_yearly = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_score_cities_yearly.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }











  //**************************************************
  convertToInt(value) {
    return parseInt(value)
  }

  recieve_message(validation, en_message, pe_message, type, en_action, pe_action) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(pe_message, pe_action, { duration: 2000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
