import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-select-repirman',
  templateUrl: './user-select-repirman.component.html',
  styleUrls: ['./user-select-repirman.component.scss']
})
export class UserSelectRepirmanComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public type: string | undefined;
  public device: number | undefined;
  public title: string | undefined;
  public list_belong_selected: any = [];
  public list_objections: any = [];
  public list_objections_selected: any = [];
  public creator: number;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public list_repairman: any = [];
  public type_task: number;
  public show_cellphone: boolean;
  public list_select_items: any = [];
  public list_selected_repairman: any = [];
  public displayedColumns = ['row', 'title', 'cellphone', 'operation'];
  //*************

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<UserSelectRepirmanComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.type_task = dialog_data.type_task;
      this.show_cellphone = dialog_data.show_cellphone;
      if (this.type_task != 3) {
        this.list_selected_repairman = dialog_data.repairman;
        this.list_select_items = String(dialog_data.repairman);
      }
    }
  }//end consructor

  ngOnInit() {
    this.get_repairman(1);
  }

  get_repairman(step: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var selected_array: any = [];
    if (this.list_selected_repairman) {
      selected_array = String(this.list_selected_repairman).split(",").map((items) => {
        return parseInt(items);
      });
    }
    if (step == 1) this.loading = true;
    var obj = {
      address: 6813,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_repairman = [];
          for (var i = 0; i < res['num']; i++) {
            if (selected_array.indexOf(res['result'][i].user_id) != -1) {
              res['result'][i].checked = true;
            }
            this.list_repairman.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_repairman);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  select_items() {
    this.list_select_items = [];
    var list_select_items_temp = <any>document.getElementsByName("repairman[]");
    for (var i = 0; i < list_select_items_temp.length; i++) {
      if (list_select_items_temp[i].checked == true) {
        this.list_select_items.push(list_select_items_temp[i].value);
      }
      this.list_select_items = this.list_select_items.map(function (items) {
        return parseInt(items);
      })
    }
    if (this.type_task == 3) this.save();
  }

  //this.matDialogRef.close({ items: this.list_select_items });
  save() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6814, items: this.list_select_items }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ items: this.list_select_items, items_title: res['result'][0].user_title });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************  */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
