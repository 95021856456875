<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span *ngIf="lang == 1">درج جدید</span>
        <span *ngIf="lang == 2">Add new</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">

    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="material_type == 2">
                <label for="group" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه </span>
                    <span *ngIf="lang == 2"> Group </span>
                    <span (click)="add_group()" class="add_icon" [ngStyle]="{'float':dir == 'ltr' ? 'right' : 'left' }">
                        <i class="fas fa-bookmark cursor"></i>
                    </span>
                    <select class="wh-input" formControlName="group" (change)="get_cate()"
                        [required]="material_type == 1" [ngClass]="{'en':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor="let x of list_group" [value]="x.wharehouse_group_id">
                            {{ x.wharehouse_group_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="material_type == 2">
                <label for="cate" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه </span>
                    <span *ngIf="lang == 2"> Subgroup </span>
                    <span (click)="add_cate()" class="add_icon">
                        <i class="fas fa-bookmark cursor" [ngStyle]="{'float':dir == 'ltr' ? 'right' : 'left' }"></i>
                    </span>
                    <select class="wh-input" formControlName="cate" [required]="material_type == 1"
                        [ngClass]="{'en':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor="let x of list_cate" [value]="x.wharehouse_material_cate_id">
                            {{ x.wharehouse_material_cate_title }}
                        </option>
                    </select>
                </label>
            </div>


            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="material_type == 1">
                        <span *ngIf="lang == 1"> عنوان کالا </span>
                        <span *ngIf="lang == 2"> Product name </span>
                    </span>
                    <span *ngIf="material_type == 2">
                        <span *ngIf="lang == 1"> عنوان خدمات </span>
                        <span *ngIf="lang == 2"> Service name </span>
                    </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="true"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="material_type == 2">
                <label for="inventory" class='wh-label'>
                    <span *ngIf="lang == 1"> موجودی اول دوره </span>
                    <span *ngIf="lang == 2"> Initial stock </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off"
                        [required]="material_type == 1" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="pending == 1">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت </span>
                    <span *ngIf="lang == 2"> Price </span>
                    <span *ngIf="material_type == 1">
                        <span *ngIf="lang == 1"> خرید </span>
                        <span *ngIf="lang == 2"> Purchase </span>
                    </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="material_type == 2 || pending == 1">
                <label for="price2" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت فروش </span>
                    <span *ngIf="lang == 2"> Sales price </span>
                    <input class='wh-input' formControlName="price2" autocomplete="off" currencyMask
                        [required]="material_type == 1" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="material_type == 2 || true">
                <label for="weight" class='wh-label'>
                    <span *ngIf="lang == 1"> واحد شمارش </span>
                    <span *ngIf="lang == 2"> Counting unit </span>
                    <select class="wh-input" formControlName="weight" [ngClass]="{'en':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor="let x of list_weight" [value]="x.wharehouse_weight_id">
                            {{ x.wharehouse_weight_title }}
                        </option>
                    </select>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            (click)="save('close')">
            <span *ngIf="lang == 1">ذخیره و بستن</span>
            <span *ngIf="lang == 2">Save and close</span>
        </button>
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            (click)="save('new')" [hidden]="ref == 'reception' ">
            <span *ngIf="lang == 1">ذخیره و جدید</span>
            <span *ngIf="lang == 2">Save and new</span>
        </button>

    </div>
</form>