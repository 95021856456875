<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='filter()'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <button [ngClass]="{en_navigation_number:lang==2}" (click)="load_data1(0)"
                    [ngClass]="{active_button:type == 0}">
                    <span *ngIf="lang == 1">همه</span>
                    <span *ngIf="lang == 2">All</span>
                </button>
                <button [ngClass]="{en_navigation_number:lang==2}" (click)="load_data1(1)"
                    [ngClass]="{active_button:type == 1}">
                    <span *ngIf="lang == 1">چکهای دریافتی </span>
                    <span *ngIf="lang == 2">Receive Checks</span>
                </button>
                <button [ngClass]="{en_navigation_number:lang==2}" (click)="load_data1(3)"
                    [ngClass]="{active_button:type == 3}">
                    <span *ngIf="lang == 1">چکهای دریافتی خرج شده</span>
                    <span *ngIf="lang == 2">Cashed checks received</span>
                </button>
                <button [ngClass]="{en_navigation_number:lang==2}" (click)="load_data1(2)"
                    [ngClass]="{active_button:type == 2}">
                    <span *ngIf="lang == 1">چکهای پرداختی </span>
                    <span *ngIf="lang == 2">Checks made out to me</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'
                (click)="reset_sort('finance_financial2_id','desc')">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef [hidden]="type != 0" style="max-width:70px"
                [ngClass]="{active1:order == 'site_cheque_type_title ' + asc_desc }"
                (click)="sort('site_cheque_type_title',asc_desc)">
                <span *ngIf="lang == 1">نوع چک</span>
                <span *ngIf="lang == 2">Check Type</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [hidden]="type != 0" style="max-width:70px" [ngClass]="{'en':dir == 'ltr'}">{{
                x.site_cheque_type_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 180px;"
                [ngClass]="{active1:order == 'finance_financial2_cheque_title ' + asc_desc }"
                (click)="sort('finance_financial2_cheque_title',asc_desc)">
                <span *ngIf="lang == 1">بابت</span>
                <span *ngIf="lang == 2">For</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                class="right rtl" style="min-width: 180px;">
                <div class="right" [ngClass]="{'en':dir == 'ltr','full-left':dir == 'ltr'}">
                    {{x.finance_financial2_cheque_title | substr:0:25 }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 180px;"
                [ngClass]="{active1:order == 'user_title ' + asc_desc }" (click)="sort('user_title',asc_desc)">
                <span *ngIf="lang == 1">نام طرف حساب</span>
                <span *ngIf="lang == 2">Account holder</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                style="min-width: 180px;">
                <div class="right" [ngClass]="{'en':dir == 'ltr','full-left':dir == 'ltr'}">{{ x.user_title |
                    substr:0:25 }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="bank">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'site_bank_title ' + asc_desc }"
                (click)="sort('site_bank_title',asc_desc)">
                <span *ngIf="lang == 1">بانک</span>
                <span *ngIf="lang == 2">Bank</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.site_bank_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="account">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'site_bank_accounts_number ' + asc_desc }"
                (click)="sort('site_bank_accounts_number',asc_desc)">
                <span *ngIf="lang == 1">شماره حساب</span>
                <span *ngIf="lang == 2">Account number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [ngClass]="{'en':dir == 'ltr'}">
                {{x.site_bank_accounts_number }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef
                [ngClass]="{active1:order == 'finance_financial2_cheque_number ' + asc_desc }"
                (click)="sort('finance_financial2_cheque_number',asc_desc)">
                <span *ngIf="lang == 1">شماره چک</span>
                <span *ngIf="lang == 2">Check number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.finance_financial2_cheque_number }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="due">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'due_date ' + asc_desc }"
                (click)="sort('due_date',asc_desc)">
                <span *ngIf="lang == 1">تاریخ سررسید</span>
                <span *ngIf="lang == 2">Due date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.due_date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_financial2_amount ' + asc_desc }"
                (click)="sort('finance_financial2_amount',asc_desc)">
                <span *ngIf="lang == 1">مبلغ چک</span>
                <span *ngIf="lang == 2">Check amount</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.finance_financial2_amount | number}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef style="max-width:100px"
                [ngClass]="{active1:order == 'site_cheque_status_title ' + asc_desc }"
                (click)="sort('site_cheque_status_title',asc_desc)">
                <span *ngIf="lang == 1">وضعیت چک</span>
                <span *ngIf="lang == 2">Check status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px" [ngClass]="{'en':dir == 'ltr'}"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_cheque_type,x.finance_financial2_cheque_myself)">{{
                x.site_cheque_status_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef style="max-width:100px"
                [ngClass]="{active1:order == 'site_cheque_status_title ' + asc_desc }"
                (click)="sort('site_cheque_status_title',asc_desc)" [hidden]="type != 3">
                <span *ngIf="lang == 1">اطلاعات</span>
                <span *ngIf="lang == 2">info</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px" [ngClass]="{'en':dir == 'ltr'}"
                [hidden]="type != 3" [matMenuTriggerFor]="menu" (click)="get_id(x.finance_financial2_id)">
                <span><i class="fas fa-info-circle icon"></i></span>
            </mat-cell>
        </ng-container>
        <mat-menu #menu="matMenu" class="check-mat-menu-panel" xPosition="before"
            [ngClass]="{'full-left':dir == 'ltr'}">
            <table class="table table-bordered" (click)="$event.stopPropagation()"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}" (click)="open_detaile_spend()" class="cursor">
                        <span *ngIf="lang == 1"> مشاهده </span>
                        <span *ngIf="lang == 2"> view </span>
                    </td>
                </tr>
                <tr>
                    <a (click)="go_to_financial()">
                        <td class="right" [ngClass]="{'left':dir == 'ltr'}" class="cursor">
                            <span *ngIf="lang == 1"> مشاهده و ویرایش </span>
                            <span *ngIf="lang == 2"> View & update </span>
                        </td>
                    </a>
                </tr>
            </table>
        </mat-menu>


        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngStyle]="{backgroundColor:row.site_cheque_status_color}"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>
    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>