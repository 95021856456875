<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>
            <span *ngIf="lang == 1">تعمیرکاران</span>
            <span *ngIf="lang == 2">Repairman</span>
        </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>

        <input class="search wh-input" (keyup)="get_repairman(2)" [(ngModel)]="title"
            placeholder="{{ messageService.get_search(lang) }}">

        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span *ngIf="lang == 1">ردیف</span>
                    <span *ngIf="lang == 2">Row</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان</span>
                    <span *ngIf="lang == 2">Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}"
                    [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                    <div class="right">{{ x.user_title }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cellphone">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره موبایل</span>
                    <span *ngIf="lang == 2">Cellphone</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}"
                    [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                    <div *ngIf="show_cellphone == 1" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_cellPhone }}</div>
                    <div *ngIf="show_cellphone == 0" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.user_cellPhone | hide_cellphone
                        }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style="max-width: 100px">
                    <span *ngIf="lang == 1">انتخاب</span>
                    <span *ngIf="lang == 2">Select</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 100px">
                    <span>
                        <mat-checkbox class="pe1" [ngClass]="{en1:lang == 2}" name="repairman[]" value="{{ x.user_id }}"
                            (change)="select_items()" [checked]="x.checked">
                        </mat-checkbox>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_hover': row === mat_table_hoverRow,'green':list_belong_selected.indexOf(row.repairman_belong2_id) >= 0}"
                class="cursor"></mat-row>
        </mat-table>
    </div>
</div>

<div mat-dialog-actions class="matDialogActions">
    <div>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="save()">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1"> ذخیره </span>
        </button>
    </div>
</div>