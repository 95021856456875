<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang)}}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>

                <button class="search1" [hidden]="HideorShow()" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>

                <button class="search1" *ngIf="HideorShow()" (click)='open_filter_group_dialog(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <button class="search1 hidden-xs" (click)='load_data4(1)' [ngClass]="{active1:invoice_type == 1}">
                    <span *ngIf="lang == 1">خرید ها</span>
                    <span *ngIf="lang == 2">Purchases</span>
                </button>
                <button class="search1 hidden-xs" (click)='load_data4(3)' [ngClass]="{active1:invoice_type == 3}">
                    <span *ngIf="lang == 1">فروش ها</span>
                    <span *ngIf="lang == 2">Sales</span>
                </button>
                <button (click)='load_data2()' class="hidden-xs"
                    [ngClass]="{active1:negative == true && invoice_type == 0}" *ngIf="level == 1">-</button>
                <button (click)='load_data3()' [ngClass]="{active1:negative == false && invoice_type == 0}">
                    {{ messageService.get_all(lang)}}
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll_bg pe1'
        [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:40px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef style="min-width:200px" [hidden]="user_id != 2445">
                <span *ngIf="lang == 1">ایجاد کننده</span>
                <span *ngIf="lang == 2">Creator</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px;direction: rtl;"
                [hidden]="user_id != 2445">
                <div class="right" [ngClass]="{'en':dir == 'ltr'}">{{x.creator_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:200px;" [hidden]="user_id != 2445"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="searial">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">سریالها</span>
                <span *ngIf="lang == 2">Serial numbers</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px;direction: rtl;">
                <span
                    (click)="open_serial(i,x.wharehouse_order_id,x.wharehouse_material_title,x.wharehouse_order_consumed_invoice,x.invoice_type)">
                    <i class="fas fa-info-circle icon"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">تاریخ</span>
                <span *ngIf="lang == 2">Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px;direction: rtl;">
                <div [ngClass]="{'en':dir == 'ltr'}">{{x.date}}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="material_id">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">شناسه کالا</span>
                <span *ngIf="lang == 2">Product ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px;direction: rtl;"
                [ngClass]="{'en':dir == 'ltr'}">
                {{x.wharehouse_material_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="material_title">
            <mat-header-cell *matHeaderCellDef style="min-width:200px">
                <span *ngIf="lang == 1">نام کالا</span>
                <span *ngIf="lang == 2">Product name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px">
                <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{x.wharehouse_material_title | substr:0:25 }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:200px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef style="min-width:150px">
                <span *ngIf="lang == 1">گروه</span>
                <span *ngIf="lang == 2">Group</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px">
                <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{x.wharehouse_group_title | substr:0:15 }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="cate">
            <mat-header-cell *matHeaderCellDef style="min-width:150px">
                <span *ngIf="lang == 1">زیر گروه</span>
                <span *ngIf="lang == 2">Subgroup</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px">
                <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{x.wharehouse_material_cate_title |
                    substr:0:15 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="type_title">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span *ngIf="lang == 1">نوع گردش</span>
                <span *ngIf="lang == 2">Type of bank transaction</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">
                {{x.type_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:120px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="order_id">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">شناسه گردش</span>
                <span *ngIf="lang == 2">Transaction ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px" [ngClass]="{'en':dir == 'ltr'}">
                {{x.id2 }}
                <span style="float: right;" (click)="order_ref(x.ref,x.id,x.wharehouse_material_id)">
                    <i class="fas fa-info-circle icon"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width:200px">
                <span *ngIf="lang == 1">نام طرف حساب</span>
                <span *ngIf="lang == 2">Account holder</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px">
                <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{x.user_title | substr:0:25 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:200px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="imported">
            <mat-header-cell *matHeaderCellDef style="max-width:60px">
                <span *ngIf="lang == 1">وارده</span>
                <span *ngIf="lang == 2">Imported</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:60px" [ngClass]="{'en':dir == 'ltr'}">
                {{x.imported }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:60px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="exited">
            <mat-header-cell *matHeaderCellDef style="max-width:60px">
                <span *ngIf="lang == 1">صادره</span>
                <span *ngIf="lang == 2">Exported</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:60px" [ngClass]="{'en':dir == 'ltr'}">
                {{x.exited }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:60px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="consumed">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span *ngIf="lang == 1">مصرف شده</span>
                <span *ngIf="lang == 2">Consumed</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:70px"
                [ngClass]="{red:x.imported < x.wharehouse_order_number_consumed , 'en':dir == 'ltr' }">
                {{x.wharehouse_order_number_consumed }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:70px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef style="max-width:60px" [hidden]="true">
                <span *ngIf="lang == 1">مانده</span>
                <span *ngIf="lang == 2">Remaining</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:60px" [hidden]="true"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.remain }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:60px" [hidden]="true"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">قیمت واحد</span>
                <span *ngIf="lang == 2">Unit price</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px"
                (click)="open_report(x.wharehouse_material_id)" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.price | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sum">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">جمع کل</span>
                <span *ngIf="lang == 2">Sum total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px"
                (click)="open_report(x.wharehouse_material_id)" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.sum | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px" [ngClass]="{'en':dir == 'ltr'}">{{ sum_all |
                number }}</mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>