<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span *ngIf="lang == 1">لیست طرف حساب ها</span>
    <span *ngIf="lang == 2">List of account holders</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent" style="overflow-y:hidden">
    <input class="pe1" [ngClass]="{en1:lang == 2}" style="width:100%" (keyup)="get_user(2)" name="title_search"
        [(ngModel)]="title_search" autocomplete="off" placeholder="{{ messageService.get_search(lang) }}">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;max-height: 25rem;'>
        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style="max-width:40px">
                    #
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width:40px" [ngClass]="{'en':dir == 'ltr'}">{{
                    i+1 }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">نام مشتری</span>
                    <span *ngIf="lang == 2">Client name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.user_id,x.user_title)" class="cursor"
                    style="text-align: center;">
                    <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">{{ x.user_title | substr:0:20
                        }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cellphone">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره همراه</span>
                    <span *ngIf="lang == 2">Cellphone number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.user_id,x.user_title)" class="cursor"
                    style="text-align: center;">
                    <div *ngIf="show_cellphone == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_cellPhone }}</div>
                    <div *ngIf="show_cellphone == false" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.user_cellPhone | hide_cellphone
                        }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره ثابت</span>
                    <span *ngIf="lang == 2">Landline number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" (click)="select(x.user_id,x.user_title)" class="cursor"
                    style="text-align: center;">
                    <div *ngIf="show_cellphone == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_phone }}</div>
                    <div *ngIf="show_cellphone == false" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                        x.user_phone | hide_cellphone
                        }}</div>
                </mat-cell>
            </ng-container>

      
            <ng-container matColumnDef="balance">
              

                    <mat-header-cell *matHeaderCellDef [hidden]="!show_ramin_column">
                        <span *ngIf="lang == 1">مانده حساب</span>
                        <span  *ngIf="lang == 2">Account balance</span>
                    </mat-header-cell>
                    <mat-cell [hidden]="!show_ramin_column" *matCellDef="let x" (click)="select(x.user_id,x.user_title)" class="cursor"
                        style="text-align: center;direction: ltr" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.balance | number }}</mat-cell>
               
         
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let x;let i = index;columns:displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>