import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';
import { ChequePayDetaileComponent } from "../cheque-pay-detaile/cheque-pay-detaile.component";
import { ChequeRecieveDetaileComponent } from "../cheque-recieve-detaile/cheque-recieve-detaile.component";
import { ChequeFilterComponent } from '../cheque-filter/cheque-filter.component';
import { FinancialDetaileComponent } from '../../financial/financial-detaile/financial-detaile.component';

@Component({
  selector: 'app-cheque-list',
  templateUrl: './cheque-list.component.html',
  styleUrls: ['./cheque-list.component.scss']
})
export class ChequeListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | any;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type_search: number | undefined;
  public search_all: string | undefined;
  public sum: number | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public financial2_id: number;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public asc_desc: string = 'asc';
  public order: string;
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'type', 'title', 'user', 'bank', 'account', 'number', 'due', 'price', 'status', 'info'];
  //*************
  public list_type: any = [];
  public type: number = 0;
  public cheque_status: number = 0;
  public creator: number | undefined;
  public video: string | undefined;
  public path_export: string | undefined;
  //************** */
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public dialogRef: MatDialog) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
        this.cheque_status = params['status'];
        this.search_array = [];
        this.check_access(4, 1, 0, 1, 1);
      }
    )
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit
  load_data1(type: number) {
    this.type = type;
    this.search_all = '';
    this.search_array = [];
    this.check_access(4, 1, 0, 1, 1);
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }
  sort(sort: string, asc_desc: string) {
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.load_data(1, 1, 1, -1);
  }

  reset_sort(sort: string, asc_desc: string) {
    this.order = sort + " " + asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6783
      , "page": this.page
      , 'type': this.type
      , 'lang': this.lang
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'cheque_status': this.cheque_status
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'order': this.order
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sum = res['sum'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_cheque_status(this.type);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //***************************************************************************
  filter() {
    const dialogRef = this.dialog.open(ChequeFilterComponent, {
      width: '32rem',
      height: 'auto',
      data: { type_task: 3, search_array: this.search_array, code: this.code, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.search_array = [];
          this.search_array = res;
          this.check_access(4, 1, 0, 1, 1);
        }
      }
    )
  }
  open_detaile(i: number, type_task: number, id: number, cheque_type: number, myself: boolean) {
    if (cheque_type == 2 && myself == false) {
      var message = " قادر به ویرایش رکوردهای پرداختی غیر خودتان نیستید";
      var message2 = "You are not able to edit payment records other than your own";
      this.message(true, this.messageService.message(this.lang, message, message2), 1, this.messageService.close(this.lang));
      return false;
    }
    var dialog: any = ChequePayDetaileComponent;
    if (cheque_type == 1) {
      dialog = ChequeRecieveDetaileComponent;
    }
    const dialogRef = this.dialog.open(dialog, {
      width: '32rem',
      height: 'auto',
      data: {
        type_task: type_task
        , id: id, i: i
        , search_array: this.search_array
        , code: this.code
        , title: this.title
        , type: this.type
        , creator: this.creator
        , ref: 'cheque'
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].site_cheque_type_title = res.result.site_cheque_type_title;
            this.list_record[i].finance_financial2_cheque_title = res.result.finance_financial2_cheque_title;
            this.list_record[i].site_bank_title = res.result.site_bank_title;
            this.list_record[i].site_bank_accounts_number = res.result.accounts_number;
            this.list_record[i].finance_financial2_cheque_number = res.result.finance_financial2_cheque_number;
            this.list_record[i].due_date = res.result.due_date;
            this.list_record[i].finance_financial2_amount = res.result.finance_financial2_amount;
            this.list_record[i].site_cheque_status_title = res.result.site_cheque_status_title;
            this.list_record[i].site_cheque_status_color = res.result.site_cheque_status_color;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }

  //********************************************************************************* */
  get_id(id: number) {
    this.financial2_id = id;
  }
  open_detaile_spend() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6806, id: this.financial2_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.dialog.open(FinancialDetaileComponent, {
            width: '65rem',
            height: 'auto',
            disableClose: true,
            data: { type_task: 2, i: 1, id: res['result'][0].finance_financial2_code, readonly: true, code: this.code }
          });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  go_to_financial() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6806, id: this.financial2_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          window.open('home/financial/192?id=' + res['result'][0].finance_financial2_code)
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

