
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserServiceDetaileComponent } from "../user-service-detaile/user-service-detaile.component";

@Component({
  selector: 'app-user-service-list',
  templateUrl: './user-service-list.component.html',
  styleUrls: ['./user-service-list.component.scss']
})
export class UserServiceListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  public title0: string;
  public title1: string;
  public title2: string;
  public title3: string;
  public title4: string;
  public title5: string;
  public title6: string;
  public title7: string;
  public title8: string;
  public title9: string;
  public title10: string;
  public title11: string;
  public title12: string;
  public title13: string;
  public title14: string;
  public title15: string;
  public title16: string;
  public title17: string;
  public title18: string;
  public title19: string;
  public title20: string;
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  public search_array: any = [];
  public video: string | undefined;
  //************
  selected_record: any = [];
  select_all: boolean | undefined;
  indeterminate: boolean = false;
  checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'title', 'sgroup', 'sort', 'status', 'id', 'operation'];
  //************** */
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , private dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    this.get_header();
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.title = res['result'][0].title;
            this.video = res['result'][0].video;
            this.loading = false;
            if (type_task == 3) {
              var selected;
              if (typeof id == 'object') {
                selected = this.get_select_records(-1);
              } else {
                var selected = id;
              }
              this.delete(selected);
            }
            if (type_task == 4) { this.load_data(page, excel, loading, -1); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6197, "page": this.page, 'type': this.type
      , 'excel': excel
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , changedRows: changedRows
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.show_excel = true;
            this.export_code = res['export_code'];
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(UserServiceDetaileComponent, {
      width: '70rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, ref: 'services' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[res.i].site_services_title = res.result.site_services_title;
            this.list_record[res.i].site_software_title = res.result.site_software_title;
            this.list_record[res.i].site_services_sort = res.result.site_services_sort;
            this.list_record[res.i].status_title = res.result.status_title;
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }

  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6021; } else { address = 6200 }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.site_services_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.site_services_id == res['result_record'][i].site_services_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            //this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  get_select(event: boolean): any {
    this.selected_record = [];
    this.get_select_all(event).then((res: any) => {
      if (res == this.list_record.length - 1) this.get_select_records(-1);
    }).catch((err: any) => { console.log(err); });
  }

  get_select_all(event: boolean): any {
    var finish;
    var promise = new Promise<void>((resolve, reject) => {
      this.indeterminate = false;
      this.select_all = event;
      for (var i = 0; i < this.list_record.length; i++) {
        this.list_record[i].checked = this.select_all;
        finish = i;
      }
      this.dataSource.data = this.list_record;
      return resolve()
    });
    return Promise.resolve(finish);
  }

  get_select_records(index: any) {
    if (index >= 0) {
      this.list_record[index].checked = !this.list_record[index].checked;
    }
    this.selected_record = [];
    var selected_record_temp;
    selected_record_temp = <any>document.getElementsByName('selete_records[]');
    for (var i = 0; i < selected_record_temp.length; i++) {
      if (selected_record_temp[i].checked == true) {
        this.selected_record.push(selected_record_temp[i].value);
      }
    }
    this.selected_record = this.selected_record.map((items: any) => {
      return parseInt(items);
    });
    if (this.selected_record.length != this.list_record.length && this.selected_record.length > 0) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
    }
    return this.selected_record;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  set_title(header: string, event: any) {
    var obj = {
      address: 6546,
      user_id: this.user_id,
      code: this.code,
      header: header,
      title: event.target.value,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_header() {
    var obj = {
      address: 6547,
      code: this.code,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.title0 = res['result'][0].site_header_title0;
          this.title1 = res['result'][0].site_header_title1;
          this.title2 = res['result'][0].site_header_title2;
          this.title3 = res['result'][0].site_header_title3;
          this.title4 = res['result'][0].site_header_title4;
          this.title5 = res['result'][0].site_header_title5;
          this.title6 = res['result'][0].site_header_title6;
          this.title7 = res['result'][0].site_header_title7;
          this.title8 = res['result'][0].site_header_title8;
          this.title9 = res['result'][0].site_header_title9;
          this.title10 = res['result'][0].site_header_title10;
          this.title11 = res['result'][0].site_header_title11;
          this.title12 = res['result'][0].site_header_title12;
          this.title13 = res['result'][0].site_header_title13;
          this.title14 = res['result'][0].site_header_title14;
          this.title15 = res['result'][0].site_header_title15;
          this.title16 = res['result'][0].site_header_title16;
          this.title17 = res['result'][0].site_header_title17;
          this.title18 = res['result'][0].site_header_title18;
          this.title19 = res['result'][0].site_header_title19;
          this.title20 = res['result'][0].site_header_title20;
        }//end if
        else {
        }
      }
    )
  }
  //*******************************************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

