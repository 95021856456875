<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="code == 212">
                        <span *ngIf="lang == 1">فروشنده</span>
                        <span *ngIf="lang == 2">Salesman</span>
                    </span>
                    <span *ngIf="code == 235">
                        <span *ngIf="lang == 1">تعمیرکار</span>
                        <span *ngIf="lang == 2">Repairman</span>
                    </span>
                    <span (click)="reset_input(1,161)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="seller_title" (click)="load_services(1,161)" readonly>
                    <input class="wh-input" formControlName="seller" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> نام طرف حساب </span>
                    <span *ngIf="lang == 2"> Account holder </span>
                    <span (click)="reset_input(2,161)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(2,161)" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="customer" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1">ورودی </span>
                    <span *ngIf="lang == 2">Document source</span>
                    <select class="wh-input" formControlName="place" [ngClass]="{'en':dir == 'ltr'}">
                        <option></option>
                        <option [value]="'invoice'">
                            <span *ngIf="lang == 1">فاکتور فروش</span>
                            <span *ngIf="lang == 2">Sales Invoice</span>
                        </option>
                        <option [value]="'reception'">
                            <span *ngIf="lang == 1">تعمیرگاه</span>
                            <span *ngIf="lang == 2">Repair shop</span>
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="customer" class='wh-label'>
                    <span *ngIf="code == 212">
                        <span *ngIf="lang == 1"> تاریخ فروش از </span>
                        <span *ngIf="lang == 2"> Sell date from</span>
                    </span>
                    <span *ngIf="code == 235">
                        <span *ngIf="lang == 1"> تاریخ خدمات از </span>
                        <span *ngIf="lang == 2"> Exit date from</span>
                    </span>
                    <input class="wh-input" matInput [matDatepicker]="picker" formControlName="date1" autocomplete="off"
                        [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}" [ngClass]="{'en':dir == 'ltr'}">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                        [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="customer" class='wh-label'>
                    <span *ngIf="code == 212">
                        <span *ngIf="lang == 1"> تاریخ فروش تا </span>
                        <span *ngIf="lang == 2"> Sell date to </span>
                    </span>
                    <span *ngIf="code == 235">
                        <span *ngIf="lang == 1">تاریخ خدمات تا </span>
                        <span *ngIf="lang == 2"> Exit date to </span>
                    </span>
                    <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="date2"
                        autocomplete="off" [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}"
                        [ngClass]="{'en':dir == 'ltr'}">
                    <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker"
                        [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>