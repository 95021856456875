<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_search()'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll_bg pe1'
        [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:100px">
                <span *ngIf="lang == 1">تاریخ سند</span>
                <span *ngIf="lang == 2">Document date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:100px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.finance_financial2_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'></mat-footer-cell>
        </ng-container>


        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:480px">
                <span *ngIf="lang == 1">شرح</span>
                <span *ngIf="lang == 2">Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:480px">
                <div class="right" [ngClass]="{'left':dir == 'ltr'}">
                    <span [ngClass]="{'fleft':dir == 'ltr'}">
                        {{ x.alias }}
                        <span> {{ x.finance_payment_type_title }} </span>
                        <span *ngIf="lang == 1">

                            <span *ngIf="x.finance_financial2_ref == 3">
                                بابت پذیرش
                            </span>
                            <span *ngIf="x.finance_financial2_ref == 2">
                                بابت فاکتور {{ x.type_title }}
                            </span>
                            <span *ngIf="x.finance_financial2_ref == 1 || x.finance_financial2_ref == 4">
                                براساس سند شماره
                            </span>
                        </span>
                        <span *ngIf="lang == 2">Based on document no</span>
                        <span *ngIf="x.finance_financial2_ref == 1 || x.finance_financial2_ref == 4"
                            [ngClass]="{'en':dir == 'ltr'}"> {{
                            x.id }}</span>
                        <span *ngIf="x.finance_financial2_ref == 3" [ngClass]="{'en':dir == 'ltr'}"> {{
                            x.id2 }}</span>
                        <span *ngIf="x.finance_financial2_ref == 2" [ngClass]="{'en':dir == 'ltr'}"> {{
                            x.wharehouse_invoice_number }}</span>
                    </span>

                    <div class="invoice_type_title" [ngClass]="{'fright':dir == 'ltr' , 'fleft':dir == 'rtl' }">
                        <span (click)="order_ref(x.id,x.finance_financial2_ref)">
                            <i class="fas fa-info-circle icon"></i>
                        </span>
                        {{ x.type_title }}
                    </div>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:480px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 300px">
                <span *ngIf="lang == 1">نام طرف حساب</span>
                <span *ngIf="lang == 2">Account holder name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 300px"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                {{
                x.user_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="direction: rtl;min-width: 300px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">بدهکار</span>
                <span *ngIf="lang == 2">Debtor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
                    <span class="amount">{{x.bed | number }}</span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
                    <span class="amount">{{ bed | number }}</span>
                </div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">بستانکار</span>
                <span *ngIf="lang == 2">Creditor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="right fright" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                    <span class="amount">{{x.best | number }}</span>
                </div>
                <div class="left" [ngClass]="{'fright':dir == 'ltr'}" [matMenuTriggerFor]="menu"
                    (click)="check_material(x.finance_financial2_order_id,x.wharehouse_order_material_type)"
                    *ngIf="x.finance_financial2_type_ref2 > 0"><i class="fas fa-info-circle icon"></i></div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="row">
                    <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
                        <span class="amount">{{ best | number }}</span>
                    </div>
                </div>
            </mat-footer-cell>
        </ng-container>

        <mat-menu #menu="matMenu" class="cardex-mat-menu-panel" xPosition="before">
            <div class="div_loading">
                <div class="my-container" [hidden]="!loading2">
                    <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                </div>
            </div>
            <table class="table table-bordered" (click)="$event.stopPropagation()"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}">
                        <span *ngIf="lang == 1">
                            <span *ngIf="amount >= 0">مبلغ سود این رکورد</span>
                            <span *ngIf="amount < 0" class="alarm">مبلغ زیان این رکورد</span>
                        </span>
                        <span *ngIf="lang == 2">
                            <span *ngIf="amount >= 0">The profit amount of this record</span>
                            <span *ngIf="amount < 0" class="alarm">The loss amount of this record</span>
                        </span>
                    </td>
                    <td [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}"> <span class="amount"
                            [ngClass]="{alarm:amount < 0}">{{ amount | number
                            }}</span> </td>
                </tr>
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}">
                        <span *ngIf="lang == 1"> تخفیف </span>
                        <span *ngIf="lang == 2"> Discount </span>
                    </td>
                    <td [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}"> <span class="amount">{{ discount | number
                            }}</span> </td>
                </tr>
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}">
                        <span *ngIf="lang == 1"> درصد محاسبه شده </span>
                        <span *ngIf="lang == 2"> Calculated commission </span>
                    </td>
                    <td [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}"> {{ profit | number }} </td>
                </tr>
            </table>
        </mat-menu>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef [hidden]="code == 203">
                <span *ngIf="lang == 1">مانده</span>
                <span *ngIf="lang == 2">Balance</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="code == 203"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="money" [ngClass]="{'en':dir == 'ltr'}">{{ x.remain2 | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div [ngClass]="{'en':dir == 'ltr'}">{{ remain | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">توضیحات</span>
                <span *ngIf="lang == 2">Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ x.comment2 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div [ngClass]="{'en':dir == 'ltr'}"></div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>