<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle" cdkDragHandle style="background-color: transparent;box-shadow:none">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
</div>
<div mat-dialog-content class="matDialogContent padding0">
    <div class="row">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text expire"
                [ngClass]="{'left':dir == 'ltr'}">
                <span *ngIf="lang == 1"> اکانت شما در تاریخ {{ expire_date }} به پایان رسیده است </span>
                <span *ngIf="lang == 2" [ngClass]="{'en':dir == 'ltr'}"> Your account has expired on date
                    <span [ngClass]="{'en':dir == 'ltr'}">{{ expire_date }}</span>
                </span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text"
                [ngClass]="{'left':dir == 'ltr'}">
                <span *ngIf="lang == 1">نسخه فعال شما در حال حاضر
                    <span class="account_text2">{{ account_title }} </span>
                    می باشد.
                </span>
                <span *ngIf="lang == 2">Your active version is currently the <span class="account_text2">{{
                        account_title
                        }}</span> version
                </span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text account_text3"
                [ngClass]="{'left':dir == 'ltr'}">
                <span *ngIf="lang == 1">هزینه تمدید هر ماه </span>
                <span *ngIf="lang == 2"> Monthly renewal fee</span>
                <strong class="account_text2"> {{ user_amount / 10 | number }} <span> {{ currency_title }}
                    </span></strong>
            </div>
            <form #f2="ngForm">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <span *ngIf="lang == 1">تعداد ماه تمدید</span>
                        <span *ngIf="lang == 2">Duration of renewal months</span>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text account_text3"
                        [ngClass]="{'left':dir == 'ltr'}">
                        <input type="number" class="wh-input input1 center" name="account_month"
                            style="width:15rem !important" [(ngModel)]="account_month" required
                            (change)="change_amount()" (keyup)="change_amount()" max="{{ month_extension }}"
                            [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">
                    </div>
                </div>
                <div class="row" style="margin-top:0.7rem">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <span *ngIf="lang == 1">مبلغ قابل پرداخت</span>
                        <span *ngIf="lang == 2">Payable amount</span>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text user_amount center"
                        [ngClass]="{'left':dir == 'ltr'}">
                        <span class="account_text2" [ngClass]="{'en':dir == 'ltr'}">
                            {{ amount_final | number }} {{ currency_title }}
                        </span>
                    </div>

                </div>
                <div class="row" style="margin-top:0.7rem">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text center">
                        <button type="button" class="btn_pe" (click)="pay()"
                            [disabled]="!f2.valid || account_month < 1">
                            <span *ngIf="lang == 1">پرداخت آنلاین</span>
                            <span *ngIf="lang == 2">Online payment</span>
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 account_text">
                        <button type="button" class="btn_pe" (click)="get_wallet(2)"
                            [disabled]="!f2.valid || account_month < 1" *ngIf="network_access == true"
                            [hidden]="true">پرداخت کیف
                            پول</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>