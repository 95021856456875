import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepairmanShelfLogComponent } from '../../repairman-shelf-log/repairman-shelf-log.component';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';


@Component({
  selector: 'app-shelf-list',
  templateUrl: './shelf-list.component.html',
  styleUrls: ['./shelf-list.component.scss']
})
export class ShelfListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public user_id: number | undefined;
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public full: number | undefined;
  public show_excel: boolean = false;
  public export_code: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  @ViewChild('myModal', { static: true }) myModal: any;
  @ViewChild('myModalSearch', { static: true }) myModalSearch: any;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_all: string | undefined;
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;

  public creator: number | undefined;
  public video: string | undefined;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'title', 'sort', 'status', 'customer', 'device', 'brand', 'model', 'reception', 'id', 'log', 'operation'];
  //*************
  public form1_group: FormGroup | any;
  public form1_search_group: FormGroup | any;
  //************** */

  current_user_level: number = 0;
  current_user_group_title: string = "";
  current_user_is_repairman: boolean | false;
  user_can_see_own_panel_only: boolean = false;
  //************** */

  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.check_user_is_repairman_and_check_access_his_shelfs();

    this.create_form1();
    this.create_search_form1();
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(null, [Validators.pattern('[0-9]{1,}'), Validators.max(200), Validators.maxLength(3)]),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')])
    });
  }

  create_search_form1() {
    this.form1_search_group = new FormGroup({
      'title_search': new FormControl(null),
      'status_id_search': new FormControl(null)
    })
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.check_update(); }
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) {
            this.get_reception_with_full_shelf();
            this.load_data(page, excel, loading, -1);
            this.recieve_status();

          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
    }
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 100); }
    if (page == 3) { this.page = this.page - 1; }
    var obj = {
      'address': 1779
      , "page": this.page
      , 'type': this.type
      , 'export_excel': excel, user_id: this.user_id
      , 'title': this.form1_search_group.value.title_search
      , 'status_id': this.form1_search_group.value.status_id_search
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'lang': this.lang
    }

    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {

                if (this.current_user_is_repairman == true) {
                  if (res['result'][i].repairmanid == this.user_id) {
                    this.list_record.push(res['result'][i]);
                  }
                }
                else {
                  this.list_record.push(res['result'][i]);
                }
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.myModal.close();
            this.myModalSearch.close();
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.serverService.change_window_resize(2, '', 0);
          this.serverService.send_services_id(this.code);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  open_log(id: number) {
    this.dialog.open(RepairmanShelfLogComponent, {
      width: '75rem',
      height: 'auto',
      data: { id: id, type: 'shelf' }
    })
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  check_user_is_repairman_and_check_access_his_shelfs() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1660, id: this.user_id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {

          this.current_user_level = res['result'][0].user_level;

          this.current_user_group_title = res['result'][0].user_groups_title;

          this.user_can_see_own_panel_only = res['result'][0].user_can_only_see_his_own_list;

          if ((this.current_user_level == 3) && (this.current_user_group_title == "تعمیرکاران")) {
            if (this.user_can_see_own_panel_only == true) {
              this.current_user_is_repairman = true;
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }



  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 100 - 99;
    this.page_number_second = this.page * 100;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 100) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  //***************************************************************************
  change_task(type: number, id: number, title: string, status_id: number, sort: string, shelf: number): any {
    if (shelf > 0) {
      var pe_message = "قفسه های پر قادر به ویرایش نیستند";
      var en_message = "Full shelves are not editable";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));

      return false;
    }
    if (type == 1 && this.expire == true) {
      this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }
    this.type_task = type;
    this.show_excel = false;
    this.id = id;
    if (type == 1) {
      this.form1_group.patchValue({
        'title': "",
        'sort': "",
        'number': "",
        'status_id': '',
      })
      this.type = 1;
      this.myModal.open();
    }//end if
    if (type == 2) {
      this.form1_group.patchValue({
        'title': title,
        'sort': sort,
        'status_id': status_id
      })
      this.myModal.open();
    }//end else
    if (type == 3) {
      this.type = 3;
      this.myModalSearch.open();
    }
  }//end change_task

  get_bg(id: number) {
    this.id = id;
  }
  //***************************************************************************
  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var address: number = 0;
    if (this.form1_group.value.number > 1) {
      address = 1788;
    } else {
      address = 1788;
    }
    var obj = {
      'address': address
      , "user_id": this.user_id
      , 'status_id': this.form1_group.value.status_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'number': this.form1_group.value.number
      , 'creator': this.creator
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (address == 1780 && res['num'] == 1) {
            this.list_record.unshift(res['result'][0]);
            this.pages(1);
            if (this.count_total > 100) {
              this.list_record.pop();
              this.next_status = false;
            }//end if
          }//1980
          else {
            for (var i = 0; i < res['num']; i++) {
              this.list_record.unshift(res['result'][i]);
            }
            var x: number = 0;
            if (res['num'] > this.count_total) { x = res['num'] - this.count_total; }
            else { x = this.count_total - res['num']; }
            this.pages(res['num']);

          }
          this.dataSource.data = this.list_record;
          this.myModal.close();
          this.id = res['result'][0].repairman_shelf_id;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  //********************************************************************************
  check_update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6257, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] > 0) {
            var pe_message = "قفسه های پر قادر به ویرایش نیستند";
            var en_message = "Full shelves are not editable";
            this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));

            return;
          }//end for
          else {
            this.update();
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1781
      , "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'status_id': this.form1_group.value.status_id
      , 'creator': this.creator
      , 'id': this.id
      , 'lang': this.lang
    }

    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < this.list_record.length; i++) {
            if (this.list_record[i].repairman_shelf_id == this.id) {
              this.list_record[i].repairman_shelf_title = res['result'][0].repairman_shelf_title;
              this.list_record[i].repairman_shelf_sort = res['result'][0].repairman_shelf_sort;
              this.list_record[i].repairman_shelf_shstatus = res['result'][0].repairman_shelf_shstatus;
              this.list_record[i].repairman_shelf_status_title = res['result'][0].repairman_shelf_status_title;
              this.list_record[i].repairman_shelf_status_color = res['result'][0].repairman_shelf_status_color;
              this.list_record[i].repairman_shelf_status_full = res['result'][0].repairman_shelf_status_full;
            }//end if
          }//end for
          this.dataSource.data = this.list_record;
          this.myModal.close();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره قفسه باید منحصر به فرد باشد";
          var en_message = "The shelf number must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  //********************************************************************************
  delete(id: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 1783; } else { address = 1782; }
      var obj = {
        'address': address, "user_id": this.user_id, 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_shelf_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_shelf_id == res['result_record'][i].repairman_shelf_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true      
  }//end delete 
  //*******************************************************************************
  recieve_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1784, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        this.list_status = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_status.push(res['result'][i]);
          }//end for
          this.message(false, "", 2, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_status
  //*******************************************************************************
  change_resize() {
    this.serverService.change_window_resize(1, '', 0);
  }//end change_resize
  //*******************************************************************************
  get_reception_with_full_shelf() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6620, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.empty_shelf_and_reception(res['result'][i].repairman_reception_id, res['result'][i].repairman_reception_shelf);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  empty_shelf_and_reception(id: number, shelf: number) {
    var obj = { address: 6621, shelf: shelf, creator: this.creator, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************

  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  ModalClose() {
    this.message(false, "", 2, this.messageService.close(this.lang));
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
