<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span *ngIf="type_task == 2 && i == -1"> {{ serverService.get_title(1,lang) }} </span>
    <span *ngIf="type_task == 2 && i >= 0"> {{ serverService.get_title(2,lang) }} </span>
    <span *ngIf="type_task == 3"> <span>{{ serverService.get_title(type_task,lang) }}</span></span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="user" class='wh-label'>
                    <span (click)="add_user()" [hidden]="readonly || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span *ngIf="lang == 1"> نام طرف حساب </span>
                    <span *ngIf="lang == 2"> Account holder </span>
                    <span (click)="reset_input(161)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="open_user()" readonly
                        [required]="required()" [readonly]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="user" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="invoice_type" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع فاکتور </span>
                    <span *ngIf="lang == 2"> Invoice type </span>
                    <span (click)="reset_input(189)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="invoice_type_title" (click)="load_services(189)"
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="invoice_type" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="invoice_number" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره فاکتور </span>
                    <span *ngIf="lang == 2"> Invoice no </span>
                    <input class='wh-input' formControlName="invoice_number" autocomplete="off"
                        [attr.disabled]="type_task != 3 ? true : null" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>



            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات فاکتور </span>
                    <span *ngIf="lang == 2"> Invoice description </span>
                    <input class='wh-input' formControlName="comment" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="invoice_date" class="wh-label">
                    <span *ngIf="lang == 1"> تاریخ صدور /دریافت </span>
                    <span *ngIf="lang == 2"> Date of issuance/ reception </span>
                    <div style="width:100%">
                        <input class="wh-input" matInput [matDatepicker]="picker" formControlName="invoice_date"
                            [required]="required()" autocomplete="off" [readonly]="readonly"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"
                            [hidden]="readonly == true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </label>
            </div>
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}" *ngIf="type_task == 3">
                <label for="invoice_Expire_date" class="wh-label">
                    <span *ngIf="lang == 1">تا تاریخ </span>
                    <span *ngIf="lang == 2"> Date of issuance/ reception </span>
                    <div style="width:100%">
                        <input class="wh-input" matInput [matDatepicker]="picker" formControlName="invoice_Expire_date"
                            [required]="required()" autocomplete="off" [readonly]="readonly"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"
                            [hidden]="readonly == true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </label>
            </div>
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}">
                <label for="invoice_settlement_date" class="wh-label" [hidden]="invoice_type_id == 5">
                    <span *ngIf="lang == 1"> تاریخ تسویه </span>
                    <span *ngIf="lang == 2"> Settlement date </span>
                    <div style="width:100%">
                        <input class="wh-input" matInput [matDatepicker]="picker2"
                            formControlName="invoice_settlement_date" autocomplete="off" [readonly]="readonly"
                            [hidden]="invoice_type_id == 5" [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"
                            [hidden]="readonly == true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}" [hidden]="true">
                <label for="currency" class='wh-label'>
                    <span *ngIf="lang == 1"> واحد پولی </span>
                    <span *ngIf="lang == 2"> Currency </span>
                    <span (click)="reset_input(156)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="currency_title" (click)="load_services(156) " readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="currency" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding,width100:type_task == 3'
                [ngClass]="{'full-left':dir=='ltr',width100:type_task == 3}" [hidden]="true">
                <label for="rate" class='wh-label'>
                    <span *ngIf="lang == 1"> نرخ تبدیل </span>
                    <span *ngIf="lang == 2"> Exchange rate </span>
                    <input class='wh-input' formControlName="rate" autocomplete="off" currencyMask [readonly]="readonly"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

        </div>
        <!-- ********************************************************************************************************* -->
        <div class="row" [hidden]="type_task == 3">
            <div style="text-align:right;padding:1rem 0">
                <button type="button" (click)='add_to_invoice()' class="invoice_button2" [hidden]="readonly">
                    <span *ngIf="lang == 1">
                        <span>درج قطعه</span>
                        <span *ngIf="document_type_text == 'exit'"> و خدمات</span>
                    </span>
                    <span *ngIf="lang == 2">
                        <span>insert part</span>
                        <span *ngIf="document_type_text == 'exit'"> and service </span>
                    </span>
                </button>
            </div>
            <div class="content-body1" class="content-body control-content-body"
                [ngClass]="{content_body_en:dir=='ltr'}">
                <mat-table [dataSource]="dataSource" style='min-width:100%'
                    class='table-scroll mat_table_fa_without_scroll pe1'
                    [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

                    <ng-container matColumnDef="row">
                        <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                            <span>#</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'
                            [ngClass]="{'en':dir == 'ltr'}">{{ i + 1 }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="material_type">
                        <mat-header-cell *matHeaderCellDef style="max-width: 70px;">
                            <span *ngIf="lang == 1">نوع</span>
                            <span *ngIf="lang == 2">Type</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 70px;">
                            <div [ngClass]="{'en':dir == 'ltr'}">{{ x.wharehouse_material_type_title }}</div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="material">
                        <mat-header-cell *matHeaderCellDef style="min-width: 200px">
                            <span *ngIf="lang == 1">نام کالا
                                <span *ngIf="document_type_text == 'exit' "> و خدمات</span>
                            </span>
                            <span *ngIf="lang == 2"> Product
                                <span *ngIf="document_type_text == 'exit' "> and service name </span>
                            </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;min-width: 200px;"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                            <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">
                                {{ x.title }}
                                <span *ngIf="x.wharehouse_order_material_type == 1">| {{
                                    x.wharehouse_material_cate_title }}</span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="number">
                        <mat-header-cell *matHeaderCellDef style="max-width: 95px;">
                            <span *ngIf="lang == 1">تعداد</span>
                            <span *ngIf="lang == 2">Number</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 95px;" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.wharehouse_order_number }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="weight">
                        <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                            <span *ngIf="lang == 1">واحد شمارش</span>
                            <span *ngIf="lang == 2">Counting unit</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width:100px" [hidden]="true">
                            {{ x.wharehouse_weight_title}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <mat-header-cell *matHeaderCellDef style="max-width: 95px;">
                            <span *ngIf="lang == 1">قیمت واحد</span>
                            <span *ngIf="lang == 2">Unit price</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 95px;" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.wharehouse_order_cost | number }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                        <mat-header-cell *matHeaderCellDef style="max-width: 95px;">
                            <span *ngIf="lang == 1">تخفیف</span>
                            <span *ngIf="lang == 2">Discount</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 95px;" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.wharehouse_order_discount | number}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sum">
                        <mat-header-cell *matHeaderCellDef style="max-width: 90px;">
                            <span *ngIf="lang == 1">جمع</span>
                            <span *ngIf="lang == 2">Total</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 90px;" [ngClass]="{'en':dir == 'ltr'}">
                            {{ x.sum | number}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="repairman">
                        <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                            [hidden]="document_type_text == 'input' || back == 1 ">
                            <span *ngIf="lang == 1">فروشنده</span>
                            <span *ngIf="lang == 2">Salesman</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            style="max-width: 150px;" [hidden]="document_type_text == 'input' || back == 1 ">
                            <div>{{ x.repairman_title }}</div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="searial">
                        <mat-header-cell *matHeaderCellDef style="max-width: 70px;" [hidden]="back == 1">
                            <span *ngIf="lang == 1">سریالها</span>
                            <span *ngIf="lang == 2">Serial numbers</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_serial(i,x.wharehouse_order_id,x.title,x.wharehouse_order_consumed_invoice)"
                            style="max-width: 70px;" [hidden]="back == 1 " [ngClass]="{bg2:x.searial == true}">
                            <span *ngIf="x.wharehouse_order_material_type == 1">
                                <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly">
                            <span *ngIf="lang == 1">عملیات</span>
                            <span *ngIf="lang == 2">Operation</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly">
                            <span
                                (click)='check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)'><i
                                    class="fas fa-desktop icon"></i></span>
                            <!-- x.wharehouse_order_material,x.wharehouse_order_status_add -->
                            <span
                                (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_material,x.wharehouse_order_trash,x.wharehouse_order_material_type,x.wharehouse_order_creator)"><i
                                    class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                    </mat-header-row>
                    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                        (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                        [ngClass]="{bg:order_id == row.wharehouse_order_id,'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                        class="cursor"></mat-row>

                </mat-table>
            </div>
            <div style="text-align: right;padding-top:1rem;" [ngClass]="{'full-left':dir == 'ltr'}">
                <span *ngIf="lang == 1"> جمع کل به عدد : </span>
                <span *ngIf="lang == 2"> Sum total in numbers: </span>
                <span [ngClass]="{'en':dir == 'ltr'}">{{ sum_all | number }}</span>
                <span> {{ currency_title }} </span>
                <span *ngIf="lang == 1"> به حروف </span>
                <span *ngIf="lang == 1"> {{ sum_all_letter }} </span>
                <span *ngIf="lang == 1"> {{ currency_title }} </span>
            </div>
        </div><!-- row -->
        <!-- ********************************************************************************************************* -->
    </div><!-- content -->

    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">

            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 hidden-xs" [hidden]="readonly || type_task == 3">
                <div class="remain" *ngIf="lang == 1"> مانده حساب به عدد </div>
                <div class="remain" *ngIf="lang == 2"> Account balance in numbers </div>
                <div class="remain">
                    <div style="display: inline-block;direction: ltr;" [ngClass]="{'en':dir == 'ltr'}"> {{ remain |
                        number }} </div>
                </div>
                <div class="remain"> {{ currency_title }} </div>
                <div class="remain" *ngIf="lang == 1"> به حروف </div>
                <div class="remain" *ngIf="lang == 1">{{ remain_letter }}</div>
                <div class="remain" *ngIf="lang == 1"> {{ currency_title }} </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12" [ngClass]="{width100:type_task == 3}"
                style="text-align: right;padding-right:0px">
                <button type="submit" (click)="save('insert_invoice')" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
                    *ngIf="type_task == 1 || type_task == 2" [hidden]="readonly"
                    [disabled]="!form1_group.valid || (list_order.length == 0 && i == -1)">
                    <span *ngIf="lang == 1">ذخیره</span>
                    <span *ngIf="lang == 2">Save</span>
                </button>
                <button type="submit" (click)="save('insert_finance')" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
                    *ngIf=" (type_task == 1 || type_task == 2) && back == 0 && access_insert_finance == true"
                    [hidden]="readonly" [disabled]="!form1_group.valid || (list_order.length == 0 && i == -1)">
                    <span *ngIf="lang == 1">ذخیره و درج امور مالی</span>
                    <span *ngIf="lang == 2">Save and go to financial entry</span>
                </button>
                <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
                    <span>فیلتر</span>
                </button>
            </div>
        </div><!-- row -->
    </div>
</form>