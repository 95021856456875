import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServerService } from '../../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountsDetaileComponent } from "../../bank-accounts/accounts-detaile/accounts-detaile.component";
import { MessageService } from '../../../../service/message/message.service';


@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public no_record: string = this.serverService.no_record();
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription | undefined;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  displayedColumns = ['row', 'title', 'branch_title', 'cart_number', 'account_number', 'default', 'operation'];
  //*************
  public form1_group: FormGroup | any;
  public form1_search_group: FormGroup | any;
  public bank_id: number | undefined;
  public ref: string | undefined;
  public creator: number | undefined;
  //************** */
  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public router: Router
    , public messageService: MessageService
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar, private dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<BankAccountsComponent>
    , public dialogRef: MatDialog) {
    if (dialog_data) {
      this.code = dialog_data.code;
      this.bank_id = dialog_data.id;
      this.ref = dialog_data.ref;
    }
  }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.title = res['result'][0].title;
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 3) {
              var selected;
              if (typeof id == 'object') {
                selected = this.get_select_records(-1);
              } else {
                var selected = id;
              }
              this.delete(selected);
            }
            if (type_task == 4) { this.load_data(page, excel, loading, -1); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 1941, "page": this.page, 'type': this.type, bank_id: this.bank_id
      , 'excel': excel
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , changedRows: changedRows
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            //this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.show_excel = true;
            this.export_code = res['export_code'];
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number, number: number, branch_title: string, branch_code: string) {
    if (type_task != 1 && (this.ref == 'cheque' || this.ref == 'reception')) {
      this.matDialogRef.close({ number: number, id: id, branch_title: branch_title, branch_code: branch_code });
    }
    else {
      const dialogRef = this.dialog.open(AccountsDetaileComponent, {
        width: '30rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: type_task, id: id, bank_id: this.bank_id, i: i, search_array: this.search_array, code: this.code, title: this.title }
      });
      dialogRef.afterClosed().subscribe(
        (res: any) => {
          if (res) {
            if (type_task == 1) {
              this.list_record.unshift(res.result);
              this.pages(1);
              if (this.list_record.length == 21) {
                this.list_record.splice(20, 1);
              }
              if (this.ref == 'reception') {
                this.matDialogRef.close({
                  number: res.result.site_bank_accounts_number
                  , id: res.result.site_bank_accounts_id
                });
              } else {
                this.dataSource.data = this.list_record;
              }
            }
            else if (type_task == 2) {
              this.list_record[i].site_bank_accounts_title = res.result.result.site_bank_accounts_title;
              this.list_record[i].site_bank_accounts_cart = res.result.result.site_bank_accounts_cart;
              this.list_record[i].site_bank_accounts_number = res.result.result.site_bank_accounts_number;
              this.list_record[i].site_bank_accounts_branch_title = res.result.result.site_bank_accounts_branch_title;
              this.list_record[i].site_bank_accounts_branch_code = res.result.result.site_bank_accounts_branch_code;
              this.list_record[i].site_bank_accounts_sheba = res.result.result.site_bank_accounts_sheba;
              this.list_record[i].site_bank_accounts_sort = res.result.result.site_bank_accounts_sort;
              this.list_record[i].status_title = res.result.result.status_title;
            }
            else if (type_task == 3) {
              var excel = res.export_excel;
              this.search_array = [];
              this.search_array = res;
              this.check_access(4, 1, 0, excel, 1);
            }
          }
        }
      )
    }
  }

  get_bg(id: number) {
    this.id = id;

  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 1945; } else { address = 1944; }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.site_bank_accounts_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.site_bank_accounts_id == res['result_record'][i].site_bank_accounts_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.delete_financial2(id);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  delete_financial2(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6352, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در تراکنش مالی کاربر";
          var en_message = "Error in the user's financial transaction";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  change_default(j: number, value: number, id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1966, user_id: this.user_id, 'status_default': value, 'id': id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (value == 0) {
            for (var i = 0; i < this.list_record.length; i++) {
              if (this.list_record[i].site_bank_accounts_default == 1) {
                this.list_record[i].site_bank_accounts_default = 0;
              }
            }
          }
          this.list_record[j].site_bank_accounts_default = res['result'][0].site_bank_accounts_default;
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.erorr_in_save_default(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }

  get_select(event: boolean): any {
    this.selected_record = [];
    this.get_select_all(event).then((res: any) => {
      if (res == this.list_record.length - 1) this.get_select_records(-1);
    }).catch((err: any) => { console.log(err); });
  }

  get_select_all(event: boolean): any {
    var finish;
    var promise = new Promise<void>((resolve, reject) => {
      this.indeterminate = false;
      this.select_all = event;
      for (var i = 0; i < this.list_record.length; i++) {
        this.list_record[i].checked = this.select_all;
        finish = i;
      }
      this.dataSource.data = this.list_record;
      return resolve()
    });
    return Promise.resolve(finish);
  }

  get_select_records(index: any) {
    if (index >= 0) {
      this.list_record[index].checked = !this.list_record[index].checked;
    }
    this.selected_record = [];
    var selected_record_temp;
    selected_record_temp = <any>document.getElementsByName('selete_records[]');
    for (var i = 0; i < selected_record_temp.length; i++) {
      if (selected_record_temp[i].checked == true) {
        this.selected_record.push(selected_record_temp[i].value);
      }
    }
    this.selected_record = this.selected_record.map((items: any) => {
      return parseInt(items);
    });
    if (this.selected_record.length != this.list_record.length && this.selected_record.length > 0) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
    }
    return this.selected_record;
  }

  close() {
    this.matDialogRef.close();
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
