import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './system/general/login/login.component';
import { HomeComponent } from './system/general/menu/home.component';
import { DeviceComponent } from './system/repairman/device/device.component';
import { UserGroupsComponent } from './system/user/user-groups/user-groups.component';
import { UserListComponent } from './system/user/user-list/user-list.component';
import { ModelListComponent } from './system/repairman/model/model-list/model-list.component';
import { RstatusListComponent } from './system/repairman/rstatus/rstatus-list/rstatus-list.component';
import { ReceptionListComponent } from './system/repairman/reception/reception-list/reception-list.component';
import { UserSexComponent } from './system/user/user-sex/user-sex.component';
import { BelongListComponent } from './system/repairman/belong/belong-list/belong-list.component';
import { ObjectionsListComponent } from './system/repairman/objections/objections-list/objections-list.component';
import { RepairmanShelfStatusComponent } from './system/repairman/repairman-shelf-status/repairman-shelf-status.component';
import { ShelfListComponent } from './system/repairman/repairman-shelf/shelf-list/shelf-list.component';
import { PaymentTypeComponent } from './system/finance/payment-type/payment-type.component';
import { BankComponent } from './system/finance/bank/bank-list/bank.component';
import { ChequeStatusComponent } from './system/finance/cheque-status/cheque-status.component';
import { DocumentComponent } from './system/finance/document/document.component';
import { FinancialListComponent } from './system/finance/financial/financial-list/financial-list.component';
import { Financial2ListComponent } from './system/finance/financial2/financial2-list/financial2-list.component';
import { ServicesComponent } from './system/repairman/services/services.component';
import { InvoiceListComponent } from './system/wharehouse/wharehouse-invoice/invoice-list/invoice-list.component';
import { DashboardComponent } from './system/general/dashboard/dashboard.component';
import { WharehouseOrderStatusComponent } from './system/wharehouse/wharehouse-order-status/wharehouse-order-status.component';
import { WharehouseInvoiceTypeComponent } from './system/wharehouse/wharehouse-invoice-type/wharehouse-invoice-type.component';
import { WharehouseCostCenterComponent } from './system/wharehouse/wharehouse-cost-center/wharehouse-cost-center.component';
import { MaterialListComponent } from './system/wharehouse/wharehouse-material/material-list/material-list.component';
import { CurrencyComponent } from './system/finance/currency/currency.component';
import { CardexListComponent } from './system/wharehouse/wharehouse-cardex/cardex-list/cardex-list.component';
import { BillComponent } from './system/finance/bill/bill.component';
import { WharehouseWeightComponent } from './system/wharehouse/wharehouse-weight/wharehouse-weight.component';
import { ProfitListComponent } from './system/finance/profit-list/profit-list.component';
import { UserServiceListComponent } from './system/user/user-service-list/user-service-list.component';
import { FinanceCardexComponent } from './system/finance/finance-cardex/finance-cardex.component';
import { WharehousePlaceComponent } from './system/wharehouse/wharehouse-place/wharehouse-place.component';
import { WharehouseGroupComponent } from './system/wharehouse/wharehouse-group/wharehouse-group.component';
import { Profit2ListComponent } from './system/finance/profit2-list/profit2-list.component';
import { BascketListComponent } from './system/finance/bascket-list/bascket-list.component';
import { IncomeTypeComponent } from './system/finance/income-type/income-type.component';
import { DeviceStatusComponent } from './system/repairman/device-status/device-status.component';
import { MaterialList2Component } from './system/wharehouse/wharehouse-material2/material-list2/material-list2.component';
import { ContentListComponent } from './system/general/content/content-list/content-list.component';
import { CodeListComponent } from './system/service/code-list/code-list.component';
import { UserLogComponent } from './system/user/user-log/user-log.component';
import { UserMyNetworkComponent } from './system/user/user-my-network/user-my-network.component';
import { MyReceiptComponent } from './system/repairman/my-receipt/my-receipt.component';
import { CostTypeGroupComponent } from './system/finance/cost-type-group/cost-type-group.component';
import { FinanceCardexPersonnelComponent } from './system/finance/finance-cardex-personnel/finance-cardex-personnel.component';
import { UserStateComponent } from './system/user/user-state/user-state.component';
import { UserHowComponent } from './system/user/user-how/user-how.component';
import { GuilsListComponent } from './system/user/guils/guils-list/guils-list.component';
import { CountryListComponent } from './system/user/user-country/country-list/country-list.component';
import { BrandListComponent } from './system/repairman/brand/brand-list/brand-list.component';
import { CashListComponent } from './system/finance/cash/cash-list/cash-list.component';
import { FinanceBankListComponent } from './system/finance/finance-bank/finance-bank-list/finance-bank-list.component';
import { UserListAccessComponent } from './system/user/user-list-access/user-list-access.component';
import { NewspaperListComponent } from './system/finance/newspaper/newspaper-list/newspaper-list.component';
import { UserListAccountsComponent } from './system/user/user-list-accounts/user-list-accounts.component';
import { CouponListComponent } from './system/user/coupon/coupon-list/coupon-list.component';
import { FinancialList2Component } from './system/finance/finance-new/financial-list2/financial-list2.component';
import { ChequeListComponent } from './system/finance/cheque/cheque-list/cheque-list.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "receipt/:id/:token", component: MyReceiptComponent },
  {
    path: "home", component: HomeComponent, children: [
      { path: "user-title/:id", component: UserSexComponent },
      { path: "device/:id", component: DeviceComponent },
      { path: "group/:id", component: WharehouseGroupComponent },
      { path: "groups/:id", component: UserGroupsComponent },
      { path: "user/:id", component: UserListComponent },
      { path: "user-account/:id", component: UserListAccountsComponent },
      { path: "brand/:id", component: BrandListComponent },
      { path: "model/:id", component: ModelListComponent },
      { path: "status/:id", component: RstatusListComponent },
      { path: "reception/:id/:status/:repairman/:pos", component: ReceptionListComponent },
      { path: "reception/:id/:status", component: ReceptionListComponent },
      { path: "reception/:id", component: ReceptionListComponent },
      { path: "reception-report/:id", component: ReceptionListComponent },
      { path: "belong/:id", component: BelongListComponent },
      { path: "objections/:id", component: ObjectionsListComponent },
      { path: "shelf-status/:id", component: RepairmanShelfStatusComponent },
      { path: "shelf/:id", component: ShelfListComponent },


      { path: "payment-type/:id", component: PaymentTypeComponent },
      { path: "bank/:id", component: BankComponent },
      { path: "cheque/:id", component: ChequeListComponent },
      { path: "cheque-status/:id", component: ChequeStatusComponent },
      { path: "document/:id", component: DocumentComponent },
      { path: "financial/:id", component: FinancialListComponent },
      { path: "financial/:id/:document", component: Financial2ListComponent },
      { path: "financial2/:id", component: Financial2ListComponent },
      { path: "financial2-bank/:id", component: FinanceBankListComponent },
      { path: "cash/:id", component: CashListComponent },
      { path: "services/:id", component: ServicesComponent },
      { path: "invoice/:id", component: InvoiceListComponent },
      { path: "dashboard/:id", component: DashboardComponent },

      { path: "order-status/:id", component: WharehouseOrderStatusComponent },
      { path: "invoice-type/:id", component: WharehouseInvoiceTypeComponent },
      { path: "cost-center/:id", component: WharehouseCostCenterComponent },
      { path: "material/:id", component: MaterialListComponent },

      { path: "invoice/:id", component: InvoiceListComponent },
      { path: "invoice/:id/:type", component: InvoiceListComponent },
      { path: "currency/:id", component: CurrencyComponent },
      { path: "cardex/:id", component: CardexListComponent },

      { path: "bill/:id", component: BillComponent },
      { path: "weight/:id", component: WharehouseWeightComponent },
      { path: "profit/:id", component: ProfitListComponent },
      { path: "service/:id", component: UserServiceListComponent },
      { path: "access/:id", component: UserListAccessComponent },
      { path: "cost-type/:id", component: CostTypeGroupComponent },
      { path: "finance-cardex/:id", component: FinanceCardexComponent },
      { path: "newspaper/:id", component: NewspaperListComponent },
      { path: "charge/:id", component: BascketListComponent },
      { path: "place/:id", component: WharehousePlaceComponent },
      { path: "profit2/:id", component: Profit2ListComponent },
      { path: "income-type/:id", component: IncomeTypeComponent },
      { path: "device-status/:id", component: DeviceStatusComponent },
      { path: "group2/:id", component: WharehouseGroupComponent },
      { path: "material2/:id", component: MaterialList2Component },
      { path: "material2-all/:id", component: MaterialList2Component },
      { path: "content/:id", component: ContentListComponent },
      { path: "code/:id", component: CodeListComponent },
      { path: "log/:id", component: UserLogComponent },
      { path: "network/:id", component: UserMyNetworkComponent },
      { path: "cardex-personnel/:id", component: FinanceCardexPersonnelComponent },
      { path: "state/:id", component: UserStateComponent },
      { path: "how/:id", component: UserHowComponent },
      { path: "guilds/:id", component: GuilsListComponent },
      { path: "country/:id", component: CountryListComponent },
      { path: "coupon/:id", component: CouponListComponent },
      { path: "finance2/:id", component: FinancialList2Component },
    ]
  },
  { path: "login", component: LoginComponent },
]



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class appRoutingModule {

}