import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { UserSearchComponent } from '../../user/user-search/user-search.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-invoice-of-person',
  templateUrl: './invoice-of-person.component.html',
  styleUrls: ['./invoice-of-person.component.scss']
})
export class InvoiceOfPersonComponent implements OnInit, OnDestroy {
  public user_info: any | undefined = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public isOnline: any | undefined;
  public loading = false;
  public subscription: Subscription | any;
  public form1: FormGroup;
  public obj: any;
  public creator: number | undefined;
  public id: number | undefined;
  public video: string | undefined;
  public code: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<InvoiceOfPersonComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.id = dialog_data.id;
      this.code = dialog_data.id;
    }
    this.create_form();
    if (dialog_data.res_search_finance && this.id == 202) {
      this.form1.patchValue({
        'user_title': dialog_data.res_search_finance.user_title,
        'user': dialog_data.res_search_finance.user,
        'cost_title': dialog_data.res_search_finance.cost_title,
        'cost': dialog_data.res_search_finance.cost,
        'date1': dialog_data.res_search_finance.date1,
        'date2': dialog_data.res_search_finance.date2,
      })
    }

    if (dialog_data.res_search_finance_cardex && this.id == 226) {
      this.form1.patchValue({
        'user_title': dialog_data.res_search_finance_cardex.user_title,
        'user': dialog_data.res_search_finance_cardex.user,
        'cost_title': dialog_data.res_search_finance_cardex.cost_title,
        'cost': dialog_data.res_search_finance_cardex.cost,
        'date1': dialog_data.res_search_finance_cardex.date1,
        'date2': dialog_data.res_search_finance_cardex.date2,
      })
    }

  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access();
  }

  check_access(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.video = res['result'][0].video;
            this.loading = false;
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  create_form() {
    this.form1 = new FormGroup({
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern("[0-9]{1,}")]),
      'cost_title': new FormControl(null),
      'cost': new FormControl(null, [Validators.pattern("[0-9]{1,}")]),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
    })
  }

  open_user() {
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: { code: 193 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1.patchValue({
            'user_title': res.title,
            'user': res.id
          })
        }
      }
    )
  }

  search() {
    var date1;
    var date2;
    if (this.form1.value.date1) { date1 = `${this.form1.value.date1.toISOString()}`; }
    if (this.form1.value.date2) { date2 = `${this.form1.value.date2.toISOString()}`; }

    this.obj = {
      'user': this.form1.value.user, 'cost': this.form1.value.cost
      , date1: date1, date2: date2, user_cost: 'user', search: true
    };

    if (this.id == 202) this.router.navigate(['/home/financial2/202'], { queryParams: this.obj });
    if (this.id == 226) this.router.navigate(['/home/finance-cardex/226'], { queryParams: this.obj });
    this.close(false);
  }

  close(close: boolean) {
    var obj = {
      'user': this.form1.value.user, 'user_title': this.form1.value.user_title,
      'cost': this.form1.value.cost, 'cost_title': this.form1.value.cost_title, user_cost: 'cost', close: close
      , date1: this.form1.value.date1, date2: this.form1.value.date2
    }
    this.matDialogRef.close(obj);
  }

  reset_customer() {
    this.form1.patchValue({
      user_title: null,
      user: null
    })
  }

  load_services(id: number) {
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 221) {
            this.form1.patchValue({
              'cost': res.id,
              'cost_title': res.title
            })
          }
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 221) {
      this.form1.patchValue({
        'cost': null,
        'cost_title': null
      })
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}