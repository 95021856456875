import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { TabComponent } from '../../service/menu/tab/tab.component';
import { MenuItemsComponent } from '../../service/menu/menu-items/menu-items.component';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ReceptionDetaileComponent } from '../../repairman/reception/reception-detaile/reception-detaile.component';
import { InvoiceDetaileComponent } from '../../wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { InvoiceOfPersonComponent } from '../../finance/invoice-of-person/invoice-of-person.component';
import { FinancialDetaileComponent } from '../../finance/financial/financial-detaile/financial-detaile.component';
import { CardexDetaileComponent } from '../../wharehouse/wharehouse-cardex/cardex-detaile/cardex-detaile.component';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { FinanaceCardexDetaileComponent } from '../../finance/finanace-cardex-detaile/finanace-cardex-detaile.component';
import { MyProfileComponent } from '../../user/my-profile/my-profile.component';
import { DOCUMENT } from '@angular/common';
import { MessageService } from '../../service/message/message.service';
import { AccountRenewalComponent } from '../account-renewal/account-renewal.component';
import * as moment from 'jalali-moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public no_record: string = this.serverService.no_record();
  public loading = false;
  public subscription: Subscription;
  public fname: string | undefined;
  public lname: string | undefined;
  public wallet: number = 0;
  public list_content: any = [];
  public profit_sale_account: number = 0;
  public network_access: boolean = false;
  public position: string = "start";

  code: number;
  uploadedAvaterProgess: number | undefined;
  user_logo: string = this.serverService.get_default_user_logo();
  user_id: number | undefined;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  user_store: string;
  site: string = this.serverService.get_site();
  list_tab: any = [];
  list_wharehouse_groups: any = [];
  list_tab_temp: any = [];
  tab_id: number = 156;
  list_section: any = [];
  list_section_temp: any = [];
  list_rstatus_in_menu: any = [];
  list_rstatus: any = [];
  rstatus_id: number | undefined;
  wharehouse_group_id: number | undefined;
  groups_id: number | undefined;
  bascket_status_id: number | undefined;
  store_id: number | undefined;
  list_devices: any = [];
  public count_material_wharehouse: number = 0;
  type: number = 1;
  i: number | undefined;
  select_i: number | undefined;
  action: string | undefined;
  id: number | undefined;
  list_menu: any = []; list_menus: any = [];
  setting_menu: boolean = false;
  count_bascket_status_all: number = 0;
  admin: number | undefined;
  services_id: number | undefined;
  user_title: string | undefined;
  show_extra_menu: boolean = true;
  groups_title: string | undefined;
  version_show_alarm: boolean = false;
  job: number | undefined;
  user_amount: number;
  menu_id: number | undefined;
  menu_id_temp: number | undefined;
  list_cate: any = [];
  list_software: any = [];
  list_cheque_status: any = [];
  list_finance_document: any = [];
  time: string | undefined;
  user_creator: number | undefined
  refrerr: any | undefined;
  search_finance: string;
  search_finance_cardex: string;
  search_finance_newspaper: string;
  search_material: string;
  level: number | undefined;
  list_groups: any = [];
  creator: number | undefined;
  similar_admin: number;
  expire: boolean | undefined;
  expire_date!: string;
  user_setting_menu: number | undefined;
  sms_charge: number = 0;
  sms_charge2: number = 0;
  loading2: boolean = false;
  amount: number;
  amount2: number = 0;
  group_id: number | undefined;
  sms_access: boolean = false;
  account_title: string | undefined;
  account_price: number = 0;
  account_month: number = 1;
  account_price_final: number = 0;
  currency_title: number = 0;
  currency_id: number = 0;
  p3: string | undefined;
  p7: string | undefined;
  now_date!: string;
  search_array_bank: any = [];
  list_bascket_status: any = [];
  bascket_status: number | undefined;
  code_bascket: number = 0;
  token_login: any | undefined;
  last_date_update: string;
  NowDate: Date | undefined;
  ExpireDate: Date | undefined;
  public acesss_invoice_sale: boolean;
  public acesss_invoice_buy: boolean;
  public acesss_invoice_return_buy: boolean;
  public month_extension: number = 0;
  public Status_pay: string;

  @ViewChild('myModalPassword', { static: true }) myModalPassword: any;
  constructor(
    public router: Router
    , public serverService: ServerService
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public http: HttpClient
    , public messageService: MessageService
    , public activatedRoute: ActivatedRoute
    , @Inject(DOCUMENT) public document: any
  ) {

    // this.serverService.get_rstatus_id().subscribe(
    //   (res) => {
    //     this.rstatus_id = res;
    //   }
    // )

    this.serverService.get_services_id().subscribe(
      (res) => {
        this.get_services_id(res);
      }
    )
    this.serverService.get_reset_services_id().subscribe(
      (res) => {
        this.rstatus_id = 0;
      }
    )

    this.serverService.get_wharehouse_group_id().subscribe(
      (res) => {
        this.get_wharehouse_group_id(res);
      }
    )

    this.serverService.get_sms_charge().subscribe(
      () => {
        this.get_sms_charge();
      }
    )

    this.serverService.get_account_charge().subscribe(
      () => {
        this.get_expire_date();
      }
    )


    this.serverService.get_user_title().subscribe(
      (res) => {
        if (res) {
          this.user_title = res.title
          if (res.user_logo) this.user_logo = res.user_logo_site + "/" + res.user_logo;
          else this.user_logo = this.serverService.get_default_user_logo();
        }
      }
    )


    this.serverService.get_tab().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_tab.push(res.result);
          this.list_tab_temp[res.result.site_tab_id] = new Array();
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_tab.length; i++) {
            if (this.list_tab[i].site_tab_id == res.result.id) {
              this.list_tab[i].site_tab_title = res.result.title;
            }
          }
        }
        if (res.action == 'delete') {
          for (var i = 0; i < this.list_tab.length; i++) {
            if (this.list_tab[i].site_tab_id == res.result) {
              this.list_tab.splice(i, 1)
            }
          }
        }//end if delete tab
        if (res.action == 'sort') {
          for (var x = 0; x < this.list_tab.length; x++) {
            for (var i = 0; i < res.result.data.length; i++) {
              if (this.list_tab[x].site_tab_id == res.result.data[i].site_tab_id) {
                this.list_tab[x].site_tab_sort = i + 1;
              }
            }
          }
          this.list_tab.sort(this.compare_tab);
        }
        if (res.action == 'select') {
          if (res.result) {
            $(document).ready(function () {
              $(".active").removeClass("active");
              $("#a" + res.result).addClass("active");
              $("#" + res.result).addClass("active");
            })
          }
        }
        if (res.action == 'status') {
          if (res.result.site_tab_status == 2) {
            for (var i = 0; i < this.list_tab.length; i++) {
              if (this.list_tab[i].site_tab_id == res.result.site_tab_id) {
                this.list_tab.splice(i, 1)
              }
            }
          }
          else {
            this.list_tab.push(res.result);
            this.list_tab.sort(this.compare_tab);
          }
        }
      }
    )

    this.serverService.get_section().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_section.push(res.result.data);
          this.list_tab_temp[res.result.tab_id].push(res.result.data);
          this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_section.length; i++) {
            if (this.list_section[i].site_section_id == res.result.id) {
              this.list_section[i].site_section_title = res.result.title;
            }
          }
        }
        if (res.action == 'delete') {
          for (var i = 0; i < this.list_tab_temp[res.result.tab_id].length; i++) {
            if (this.list_tab_temp[res.result.tab_id][i].site_section_id == res.result.section_id) {
              this.list_tab_temp[res.result.tab_id].splice(i, 1);
            }
          }
        }//end if delete tab
        if (res.action == 'sort') {
          for (var x = 0; x < this.list_tab_temp[res.result.tab_id].length; x++) {
            for (var i = 0; i < res.result.data.length; i++) {
              if (this.list_tab_temp[res.result.tab_id][x].site_section_id == res.result.data[i].site_section_id) {
                this.list_tab_temp[res.result.tab_id][x].site_section_sort = i + 1;
              }
            }
          }
          this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
        }
        if (res.action == 'status') {
          if (res.result.data.site_section_status == 2) {
            for (var i = 0; i < this.list_section.length; i++) {
              if (this.list_section[i].site_section_id == res.result.data.site_section_id) {
                this.list_section.splice(i, 1);
              }
            }
            for (var i = 0; i < this.list_tab_temp[res.result.tab_id].length; i++) {
              if (this.list_tab_temp[res.result.tab_id][i].site_section_id == res.result.data.site_section_id) {
                this.list_tab_temp[res.result.tab_id].splice(i, 1);
              }
            }
          }
          else {
            this.list_section.push(res.result.data);
            this.list_tab_temp[res.result.tab_id].push(res.result.data);
            this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
          }
        }
      }
    )

    this.serverService.get_menu2().subscribe(
      (res) => {
        this.list_tab = [];
        this.list_tab_temp = [];
        this.list_menus = [];
        this.list_section = [];
        this.get_tab();
      }
    )


    this.serverService.get_menu().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_menus[res.result.section_id] = [];
          for (var i = 0; i < res.result.data.length; i++) {
            if (res.result.data[i].site_services_img) {
              res.result.data[i].src = res.result.data[i].site_services_site + "/" + res.result.data[i].site_services_img;
            } else {
              res.result.data[i].src = this.serverService.get_default_image();
            }

            if (res.result.data[i].site_services_status == 1) {
              this.list_menus[res.result.section_id].push(res.result.data[i]);
            }

          }
        }
        if (res.action == 'remove') {
          for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
            if (this.list_menus[res.result.section_id][i].site_menu_id == res.result.menu_id) {
              this.list_menus[res.result.section_id].splice(i, 1);
            }
          }
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
            if (this.list_menus[res.result.section_id][i].site_services_id == res.result.id) {
              this.list_menus[res.result.section_id][i].site_services_title = res.result.title;
            }
          }
        }
        if (res.action == 'logo') {
          for (var i = 0; i < this.list_menus[res.result.menu_section].length; i++) {
            if (this.list_menus[res.result.menu_section][i].site_menu_id == res.result.menu_id) {
              if (res.result.data.path) {
                this.list_menus[res.result.menu_section][i].src = res.result.data.site + "/" + res.result.data.path;
              } else {
                this.list_menus[res.result.menu_section][i].src = this.serverService.get_default_image();
              }
            }
          }
        }
        if (res.action == 'status') {
          if (res.result.data.site_services_status == 2) {
            for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
              if (this.list_menus[res.result.section_id][i].site_services_id == res.result.data.site_services_id) {
                this.list_menus[res.result.section_id].splice(i, 1);
              }
            }
          }
          else {
            if (res.result.data.site_services_img) {
              res.result.data.src = res.result.data.site_services_site + "/" + res.result.data.site_services_img;
            } else {
              res.result.data.src = this.serverService.get_default_image();
            }
            this.list_menus[res.result.section_id].push(res.result.data);
            this.list_menus[res.result.section_id].sort(this.compare_menus);
          }
        }
      }
    )
  }

  select(id: number) {
    this.tab_id = id;
    $(".active").removeClass("active");
    $("#a" + id).addClass("active");
    $("#" + id).addClass("active");
  }

  //**************************************************************************************** */
  ngOnInit() {
    if (this.dir == 'ltr') {
      this.position = "end";
    }
    if (!this.token_login) this.serverService.status1(2);
    this.refrerr = JSON.parse(<any>localStorage.getItem('refrerr'));
    if (this.user_info) {
      this.user_store = this.user_info.user_store;
    }
    var today = new Date();
    this.time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
      this.admin = this.user_info.admin;
      this.user_title = this.user_info.user_title;
      this.groups_title = this.user_info.groups_title;
      this.job = this.user_info.job;
      this.user_creator = this.user_info.user_creator;
    }
    if (!this.token_login && this.user_info) this.get_logo();
    this.remind_sms();

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.Status_pay = params['Status'];
        if (params['user_financial'] != null) {

          this.new_financial();
        }
        else
          if (params['user_invoice'] != null) {

            this.get_default_invoice();

          }
      })
  }
  get_list_users_for_send_sms(number_day, reminder) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6315, creator: this.creator, number_day: number_day }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.send_sms_expire(res['result'][i].user_sex_title + " " + res['result'][i].user_title, res['result'][i].user_cellphone, number_day, reminder);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert_reminder_log() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6316, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
      }
    )
  }

  send_sms_expire(user_store: string, cellphone: number, number_day: any, reminder: string): any {
    if (number_day == 0) number_day = 'امروز';
    var obj = {
      address: 6515,
      cellphone: cellphone,
      number_day: number_day,
      user_store: user_store,
      reminder: reminder
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
      })
  }

  get_sms_charge() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.sms_charge = res['result'][0].amount;
          this.loading2 = false;
        }//end if
        else {
          this.loading2 = false;
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_charge2() {
    this.amount2 = this.amount * 9 / 100 + this.amount;
  }

  charge() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 6367
      , 'sum': this.amount2 / 10
      , 'user_id': this.user_id, creator: this.creator, type: 'sms', account_month: 1
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.charge2(res['id']);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  charge2(bascket_id: number) {
    var obj = {
      'site': this.serverService.get_site(),
      'sum': this.amount2 / 10,
      'amount2': this.amount / 10,
      'user_id': this.user_id,
      'bascket_id': bascket_id, number: 1
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'pay', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          window.location.href = res['address'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  open_show_extra_menu() {
    this.show_extra_menu = !this.show_extra_menu;
  }

  get_tab() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1004, lang: this.lang, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        this.list_tab = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].site_tab_title) {
              res['result'][i].width = Number(res['result'][i].site_tab_title.length + 5);
            }
            this.list_tab.push(res['result'][i]);
            this.list_tab_temp[res['result'][i].site_tab_id] = new Array();
          }//end for
          this.tab_id = res['result'][0].site_tab_id;
          this.get_section();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end get_tab

  get_section() {
    this.loading = true;
    var obj = {
      address: 1021
      , lang: this.dir
      , software_id: this.serverService.get_software_id()
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_section = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_section.push(res['result'][i]);
            for (var j = 0; j < this.list_tab.length; j++) {
              this.list_tab[j].show = false;
              if (this.list_tab[j].site_tab_id == res['result'][i].site_section_tab) {
                this.list_tab_temp[this.list_tab[j].site_tab_id].push(res['result'][i]);
              }
            }
            this.list_menus[res['result'][i].site_section_id] = new Array();
          }//end for
          this.get_menu();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end get_section

  open_add_section() {
    const dialogRef = this.dialog.open(TabComponent, {
      width: '60rem',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        //this.get_tab();
      }
    )
  }

  compare_tab(a: any, b: any) {
    let comparison = 0;
    if (a.site_tab_sort > b.site_tab_sort) {
      comparison = 1;
    } else if (a.site_tab_sort < b.site_tab_sort) {
      comparison = -1;
    }
    return comparison;
  }

  compare_section(a: any, b: any) {
    let comparison = 0;
    if (a.site_section_sort > b.site_section_sort) {
      comparison = 1;
    } else if (a.site_section_sort < b.site_section_sort) {
      comparison = -1;
    }
    return comparison;
  }

  compare_menus(a: any, b: any) {
    let comparison = 0;
    if (a.site_menu_sort > b.site_menu_sort) {
      comparison = 1;
    }
    else if (a.site_menu_sort < b.site_menu_sort) {
      comparison = -1;
    }
    return comparison;
  }

  get_services_id(id: number) {
    this.menu_id = id;
  }
  get_wharehouse_group_id(id: number) {
    this.wharehouse_group_id = id;
    this.menu_id = 0;
    this.rstatus_id = 0;
    this.services_id = 0;
  }

  open_menu(i: number, link: string, id: number, title: string, section: any) {
    this.matSnackBar.dismiss();
    this.services_id = id;
    this.menu_id_temp = this.menu_id;
    this.menu_id = id;
    if (this.setting_menu == true) {
      this.open_menu_item(i, id, title, section);
      this.menu_id = id;
      this.rstatus_id = 0;
    }
    else if (id == 157) {
      this.new_reception();
      this.rstatus_id = 0;
    }
    else if (id == 198) {
      this.get_default_invoice();
    }
    else if (id == 202) {
      this.invoice_of_person(id);
    }
    else if (id == 226) {
      this.cardex_person(id);
    }
    else if (id == 204) {
      this.new_financial();
    }
    else if (id == 205) {
      this.open_cardex();
    }
    else if (id == 222) {
      this.open_profile();
    }
    else {
      //********** */
      this.search_material = "";
      this.search_array_bank = "";
      this.search_finance_cardex = "";
      this.search_finance_newspaper = "";
      this.search_finance = "";
      //********** */
      this.router.navigate(['/home', link, id]);
      this.menu_id = id;
      this.rstatus_id = 0;
      this.wharehouse_group_id = 0;
    }
  }

  open_profile() {
    this.rstatus_id = 0;
    this.services_id = 0;
    const dialogRef = this.dialog.open(MyProfileComponent, {
      width: '50rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: this.user_id, code: 222, ref: 'menu' }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        this.menu_id = this.menu_id_temp;
      }
    )
  }
  //******************************************************************************** */

  new_financial(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = { address: 1927, user_id: this.user_id, creator: this.creator }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial(-1, 2, res['id']);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_financial(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(FinancialDetaileComponent, {
      width: '65rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, code: 204, ref: 'menu' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.serverService.send_financial(res);
          this.router.navigate(['/home/financial/192']);
        } else {
          this.menu_id = this.menu_id_temp;
        }
      }
    )
  }
  open_account() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading2 = true;
    var address = 6668;
    if (this.lang == 2) address = 6772;
    var obj = {
      address: address
      , creator: this.creator
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.account_title = res['result'][0].type_account_title;
            this.account_price = res['result'][0].type_account_price;
            this.currency_title = res['result'][0].site_currency_title;
            this.currency_id = res['result'][0].site_currency_id;
            this.user_amount = res['result'][0].user_amount;

            if (this.lang == 1) {
              if (res['year'] == res['result'][0].user_expire_date!.split("/")[0]) {
                this.month_extension = 12 - res['result'][0].user_expire_date!.split("/")[1];
              } else {
                this.month_extension = 12 - res['month'];
              }
            } else {
              this.month_extension = 100;
            }
            this.change_account_price();
            this.get_profit();
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_profit() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6586, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.profit_sale_account = res['profit'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  change_account_price() {
    this.account_price_final = this.account_month * this.account_price;
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: 241 }
    })
  }
  //************************************************************************************************************* */
  get_wallet(type: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 6588
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.wallet = res['wallet'];
          this.select(216);

          if (type == 2) {
            if (this.wallet < this.account_price_final) {
              var pe_message = "مقدار کیف پول شما کمتر از هزینه تمدید می باشد.";
              var en_message = "The amount of your wallet is less than the renewal fee.";
              this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
              return false;
            }
            else {
              this.charge_account_with_wallet();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  charge_account_with_wallet() {
    var obj = {
      address: 6587
      , 'sum': this.account_price_final / 10
      , 'user_id': this.user_id, creator: this.creator, account_month: this.account_month, type: 'account'
      , 'number': this.account_month * 30
    }
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.update_user_date();
          this.get_wallet(1);
        }//end if
        else if (res['status'] == 4) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_user_date() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6378, creator: this.creator, number: this.account_month * 31 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_account_charge();
          var pe_message = "تمدید اکانت با موفقیت انجام شد";
          var en_message = "Account renewal has been successfully completed";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "Error in user update";
          var en_message = "خطا در آپدیت کاربر";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************************************* */

  charge_account() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    localStorage.setItem('sum', JSON.stringify(this.account_price_final / 10));
    localStorage.setItem('account_month', JSON.stringify(this.account_month));
    var obj = {
      address: 6367
      , 'sum': this.account_price_final / 10
      , 'user_id': this.user_id, creator: this.creator, account_month: this.account_month, type: 'account'
    }
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.charge_account2(res['id']);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  charge_account2(bascket_id: number) {
    var obj = {
      'site': this.serverService.get_site(),
      'sum': this.account_price_final * 1000
      , 'amount2': this.account_price_final * 1000
      , 'user_id': this.user_id
      , 'bascket_id': bascket_id
      , 'number': this.account_month
    }
    this.subscription = this.serverService.post_address(this.server_main, 'pay2', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          window.location.href = res['address'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************** */
  open_cardex() {
    const dialogRef = this.dialog.open(CardexDetaileComponent, {
      'width': '22rem',
      'height': 'auto',
      hasBackdrop: false,
      data: { res: this.search_material, 'ref': 'home', creator: this.creator }
    })
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_material = res;
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              }
            }
          )
        }
      }
    )
  }
  invoice_of_person(id: number) {
    const dialogRef = this.dialog.open(InvoiceOfPersonComponent, {
      width: '22rem',
      height: 'auto',
      data: { res_search_finance: this.search_finance, creator: this.creator, id: id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_finance = res;
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              } else {
                if (res.close == true) {
                  this.menu_id = this.menu_id_temp;
                }
              }
            }
          )
        }
      }
    )
  }
  cardex_person(id: number) {
    const dialogRef = this.dialog.open(FinanaceCardexDetaileComponent, {
      width: '22rem',
      height: 'auto',
      hasBackdrop: false,
      data: { res_search_finance_newspaper: this.search_finance_newspaper, res_search_finance_cardex: this.search_finance_cardex, creator: this.creator, id: id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 226) this.search_finance_cardex = res;

          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              } else {
                if (res.close == true) {
                  this.menu_id = this.menu_id_temp;
                }
              }
            }
          )
        }
      }
    )
  }
  get_default_invoice() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6418, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            var x = 0;

            if (res['result'][0].user_default_invoice == 3 && this.acesss_invoice_sale == true) {
              x = res['result'][0].user_default_invoice;
            }
            if (res['result'][0].user_default_invoice == 1 && this.acesss_invoice_buy == true) {
              x = res['result'][0].user_default_invoice;
            }
            if (res['result'][0].user_default_invoice == 5 && this.acesss_invoice_return_buy == true) {
              x = res['result'][0].user_default_invoice;
            }
            this.new(x);
          } else {
            this.new(0);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  new(id): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6217, id: id, creator: this.creator, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var new_invoice_id;
          if (res['num'] == 1) new_invoice_id = Number(res['result'][0].wharehouse_invoice_number) + 1;
          else new_invoice_id = 1;
          this.open_detaile(-1, 2, res['id'], new_invoice_id);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_detaile(i: number, type_task: number, id: number, new_invoice_id: number) {
    const dialogRef = this.dialog.open(InvoiceDetaileComponent, {
      width: '70rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: id, new_invoice_id: new_invoice_id, code: 198, title: '', invoice_type_id: 1, i: -1 }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.serverService.send_invoice(res);
          this.router.navigate(['/home/invoice/199/0'], { queryParams: { id1: 0 } });
        } else {
          this.menu_id = this.menu_id_temp;
        }
        this.update_financial2(res);
        if (res.type_save == 'insert_finance' && res.back == 0) {
          this.pay(res.result.user_id, res.result.wharehouse_invoice_amount, res.result.document_type_text);
        }
        if (res.result.confirm_sms == true) {
          var x = res.result;
          this.check_charge_sms('invoice', x.wharehouse_invoice_id, 0, x.user_cellphone, x.wharehouse_invoice_type_title, x.user_sex_title + ' ' + x.user_title, x.user_store, x.user_phone, x.wharehouse_invoice_token_sms)
        } else {
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  pay(user: number, price: number, document_type_text: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 1927, user: user
      , user_id: this.user_id, creator: this.creator
      , document_type_text: document_type_text
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial2(res['id'], price);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_financial2(id, price: number) {
    const dialogRef = this.dialog.open(FinancialDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: 2, id: id, code: 192, document: 1, sum: price }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {

      }
    )
  }


  check_charge_sms(type: string, id: number, code: number, cellphone: any, type_title: string, title: string, user_store: string, user_phone: string, tokrn_sms: any): any {
    if (cellphone.match(/^\d{11}$/g) == null) {
      var pe_message = "فاکتور یا موفقیت ثبت شد ولی فرمت شماره برای ارسال پیامک معتبر نمی باشد.";
      var en_message = "The invoice or success was registered, but the number format is not valid for sending SMS.";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= 2000) {
              if (type == 'invoice') this.send_sms_invoice(id, cellphone, type_title, title, user_store, user_phone, tokrn_sms);
              else this.send_sms_reception(id, code, cellphone, title, user_store);
            }
            else {
              var pe_message = "رکورد یا موفقیت ثبت شد ولی قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید.";
              var en_message = "The record or success was recorded, but you are not able to send SMS, please recharge.";
              this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  send_sms_invoice(id: number, cellphone: number, type_title, title: string, user_store: string, user_phone: string, token_sms: any): any {
    var token2 = this.serverService.get_my_domain() + "/invoice/" + id;
    var obj = {
      address: 6514,
      cellphone: cellphone,
      type_title: type_title,
      token2: token2,
      token_sms: token_sms,
      title: title,
      user_store: user_store,
      user_phone: user_phone,
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.log_sms(res['response'].body.entries[0].cost);
      })
  }//end if

  send_sms_reception(id: number, code: number, cellphone: any, title: string, user_store: string): any {
    var token = this.serverService.get_my_domain() + "/tracking/" + id;
    var obj = {
      address: 6513,
      cellphone: cellphone,
      token: token,
      code: code,
      title: title,
      user_store: user_store
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.log_sms(res['response'].body.entries[0].cost);
      })
  }


  update_financial2(result: any) {
    var obj = {
      address: 6106,
      id: result.result.wharehouse_invoice_id,
      amount: result.result.price,
      date3: result.result.wharehouse_invoice_date3,
      document_type: result.result.document_type,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2(result);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_all_financial2(res: any) {
    var obj = {
      address: 6111,
      id: res.result.wharehouse_invoice_id,
      user: res.result.wharehouse_invoice_user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  payment(event: any) {
    if (this.setting_menu == false) {
      this.router.navigate(["/home/charge/230"], { queryParams: { type: 'sms' } });
      //event.startPropagation();
    } else {
      this.open_menu_item(0, 230, 'لیست پرداخت', '0')
      //event.stopPropagation();
    }
  }

  open_video2(id: number) {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { code: 249, id: id }
    })
  }

  get_content() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = { address: 6545 }
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_content = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_content.push(res['result'][i])
          }
          this.loading2 = false;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  payment2(event: any) {
    if (this.setting_menu == false) {
      this.router.navigate(["/home/charge/232"], { queryParams: { type: 'account' } });
      //event.startPropagation();
    } else {
      this.open_menu_item(0, 230, 'لیست پرداخت', '0')
      //event.stopPropagation();
    }
  }

  open_menu_item(i: number, id: number, title: string, section_id: string) {
    const dialogRef = this.dialog.open(MenuItemsComponent, {
      width: '45rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, title: title, type_task: 2 }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.serverService.send_menu({ title: res.result.site_services_title, id: res.result.site_services_id, section_id: section_id }, 'update');
        }
      }
    )
  }

  new_reception() {
    const dialogRef = this.dialog.open(ReceptionDetaileComponent, {
      width: '70rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 1, code: 157, rstatus_id: this.rstatus_id, ref: 'menu' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (this.rstatus_id == res.result.repairman_reception_status) {
          } else {
            this.go_to_status(res.result.repairman_reception_status);
          };
          this.update_financial22(res.result.repairman_reception_id);
          this.update_attachment(res.result.repairman_reception_id, res.token);
          if (res.isprint == 1) window.open(this.serverService.get_my_domain() + "/tracking/" + res.result.repairman_reception_id + "/" + res.result.repairman_reception_code, "_blank");
          if (res.result.confirm_sms == true) {
            this.check_charge_sms('reception', res.result.repairman_reception_id, res.result.repairman_reception_code, res.result.user_cellPhone, '', res.result.user_sex_title + ' ' + res.result.user_title, res.result.user_store, res.result.user_phone, '')
          }
        } else {
          this.menu_id = this.menu_id_temp;
        }
      }
    )
  }

  update_attachment(id: number, token: number) {
    var obj = {
      address: 6358,
      id: id,
      user_id: this.user_id,
      creator: this.creator,
      token: token,
      type_task: 1
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ذخیره ضمیمه ها";
          var en_message = "Error saving attachments";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_financial22(id: number) {
    var obj = {
      address: 6108,
      id: id,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  go_to_user() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6570, id: this.user_creator }).subscribe(
      (res: any) => {
        //localStorage.clear();
        if (res['status'] == 1) {
          var user_info = {
            user_id: res['result'][0].user_id,
            username: res['result'][0].user_userName,
            user_title: res['result'][0].user_title,
            groups_title: res['result'][0].user_groups_title,
            user_token: res['result'][0].user_token,
            lang: this.lang,
          };
          localStorage.setItem("user_info", JSON.stringify(user_info));
          localStorage.setItem('status', '1');
          localStorage.setItem('refrerr', '1');
          localStorage.setItem('lang', this.lang);
          if (this.level == 2) window.open("/home/user/261", "_self");
          else window.open("/home/access/219", "_self");
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_send_sms(id: number, code: number, cellphone: number, title: string) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6249, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].user_sms == 1) {
            this.subscription = this.http.get(this.serverService.sms_token + "?receptor=" + cellphone + "&token=" + id + "&token2=" + code + "&token10=" + title + "&token20=" + this.user_store + "&template=tracking").subscribe(
              (res: any) => {
                this.log_sms(res['entries'][0].cost);
              })
          }
        }//end if
        else {
          var pe_message = "Error sending SMS";
          var en_message = "Error in recording the sent SMS log";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  log_sms(cost: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6308, user_id: this.user_id, creator: this.creator, cost: cost }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          var en_message = "Error in recording the sent SMS log";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //**************************************************************************************************** */
  get_menu() {
    this.loading = true;
    var obj = {
      address: 6569
      , user_id: this.user_id
      , level: this.level
      , creator: this.creator
      , similar_admin: this.similar_admin
      , lang: this.dir
      , software_id: this.serverService.get_software_id()
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_menu = [];
        if (res['status'] == 1) {
          this.user_setting_menu = res['user_setting_menu'];
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].site_services_img) {
              res['result'][i].src = res['result'][i].site_services_site + "/" + res['result'][i].site_services_img;
            } else {
              res['result'][i].src = this.serverService.get_default_image();
            }
            if (res['result'][i].site_services_id == 253) {
              this.network_access = true;
            }
            if (res['result'][i].site_services_id == 197) {
              this.get_rstatus();

              this.activatedRoute.queryParams.subscribe(
                (params: Params) => {
                  if (params['user_reception'] != null) {
                    this.new_reception();
                    this.rstatus_id = 0;
                  }
                }
              )
            }
            this.list_menu.push(res['result'][i]);
          }//end for
          this.join_section_menu();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.serverService.signout();
        }
      }
    )
  }//end get_menu res['result'][i].site_section_tab

  join_section_menu() {
    for (var i = 0; i < this.list_section.length; i++) {
      for (var j = 0; j < this.list_menu.length; j++) {
        if (this.list_section[i].site_section_id == this.list_menu[j].site_menu_section) {
          this.list_menus[this.list_section[i].site_section_id].push(this.list_menu[j]);

          for (var x = 0; x < this.list_tab.length; x++) {
            if (this.list_tab[x].site_tab_id == this.list_section[i].site_section_tab) {
              this.list_tab[x].show = true;
            }//end if
          }
        }
      }
    }
    var x1 = this.document.location.pathname.split("/")[3];
    for (var i = 0; i < this.list_menu.length; i++) {
      if (this.list_menu[i].site_services_id == x1) {

        for (var ii = 0; ii < this.list_section.length; ii++) {
          if (this.list_section[ii].site_section_id == this.list_menu[i].site_menu_section) {
            this.select(this.list_section[ii].site_section_tab);
          }
        }

      }
    }
  }


  change_avater(event: any) {
    var selectedavater: File = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedavater, selectedavater.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd)
      .subscribe(
        (event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
          }
          else if (event.type === HttpEventType.Response) {
            this.update_avater_user(event);
          }
        }
      )
  }

  update_avater_user(result: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 1037,
      user_id: this.user_id,
      site: this.serverService.get_site_upload_image(),
      path: this.serverService.get_path_upload_image() + "/" + result.body.result.filename
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.user_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_logo() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1038, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.acesss_invoice_sale = res['result'][0].user_access_invoice_sale;
          this.acesss_invoice_buy = res['result'][0].user_access_invoice_buy;
          this.acesss_invoice_return_buy = res['result'][0].user_access_invoice_return_buy;
          if (res['result'][0].user_logo) {
            this.user_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo;
          }
          else {
            this.user_logo = this.serverService.get_default_user_logo();
          }

          this.level = res['level'];
          this.creator = res['creator'];
          this.similar_admin = res['user_similar_admin'];

          this.sms_access = res['access_sms'];
          this.last_date_update = res['last_date_update'];
          this.get_tab();

          if (this.level != 1) {
            this.get_sms_charge();
            this.get_wallet(1);

            this.get_expire_date();
          }
        }//end if
        else {
          this.serverService.signout();
        }
      }
    )
  }
  get_count_material_wharehouse() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6593, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_material_wharehouse = res['num'];
        }//end if
        else {
        }
      }
    )
  }

  remind_sms() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6390, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['send_sms'] == 0 && res['time'] > '08:00:00') {
            this.get_list_users_for_send_sms(3, 'reminder');
            this.get_list_users_for_send_sms(7, 'reminder');
            this.get_list_users_for_send_sms(0, 'reminder2');
            this.insert_reminder_log();
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_expire_date() {
    var address;
    if (this.lang == 1) address = 6309;
    else address = 6771;
    var obj = {
      'address': address,
      'creator': this.creator,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.expire_date = res['expire_date'];
          this.p7 = res['p7'];
          this.p3 = res['p3'];
          this.now_date = res['now_date'];
          this.get_status_expire();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_status_expire() {

    //set default value for variables

    this.NowDate = new Date();

    this.NowDate.setDate(this.NowDate.getDate() - 1);


    this.ExpireDate = new Date();

    this.ExpireDate.setDate(this.ExpireDate.getDate() - 1);

    // if lang is persian
    // try convert to gregorian date
    if (this.lang == 1) {
      
      let date1 = moment.from(this.now_date, 'fa', 'YYYY/MM/DD');

      this.NowDate= new Date(date1.format('YYYY/MM/DD'));

      let date2 = moment.from(this.expire_date, 'fa', 'YYYY/MM/DD');

      this.ExpireDate = new Date(date2.format('YYYY/MM/DD'));
    }
    else {
      // pars as date
      this.NowDate = new Date(this.now_date);
      // pars as date
      this.ExpireDate = new Date(this.expire_date);
    }

    if (this.NowDate.getTime() > this.ExpireDate.getTime()) 
    {
      this.dialog.open(AccountRenewalComponent, {
        width: '30rem',
        height: 'auto',
        disableClose: true,
        data: { user_id: this.user_id, creator: this.creator, expire_date: this.expire_date }
      })
    }
  }

  get_rstatus() {
    var obj = {
      address: 1682
      , user_id: this.user_id
      , creator: this.creator
      , level: this.level
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_groups = [];
        this.list_rstatus = [];
        this.list_cheque_status = [];
        this.list_finance_document = [];
        this.list_bascket_status = [];
        this.list_rstatus_in_menu = [];

        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_logo) {
              res['result'][i].logo = res['result'][i].repairman_rstatus_site_logo + "/" + res['result'][i].repairman_rstatus_logo;
            } else {
              res['result'][i].logo = this.serverService.get_default_image();
            }
            this.list_rstatus.push(res['result'][i]);
            if (res['result'][i].repairman_rstatus_show_menu == 1) this.list_rstatus_in_menu.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  go_to_status(id: number) {
    this.rstatus_id = id;
    this.router.navigate(['/home', 'reception', 170], { queryParams: { rstatus: id } });
    this.menu_id = 0;
    this.wharehouse_group_id = 0;
  }

  go_to_wharehouse_group(id: number) {
    this.wharehouse_group_id = id;
    this.router.navigate(['/home', 'material2', 247], { queryParams: { group: id } });
    this.menu_id = 0;
    this.rstatus_id = 0;
    this.services_id = 0;
  }

  go_to_user_with_id(id) {
    this.groups_id = id;
    //this.router.navigate(['/home/reception/170', rstatus, this.user_id, 'right']);
  }

  go_to_store(id: number) {
    this.store_id = id;
    this.router.navigate(['/home', 'store', 185, id]);
    this.menu_id = 0;
  }
  //********************************************************************************************** */
  access_setting_menu() {
    this.setting_menu = !this.setting_menu;
  }
  //***********************************************************************************
  signout() {
    localStorage.removeItem('user_info');
    localStorage.removeItem('status');
    localStorage.removeItem('language');
    localStorage.removeItem('lang');
    localStorage.removeItem('dir');
    window.open("/", "_self");
  }//end signout


  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //************************************************************************************
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }
}
