import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Financial2DetaileComponent } from "../financial2/financial2-detaile/financial2-detaile.component";
import { ChequePayDetaileComponent } from '../cheque/cheque-pay-detaile/cheque-pay-detaile.component';
import { InvoiceDetaileComponent } from '../../wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { FinancialDetaileComponent } from '../financial/financial-detaile/financial-detaile.component';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { WharehouseExit2Component } from '../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { MessageService } from '../../service/message/message.service';
import { FinanceCardexPersonnelDetaileComponent } from '../finance-cardex-personnel-detaile/finance-cardex-personnel-detaile.component';


@Component({
  selector: 'app-finance-cardex-personnel',
  templateUrl: './finance-cardex-personnel.component.html',
  styleUrls: ['./finance-cardex-personnel.component.scss']
})
export class FinanceCardexPersonnelComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public loading2: boolean = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public remain_temp: number = 0;
  public remain_temp2: number = 0;
  public list_remain: any = [];
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined;
  public search_all: string | undefined;
  public profit: number;
  public amount: number;
  public discount: number;
  //******************************************************************************
  public list_record: any = [];
  public list_ref: any = [];
  public creator: number | undefined;
  public video: string | undefined;
  public user_cost: number | undefined;
  public path_export: string | undefined;
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public access_service: number | undefined;
  public level: number | undefined;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'date', 'document', 'user', 'bed', 'best', 'remain', 'comment'];
  //*************
  public form1_group: FormGroup | any;
  public form1_search_group: FormGroup | any;
  public sum: number = 0;
  public best: number = 0;
  public bed: number = 0;
  public remain: number = 0;
  public remain2: number = 0;
  public user: number | undefined;
  public cost: number | undefined;
  public date1: string;
  public date2: string;
  public order: number;
  public ref: number | undefined
  public bill_i: number | undefined
  public bill_page: number | undefined
  public bill_ref: number | undefined;
  public bill_search_all: number | undefined;
  public bill_link: string | undefined;
  public bank: number | undefined;
  public account: number | undefined;

  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public dialogRef: MatDialog
    , public messageService: MessageService
    , public sheet: MatBottomSheet) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);

    //**************
  }//end ngOnInit

  order_ref(id: number, ref: number, order_id: number) {
    if (ref == 1) {
      const dialogRef = this.dialog.open(FinancialDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: 192, order_id: order_id }
      });
    }

    if (ref == 2) {
      const dialogRef = this.dialog.open(InvoiceDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: 199, order_id: order_id }
      });
    }
    if (ref == 3) {
      this.dialog.open(WharehouseExit2Component, {
        'width': '70rem',
        'height': 'auto',
        data: { 'reception_id': id, ref: 'exit', type: 2, code: 160, creator: this.creator, readonly: true, order_id: order_id }
      });
    }
  }

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.path_export = res['path_export'];
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {

          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    //6625
    var obj = {
      'address': 6804, "page": this.page, 'type': this.type
      , 'excel': excel
      , lang: this.lang
      , user_id: this.user_id
      , search: this.search_array
      , ref: this.ref
      , search_all: this.search_all
      , changedRows: changedRows
      , code: this.code
      , creator: this.creator
      , path_export: this.path_export
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                if (i == 0 && page == 1) {
                  res['result'][i].remain2 = res['remain'];
                  this.remain_temp = res['remain'];
                }
                else if (i == 0 && page == 2) {
                  res['result'][i].remain2 = this.remain_temp;
                }
                else if (i == 0 && page == 3) {
                  res['result'][i].remain2 = this.list_remain[this.page];
                  this.list_remain.splice(this.list_remain.length - 1, 1);
                  if (this.page == 1) this.list_remain[1] = res['remain'];
                }
                else {
                  if (res['result'][i - 1].bed > 0) {
                    res['result'][i].remain2 = Number(res['result'][i - 1].remain2) - Number(res['result'][i - 1].bed);
                  }
                  else {
                    res['result'][i].remain2 = Number(res['result'][i - 1].remain2) + Number(res['result'][i - 1].best);
                  }
                }
                if (i == 19) {
                  this.remain_temp = res['result'][i].remain2;
                  if (res['result'][i].bed > 0) {
                    this.remain_temp = Number(this.remain_temp) - Number(res['result'][i].bed);
                  }
                  else {
                    this.remain_temp = Number(this.remain_temp) + Number(res['result'][i].best);
                  }
                }
                this.list_record.push(res['result'][i]);
              }//end for
            }
            if (page == 1) {
              this.list_remain.push(0);
              this.list_remain.push(res['remain']);
            }
            if (page == 2 && this.list_remain.length > 0) {
              this.list_remain.push(res['result'][0].remain2);
            }
            this.sum = res['sum'];
            this.best = res['best'];
            this.bed = res['bed'];
            this.remain = res['remain'];

            this.remain2 = res['remain2'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  check_material(order_id: number, material_type: number) {
    if (material_type == 2) {
      this.get_amount2(order_id, 6626);
    } else {
      this.get_amount2(order_id, 6640);
    }
  }

  get_amount2(order_id: number, address: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: address, order_id: order_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.amount = res['result'][0].wharehouse_order_cost;
            this.profit = res['result'][0].wharehouse_order_profit;
            this.discount = res['result'][0].wharehouse_order_discount;
          } else {
            this.amount = 0;
            this.profit = 0;
            this.discount = 0;
          }
          this.loading2 = false;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************
  open_search() {
    const dialogRef = this.dialog.open(FinanceCardexPersonnelDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { search_array: this.search_array, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_array = res;
          this.load_data(1, 1, 1, -1);
        }
      }
    )
  }

  open_detaile(i: number, type_task: number, id: number, payment_type: number, cheque: number): any {
    if (type_task == 2 || type_task == 1) {
      return false;
    }
    if (payment_type == 2) {
      this.dialog.open(ChequePayDetaileComponent, {
        width: '45rem',
        height: 'auto',
        data: {
          type_task: 4, code: this.code, id: cheque
        }
      })
    } else {
      const dialogRef = this.dialog.open(Financial2DetaileComponent, {
        width: '45rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
      });
      dialogRef.afterClosed().subscribe(
        (res: any) => {
          if (res) {
            if (type_task == 1) {
              this.list_record.unshift(res.result);
              this.pages(1);
              if (this.list_record.length == 21) {
                this.list_record.splice(20, 1);
              }
              this.dataSource.data = this.list_record;
            }
            else if (type_task == 2) {
              this.list_record[res.i].user_title = res.result.user_title;
              this.list_record[res.i].finance_financial2_amount = res.result.finance_financial2_amount;
              this.list_record[res.i].finance_payment_type_title = res.result.finance_payment_type_title;
              this.list_record[res.i].finance_document_title = res.result.finance_document_title;
              this.list_record[res.i].finance_financial2_sort = res.result.finance_financial2_sort;
              this.list_record[res.i].status_title = res.result.status_title;
            }
            else if (type_task == 3) {
              this.page = 1;
              var excel = res.export_excel;
              this.search_array = [];
              this.search_array = res;
              this.check_access(4, 1, 0, excel, 1);
            }
          }
        }
      )
    }
  }

  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

