
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-paccess',
  templateUrl: './paccess.component.html',
  styleUrls: ['./paccess.component.scss']
})
export class PaccessComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  user_id: number = this.user_info.user_id;
  public loading: boolean = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any | undefined;
  user: string | undefined;
  lang: any | undefined;
  list_user: any = [];
  list_user_select: any = [];
  list_user_selected: any = [];
  arr_users: any = [];
  id: number | undefined;
  pattern_title_with_persian: string | undefined;
  title: string | undefined;
  change_alter: number = 2;
  error: boolean = false;



  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any
    , private matDialogRef: MatDialogRef<PaccessComponent>) {
    if (dialog_data) {
      this.id = this.dialog_data.id;
      this.title = this.dialog_data.title;
    }
  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.serverService.status1(2);
    this.lang = localStorage.getItem("lang");
    this.check_access(4);
  }//

  check_access(type_type: number): any {
    if (this.error == true) {
      var pe_message = "خطایی در سیستم وجود دارد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }//end if  
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
            return false;
          }
          else {
            if (type_type == 4) { this.get_selected_users(); }
            if (type_type == 5) { this.get_users(); }
            return true;
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          return false;
        }
      }
    )
  }

  get_selected_users(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1030, id: this.id }).subscribe(
      (res: any) => {
        this.list_user_selected = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_user_selected.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//end get_selected_users



  get_users() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1031, title: this.user }).subscribe(
      (res: any) => {
        this.list_user = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_user.push(res['result'][i]);
            if (this.list_user_selected.findIndex((x: any) => x.user_id == res['result'][i].user_id) == -1) {
              this.list_user[i].checked = false;
            } else {
              this.list_user[i].checked = true;
            }
          }//end for
          console.log(this.list_user);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//get_users


  insert(i: number, event: any, id: number, username: any, title: string) {
    var ckecked = event.checked;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1032, id: this.id, user: id, ckecked: ckecked }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.change_alter = 1;
          var obj = {
            user_title: title, user_userName: username, user_id: id
          }
          this.list_user[i].checked = ckecked;
          if (ckecked == true) {
            this.list_user_selected.push(obj);
          }
          else {
            this.list_user_selected.splice(
              this.list_user_selected.findIndex((x: any) => x.user_id == id), 1);
          }//end else
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      })
  }//end insert


  delete(id: number) {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1032, id: this.id, user: id, ckecked: false }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.change_alter = 1;
          this.list_user_selected.splice(this.list_user_selected.findIndex((x: any) => x.user_id == id), 1);
          if (this.list_user[this.list_user.findIndex((x: any) => x.user_id == id)]) {
            this.list_user[this.list_user.findIndex((x: any) => x.user_id == id)].checked = false;
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      })
  }//end delete

  close() {
    this.matDialogRef.close({ alter: this.change_alter });
  }

  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 2000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 2000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


