import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { BascketDetaileComponent } from '../bascket-detaile/bascket-detaile.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-bascket-list',
  templateUrl: './bascket-list.component.html',
  styleUrls: ['./bascket-list.component.scss']
})
export class BascketListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public typeCharge: number;
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public export_code: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_array: any = [];
  public similar_admin: number;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  public expire: boolean | undefined;
  public video: string | undefined;
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public search_all: string | undefined;
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public readonly: boolean = false;
  public checked: boolean = false;
  public status: any = 0;
  public status_buy: boolean | undefined;
  public Authority: any;
  public Status: any;
  public sum: any;
  public RefID: any = 0;
  public creator: number | undefined;
  public type_charge: string | undefined;
  public number: number = 0;
  public amount: number | undefined;
  public amount2: number | undefined;
  public bascket_id: number | undefined;
  public access_service: number | undefined;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  public displayedColumns = ['row', 'how_to_charge', 'comment', 'user_title', 'account', 'expire', 'amount', 'coupon', 'code', 'date', 'time', 'id', 'operation'];
  //*************
  public bascket_status_id: number = 0;
  //************** */
  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public messageService: MessageService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog) { }

  ngOnInit() {
    //this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.search_all = '';
        this.code = params['id'];
        this.check_access(4, 1, 0, 1, 1);
      }
    )

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.search_all = '';
        this.Status = params['Status'];
        this.Authority = params['Authority'];
        this.type_charge = params['type'];
        this.number = params['number'];
        this.bascket_status_id = params['status'];
        this.amount = params['amount'];
        this.amount2 = params['amount2'];
        this.typeCharge = params['typeCharge'];
        this.bascket_id = params['bascket_id'];
        if (this.bascket_status_id >= 0) this.check_access(4, 1, 0, 1, 1);
      }
    )
    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.similar_admin = res['similar_admin'];
          this.level = res['level'];
          if (this.similar_admin == 1) {
            this.creator = 2445;
            this.level = 1;
          }
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) {
            if (this.Status == 'OK') {
              this.Verification();
              this.log();
            }//end status
            else if (this.Status == 'NOK') {
              this.Verification2();
            }//end status
            else {
              this.load_data(page, excel, loading, -1, false);
              this.status_buy = false;
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_data(page: number, excel: number, loading: number, changedRows: number, after_buy) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
    }
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (!this.bascket_status_id) this.bascket_status_id = 0;
    var obj = {
      'address': 6373
      , "page": this.page
      , "lang": this.lang
      , 'type': this.type
      , 'export_excel': excel
      , 'user_id': this.user_id
      , 'search_all': this.search_all
      , 'search': this.search_array
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'level': this.level
      , 'type_charge': this.type_charge
      , 'code': this.code
      , 'bascket_status': Number(this.bascket_status_id)
      , 'path_export': this.path_export
      , 'typeCharge': this.typeCharge
      , 'similar_admin': this.similar_admin
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sum = res['sum'];
            this.pages(0);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.serverService.change_window_resize(2, '', 0);
          this.serverService.send_services_id(this.code);
          if (this.typeCharge != 2) this.serverService.send_bascket_status({ code_bascket: this.code, bascket_status_id: this.bascket_status_id });
          //this.serverService.send_reset();
          if (after_buy == false) this.message(false, "", 1, this.messageService.close(this.lang));
          else this.add_sharj();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.level != 1 && type_task != 3) {
      return false;
    }
    const dialogRef = this.dialog.open(BascketDetaileComponent, {
      width: '25rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].site_bascket_number = res.result.result.site_bascket_number;
            this.list_record[i].site_bascket_type = res.result.result.site_bascket_type;
            this.list_record[i].type_account_title = res.result.result.type_account_title;
            this.list_record[i].user_store = res.result.result.user_store;
            this.list_record[i].site_bascket_expire_date = res.result.result.site_bascket_expire_date;
            this.list_record[i].site_bascket_amount = res.result.result.site_bascket_amount;
            this.list_record[i].site_bascket_code = res.result.result.site_bascket_code;
            this.list_record[i].site_bascket_date = res.result.result.site_bascket_date;
            this.list_record[i].site_bascket_time = res.result.result.site_bascket_time;
            this.list_record[i].title = res.result.result.title;
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }

  get_bg(id: number) {
    this.id = id;
  }
  //***************************************************************************************************************  */
  add_sharj() {
    var pe_message = "موفقیت ثبت شد";
    var en_message = "Success was recorded";
    this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
    this.serverService.send_sms_charge();
  }
  Verification() {
    var obj = {
      'authority': this.Authority, 'Amount': this.amount
      , 'user_id': this.user_id
      , creator: this.creator, number: this.number
    }
    this.subscription = this.serverService.post_address(this.server_main, 'authority', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.RefID = res['RefID'];
          this.log2();
          this.status_buy = true;
        }//end if
        else {
          this.status_buy = false;
          /*
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          */
        }
      }
    )
  }//end Verification

  Verification2() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 6368, 'authority': this.Authority, 'Amount': this.sum, 'user_id': this.user_id, creator: this.creator, bascket_id: this.bascket_id }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.status_buy = true;
          var pe_message = "شما از پرداخت انصراف دادید";
          var en_message = "You have canceled the payment";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.status_buy = false;
          var pe_message = "دریافت خطا";
          var en_message = "Recieve Erorr";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end Verification
  //***************************************************************************************************************  */
  log() {
    this.loading = true;
    var obj = {
      address: 6377, sum: this.sum, code: this.RefID
      , user_id: this.user_id, bascket_id: this.bascket_id, creator: this.creator
      , type_charge: this.type_charge, number: this.number, amount: this.amount2
      , Authority: this.Authority
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.load_data(1, 1, 2, -1, true);
          if (this.code == 232) {
            this.update_user_date();
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end log


  update_user_date() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6378, creator: this.creator, number: this.number * 31 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.log3();
          this.serverService.send_account_charge()
        }//end if
        else {
          var pe_message = "خطا در آپدیت کاربر";
          var en_message = "Error in user update";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_user(user: number) {
    var obj = { address: 6382, user: user }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  log3() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6379, creator: this.creator, number: this.number * 31, bascket_id: this.bascket_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در آپدیت کاربر";
          var en_message = "Error in user update";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  //برای درج شماره رهگیری می باشد
  log2() {
    var obj = { address: 6375, code: this.RefID, bascket_id: this.bascket_id }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end log
  //********************************************************************************
  delete(id: any, user: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6301; } else { address = 6300; }
      var obj = {
        'address': address, "user_id": this.user_id, 'id': id
      }
      this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.site_bascket_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.site_bascket_id == res['result_record'][i].site_bascket_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            //this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows, false);
            this.update_user(user);
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true      
  }//end delete 
  //*******************************************************************************
  change_resize() {
    this.serverService.change_window_resize(1, '', 0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


