<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">مانده اول دوره</span>
        <span *ngIf="lang == 2">Old debit/ credit</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1 padding0">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr'}" style='margin-top:5px'>
                <label for="finance_nature" class='wh-label'>
                    <span *ngIf="lang == 1"> ماهیت مانده </span>
                    <span *ngIf="lang == 2"> Nature of balance</span>
                    <span (click)="reset_input(218)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close
                        </mat-icon>
                    </span>
                    <input class='wh-input' formControlName='finance_nature_title' autocomplete="off"
                        (click)="load_services(218)" readonly required [ngClass]="{'en':dir=='ltr'}">
                    <input class='wh-input' formControlName='finance_nature' autocomplete="off" [hidden]="true" readonly
                        required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr'}" style='margin-top:5px'>
                <label for="balance" class='wh-label'>
                    <span *ngIf="lang == 1"> مانده اول دوره </span>
                    <span *ngIf="lang == 2"> Old debit/ credit</span>
                    <input class='wh-input' formControlName='balance' currencyMask
                        style="direction:ltr;text-align:right" required [ngClass]="{'left':dir=='ltr','en':dir=='ltr'}">
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1.valid" class="btn_pe">
            <span *ngIf="lang == 1"> ذخیره </span>
            <span *ngIf="lang == 2"> Save </span>
        </button>
    </div>
</form>