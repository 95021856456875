<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button (click)='open_dialog_fund()' *ngIf="level == 2 && lang == 1" class="hidden-xs"> موجودی اول
                </button>
                <button (click)='open_dialog_fund()' *ngIf="level == 2 && lang == 2" class="hidden-xs"> First
                    Balance</button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span *ngIf="lang == 1">موجودی صندوق</span>
                    <span *ngIf="lang == 2">Cash register balance</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'
        [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:120px">
                <span *ngIf="lang == 1">تاریخ سند</span>
                <span *ngIf="lang == 2">Document date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_financial2_id)"
                style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.finance_financial2_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:440px">
                <span *ngIf="lang == 1">شرح</span>
                <span *ngIf="lang == 2">Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:440px">
                <div class="right" [ngClass]="{'left':dir == 'ltr'}">
                    <span [ngClass]="{'fleft':dir == 'ltr'}">
                        {{ x.alias }}
                        <span *ngIf="lang == 1">براساس سند شماره</span>
                        <span *ngIf="lang == 2">Based on document no</span>
                        <span *ngIf="x.finance_financial2_ref == 1 || x.finance_financial2_ref == 4"
                            [ngClass]="{'en':dir == 'ltr'}"> {{
                            x.id }}</span>
                    </span>
                    <div class="invoice_type_title" [ngClass]="{'fright':dir == 'ltr' , 'fleft':dir == 'rtl' }">
                        <span (click)="order_ref(x.id,x.finance_financial2_ref)">
                            <i class="fas fa-info-circle icon"></i>
                        </span>
                    </div>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:440px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px;">
                <span *ngIf="lang == 1">نام طرف حساب / هزینه / درآمد</span>
                <span *ngIf="lang == 2">Client name/ Expense/ Income</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_financial2_id)"
                style="min-width: 250px;">
                {{
                x.user_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 250px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">بدهکار</span>
                <span *ngIf="lang == 2">Debtor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_financial2_id)">
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{x.bed | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ bed | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">بستانکار</span>
                <span *ngIf="lang == 2">Creditor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_financial2_id)">
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">
                    {{x.best | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="right" [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}">{{ best | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">مانده</span>
                <span *ngIf="lang == 2">Balance</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_financial2_id)">
                <div class="money" [ngClass]="{'en':dir == 'ltr'}">{{ x.remain2 | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="footer1 money" [ngClass]="{'en':dir == 'ltr'}">{{ remain | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>


<ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form #form_myModal="ngForm">
        <div style='text-align:center;padding:12px !important'>
            <span>{{ title }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> موجودی ابتدایی </span>
                    <span *ngIf="lang == 2"> Initial balance </span>
                    <input class='wh-input' [(ngModel)]="fund" name="fund" autocomplete="off" currencyMask required>
                </label>
            </div>
        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" [disabled]="!form_myModal.valid"
                    (click)="update_fund()">
                    <span *ngIf="lang == 1">ويرايش</span>
                    <span *ngIf="lang == 2">Edit</span>
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>