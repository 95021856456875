import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import Num2persian from 'num2persian';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-my-receipt',
  templateUrl: './my-receipt.component.html',
  styleUrls: ['./my-receipt.component.scss']
})
export class MyReceiptComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading = false;
  public lang: any = 1;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public server: any = this.serverService.get_server();
  public reception_id: number | undefined;
  public code: number | undefined;
  public id: number;
  public cellphone: number | undefined;
  public user_title: any | undefined;
  public user_address: number | undefined;
  public user_code_posti: number | undefined;
  public store_code_posti: number;
  public store_title: string | undefined;
  public store_address: string | undefined;
  public store_phone: string | undefined;
  public user_cellphone: string | undefined;
  public user_phone: string | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public activatedRoute: ActivatedRoute
    , public domSanitizer: DomSanitizer) { }//end consructor

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.reception_id = params['id'];
        this.code = params['token'];
      }
    )
    this.get_data();
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 6592, id: this.reception_id, code: this.code }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.store_title = res['result'][0].store_sex + " " + res['result'][0].store_title;
          this.store_phone = res['result'][0].store_phone;
          this.store_address = res['result'][0].store_address;
          this.store_code_posti = res['result'][0].store_code_posti;
          this.user_title = res['result'][0].user_sex_title + " " + res['result'][0].user_title;
          this.user_phone = res['result'][0].user_phone;
          this.user_cellphone = res['result'][0].user_cellphone;
          this.user_address = res['result'][0].user_address;
          this.user_code_posti = res['result'][0].user_code_posti;
          this.id = res['result'][0].repairman_reception_id;
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}