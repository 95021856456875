<div class="container-fluid">
    <div id="main">
        <table id="first_tbl" class="recept-table1 table table-bordered">
            <tbody>
                <tr>
                    <td scope="row" class="text-right">فرستنده :</td>
                    <td colspan="4" class="text-right"> {{ store_title }} <span class="pull-left text-left"> {{
                            store_phone }}
                        </span></td>
                </tr>
                <tr>
                    <td scope="row" class="text-right mid"> آدرس :</td>
                    <td colspan="4" class="text-right">
                        {{ store_address }}
                        <br />
                        <span class="pull-left text-left">کدپستی : {{ store_code_posti }} </span>
                    </td>
                </tr>

                <tr>
                    <td scope="row" colspan="5" class="text-right">توضیحات : </td>
                </tr>
                <tr>
                    <td scope="row" colspan="5" class="text-right"
                        style="border-left-style: none !important;border-right-style: none !important;">
                    </td>
                </tr>
                <tr>
                    <td scope="row" class="text-right">گیرنده :</td>
                    <td colspan="4" class="text-right"> {{ user_title }}
                        <span class="pull-left text-left"> {{ user_phone }}<span *ngIf="user_cellphone">-</span> {{
                            user_cellphone }} </span>
                    </td>
                </tr>
                <tr>
                    <td scope="row" class="text-right mid"> آدرس :</td>
                    <td colspan="3" class="text-right">{{ user_address }}

                        <br />
                        <span class="pull-left text-left">کدپستی :{{ user_code_posti }}</span>
                    </td>
                </tr>

            </tbody>
        </table>

        <div id="Secondtbl">
            <table class="table" id="second_table">
                <tr>
                    <td scope="row" colspan="1">
                        <img src='../../../../assets/images/receipt5.png'>
                    </td>
                    <td class="text-center">
                        <img src='../../../../assets/images/receipt4.png'>
                    </td>
                    <td class="text-center">
                        <img src='../../../../assets/images/receipt3.png'>
                    </td>
                    <td class="text-center">
                        <img src='../../../../assets/images/receipt2.png'>
                    </td>
                    <td class="text-center">
                        <img src='../../../../assets/images/receipt.png'>
                    </td>
                </tr>

            </table>
        </div>
    </div>
</div>