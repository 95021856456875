import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { PaccessComponent } from '../paccess/paccess.component';
import { GaccessComponent } from '../gaccess/gaccess.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { UserServiceDetaileComponent } from '../../../user/user-service-detaile/user-service-detaile.component';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any | undefined;
  public server_main: string = this.serverService.get_server_main();
  lang: any | undefined;
  title: string | undefined;
  form1: FormGroup | any;
  type_task: number | undefined;
  user_info: any | undefined;
  user_id: number | undefined;
  id: number | undefined;
  site_services_img: string | undefined;
  site_services_site: string | undefined;
  error: boolean = false;

  logo: any | undefined;
  logo_src: any | undefined;
  logo_bin: boolean = false;
  logo_info: any = [];

  menu_section: number | undefined;
  menu_id: number | undefined;

  constructor(public serverService: ServerService, public router: Router, private dialog: MatDialog
    , public matSnackBar: MatSnackBar
    , private matDialogRef: MatDialogRef<MenuItemsComponent>
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any) {
    if (this.dialog_data) {
      this.title = dialog_data.title;
      this.type_task = dialog_data.type_task;
      this.id = dialog_data.id;
    }
  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.lang = JSON.parse(<any>localStorage.getItem("lang"));
    this.user_info = JSON.parse(<any>localStorage.getItem("user_info"));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
    this.check_access(4);
  }

  create_form() {
    this.form1 = new FormGroup({
      'title': new FormControl(null, Validators.required)
    })
  }

  check_access(type_task: number): any {
    if (this.error == true) {
      var pe_message = "خطایی در سیستم وجود دارد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.user_id, id: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
            return false;
          }
          else {
            if (type_task == 2) { this.update(); }
            if (type_task == 4) { this.get_data(); }
            return true;
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          return false;
        }
      }
    )
  }

  get_data(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1028, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            'title': res['result'][0].site_services_title
          });
          if (res['result'][0].site_services_img) {
            this.logo_src = res['result'][0].site_services_site + "/" + res['result'][0].site_services_img;
            this.logo_bin = true;
          }
          else {
            this.logo_src = this.serverService.get_default_image();
          }
          this.logo_info = {
            site: res['result'][0].site_services_site,
            path: res['result'][0].site_services_img
          };
          this.menu_id = res['result'][0].site_menu_id;
          this.menu_section = res['result'][0].site_menu_section;
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          this.error = true;
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//end get_data

  update(): any {
    this.loading = true;
    var obj = { address: 1017, id: this.id, title: this.form1.value.title, logo_info: this.logo_info };
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.menu_section) {
            this.serverService.send_menu({ data: this.logo_info, menu_id: this.menu_id, menu_section: this.menu_section }, 'logo')
          }
          this.matDialogRef.close({ result: res['result'][0] });
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  open_paccess() {
    const dialogRef = this.dialog.open(PaccessComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: this.id, title: this.title }
    });
  }

  open_gaccess() {
    const dialogRef = this.dialog.open(GaccessComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: this.id, title: this.title }
    });
  }

  open_video() {
    const dialogRef = this.dialog.open(UserServiceDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: this.id, title: this.title, ref: 'items', type_task: 2, code1: true }
    });
  }
  //********************************************************************************************************************** */
  change_logo(event: any) {
    var logo_selected_file = <File>event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(logo_selected_file);
    reader.onload = (event) => {
      this.logo = (<FileReader>event.target).result;
    }
    var fd = new FormData();
    fd.append("image", logo_selected_file, logo_selected_file.name);
    this.serverService.post_address_file(this.server_main, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
        }
        else {
          if (event.type == HttpEventType.Response) {
            var a = <any>event.body;
            this.logo_src = this.serverService.get_site_media() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
            this.logo_bin = true;
            this.logo_info = {
              'site': this.serverService.get_site_media(),
              'path': this.serverService.get_path_upload_image() + a.result.filename
            }
          }
        }
      }
    )
  }

  delete_logo() {
    this.logo_bin = false;
    this.logo_src = this.serverService.get_default_image();
    this.logo_info = {
      path: '',
      site: ''
    }
  }
  //********************************************************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 2000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 2000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}