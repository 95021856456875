
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProfitDetaileComponent } from "../profit-detaile/profit-detaile.component";
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { InvoiceDetaileComponent } from '../../wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { WharehouseExit2Component } from '../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-profit2-list',
  templateUrl: './profit2-list.component.html',
  styleUrls: ['./profit2-list.component.scss']
})
export class Profit2ListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public sum_profit: number | undefined;
  public sum_service: number | undefined;
  public profit: number = 0;
  public discount: number = 0;

  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public creator: number | undefined;
  public video: string | undefined;
  public year: number;
  public month: number;
  public seller: number;
  public status_search: boolean | undefined;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'date', 'type', 'reception', 'customer', 'user', 'service', 'factor_price', 'discount', 'porsant1', 'porsant2'];
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router
    , public messageService: MessageService
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public dialogRef: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    );

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.year = params['year'];
        this.month = params['month'];
        this.seller = params['seller'];
        this.status_search = params['search'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    var obj = {
      'address': 6
      , 'type': 'user'
      , 'user_id': this.user_id
      , 'id': this.code
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    var obj = {
      'address': 6
      , 'user_id': this.creator
      , 'id': this.code
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    //6354
    var obj = {
      'address': 6457, "page": this.page, 'type': this.type
      , 'excel': excel
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , changedRows: changedRows
      , creator: this.creator
      , year: this.year
      , month: this.month
      , seller2: this.seller
      , path_export: this.path_export
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.discount = res['discount'];
            this.sum_profit = res['sum_profit'];
            this.sum_service = res['sum_service'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  open_detaile_readonly(type: string, id: number) {
    if (type == 'reception') {
      this.open_reception(id)
    } else {
      this.open_sale(id)
    }
  }

  open_reception(reception: number) {
    this.dialog.open(WharehouseExit2Component, {
      'width': '70rem',
      'height': 'auto',
      data: { 'reception_id': reception, readonly: true }
    });
  }

  open_sale(id: number) {
    this.dialog.open(InvoiceDetaileComponent, {
      width: '65rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: Number(id), readonly: true, code: 199 }
    });
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number): any {
    const dialogRef = this.dialog.open(ProfitDetaileComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
          }
          else if (type_task == 2) {
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }



  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

