<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>{{ serverService.get_title(type_task,lang) }}</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="Device" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع دستگاه </span>
                    <span *ngIf="lang == 2"> Device </span>
                    <span (click)="reset_input(154)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="device_title" (click)="load_services(154) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="device" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="brand" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع برند </span>
                    <span *ngIf="lang == 2"> Brand </span>
                    <span (click)="reset_input(164)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="brand_title" (click)="load_services(164) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="brand" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان مدل</span>
                    <span *ngIf="lang == 2"> Model </span>
                    <input class="wh-input font-en" formControlName="title" [required]="required()"
                        (keypress)="check_letter($event)" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب </span>
                    <span *ngIf="lang == 2"> Sort </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        autocomplete="off">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea class='wh-input' style="height:5rem" formControlName="comment" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="pe1 matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1_group.valid"
            *ngIf="type_task != 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div><!-- row -->
</form>