<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">فروش کالا</span>
        <span *ngIf="lang == 2">Sell product</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="group" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> گروه </span>
                    <span *ngIf="lang == 2">Group</span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223,0) " required>
                    <input class="wh-input" formControlName="group" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="cate" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> زیر گروه </span>
                    <span *ngIf="lang == 2">Subgroup</span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184,0)" required>
                    <input class="wh-input" formControlName="cate" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="material" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> نام کالا </span>
                    <span *ngIf="lang == 2">Product name</span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(171,0) " required>
                    <input class="wh-input" formControlName="material" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="number" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تعداد </span>
                    <span *ngIf="lang == 2">Number</span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [hidden]="access_price_column_buy == false">
                <label for="price" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> قیمت خرید </span>
                    <span *ngIf="lang == 2">Purchase price</span>
                    <input class='wh-input' formControlName="price" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="cost" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> قیمت فروش : </span>
                    <span *ngIf="lang == 2">Selling price</span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="discount" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> تخفیف </span>
                    <span *ngIf="lang == 2">Discount</span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}"
                [hidden]="ref == 'invoice' || level == 3">
                <label for="repairman" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1">نام تعمیرکار </span>
                    <span *ngIf="lang == 2">Repairman name</span>
                    <span (click)="reset_input(220,1)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220,1)" readonly
                        [required]="required() && ref == 'reception'">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly
                        [required]="required() && ref == 'reception'" [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}"
                [hidden]="ref == 'reception' || level == 3">
                <label for="seller" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> نام فروشنده </span>
                    <span *ngIf="lang == 2">Salesman name</span>
                    <span (click)="reset_input(220,2)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="seller_title" (click)="load_services(220,2)" readonly
                        [required]="required() && ref == 'invoice'" [attr.disabled]="level == 3 ? true : null">
                    <input class="wh-input" formControlName="seller" [hidden]="true" readonly
                        [required]="required() && ref == 'invoice'" [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [hidden]="level == 3">
                <label for="profit" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> درصد سود</span>
                    <span *ngIf="lang == 2">Profit commission</span>
                    <input class='wh-input' formControlName="profit" autocomplete="off" currencyMask required>
                </label>
            </div>


            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="comment" class='wh-label' [ngClass]="{'full-left-padding0':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> توضیحات : </span>
                    <span *ngIf="lang == 2">Description</span>
                    <textarea style="height:10rem" class="wh-input" formControlName="comment"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 1">ذخیره</span>
            <span *ngIf="lang == 2">Save</span>
        </button>
    </div>
</form>