import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-list-accounts-detaile',
  templateUrl: './user-list-accounts-detaile.component.html',
  styleUrls: ['./user-list-accounts-detaile.component.scss']
})
export class UserListAccountsDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1: FormGroup | any;
  public user_id: number | any;
  public ref: string | undefined
  public result: any | undefined;
  public gropus: number = 0;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public list_service_select: any = [];
  public creator: number | undefined;
  public level: number;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public default_level2 = "255,252,242,161,221,231,223,231,230,232,226,227,225,222,201,197,195,173,228,229,169,167,164,163,160,157,154,207,205,199,171,219,253,10,155,212,235,210,209,204,203,202,198,224,192,181,178,177,228,193";
  public default_repairman = "255,242,161,230,232,221,223,231,225,226,227,222,207,205,204,203,202,201,199,198,197,195,194,224,192,188,185,181,179,178,177,175,173,228,229,172,171,170,169,167,164,163,162,160,157,154,10,155,212,235,210,209,228,193";
  public default_repairman2 = "255,242,219,161,230,232,221,223,231,225,226,227,222,207,205,204,203,202,201,199,198,197,195,194,224,192,188,185,181,179,178,177,175,173,228,229,172,171,170,169,167,164,163,162,160,157,154,10,155,212,235,210,209,228,193";
  public default_colleague = "255,242,221,161,223,231,225,226,227,222,207,205,204,203,202,199,198,197,194,224,192,188,185,181,179,178,177,173,228,229,171,170,169,167,160,157,228,193";


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<UserListAccountsDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.level = dialog_data.level;
      this.create_form1();
      this.id = dialog_data.id;
      this.creator = dialog_data.creator;
      this.ref = dialog_data.ref;
      this.code = dialog_data.code;
      this.gropus = dialog_data.gropus;
      this.i = dialog_data.i;

      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1.patchValue({
          title: dialog_data.search_array.title,
          group_title: dialog_data.search_array.group_title,
          group: dialog_data.search_array.group,
          sex_title: dialog_data.search_array.sex_title,
          sex: dialog_data.search_array.sex,
          cellphone: dialog_data.search_array.cellphone,
          phone: dialog_data.search_array.phone,
          user: dialog_data.search_array.user,
          email: dialog_data.search_array.email,
          code_meli: dialog_data.search_array.code_meli,
          economic_code: dialog_data.search_array.economic_code,
          code_posti: dialog_data.search_array.code_posti,
          address: dialog_data.search_array.address1,
          comment: dialog_data.search_array.comment,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
          state_title: dialog_data.search_array.state_title,
          state: dialog_data.search_array.state,
          store: dialog_data.search_array.store,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1 = new FormGroup({
      'title': new FormControl(null),
      'store': new FormControl(null),
      'sex_title': new FormControl(null),
      'sex': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'state_title': new FormControl(null),
      'state': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user': new FormControl(null),
      'code_meli': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'code_posti': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'economic_code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'email': new FormControl(null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      'cellphone': new FormControl(null),
      'group': new FormControl(null),
      'group_title': new FormControl(null),
      'phone': new FormControl(null, [Validators.pattern('[0-9-]{1,}')]),
      'status_id': new FormControl(null),
      'status_title': new FormControl(null),
      'address': new FormControl(''),
      'comment': new FormControl(null),
      'user_date': new FormControl(null),
    });
    if (this.type_task == 1 || this.type_task == 2) {
      this.form1.controls['sex'].setValidators([Validators.required]);
      this.form1.controls['title'].setValidators([Validators.required]);
      this.form1.controls['group'].setValidators([Validators.required]);
      this.form1.controls['status_id'].setValidators([Validators.required]);
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.title = res['result'][0].title;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          //this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.get_status();
            this.get_default_group();
            this.get_default_sex();
          }
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_number(event) {
    this.serverService.get_number(event)
  }

  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1.value.title,
      group_title: this.form1.value.group_title,
      group: this.form1.value.group,
      sex_title: this.form1.value.sex_title,
      sex: this.form1.value.sex,
      cellphone: this.form1.value.cellphone,
      phone: this.form1.value.phone,
      user: this.form1.value.user,
      email: this.form1.value.email,
      code_meli: this.form1.value.code_meli,
      economic_code: this.form1.value.economic_code,
      code_posti: this.form1.value.code_posti,
      address1: this.form1.value.address,
      comment: this.form1.value.comment,
      status_id: this.form1.value.status_id,
      status_title: this.form1.value.status_title,
      state: this.form1.value.state,
      state_title: this.form1.value.state_title,
      store: this.form1.value.store,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6742, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            'title': res['result'][0].user_title,
            'store': res['result'][0].user_store,
            'sex_title': res['result'][0].user_sex_title,
            'sex': res['result'][0].user_sex_id,
            'state_title': res['result'][0].site_state_title,
            'state': res['result'][0].site_state_id,
            'email': res['result'][0].user_email,
            'code_meli': res['result'][0].user_code_meli,
            'economic_code': res['result'][0].user_economic_code,
            'code_posti': res['result'][0].user_code_posti,
            'cellphone': res['result'][0].user_cellPhone,
            'phone': res['result'][0].user_phone,
            'address': res['result'][0].user_address,
            'comment': res['result'][0].user_comment,
            'group': res['result'][0].user_groups_id,
            'group_title': res['result'][0].user_groups_title,
            'status_id': res['result'][0].status_id,
            'status_title': res['result'][0].status_title,
          }
          );
          this.list_service_select = res['result'][0].user_services;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6740
      , "user_id": this.user_id
      , 'title': this.form1.value.title
      , 'state': this.form1.value.state
      , 'store': this.form1.value.store
      , 'group': this.form1.value.group
      , 'sex': this.form1.value.sex
      , 'cellphone': this.form1.value.cellphone
      , 'status_id': this.form1.value.status_id
      , 'phone': this.form1.value.phone
      , 'user': this.form1.value.user
      , 'email': this.form1.value.email
      , 'code_meli': this.form1.value.code_meli
      , 'economic_code': this.form1.value.economic_code
      , 'code_posti': this.form1.value.code_posti
      , 'comment': this.form1.value.comment
      , 'address1': this.form1.value.address
      , 'creator': this.creator
      , 'level': this.level
      , 'list_service_select': this.list_service_select
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.serverService.send_reception_repairman();
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.serverService.send_group();
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "نام کاربری و شماره همراه باید منحصر به فرد باشد";
          var en_message = "Username and mobile number must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update() {
    var obj = {
      'address': 6741
      , "user_id": this.user_id
      , "lang": this.lang
      , 'title': this.form1.value.title
      , 'store': this.form1.value.store
      , 'group': this.form1.value.group
      , 'sex': this.form1.value.sex
      , 'state': this.form1.value.state
      , 'cellphone': this.form1.value.cellphone
      , 'status_id': this.form1.value.status_id
      , 'phone': this.form1.value.phone
      , 'user': this.form1.value.user
      , 'email': this.form1.value.email
      , 'code_meli': this.form1.value.code_meli
      , 'economic_code': this.form1.value.economic_code
      , 'code_posti': this.form1.value.code_posti
      , 'comment': this.form1.value.comment
      , 'address1': this.form1.value.address
      , 'id': this.id
      , 'creator': this.creator
      , 'list_service_select': this.list_service_select
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.serverService.send_group();
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "نام کاربری باید منحصر به فرد باشد";
          var en_message = "Username must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  //************************************************************************************************ */
  get_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_default_sex() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1733, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            sex: res['result'][0].user_sex_id,
            sex_title: res['result'][0].user_sex_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_default_group() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1972, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1.patchValue({
              group: res['result'][0].user_groups_id,
              group_title: res['result'][0].user_groups_title,
            });
            if (res['result'][0].user_groups_colleague == 1) {
              this.list_service_select = this.default_colleague;
            }
            else if (res['result'][0].user_groups_repairman == 1) {
              this.list_service_select = this.default_repairman;
            }
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_user_group(id: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6209, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            group: res['result'][0].user_groups_id,
            group_title: res['result'][0].user_groups_title,
          });

          this.list_service_select = '';
          if (res['result'][0].user_groups_colleague == 1) {
            this.list_service_select = this.default_colleague;
          }
          if (res['result'][0].user_groups_repairman == 1) {
            this.list_service_select = this.default_repairman;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_services(id: number): any {
    var where;
    var code_id;
    if (id == 155) {
      code_id = 6470;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      data: {
        id: id, where: where, creator: this.creator, code_id: code_id
        , obj: { level: this.level }
      }
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (id == 155) {
            this.form1.patchValue({
              'group_title': result.title,
              'group': result.id
            });
            this.get_user_group(result.id);
          }
          if (id == 26) {
            this.form1.patchValue({
              'status_title': result.title,
              'status_id': result.id
            })
          }
          if (id == 10) {
            this.form1.patchValue({
              'sex_title': result.title,
              'sex': result.id
            })
          }
          if (id == 233) {
            this.form1.patchValue({
              'state_title': result.title,
              'state': result.id
            })
          }
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 155) {
      this.form1.patchValue({
        'group_title': null,
        'group': null
      })
    }
    if (id == 26) {
      this.form1.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 10) {
      this.form1.patchValue({
        'sex_title': null,
        'sex': null
      })
    }
    if (id == 233) {
      this.form1.patchValue({
        'state_title': null,
        'state': null,
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}