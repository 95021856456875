
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';

@Component({
  selector: 'app-user-service-detaile',
  templateUrl: './user-service-detaile.component.html',
  styleUrls: ['./user-service-detaile.component.scss']
})
export class UserServiceDetaileComponent implements OnInit, OnDestroy {
  public isOnline: any;
  public loading = false;
  public subscription: Subscription;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any;
  public server_main: string = this.serverService.get_server_main();
  lang: any;
  type_task: number | undefined;
  form1_group: FormGroup | any;
  user_info: any;
  user_id: number | any;
  id: number | undefined;
  i: number | undefined;
  code: number | undefined;
  code1: boolean = false;
  title: string | undefined;
  search_array: any = [];

  type_file_uploaded: string;
  duration: any = "00:00:00";
  selectedFile: File;
  format: string;
  loaded: number = 0;
  volumn_loaded: any = 0;
  volumn: any;
  reader: any | undefined;
  current_site: any;
  site: string = this.serverService.get_site();

  video: any = [];
  video_stop: boolean = false;
  video_url: any;
  video_width: any;
  video_poster_list: any = [];
  video_poster_list_temp: any = [];
  video_poster: string;
  video_poster_default: number = 0;
  result: any | undefined;
  ref: string | undefined;
  creator: number | undefined;

  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog
    , private matDialogRef: MatDialogRef<UserServiceDetaileComponent>, private http: HttpClient) {
    this.server = this.serverService.get_server();
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      this.code1 = dialog_data.code1;
      this.i = dialog_data.i;
      this.ref = dialog_data.ref;

      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.check_access(6, 1);
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
          sgroup: dialog_data.search_array.sgroup,
          sgroup_title: dialog_data.search_array.sgroup_title,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.lang = JSON.parse(<any>localStorage.getItem('lang'));
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'sgroup_title': new FormControl(null),
      'sgroup': new FormControl(null, [Validators.pattern('[0-9]{1,}')])
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required])
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }

  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0 && this.code1 == false) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.title = res['result'][0].title;
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) { this.get_data(); }
            if (type_task == 5) { this.get_status(); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      sgroup: this.form1_group.value.sgroup,
      sgroup_title: this.form1_group.value.sgroup_title,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6202, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].site_services_title,
            'sort': res['result'][0].site_services_sort,
            'status_id': res['result'][0].site_services_status,
            'status_title': res['result'][0].status_title,
            'sgroup_title': res['result'][0].site_software_title,
            'sgroup': res['result'][0].site_software_id,
          }
          );

          if (res['result'][0].site_services_path_video) {
            this.video_url = res['result'][0].site_services_site_video + "/" + res['result'][0].site_services_path_video + "/" + res['result'][0].site_services_filename_video;
            this.video.push({
              site: res['result'][0].site_services_site_video,
              path: res['result'][0].site_services_path_video,
              filename: res['result'][0].site_services_filename_video
            });
            this.video_stop = true;
            this.video_width = 0;
          }


          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6198, "user_id": this.user_id, 'status_id': this.form1_group.value.status_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'sgroup': this.form1_group.value.sgroup
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6199, "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'status_id': this.form1_group.value.status_id
      , 'id': this.id
      , 'video': this.video
      , 'video_duration': this.duration
      , 'sgroup': this.form1_group.value.sgroup
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update

  get_status() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1064 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  load_services(id: number) {
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
          if (id == 215) {
            this.form1_group.patchValue({
              'sgroup': res.id,
              'sgroup_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 215) {
      this.form1_group.patchValue({
        'sgroup': null,
        'sgroup_title': null
      })
    }
  }
  //******************************************************************************************************* */
  //************************************************************************************************** */
  open_upload() {
    var x = <any>document.getElementById("uploadButton");
    x.click();
  }

  onFileSelected(event: any, back_status_message: any) {
    this.selectedFile = <File>event.target.files[0];
    if (this.selectedFile) {
      var reader: any = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      this.format = 'video'
    }
    this.reader = reader;
    this.upload_file(reader, back_status_message);
  }

  upload_file(reader, back_status_message) {
    reader.onload = (event) => {
      this.video_url = (<FileReader>event.target).result;
    }
    var fd = new FormData();
    fd.append('video', this.selectedFile, this.selectedFile.name);
    this.subscription = this.http.post(this.server_main + "/uploadFilm", fd, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      (events: any) => {
        this.video_stop = true;
        if (events.type === HttpEventType.UploadProgress) {
          this.video_width = 100 - (events.loaded / events.total * 100) + '%';
          this.loaded = Math.floor(events.loaded / events.total * 100 - 1);
          this.volumn = parseFloat(String(events.total / 100 / 1024 / 1024 * 100)).toFixed(2);
          this.volumn_loaded = parseFloat(String(events.loaded / 100 / 1024 / 1024 * 100)).toFixed(2);
        }
        else if (events.type === HttpEventType.Response) {
          if (events && events.status == 200) {
            events.body.result.site = this.serverService.get_site_upload_video_main();
            events.body.result.path = this.serverService.get_path_upload_video();
            this.video.push(events.body.result);
            this.loaded = 100;
            this.type_file_uploaded = events.body.result.fieldname;
            var x: any = document.getElementById(back_status_message)
            x.innerHTML = "ویدئوی شما آماده ی انتشار می باشد";
          }
        }
      }
    )
  }

  video_stop_upload() {
    this.subscription.unsubscribe();
    this.video_stop = false;
    this.loaded = 0;
    this.video_poster_list = [];
    this.video_poster_default = 0;
    this.video = [];
    this.duration = '00:00:00';
    this.form1_group.patchValue({
      video_title: '',
      video_keyword: ''
    })
  }

  video_get_posters(result) {
    this.video_poster_list = [];
    if (result.site_lcourse_video_convas1 || result.id == 1) { this.video_poster_list.push({ id: 1, modify: 0, src: result.site_lcourse_video_convas1 }) }
    if (result.site_lcourse_video_convas2 || result.id == 2) { this.video_poster_list.push({ id: 2, modify: 0, src: result.site_lcourse_video_convas2 }) }
    if (result.site_lcourse_video_convas3 || result.id == 3) { this.video_poster_list.push({ id: 3, modify: 0, src: result.site_lcourse_video_convas3 }) }
    this.video_poster_list_temp = this.video_poster_list;
    this.video_get_poster_default(0, this.video_poster_list, result.site_lcourse_video_poster_default);
  }

  video_get_poster_default(poster_id, result, video_poster_default) {
    if (poster_id == 0) {
      this.video_poster_default = video_poster_default;
      if (video_poster_default == 1) this.video_poster = this.site + "/" + result[0].src;
      if (video_poster_default == 2) this.video_poster = this.site + "/" + result[1].src;
      if (video_poster_default == 3) this.video_poster = this.site + "/" + result[2].src;
    }
    else {
      this.video_poster_default = poster_id;
      this.video_poster = this.site + "/" + result[poster_id - 1].src;
    }
  }

  video_save_poster_default(i, poster_id) {
    if (this.serverService.check_internet()['status'] == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1001, poster_id: poster_id, id: this.id, code: this.code }).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.video_poster_default = res['result'][0].site_lcourse_video_poster_default;
          this.video_get_poster_default(poster_id, this.video_poster_list, 0);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  video_delete_posters(type) {
    if (this.serverService.check_internet()['status'] == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1002, id: this.id, code: this.code }).subscribe(
      (res) => {
        if (res['status'] == 1) {
          if (type == 2) {
            this.matDialogRef.close(this.result);
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "گویا خطایی رخ داده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  video_get_insert_posters() {
    /*
    const dialogRef = this.dialog.open(MoviePosterComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: this.id, video_url: this.video_url, code: this.code, video_poster_list: this.video_poster_list, video_poster_default: this.video_poster_default }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.video_poster_list = [];
        for (var i = 0; i < res.result.length; i++) {
          this.video_poster_list.push({ id: res.result[i].id, src: res.result[i].src, modify: res.result[i].modify });
          if (this.video_poster_list_temp[i]) this.video_poster_list_temp[i].modify = res.result[i].modify;
        }

        this.video_get_poster_default(0, res.result, this.video_poster_default);
      }
    )
    */
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
