import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-rstatus-detaile',
  templateUrl: './rstatus-detaile.component.html',
  styleUrls: ['./rstatus-detaile.component.scss']
})
export class RstatusDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));

  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public title2: string | undefined;
  public search_array: any = [];
  public logo_bin: boolean = false;
  public status_logo: string = this.serverService.get_default_image();
  public logo_info: any;
  public uploadedAvaterProgess: any;
  public seleted_groups: any;
  public creator: number | undefined;
  public expire: boolean | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<RstatusDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.title2 = dialog_data.title2;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.get_status();
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          title2: dialog_data.search_array.title2,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
        });
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'title2': new FormControl(null),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')])
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required])
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }

  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            this.expire = res['expire'];
            if (type_task == 1) {
              if (this.expire == true) {
                this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
                return false;
              } else {
                this.insert();
              }
            }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      title2: this.form1_group.value.title2,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1681, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].repairman_rstatus_title,
            'title2': res['result'][0].repairman_rstatus_title2,
            'sort': res['result'][0].repairman_rstatus_sort,
            'status_id': res['result'][0].repairman_rstatus_status,
            'status_title': res['result'][0].status_title,
          });
          if (res['result'][0].repairman_rstatus_logo) {
            this.status_logo = res['result'][0].repairman_rstatus_site_logo + "/" + res['result'][0].repairman_rstatus_logo;
            this.logo_bin = true;
          } else {
            this.status_logo = this.serverService.get_default_image();
            this.logo_bin = false;
          }
          this.logo_info = {
            site: res['result'][0].repairman_rstatus_site_logo,
            path: res['result'][0].repairman_rstatus_logo
          };
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1677, "user_id": this.user_id, 'status_id': this.form1_group.value.status_id
      , 'title': this.form1_group.value.title
      , 'title2': this.form1_group.value.title2
      , 'sort': this.form1_group.value.sort
      , 'logo_info': this.logo_info
      , seleted_groups: this.seleted_groups
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          );
          this.serverService.send_status();
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1678, "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'title2': this.form1_group.value.title2
      , 'sort': this.form1_group.value.sort
      , 'status_id': this.form1_group.value.status_id
      , 'logo_info': this.logo_info
      , seleted_groups: this.seleted_groups
      , 'creator': this.creator
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          );
          this.serverService.send_status();
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  get_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1064 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_services(id: number) {

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
        }
      }
    )

  }


  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
  }
  //************************************************************************* */
  change_logo(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.status_logo = this.serverService.get_site() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.logo_bin = true;
          this.logo_info = {
            'site': this.serverService.get_site(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }

  delete_logo() {
    this.status_logo = this.serverService.get_default_image();
    this.logo_bin = false;
    this.logo_info = {
      path: '',
      site: ''
    }
  }
  //**************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
