<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span>کالا</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="group" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه: </span>
                    <span *ngIf="lang == 2"> Group : </span>
                    <span (click)="reset_input(223,0)" [hidden]="readonly == true || true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223,0) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="group" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="cate" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه: </span>
                    <span *ngIf="lang == 2"> Subgroup : </span>
                    <span (click)="reset_input(184,0)" [hidden]="readonly == true || true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184,0) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="cate" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> نام کالا: </span>
                    <span *ngIf="lang == 2"> Product name : </span>
                    <span (click)="reset_input(171,0)" [hidden]="readonly == true || true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(171,0) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="material" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }">
                <label for="number" class='wh-label'>
                    <span *ngIf="lang == 1"> تعداد : </span>
                    <span *ngIf="lang == 2"> Number : </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" [required]="required()"
                        pattern="[0-9]{1,}" currencyMask>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }"
                *ngIf="document_type_text == 'exit' && back == 0">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت خرید : </span>
                    <span *ngIf="lang == 2"> Purchase price : </span>
                    <input class='wh-input' formControlName="price" autocomplete="off" currencyMask pattern="[0-9]{1,}"
                        [attr.disabled]="true">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }" [hidden]="ref2 == 'requests'">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت فروش : </span>
                    <span *ngIf="lang == 2"> Selling price : </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask
                        [required]="required()" pattern="[0-9]{1,}">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref2 == 'requests',en:lang==2 }" [hidden]="ref2 == 'requests'">
                <label for="discount" class='wh-label'>
                    <span *ngIf="lang == 1"> تخفیف : </span>
                    <span *ngIf="lang == 2"> Discount : </span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask
                        pattern="[0-9]{1,}">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="ref == 'invoice'">
                <label for="repairman" class='wh-label'>
                    <span *ngIf="lang == 1">نام تعمیرکار : </span>
                    <span *ngIf="lang == 2"> Repairman name : </span>
                    <span (click)="reset_input(220,1)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220,1)" readonly
                        [required]="required() && ref == 'reception'">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly
                        [required]="required() && ref == 'reception'">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="ref == 'reception' || document_type_text == 'input'">
                <label for="seller" class='wh-label'>
                    <span *ngIf="lang == 1"> نام فروشنده : </span>
                    <span *ngIf="lang == 2"> Salesman name : </span>
                    <span (click)="reset_input(220,2)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="seller_title" (click)="load_services(220,2)" readonly
                        [required]="required() && ref == 'invoice' && document_type_text == 'exit' && back == 0">
                    <input class="wh-input" formControlName="seller" [hidden]="true" readonly
                        [required]="required() && ref == 'invoice' && document_type_text == 'exit' && back == 0">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{ width100:ref == 'invoice' || ref2 == 'requests',en:lang==2 }" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات : </span>
                    <span *ngIf="lang == 2"> Description : </span>
                    <textarea style="height:10rem" class="wh-input" formControlName="comment"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2" [hidden]="readonly == true">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
            [hidden]="readonly == true">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
            (click)="check_access(type_task,2)">
            <span *ngIf="lang == 2">Export Excel file</span>
            <span *ngIf="lang == 1">خروجی اکسل</span>
        </button>
    </div>
</form>