<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">معرفی حساب</span>
        <span *ngIf="lang == 2">Add bank account</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> نام دارنده حساب </span>
                    <span *ngIf="lang == 2"> Account holder </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="required()"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="branch_title" class='wh-label'>
                    <span *ngIf="lang == 1"> نام شعبه </span>
                    <span *ngIf="lang == 2"> Branch name </span>
                    <input class='wh-input' formControlName="branch_title" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="branch_code" class='wh-label'>
                    <span *ngIf="lang == 1"> کد شعبه </span>
                    <span *ngIf="lang == 2"> Branch code </span>
                    <input class='wh-input' formControlName="branch_code" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="number" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره حساب </span>
                    <span *ngIf="lang == 2"> Account number </span>
                    <input class='wh-input center' formControlName="number" autocomplete="off"
                        (keypress)="get_account($event)" [required]="required()" length=25 maxLength="25"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cart" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره کارت </span>
                    <span *ngIf="lang == 2"> Card number </span>
                    <input class='wh-input center' formControlName="cart" autocomplete="off" (keypress)="cart($event)"
                        style="direction: ltr;" length=19 maxLength="19" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="sheba" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره شبا </span>
                    <span *ngIf="lang == 2"> IBAN number </span>
                    <div>
                        <span style="float:left;padding-top:9px" *ngIf="lang == 1">IR</span>
                        <input class='wh-input center' formControlName="sheba" autocomplete="off"
                            style="width:93%;direction: ltr;" (keypress)="sheba($event)" length=30 maxLength="30"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </div>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="balance" class='wh-label'>
                    <span *ngIf="lang == 1"> موجودی حساب </span>
                    <span *ngIf="lang == 2"> Account balance </span>
                    <input class='wh-input center' formControlName="balance" autocomplete="off" style="direction: ltr;"
                        currencyMask required [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت </span>
                    <span *ngIf="lang == 2"> Status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()" readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> آدرس </span>
                    <span *ngIf="lang == 2">Address </span>
                    <div>
                        <input class='wh-input' formControlName="branch_address" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </div>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>