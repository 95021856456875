<div class="my-container" [hidden]="!loading">
    <mat-progress-bar mode="indeterminate" style="position:relative !important"></mat-progress-bar>
</div>


<div mat-dialog-title class="matDialogTitle">
    دسترسی گروهها به {{ title }}
    <span>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent pe1" style="padding:0px !important" [ngClass]="{en1:lang==2}"
    style="width:100%">
    <div class="row">
        <div class='col-md-12 col-xl-12 col-lg-12  col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}"
            *ngFor="let x of list_groups;let i=index" [hidden]="x.user_groups_id == 1">
            <mat-checkbox value="{{ x.user_groups_id}}" name="groups[]" (change)="select_groups()"
                [checked]="x.checked"></mat-checkbox>
            <span class="span1">{{ x.user_groups_title }} </span>
        </div>
    </div>
</div>

<div mat-dialog-actions class="matDialogActions pe1"  [ngClass]="{en1:lang==2}">
 <button class="btn btn_pe" (click)="check_access(2)">
   ذخیره
 </button>
</div>