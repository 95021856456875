<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span> {{ title }} </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video && ref == 'menu'" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <mat-tab-group dynamicHeight [ngStyle]="{'direction':dir == 'ltr' ? 'ltr' : 'rtl'}">
            <mat-tab label="{{ tab1 }}" [ngStyle]="{'direction':dir == 'ltr' ? 'ltr' : 'rtl'}">
                <div class="row pe" style="padding:0 1rem">

                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding' style='margin-top:5px'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="title" class='wh-label'>
                            <span *ngIf="lang == 1">نام طرف حساب</span>
                            <span *ngIf="lang == 2">Account holder</span>
                            <input class='wh-input' formControlName='title' autocomplete="off" [required]="required()"
                                [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'>
                        <label for="group" class='wh-label'>
                            <span *ngIf="lang == 1"> گروههای کاربری </span>
                            <span *ngIf="lang == 2"> User groups </span>
                            <span (click)="reset_input(155)" [hidden]="code == 219 ">
                                <mat-icon class="close" class="input_search input_search_pe"
                                    [ngClass]="{input_search_en:lang==2}">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='group_title' autocomplete="off"
                                (click)="load_services(155)" [required]="required()" readonly
                                [ngClass]="{'en':dir == 'ltr'}">
                            <input class='wh-input' formControlName='group' autocomplete="off" [required]="required()"
                                [hidden]="true" readonly>
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'>
                        <label for="login_service_title" class='wh-label'>
                            <span *ngIf="lang == 1"> سرویس ورود </span>
                            <span *ngIf="lang == 2"> Login service </span>
                            <span (click)="reset_input(213)">
                                <mat-icon class="close" class="input_search input_search_pe"
                                    [ngClass]="{input_search_en:lang==2}">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='login_service_title' autocomplete="off"
                                (click)="load_services(213)" [required]="required()" readonly
                                [ngClass]="{'en':dir == 'ltr'}">
                            <input class='wh-input' formControlName='login_service' autocomplete="off"
                                [required]="required()" [hidden]="true" readonly>
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-3  col-xs-12 pe div_panding' style='margin-top:5px'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="cellphone" class='wh-label'>
                            <span *ngIf="lang == 1"> شماره همراه</span>
                            <span *ngIf="lang == 2"> Cellphone number </span>
                            <input class='wh-input' formControlName='cellphone' autocomplete="off"
                                (keypress)="serverService.get_number($event)" minlength="11" maxlength="11" length="11"
                                [required]="required()" [attr.disabled]="level == 3 ? true : null"
                                [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-3  col-xs-12 pe div_panding' style='margin-top:5px'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="user" class='wh-label'>
                            <span *ngIf="lang == 1">نام کاربری</span>
                            <span *ngIf="lang == 2"> Username </span>
                            <input class='wh-input' formControlName='user' autocomplete="off"
                                [ngClass]="{'en':dir == 'ltr'}" [required]="required()">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding' style='margin-top:5px'
                        [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="type_task == 3">
                        <label for="password" class='wh-label'>
                            <span *ngIf="lang == 1">رمز عبور</span>
                            <span *ngIf="lang == 2"> Password </span>
                            <input type="text" class='wh-input' formControlName='password'
                                [ngClass]="{'en':dir == 'ltr'}" [required]="required()">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'>
                        <label for="profit_sale" class='wh-label'>
                            <span class="input-info" [matMenuTriggerFor]="infoProfitSale"><i
                                    class="fas fa-info-circle icon"></i></span>
                            <span *ngIf="lang == 1" class="input-info-text"> درصد سود فروش </span>
                            <span *ngIf="lang == 2" class="input-info-text"> Sales profit commission </span>
                            <input class='wh-input' formControlName='profit_sale' maxlength="3" min=0 max=100
                                [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>
                    <mat-menu #infoProfitSale="matMenu" class="input-mat-menu-panel input-user-mat-menu-panel2"
                        XPosition="after">
                        باید عدد ورودی بین 0 تا 100 باشد.
                        هر عددی را به عنوان مثال 35 قرار دهید یعنی 35 درصد از
                        سود فروش به
                        {{ sex_title }} {{ form1.get('title').value }}
                        تعلق می گیرد
                    </mat-menu>

                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'>
                        <label for="profit_repairman" class='wh-label'>
                            <span class="input-info" [matMenuTriggerFor]="infoProfitRepairman"><i
                                    class="fas fa-info-circle icon"></i></span>
                            <span class="input-info-text" *ngIf="lang == 1"> درصد سود تعمیرات</span>
                            <span class="input-info-text" *ngIf="lang == 2"> Repair profit commission </span>
                            <input class='wh-input' formControlName='profit_repairman' maxlength="3" min=0 max=100
                                [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>
                    <mat-menu #infoProfitRepairman="matMenu" class="input-mat-menu-panel input-user-mat-menu-panel2"
                        XPosition="after">
                        باید عدد ورودی بین 0 تا 100 باشد.
                        هر عددی را به عنوان مثال 35 قرار دهید یعنی 35 درصد از
                        سود تعمیرات به
                        {{ sex_title }} {{ form1.get('title').value }}
                        تعلق می گیرد
                    </mat-menu>
                </div><!-- row -->
            </mat-tab>
            <mat-tab label="{{ tab2 }}">
                <div class="row" style="padding-top:1em">

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="admin" class='wh-label'>
                            <mat-checkbox formControlName='admin' style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1">آیا کاربر به نرم افزار می تواند ورود کند؟</span>
                            <span *ngIf="lang == 2">login to software</span>
                        </label>
                    </div>


                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_change_in_invoice" class='wh-label'>
                            <mat-checkbox formControlName='access_change_in_invoice'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند آیتم های فاکتور انبار را تغییر بدهد؟</span>
                            <span *ngIf="lang == 2"> Can user change items of warehouse invoice? </span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_change_in_repairman" class='wh-label'>
                            <mat-checkbox formControlName='access_change_in_repairman'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند آیتم های فاکتور پذیرش را تغییر بدهد؟</span>
                            <span *ngIf="lang == 2"> Can user change items of reception invoice? </span>
                        </label>
                    </div>
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'
                        *ngIf="type_task != 3 && Presentable==true">
                        <label for="user_can_only_see_his_own_list" class='wh-label'>
                            <mat-checkbox formControlName='user_can_only_see_his_own_list'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1">تعمیرکار فقط می تواند دستگاههای تعمیری مربوط به پنل خودش را
                                ببینید</span>
                            <span *ngIf="lang == 2"> The repairman can see the repair devices related to his panel
                            </span>
                        </label>
                    </div>
             
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'
                        *ngIf="type_task != 3 && Presentable==true">
                        <label for="sending_sms_when_acceptance_of_the_new_device" class='wh-label'>
                            <mat-checkbox formControlName='sending_sms_when_acceptance_of_the_new_device'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1">ارسال پیامک هنگام ثبت پذیرش دستگاه برای این تعمیرکار</span>
                            <span *ngIf="lang == 2"> Send an SMS when acceptance of the new device for this
                                repairman</span>
                        </label>
                    </div>
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_finance_in_invoice" class='wh-label'>
                            <mat-checkbox formControlName='access_finance_in_invoice'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند درج مالی در فاکتور داشته باشد؟</span>
                            <span *ngIf="lang == 2"> Can user add financial entries in the invoice </span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_invoice_sale" class='wh-label'>
                            <mat-checkbox formControlName='access_invoice_sale'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند فاکتور فروش را ببیند؟</span>
                            <span *ngIf="lang == 2">Can user see the sales invoice?</span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_invoice_buy" class='wh-label'>
                            <mat-checkbox formControlName='access_invoice_buy'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند فاکتور خرید را ببیند؟ </span>
                            <span *ngIf="lang == 2"> Can user see the purchase invoice? </span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_price_column_buy" class='wh-label'>
                            <mat-checkbox formControlName='access_price_column_buy'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1">آیا ستون قیمت خرید هنگام صدور فاکتور فروش نمایش داده شود؟
                                (برای کاربرانی که دسترسی به فاکتور خرید ندارند)
                            </span>
                            <span *ngIf="lang == 2">
                                Should purchase price column be visible while issuing sales invoice?
                                (for users who do not have access to purchase invoice)
                            </span>
                        </label>
                    </div>


                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="access_invoice_return_buy" class='wh-label'>
                            <mat-checkbox formControlName='access_invoice_return_buy'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند فاکتور برگشت از خرید را ببیند؟</span>
                            <span *ngIf="lang == 2"> Can user see purchase return invoice? </span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="show_cellphone" class='wh-label'>
                            <mat-checkbox formControlName='show_cellphone'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1"> آیا کاربر می تواند شماره موبایل ها را ببیند؟</span>
                            <span *ngIf="lang == 2"> Can user see cellphone numbers? </span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px' *ngIf="type_task != 3">
                        <label for="login_with_sms" class='wh-label'>
                            <mat-checkbox formControlName='login_with_sms'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span *ngIf="lang == 1">فعال سازی تایید دو مرحله ای از طریق پیامک</span>
                            <span *ngIf="lang == 2">Enable two-step verification via SMS</span>
                        </label>
                    </div>

                </div>

            </mat-tab>
            <mat-tab label="{{ tab3 }}">
                <div class='col-md-3 col-lg-3 col-sm-3 col-xs-12 pe'
                    [ngClass]="{'full-left':dir == 'ltr','width50':ref == 'menu'}" style="padding-top:5px"
                    *ngIf=" (ref == 'menu' || code == 219) && type_task != 3">
                    <span>
                        <span *ngIf="lang == 1">امضای</span>
                        <span *ngIf="lang == 2">Signature</span>
                        {{ form1.get('title').value }}</span>
                    <mat-card class="example-card logo cursor backgroundImage"
                        style="padding:3px 16px 7px 16px;width:100%;height:10rem"
                        [ngStyle]="{'background-image':'url( ' + sign_logo + ')'}" (click)="logo2.click()">
                        <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                        </mat-card-header>
                    </mat-card>
                    <span (click)="delete_sign_logo()" *ngIf="sign_logo_bin == true"><i class="far fa-trash-alt icon"
                            style="margin: 0px 4px;"></i></span>
                    <input type="file" (change)="change_sign_logo($event)" accept="image/*" style="display: none;"
                        #logo2>
                </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->
            </mat-tab>
            <mat-tab label="{{ tab4 }}">

                <div class="row">
                    <div class="row user_div1" *ngFor="let s of list_sgroup" style="padding-top:1rem"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 user_div2"
                            [ngClass]="{'full-left':dir == 'ltr'}">{{ s.site_software_title }}</div>
                        <span *ngFor="let x of list_sgroup_id[s.site_software_id]">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe service"
                                [ngClass]="{'full-left':dir == 'ltr','width50':ref == 'menu'}">
                                <mat-checkbox class="user-checkbox" [value]="x.site_services_id"
                                    (change)="change_select_service()" name="service[]" [checked]="x.checked">
                                </mat-checkbox>
                                {{ x.site_services_title }}
                            </div>
                        </span>
                    </div>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
        <button type="submit" [disabled]="!form1.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 1">ذخیره</span>
            <span *ngIf="lang == 2">Save</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 1">فیلتر</span>
            <span *ngIf="lang == 2">Filter</span>
        </button>
    </div><!-- row -->
</form>