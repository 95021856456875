import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServerService } from '../../service/server/server.service';
import { ModulesComponent } from '../../service/modules/modules.component';

@Component({
  selector: 'app-insert',
  templateUrl: './insert.component.html',
  styleUrls: ['./insert.component.scss']
})
export class InsertComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem("user_info"));
  public dir: any = JSON.parse(<any>localStorage.getItem("dir"));
  public user_id: number = this.user_info.user_id;
  public isOnline: any | undefined;
  public loading = false;
  public lang: any = localStorage.getItem("lang");
  public subscription: Subscription | any;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any = this.serverService.get_server();
  public device: number | undefined;
  public brand: number | undefined;
  public form1_group: FormGroup | any;
  public model: number | undefined;
  public creator: number | undefined;
  public service: string | undefined;
  public group: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<InsertComponent>) {

    if (dialog_data) {
      this.device = dialog_data.device;
      this.group = dialog_data.group;
      this.creator = dialog_data.creator;
      this.service = dialog_data.service;
    }

  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
  }

  create_form() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, Validators.required),
      'title2': new FormControl(null),
      'cost_group_title': new FormControl(null),
      'cost_group': new FormControl(null),
      'comment': new FormControl(null),
    })
  }

  save() {
    var address;
    if (this.service == 'brand') { this.insert_brand(); }
    if (this.service == 'device') { this.insert_device(); }
    if (this.service == 'device_status') { this.insert_device_status(); }
    if (this.service == 'cate') { this.insert_cate(); }
    if (this.service == 'group') { this.insert_group(); }
    if (this.service == 'income') { this.insert_income(); }
    if (this.service == 'cost') { this.insert_cost(); }
  }

  insert_brand(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6272
      , 'creator': this.creator
      , 'user_id': this.user_id
      , 'device': this.device
      , 'status_id': 1
      , 'title': this.form1_group.value.title
      , 'title2': this.form1_group.value.title2,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert_group(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6342, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert_cost(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6434, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title,
      cost_group: this.form1_group.value.cost_group,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert_income(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6428, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  insert_device(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6273, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert_device_status(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6465, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  insert_cate(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid) {
      return false;
    }
    var obj = {
      address: 6274, creator: this.creator, user_id: this.user_id, status_id: 1,
      title: this.form1_group.value.title, group: this.group
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.matDialogRef.close(res['result'][0]);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این عنوان قبلا در سیستم ثبت شده است";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  load_services(id: number) {

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 254) {
            this.form1_group.patchValue({
              'cost_group': res.id,
              'cost_group_title': res.title
            })
          }
        }
      }
    )
  }


  reset_input(id: number) {
    if (id == 254) {
      this.form1_group.patchValue({
        'cost_group': null,
        'cost_group_title': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
