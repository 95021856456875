<div class="row right-menu-row1" style="margin-top:1rem">
    <span *ngIf="list_groups.length > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" *ngFor="let x of list_groups"
            (click)="go_to_group(x.user_groups_id)" [routerLinkActive]="['activeStatus']"
            [ngClass]="{activeStatus:groups_id == x.user_groups_id,div1_en:dir == 'ltr'}">
            <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.user_groups_title }}</div>
            <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" (click)="get_moarf('my_moarf')"
            [routerLinkActive]="['activeStatus']" [ngClass]="{activeStatus:moarf == 'my_moarf',div1_en:dir == 'ltr'}"
            *ngIf="level == 1">
            <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">ست کنندگان کد معرف</div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" (click)="get_moarf('moarf_id')"
            [routerLinkActive]="['activeStatus']" [ngClass]="{activeStatus:moarf == 'moarf_id',div1_en:dir == 'ltr'}"
            *ngIf="level == 1">
            <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">استفاده کنندگان کد معرف</div>
        </div>
    </span>

    <span *ngIf="level != 1 && my_network == true">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
            [ngClass]="{activeStatus:netwok == 'profit','full-left':dir == 'ltr'}" (click)="get_my_netwok()">
            <span *ngIf="lang == 1">درآمد من از کد معرف</span>
            <span *ngIf="lang == 2">My commission from referral code</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
            (click)="get_my_consume_in_netwok()"
            [ngClass]="{activeStatus:netwok == 'consume','full-left':dir == 'ltr'}">
            <span *ngIf="lang == 1"> پرداخت های کیف پول </span>
            <span *ngIf="lang == 2"> Wallet payments</span>
        </div>
    </span>

    <span *ngIf="level == 1 && list_bascket_status.length > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" (click)="change_bascket(0)"
            [ngClass]="{activeStatus:bascket_status_id == 0,div1_en:dir == 'ltr'}">
            <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">
                <span *ngIf="lang == 1">موفق</span>
                <span *ngIf="lang == 2">successfully</span>
            </div>
            <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ count_bascket_status_all }}</span>

        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
            *ngFor="let x of list_bascket_status" (click)="change_bascket(x.bascket_status_id)"
            [ngClass]="{activeStatus:bascket_status_id == x.bascket_status_id,div1_en:dir == 'ltr'}">
            <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.bascket_status_title }}</div>
            <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
        </div>
    </span>

    <span *ngIf="(level == 2 || level == 3)">
        <ng-container *ngIf="level == 2">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                [ngClass]="{active2:report == 'rstatus'}" (click)="change_rstatus('rstatus')">
                وضعیتها</div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                [ngClass]="{active2:report == 'repairman'}" (click)="change_rstatus('repairman')">
                پنل تعمیرکاران</div>
        </ng-container>
        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
            [ngClass]="{active2:rstatus_report == true}" (click)="change_rstatus(true)" *ngIf="show_chart == true">
            گزارشات</div>
        <ng-container *ngIf="report == false">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                *ngFor="let x of list_rstatus" (click)="go_to_reception_with_repairman(x.repairman_rstatus_id)"
                [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:rstatus_id == x.repairman_rstatus_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}">
                <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.repairman_rstatus_title }}</div>
                <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
            </div>
        </ng-container> -->
        <div style="padding-top: 4rem;" *ngIf="loading2 == true">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <ng-container *ngIf="report == 'rstatus' && loading2 == false">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                *ngFor="let x of list_rstatus" (click)="go_to_reception_with_rstatus(x.repairman_rstatus_id)"
                [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:rstatus_id == x.repairman_rstatus_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}">
                <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.repairman_rstatus_title }}</div>
                <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="report == 'repairman'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                *ngFor="let x of list_repairman" [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:record_id == x.user_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}"
                (click)="go_to_reception_with_repairman(x.user_id)">
                <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.user_title }}</div>
                <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
            </div>
        </ng-container>
        <div (click)="change_show_chart()">.</div>
    </span>
</div>