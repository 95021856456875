
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../user/user-video/user-video.component';


@Component({
  selector: 'app-wharehouse-weight',
  templateUrl: './wharehouse-weight.component.html',
  styleUrls: ['./wharehouse-weight.component.scss']
})
export class WharehouseWeightComponent implements OnInit, OnDestroy {
  //**********************************************************
  public username: string | undefined;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  user_id: number | undefined;
  public server: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public type_task: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  sort: string | undefined;
  public show_excel: boolean = false;
  public export_code: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  @ViewChild('myModal', { static: true }) myModal: any;
  @ViewChild('myModalSearch', { static: true }) myModalSearch: any;
  public pattern_title_with_persian: any;
  //******************************************************************************
  public obj = {};
  public data = {};
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  search_all: string | undefined;
  //************
  selected_record: any = [];
  select_all: boolean | undefined;
  indeterminate: boolean = false;
  checked: boolean = false;
  video: string | undefined;
  creator: string | undefined;
  path_export: string | undefined;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  dataSource: any | undefined;
  displayedColumns = ['row', 'title', 'default', 'sort', 'status', 'id', 'operation'];
  //*************
  form1_group: FormGroup | any;
  form1_search_group: FormGroup | any;
  //************** */
  constructor(public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public router: Router, public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form1();
    this.create_search_form1();
    this.recieve_status();
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.required]),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_id': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')])
    })
  }

  create_search_form1() {
    this.form1_search_group = new FormGroup({
      'title_search': new FormControl(null),
      'status_id_search': new FormControl(null)
    })
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.title = res['result'][0].title;
            this.video = res['result'][0].video;
            this.path_export = res['path_export'];
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) {
              var selected;
              if (typeof id == 'object') {
                selected = this.get_select_records(-1);
              } else {
                var selected = id;
              }
              this.delete(selected);
            }
            if (type_task == 4) { this.load_data(page, excel, loading, -1); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
    }
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6185, "page": this.page
      , 'type': this.type, 'export_excel': excel, user_id: this.user_id
      , 'title': this.form1_search_group.value.title_search
      , 'status_id': this.form1_search_group.value.status_id_search
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'path_export': this.path_export
    }

    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.myModal.close();
            this.myModalSearch.close();
          }
          else if (excel == 2) {
            this.show_excel = true;
            this.export_code = res['export_code'];
          }
          this.serverService.change_window_resize(2, '', 0);
          this.serverService.send_services_id(this.code);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  change_default(j: number, value: number, id: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6210, user_id: this.user_id, 'status_default': value, 'id': id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (value == 0) {
            for (var i = 0; i < this.list_record.length; i++) {
              if (this.list_record[i].wharehouse_weight_default == 1) {
                this.list_record[i].wharehouse_weight_default = 0;
              }
            }
          }
          this, this.serverService.send_weight_title();
          this.list_record[j].wharehouse_weight_default = res['result'][0].wharehouse_weight_default;
          this.dataSource = new MatTableDataSource(this.list_record);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شما می توانید فقط یک گزینه را پیش فرض کنید";
          var pe_action = "بستن";
          this.recieve_message(true, 'You can only default to one option', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //***************************************************************************
  change_task(type: number, id: number, title: string, status_id: number, sort: string) {
    this.type_task = type;
    this.show_excel = false;
    this.id = id;
    if (type == 1) {
      this.form1_group.patchValue({
        'title': "",
        'sort': "",
        'status_id': 1,
      })
      this.type = 1;
      this.myModal.open();
    }//end if
    if (type == 2) {
      this.form1_group.patchValue({
        'title': title,
        'sort': sort,
        'status_id': status_id
      })
      this.myModal.open();
    }//end else
    if (type == 3) {
      this.type = 3;
      this.myModalSearch.open();
    }
  }//end change_task

  get_bg(id: number) {
    this.id = id;
  }
  //***************************************************************************
  insert() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6186, "user_id": this.user_id, 'status_id': this.form1_group.value.status_id, 'title': this.form1_group.value.title, 'sort': this.form1_group.value.sort, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_record.unshift(res['result'][0]);
          this.pages(1);
          if (this.count_total > 20) {
            this.list_record.pop();
            this.next_status = false;
          }//end if
          this.dataSource = new MatTableDataSource(this.list_record);
          this.myModal.close();
          this.id = res['result'][0].wharehouse_weight_id;
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان باید منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title must be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert
  //********************************************************************************
  update() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6187, "user_id": this.user_id, 'title': this.form1_group.value.title, 'sort': this.form1_group.value.sort, 'status_id': this.form1_group.value.status_id, 'id': this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < this.list_record.length; i++) {
            if (this.list_record[i].wharehouse_weight_id == this.id) {
              this.list_record[i].wharehouse_weight_title = res['result'][0].wharehouse_weight_title;
              this.list_record[i].wharehouse_weight_sort = res['result'][0].wharehouse_weight_sort;
              this.list_record[i].status_id = res['result'][0].status_id;
              this.list_record[i].status_title = res['result'][0].status_title;
            }//end if
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.myModal.close();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان باید منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title must be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert
  //********************************************************************************
  delete(id: any) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6189; } else { address = 6188; }
      var obj = {
        'address': address, "user_id": this.user_id, 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_weight_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_weight_id == res['result_record'][i].wharehouse_weight_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            //this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true      
  }//end delete 

  get_select(event: boolean): any {
    this.selected_record = [];
    this.get_select_all(event).then((res: any) => {
      if (res == this.list_record.length - 1) this.get_select_records(-1);
    }).catch((err: any) => { console.log(err); });
  }

  get_select_all(event: boolean): any {
    var finish;
    var promise = new Promise<void>((resolve, reject) => {
      this.indeterminate = false;
      this.select_all = event;
      for (var i = 0; i < this.list_record.length; i++) {
        this.list_record[i].checked = this.select_all;
        finish = i;
      }
      this.dataSource.data = this.list_record;
      return resolve()
    });
    return Promise.resolve(finish);
  }

  get_select_records(index: number) {
    if (index >= 0) {
      this.list_record[index].checked = !this.list_record[index].checked;
    }
    this.selected_record = [];
    var selected_record_temp;
    selected_record_temp = <any>document.getElementsByName('selete_records[]');
    for (var i = 0; i < selected_record_temp.length; i++) {
      if (selected_record_temp[i].checked == true) {
        this.selected_record.push(selected_record_temp[i].value);
      }
    }
    this.selected_record = this.selected_record.map((items: any) => {
      return parseInt(items);
    });
    if (this.selected_record.length != this.list_record.length && this.selected_record.length > 0) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
    }
    return this.selected_record;
  }
  //*******************************************************************************
  recieve_status() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1 }).subscribe(
      (res: any) => {
        this.list_status = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_status.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 2, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 2, 'close', pe_action);
        }
      }
    )
  }//end recieve_status
  //*******************************************************************************
  change_resize() {
    this.serverService.change_window_resize(1, '', 0);
  }//end change_resize
  //*******************************************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  message(validation: boolean, message: string, type: number, action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 



  ModalClose() {
    this.recieve_message(false, "", "", 2, "", "");
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
