<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">لاگ قفسه</span>
        <span *ngIf="lang == 2">Shelf log record</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>

        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="shelf">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شماره قفسه</span>
                    <span *ngIf="lang == 2">Shelf no</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_shelf_title
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="reception">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">شناسه پذیرش</span>
                    <span *ngIf="lang == 2">Reception code</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_reception_id2
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">نام و نام خانوادگی</span>
                    <span *ngIf="lang == 2">Name and surname</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="brand">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">برند</span>
                    <span *ngIf="lang == 2">Brand</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_brand_title
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">مدل</span>
                    <span *ngIf="lang == 2">Model</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_model_title
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تاریخ ورود</span>
                    <span *ngIf="lang == 2">Entry date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.repairman_shelf_log_date }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date_exit">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تاریخ خروج</span>
                    <span *ngIf="lang == 2">Exit date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.date_exit }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creator">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">ایجاد کننده</span>
                    <span *ngIf="lang == 2">Creator</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.creator }}
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                [ngStyle]="{'background':row.repairman_reception_urgent == 1 && row.repairman_rstatus_empty_shelf != 1 && row.repairman_rstatus_sub_ready_delivery != 1 ? '#f5909a' : '' }"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>
    </div>

</div><!-- content -->