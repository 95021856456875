import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-bascket-detaile',
  templateUrl: './bascket-detaile.component.html',
  styleUrls: ['./bascket-detaile.component.scss']
})
export class BascketDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public isOnline: any;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public level: number | undefined;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public readonly: boolean = false;
  public price: number = 0;
  public result: any | undefined;
  public access_service: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<BascketDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.creator = dialog_data.creator;
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          customer: this.dialog_data.search_array.customer,
          customer_title: this.dialog_data.search_array.customer_title,
          expire_date: this.dialog_data.search_array.expire_date,
          amount: this.dialog_data.search_array.amount,
          code: this.dialog_data.search_array.code_bascket,
          comment: this.dialog_data.search_array.comment,
          status_id: this.dialog_data.search_array.status_id,
          status_title: this.dialog_data.search_array.status_title,
          coupon: this.dialog_data.search_array.coupon,
          coupon_title: this.dialog_data.search_array.coupon_title,
          number: this.dialog_data.search_array.number,
          plan: this.dialog_data.search_array.plan,
          plan_title: this.dialog_data.search_array.plan_title,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'coupon_title': new FormControl(null),
      'coupon': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'customer_title': new FormControl(null),
      'customer': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'plan_title': new FormControl(null),
      'plan': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'expire_date': new FormControl(new Date()),
      'comment': new FormControl(null),
      'number': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['customer'].setValidators([Validators.required])
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }

  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) {
          }
          if (type_task == 4) { this.get_data(); }

        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      customer: this.form1_group.value.customer,
      customer_title: this.form1_group.value.customer_title,
      expire_date: this.form1_group.value.expire_date,
      amount: this.form1_group.value.amount,
      coupon: this.form1_group.value.coupon,
      coupon_title: this.form1_group.value.coupon_title,
      code_bascket: this.form1_group.value.code,
      comment: this.form1_group.value.comment,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      plan: this.form1_group.value.plan,
      plan_title: this.form1_group.value.plan_title,
      number: this.form1_group.value.number,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6371, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'amount': res['result'][0].site_bascket_amount,
            'number': res['result'][0].site_bascket_number,
            'code': res['result'][0].site_bascket_code,
            'status_id': res['result'][0].bascket_status_id,
            'status_title': res['result'][0].bascket_status_title,
            'customer': res['result'][0].customer,
            'customer_title': res['result'][0].customer_title,
            'plan': res['result'][0].type_account_id,
            'plan_title': res['result'][0].type_account_title,
            'expire_date': res['result'][0].site_bascket_expire_date_main,
            'comment': res['result'][0].site_bascket_comment,
          });
          this.price = res['result'][0].type_account_price
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6372
      , "user_id": this.user_id, code: this.code
      , 'status_id': this.form1_group.value.status_id
      , 'number': this.form1_group.value.number
      , 'code_bascket': this.form1_group.value.code
      , 'amount': this.form1_group.value.amount
      , 'comment': this.form1_group.value.comment
      , 'creator': this.form1_group.value.customer
      , 'expire_date': this.form1_group.value.expire_date
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = { result: res['result'][0] };
          this.update_user();
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang))
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang))
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6374, "user_id": this.user_id
      , 'code': this.code
      , 'number': this.form1_group.value.number
      , 'plan': this.form1_group.value.plan
      , 'status_id': this.form1_group.value.status_id
      , 'code_bascket': this.form1_group.value.code
      , 'amount': this.form1_group.value.amount
      , 'comment': this.form1_group.value.comment
      , 'creator': this.form1_group.value.customer
      , 'expire_date': this.form1_group.value.expire_date
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = { result: res['result'][0], i: this.i };
          this.update_user();
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang))
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang))
        }
      }
    );
  }//end update

  update_user() {
    var obj = { address: 6382, expire_date: this.form1_group.value.expire_date, user: this.form1_group.value.customer }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result, i: this.i }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
          this.serverService.send_bascket_status({ code_bascket: this.code, bascket_status_id: this.form1_group.valid.status });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_services(id: number) {
    var code_id;
    if (this.readonly == true) {
      return false;
    }
    if (id == 236) {
      code_id = 6370;
    }
    if (id == 237) {
      code_id = 6380;
    }
    if (id == 161) {
      code_id = 6384;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id, obj: { lang: this.lang } }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 236) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
          if (id == 237) {
            this.form1_group.patchValue({
              'plan': res.id,
              'plan_title': res.title
            });
            if (this.type_task != 3) this.get_account_type(res.id);
          }
          if (id == 161) {
            this.form1_group.patchValue({
              'customer': res.id,
              'customer_title': res.title
            })
          }
          if (id == 262) {
            this.form1_group.patchValue({
              'coupon': res.id,
              'coupon_title': res.title
            })
          }
          if (this.type_task != 3) this.get_expire_date(res.id);
        }
      }
    )
  }

  get_amount(): any {
    if (this.type_task == 3) {
      return false;
    }
    this.form1_group.patchValue({
      'amount': this.form1_group.value.number * this.price
    })
  }

  get_expire_date(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6383, user: this.form1_group.value.customer }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'expire_date': res['result'][0].user_expire_date_main
            });
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_account_type(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6381, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.price = res['result'][0].type_account_price;
            this.form1_group.patchValue({
              'amount': this.form1_group.value.number * res['result'][0].type_account_price
            })
          }

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  reset_input(id: number) {
    if (this.readonly == true) {
      return false;
    }
    if (id == 236) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 161) {
      this.form1_group.patchValue({
        'customer': null,
        'customer_title': null,
        'expire_date': null
      })
    }
    if (id == 237) {
      this.form1_group.patchValue({
        'plan': null,
        'plan_title': null
      });
    }
    if (id == 262) {
      this.form1_group.patchValue({
        'coupon': null,
        'coupon_title': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) { this.loading = false; }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}