<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>


<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden !important;">
    <div class="row" style="direction: rtl;display: flex !important;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 padding0">
            <button (click)='change_task(1,1,"",1,"")' class="fright" [ngClass]="{'fleft':dir == 'ltr'}">
                <span *ngIf="lang == 2">New</span>
                <span *ngIf="lang == 1">جدید</span>
            </button>
            <input name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
                placeholder="{{ serverService.get_title(3,lang) }}..." autocomplete="off"
                [ngClass]="{'full-left':dir == 'ltr'}" style="width:100%">
        </div>
    </div>
    <div class="content-body2 control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;overflow-y: scroll;min-height: 22rem;overflow:auto'>
        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:50px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:50px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + page_number_first }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان</span>
                    <span *ngIf="lang == 2">Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='change_task(2,x.repairman_belong2_id,x.repairman_belong2_title,x.status_id,x.repairman_belong2_sort)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.repairman_belong2_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort">
                <mat-header-cell *matHeaderCellDef [hidden]="true">
                    <span *ngIf="lang == 1">ترتیب</span>
                    <span *ngIf="lang == 2">Sort</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.repairman_belong2_sort }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='change_task(2,x.repairman_belong2_id,x.repairman_belong2_title,x.status_id,x.repairman_belong2_sort)'
                    [ngClass]="{'en':dir == 'ltr'}">
                    <span>{{ x.status_title }}</span>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                    <span *ngIf="lang == 1"> عملیات </span>
                    <span *ngIf="lang == 2">Operation</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px;text-align:center'>
                    <span (click)="check_access(3,0,x.repairman_belong2_id,0,1)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>

    </div>


    <ngx-smart-modal #myModal identifier="myModal" class="pe" (onClose)="ModalClose()">
        <form [formGroup]="form1_group">
            <div class="dialog_title1">
                <span>{{ serverService.get_title(type_task,lang) }}</span>
            </div>
            <div class="row">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> عنوان : </span>
                        <span *ngIf="lang == 2"> Title : </span>
                        <input class='wh-input' formControlName="title" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}" required>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}"
                    [hidden]="true">
                    <label for="sort" class='wh-label'>
                        <span *ngIf="lang == 1"> ترتیب : </span>
                        <span *ngIf="lang == 2"> Sort : </span>
                        <input class='wh-input' formControlName="sort" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="status_id" class='wh-label'>
                        <span *ngIf="lang == 1"> وضعیت نمایش : </span>
                        <span *ngIf="lang == 2"> Display status : </span>
                        <select class='wh-input' formControlName="status_id" [ngClass]="{'en':dir == 'ltr'}" required>
                            <option value=""></option>
                            <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                                <span>{{ x.status_title }}</span>
                            </option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'>
                    <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="check_access(type_task,0,0,0,1)"
                        [disabled]="!form1_group.valid">
                        {{ serverService.get_event_title(type_task,lang) }}
                    </button>
                </div>
            </div>
        </form>
    </ngx-smart-modal>
</div>