<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">فیلتر</span>
        <span *ngIf="lang == 2">Filter</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> بابت</span>
                        <span *ngIf="lang == 2">Description</span>
                        <input class='wh-input' formControlName="title" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="amount" class='wh-label'>
                        <span *ngIf="lang == 1"> مبلغ چک </span>
                        <span *ngIf="lang == 2"> Check amount </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="bank" class='wh-label'>
                        <span *ngIf="lang == 1"> بانک </span>
                        <span *ngIf="lang == 2"> Bank </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank_title" (click)="load_services(178) " readonly
                            [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="bank" [hidden]="true" readonly
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="account_title" class='wh-label'>
                        <span *ngIf="lang == 1"> شماره حساب </span>
                        <span *ngIf="lang == 2"> Account number </span>
                        <span (click)="reset_account(1)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account_title" (click)="select_account() " readonly
                            [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="account" [hidden]="true" readonly
                            [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="number" class='wh-label'>
                        <span *ngIf="lang == 1"> شماره چک </span>
                        <span *ngIf="lang == 2"> Check number </span>
                        <input class="wh-input" formControlName="number" [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="due" class="wh-label">
                        <span *ngIf="lang == 1">تاریخ سررسید</span>
                        <span *ngIf="lang == 2"> Due date </span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker2" formControlName="due" autocomplete="off"
                                [ngClass]="{'en':dir == 'ltr'}">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker"
                                [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="issue_date" class="wh-label">
                        <span *ngIf="lang == 1">تاریخ دریافت </span>
                        <span *ngIf="lang == 2">Date of reception</span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker" formControlName="issue_date"
                                autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
                            <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                                [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="status_id" class='wh-label'>
                        <span *ngIf="lang == 1"> وضعیت چک </span>
                        <span *ngIf="lang == 2"> Check status </span>
                        <span (click)="reset_input(190)">
                            <mat-icon class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="status_title" (click)="load_services(190) "
                            [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                        <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                            readonly [ngClass]="{'en':dir == 'ltr'}">
                    </label>
                </div>
                <!-- -------------------------------------------------------------------------------------------------------------- -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>