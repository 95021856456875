import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewFastMaterialComponent } from '../../wharehouse/new-fast-material/new-fast-material.component';
import { DecimalPipe } from '@angular/common';
import { WharehouseOrderCostDetaileComponent } from '../wharehouse-order-cost-detaile/wharehouse-order-cost-detaile.component';
import { WharehouseOrderBuyDetaileComponent } from '../wharehouse-order-buy-detaile/wharehouse-order-buy-detaile.component';
import { MessageService } from '../../service/message/message.service';
import Num2persian from 'num2persian';


@Component({
  selector: 'app-wharehouse-invoice-input',
  templateUrl: './wharehouse-invoice-input.component.html',
  styleUrls: ['./wharehouse-invoice-input.component.scss'],
  providers: [DecimalPipe]
})
export class WharehouseInvoiceInputComponent implements OnInit, OnDestroy {
  public user_info: any | undefined = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public subscription1: Subscription | any;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public list_record: any = [];
  public search: string | undefined;
  public page: number = 1;
  public invoice_id: number | undefined;
  public device: number | undefined;
  public cate: number | undefined;
  public reception_id: number | undefined;
  public exit_id: number | undefined;
  public list_material_type: any = [];
  public material_type: number = 1;
  public ref: string | undefined;
  public type: number | undefined;
  public list_invoice: any = [];
  public new_invoice_id: number;
  public sum_all: number = 0;
  public remain: number = 0;
  public sum_all_letter: string | undefined;
  public remain_letter: string | undefined;
  public code: number = 0;
  public id: number | undefined;
  public token: number | undefined;;
  public creator: any | undefined;
  public type_list: string = 'material';
  public invoice_price1: number = 0;
  public sum_all_real: number = 0;
  public document_type: number | undefined;
  public date3: number | undefined;
  public x: number = 0;
  public x2: number = 0;
  public x3: number = 0;
  public list_repairman: any = [];
  public requests_id: number = 0;
  public count_order: number = 0;
  public level: number | undefined;
  public back: number = 0;//این فیلد برای زمانی هستش که می خواهید بگوئییم نوع فاکتور برگشت هستش یا خیر
  public user: number | undefined;//این برای موقعی است که می خواهیم نام ظرف حساب در فاکتور ها چه کسی هستش
  public count_total: number = 1;
  public count_total_cost: number = 0;
  public count_total_material: number = 0;
  public page_number_first: number | undefined; public page_number_second: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;

  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public document_type_text: string | undefined;
  public invoice1_id: number | undefined;
  public displayedColumns2 = ['row', 'material', 'seller', 'inventory', 'order', 'last_invoice_price', 'invoice_price', 'number', 'discount', 'add'];
  public displayedColumns3 = ['row', 'title', 'group', 'number', 'cost', 'comment', 'add'];
  public displayedColumns = ['row', 'material', 'title', 'number', 'weight', 'price', 'discount', 'sum', 'operation'];



  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<WharehouseInvoiceInputComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public _decimalPipe: DecimalPipe) {

    this.subscription2 = this.serverService.get_material().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )

    if (dialog_data) {
      this.invoice_id = dialog_data.invoice_id;
      this.requests_id = dialog_data.requests_id;
      this.date3 = dialog_data.date3;
      this.token = dialog_data.token;
      this.ref = dialog_data.ref;
      this.document_type_text = dialog_data.document_type_text;
      this.document_type = dialog_data.document_type;
      this.device = dialog_data.device;
      this.exit_id = dialog_data.exit_id;
      this.cate = dialog_data.cate;
      this.type = dialog_data.type;
      this.reception_id = dialog_data.reception_id;
      this.code = dialog_data.code;
      this.id = dialog_data.reception_id;
      this.back = dialog_data.back;
      this.user = dialog_data.user;
      this.count_order = dialog_data.count_order;
      this.level = dialog_data.level;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
  }

  which(page) {
    if (page == 2) { this.page = this.page + 1; }
    if (page == 3) { this.page = this.page - 1; }
    if (this.type_list == 'material') {
      this.get_material(1);
    }
    else if (this.type_list == 'cost') {
      this.get_cost(1);
    }
    else {
      this.get_order();
    }
  }

  check_access(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 4) {
              this.get_material(1);
              this.get_cost_count()
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_cost_count() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6485, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_total_cost = res['count'];
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_material2() {
    this.page = 1;
    this.get_material(2);
  }

  get_material3() {
    this.page = 1;
    this.search = '';
    this.get_material(1);
  }

  get_services2() {
    this.page = 1;
    this.get_cost(2);
  }

  get_cost3() {
    this.page = 1;
    this.search = '';
    this.get_cost(1);
  }

  show_price(i: number, id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6641, material_id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_record[i].price = res['result'][0].price;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  hide_price(i) {
    this.list_record[i].price = null;
  }

  get_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 1;
    this.type_list = "material";
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    var address;
    if (this.document_type_text == 'input' || this.document_type_text == 'request') address = 6407;
    else address = 6435;

    var obj = {
      address: address, search: this.search, page: this.page
      , device: this.device, cate: this.cate, creator: this.creator, token: this.token, user: this.user
      , back: this.back, document_type_text: this.document_type_text,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            //if (this.document_type_text == 'exit') res['result'][i].inventory = res['result'][i].wharehouse_order_number - res['result'][i].order_consumed;
            if (this.document_type_text == 'input' || this.document_type_text == 'request') res['result'][i].wharehouse_order_cost = res['result'][i].wharehouse_material_price;
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total_material = res['count'];
          this.pages(0);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_cost(loading) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 3;
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    this.type_list = 'cost';
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6484, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource.data = this.list_record;
          this.pages(0);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 10 - 9;
    this.page_number_second = this.page * 10;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 10) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  //****************************************************************************************************************** */
  save(i: number, material_id: number, order_id: number, number1: number, material_type: number, cost: any, title: string): any {
    var input1 = <any>document.getElementById("input1" + material_id);
    var number_sale = parseInt(input1.value.match(/\d/g).join(""));

    var discount1 = <any>document.getElementById("discount1" + material_id);
    var discount = parseInt(discount1.value.match(/\d/g).join(""));

    var invoice_price1 = <any>document.getElementById("invoice_price1" + material_id);
    var price_buy = parseInt(invoice_price1.value.match(/\d/g).join(""));

    if (discount > price_buy * number_sale) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.check_add_to_invoice(i, material_id, number_sale, price_buy, discount)
  }

  check_add_to_invoice(i: number, material_id: number, number_sale: number, cost: number, discount) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6364, material_id: material_id, token: this.token, invoice_id: this.invoice_id, cost: cost
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.add_to_invoice_material(i, material_id, number_sale, 1, cost, discount);
          }
          else {
            var pe_message = "این کالا هم اکنون در سبد شما قرار دارد.";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
            return false;
            //this.update_order(res['result'][0].wharehouse_order_id, number_sale);
            this.message(false, "", 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_to_invoice_material(i: number, id: number, number: number, material_type, cost: number, discount): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 6482
      , reception_id: this.reception_id, material_type: material_type
      , material_id: id, number: number, cost: cost, discount: discount
      , invoice_id: this.invoice_id
      , token: this.token
      , type: 1
      , ref: this.ref
      , user_id: this.user_id
      , creator: this.creator
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.count_order++;
          this.serverService.send_invoice_material(res['result'][0]);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_to_invoice_cost(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var cost_price = <any>document.getElementById("cost2" + id);
    var cost = parseInt(cost_price.value.match(/\d/g).join(""));

    var comment = <any>document.getElementById("comment" + id);
    comment = comment.value;
    var obj = {
      address: 6482,
      material_type: 3
      , material_id: id, number: 1, cost: cost, comment: comment, discount: 0
      , invoice_id: this.invoice_id
      , token: this.token
      , type: 1
      , ref: this.ref
      , user_id: this.user_id
      , creator: this.creator
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.count_order++;
          this.serverService.send_invoice_material(res['result'][0]);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_account(user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = 0;
          this.remain = res['result'][0].balance;
          this.x = 0;
          for (var j = 0; j < this.list_record.length; j++) {
            if (this.list_record[j].wharehouse_order_trash == 3) {
              this.x = this.x + Number(this.list_record[j].wharehouse_order_cost * this.list_record[j].wharehouse_order_number) - this.list_record[j].wharehouse_order_discount;
            }
          }
          this.x3 = 0;
          if (this.document_type == 1) this.remain = Number(this.remain) + Number(this.x) - Number(this.x3);
          if (this.document_type == 2) this.remain = Number(this.remain) - Number(this.x) + Number(this.x3);
          this.remain_letter = Num2persian(this.remain);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*************************************** */
  get_order() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.type_list = 'order';

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6486, invoice: this.invoice_id, token: this.token }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.count_order = this.list_record.length;
          this.dataSource.data = this.list_record;
          this.pages(0);
          this.get_sum_all();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_sum_all() {
    this.x2 = 0;
    for (var j = 0; j < this.list_record.length; j++) {
      if (this.list_record[j].wharehouse_order_trash == 3) {
        this.x2 = Number(this.x2) + Number(this.list_record[j].wharehouse_order_cost * this.list_record[j].wharehouse_order_number - this.list_record[j].wharehouse_order_discount);
      }
    }
    this.sum_all = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all += this.list_record[i].sum;
    }
    this.sum_all_letter = Num2persian(this.sum_all);
    if (this.document_type == 1) this.sum_all_real = Number(this.sum_all) + Number(this.x2);
    if (this.document_type == 2) this.sum_all_real = Number(this.sum_all) - Number(this.x2);
  }

  open_order_buy(i, id): any {
    const dialogRef = this.dialog.open(WharehouseOrderBuyDetaileComponent, {
      width: '25rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: id, trash: 3, code: this.code, invoice_id: this.id, token: this.token, document_type_text: this.document_type_text }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].sum = res.result.sum;
          this.get_update_material_remain(res.result.wharehouse_material_id);
          this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.user) this.get_account(this.user);
          this.serverService.send_order();
        }
      }
    )
  }


  update_order(order_id: number, number_sale: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6365, order_id: order_id, number_sale: number_sale
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_update_invoice_material();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************666666666666*************************************************************** */
  add() {
    if (!this.creator) {
      var pe_message = "نام صاحب اکانت مشخص نیست . لطفا یکبار دیگر لاگین شوید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(NewFastMaterialComponent, {
      width: '22rem',
      height: 'auto',
      data: { device: this.device, cate: this.cate, material_type: 1, creator: this.creator, back: this.back }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }
  //********************************************************************** */
  send_invoice_material(result) {
    this.serverService.send_invoice_material(result);
  }
  //************************************************************************************************* */
  check_for_access(i: number, type_task: number, id: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 3) {
                this.open_cost(i, id);
              }
              else {
                this.open_order_buy(i, id);
              }
              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_cost(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderCostDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].title = res.result.finance_cost_type_title;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].sum = res.result.sum;
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          this.serverService.send_order();
        }
      }
    )
  }

  //******************************************************************************************************************* */
  access_for_delete(id: number, material: number, trash: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.check_to_delete(id, material, trash, material_type);
              } else {
                this.delete_order(id, trash);
              }
            } else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_to_delete(id: number, material: number, trash: number, material_type: number): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6003, material: material, check_order_id: id, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.document_type_text == 'input' && res['remain'] == res['result'][0].order_number) {
            this.delete_order(id, trash);
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_order(id: number, trash: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (trash == 2) {
              for (var i = 0; i < this.list_record.length; i++) {
                if (this.list_record[i].wharehouse_order_id == id && this.list_record[i].wharehouse_order_material_type == 1) {
                  this.get_update_material_remain(this.list_record[i].wharehouse_order_material);
                  this.get_update_order_consumed(this.list_record[i].wharehouse_order_consumed_invoice);
                }
              }
            }
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.get_sum_all();
            if (trash == 2) {
              this.update_financial2();
            }
            this.serverService.send_order();
            this.count_order = this.list_record.length;
            this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  //******************************************************************************* */
  get_update_material_remain(material_id) {
    var obj = {
      address: 6445,
      material_id: material_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_order_consumed(order_id: number) {
    var obj = {
      address: 6446,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_financial2() {
    var obj = {
      address: 6106,
      id: this.invoice_id,
      amount: this.sum_all_real,
      date3: this.date3,
      document_type: this.document_type,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_all_financial2() {
    var obj = {
      address: 6111,
      id: this.invoice_id,
      user: this.user,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.user) this.get_account(this.user);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************  */

  close() {
    this.matDialogRef.close();
  }

  //************************************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }//end if

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
  }//end OnDestroy
}
