<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>


<div class="container-fluid" style="overflow-y:auto;padding:0px;">
    <div class="container-fluit">
        <div class="row" style="display: block;">

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 content ranking-div3"
                style="padding:0.3rem 0px 0px  11px !important;overflow-y:auto;">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px">
                    <div>
                        <div class="row" style="padding-top:6px;text-align:right;display: block;">

                            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12'
                                style="padding:0px 2px;float:right">
                                <label for="issue_date" class="wh-label">
                                    <span>تاریخ از: </span>
                                    <div style="width:100%">
                                        <input class="wh-input" (dateChange)="date_change()" matInput
                                            [matDatepicker]="picker" [(ngModel)]="date1" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker1"
                                            style="line-height:28px !important">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                </label>
                            </div>

                            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12' style="padding:0px 2px">
                                <label for="issue_date" class="wh-label">
                                    <span>تاریخ تا: </span>
                                    <div style="width:100%">
                                        <input class="wh-input" matInput [matDatepicker]="picker2" autocomplete="off"
                                            (dateChange)="date_change()" [(ngModel)]="date2">
                                        <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker1">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </div>
                                </label>
                            </div>



                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                style="float:right;padding:0px 2px">
                                <label>انتخاب سال</label>
                                <select class="wh-input" name="year_id" [(ngModel)]="year_id"
                                    (ngModelChange)="select_month(year_id,month_id);">
                                    <option *ngFor="let x of list_year" [value]="x.id">
                                        {{ x.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" style="padding:0px 2px">
                                <label>انتخاب ماه</label>
                                <select class="wh-input" name="month_id" [(ngModel)]="month_id">
                                    <option *ngFor="let x of list_month" [value]="x.site_month_value">
                                        {{x.site_month_title }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row" style="margin-top:1rem">
                            <div class="month" *ngFor="let month of list_month"
                                (click)="select_month(year_id,month.site_month_value)"
                                [ngClass]="{month_active:month.site_month_value == month_id}">
                                {{ month.site_month_id }}
                            </div>
                            <div class="month" (click)="select_month(year_id,'none')"
                                [ngClass]="{month_active:month_id == 'none'}">
                                سال
                            </div>
                        </div>

                        <mat-nav-list class="list1">
                            <div class="row">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8  col-xs-12"
                                    style="padding:0;padding-right:3px">
                                    <mat-list-item class="header-item1 mat-list-item1">
                                        <div
                                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12   item6_header item8_header">
                                            <div class="item3_header"> کل خدمات </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12   item6_header">
                                            <div class="item3_header"> کل تخفیف </div>
                                        </div>
                                        <div
                                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12  item6_header item7_header">
                                            <div class="item3_header">
                                                پورسانت
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </div>
                            </div><!-- row -->

                            <div class="row" *ngFor="let x of list_users;let i = index">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12"
                                    style="padding:0;padding-right:3px">
                                    <mat-list-item class="item1"
                                        [ngStyle]="{'border-left-color': list_color[i],'background': x.color}"
                                        [ngClass]="{branchactive:branch_id == x.user_id && x.footer != true}"
                                        (click)="get_porsant_user(x.user_id)">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12  item6 right">
                                            <div class="item3">{{ x.price | number }} </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12  item6">
                                            <div class="item3">{{ x.discount | number }} </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12  item6">
                                            <div class="item3">
                                                {{ x.porsant | number }}
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"
                                    style="padding:0;padding-left: 0px;padding-right:3px">
                                    <mat-list-item class="item4">
                                        <span [ngClass]="{infoactive:branch_id == x.user_id}"
                                            (click)="open_detaile2(x.user_id,x.user_title)">
                                            <i style="font-size: 1.4rem;color:#b58484;"
                                                class="fas fa-info-circle icon"></i>
                                        </span>
                                        {{ x.user_title }}
                                    </mat-list-item>
                                </div>

                            </div><!-- row -->
                        </mat-nav-list>

                        <!-- ***************************************************************************************************** -->

                        <mat-nav-list class="list1">
                            <div class="row">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8  col-xs-12"
                                    style="padding:0;padding-right:3px">
                                    <mat-list-item class="header-item1 mat-list-item1">
                                        <div
                                            class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12  item6_header item8_header">
                                            <div class="item3_header"> کل خرید </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 item6_header">
                                            <div class="item3_header">کل فروش</div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 item6_header">
                                            <div class="item3_header"> کل تخفیف </div>
                                        </div>
                                        <div
                                            class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 item6_header item7_header">
                                            <div class="item3_header">پورسانت</div>
                                        </div>

                                    </mat-list-item>
                                </div>
                            </div><!-- row -->

                            <div class="row" *ngFor="let x of left_list_users2;let i = index">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12"
                                    style="padding:0;padding-right:3px">
                                    <mat-list-item class="item1"
                                        [ngStyle]="{'border-left-color': list_color[i],'background': x.color}"
                                        [ngClass]="{branchactive:branch_id == x.user_id}"
                                        (click)="get_porsant_user(x.user_id)">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12   item6 right">
                                            <div class="item3">{{ x.price | number }}</div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12   item6">
                                            <div class="item3">{{ x.sale | number }}</div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12  item6 ">
                                            <div class="item3"> {{ x.discount | number }}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12   item6">
                                            <div class="item3">
                                                {{ x.porsant | number }}
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"
                                    style="padding:0;padding-left: 0px;padding-right:3px">
                                    <mat-list-item class="item4" (click)="get_porsant_user(x.user_id)">
                                        <span [ngClass]="{infoactive:branch_id == x.user_id}"
                                            (click)="open_detaile(x.user_id,x.user_title)">
                                            <i style="font-size: 1.4rem;color:#b58484;"
                                                class="fas fa-info-circle icon"></i>
                                        </span>
                                        {{ x.user_title }}
                                    </mat-list-item>
                                </div>

                            </div><!-- row -->
                        </mat-nav-list>
                        <!--********************************************************************************************************-->
                    </div><!-- end  -->
                </div>

            </div><!-- -->


            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 ranking-div4"
                style="padding:0rem 0 0 0 !important">
                <div class="row  content"
                    style="margin:14px !important;overflow-y:scroll;padding-bottom:2rem;direction: ltr;">

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
                            <div style="background:#fff;padding-top:0.5rem;margin-bottom:1rem">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="{
                                title : { text : 'میزان ورودی ماهانه'  },
                                chart : {type: 'column'},
                                tooltip : tooltip2,
                                xAxis : {categories: list_score_month_text,crosshair: true},
                                yAxis : {min: 0,title: {text: 'فروش ماهانه'}},
                                legend: {
                                    enabled: false
                                },
                                series : [
                                { 
                                    name: 'Browsers',
                                    type:'area',
                                    data: list_score_branch_monthly,
                                    color: '#97CC43'

                                } 
                                ]
                                
                            }" style="width: 100%; height: 300px; display: block;padding-top: 1rem;">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div><!-- row -->

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
                            <div style="background:#fff;padding-top:0.5rem;margin-bottom:1rem">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="{
                                title : { text : 'تعداد دستگاههای پذیرش شده' + year_id },
                                chart : {type: 'column'},
                                tooltip : tooltip3,
                                xAxis : {categories: list_goods2,crosshair: true},
                                yAxis : {min: 0,title: {text: 'فروش محصولات در ' + year_id }},
                                legend: {
                                    enabled: false
                                },
                                series : [
                                { 
                                    name: 'Browsers', 
                                    type:'line',
                                    colorByPoint: true,
                                    data: list_goods2_data
                                }
                                ]
                                
                            }" style="width: 100%; height: 300px; display: block;padding-top: 1rem;">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            style="padding:0;margin-bottom:1rem">
                            <div style="background:#fff;padding-top:0.5rem">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="{
                                    title : { text :  ' نمودار پورسانت  ' +  user_store },
                                    chart : {type: 'area'},
                                    tooltip : tooltip4, 
                                    xAxis : {categories: list_score_month_text,crosshair: true},
                                    yAxis : {min: 0,title: {text: 'فروش ماهانه'}},
                                    legend: {
                                        enabled: false
                                    },
                                    series : [{
                                        name:  'سال ' + year_id, 
                                        colorByPoint: true,
                                        type:'column',
                                        data: list_score_branch_data
                                    }]
                                    
                                }" style="width: 100%; height: 300px; display: block;padding-top: 1rem;">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>





                </div>
            </div>


        </div>



    </div>
</div>