import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MessageService } from '../../../service/message/message.service';


@Component({
  selector: 'app-content-detaile',
  templateUrl: './content-detaile.component.html',
  styleUrls: ['./content-detaile.component.scss']
})
export class ContentDetaileComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server_main();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));

  public loading = false;
  public subscription: Subscription;
  public type_file_uploaded: string;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];


  public video: any = [];
  public video_stop: boolean = false;
  public video_url: any;
  public video_width: any;
  public video_poster_list: any = [];
  public video_poster_list_temp: any = [];
  public video_poster: string;
  public video_poster_default: number = 0;
  public selectedFile: File;
  public format: string;
  public reader: any | undefined;
  public loaded: number = 0;
  public volumn_loaded: any = 0;
  public volumn: any;

  public creator: number;
  public access: boolean = false;
  public level: number;
  public access_service: number;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , private http: HttpClient
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<ContentDetaileComponent>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
        this.check_access(5, 1);
        this.get_status();
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        if (this.dialog_data) this.creator = this.dialog_data.creator;
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
        });
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  onselected(e: any) {
    e.filesData[0].name = "UploadFiles_" + Date.now() + "." + e.filesData[0].type;
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required])
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }
  }


  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, res['result'][0].count, excel);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, access_service: number, excel: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.loading = false;
          if (type_task == 1) { this.insert(); }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.get_status();
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6543, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].site_content_title,
            'sort': res['result'][0].site_content_sort,
            'status_id': res['result'][0].site_content_status,
            'status_title': res['result'][0].status_title,
          }
          );
          if (res['result'][0].site_content_path_video) {
            this.video_url = res['result'][0].site_content_site_video + "/" + res['result'][0].site_content_path_video + "/" + res['result'][0].site_content_filename_video;
            this.video.push({
              site: res['result'][0].site_content_site_video,
              path: res['result'][0].site_content_path_video,
              filename: res['result'][0].site_content_filename_video
            });
            this.video_stop = true;
            this.video_width = 0;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6539, "user_id": this.user_id
      , 'status_id': this.form1_group.value.status_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'video': this.video
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6540, "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'sort': this.form1_group.value.sort
      , 'status_id': this.form1_group.value.status_id
      , 'video': this.video
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  //*********************************************************************************************************************************** */
  open_upload() {
    var x = <any>document.getElementById("uploadButton");
    x.click();
  }

  onFileSelected(event: any, back_status_message: any) {
    this.selectedFile = <File>event.target.files[0];
    if (this.selectedFile) {
      var reader: any = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      this.format = 'video'
    }
    this.reader = reader;
    this.upload_file(reader, back_status_message);
  }

  upload_file(reader, back_status_message) {
    reader.onload = (event) => {
      this.video_url = (<FileReader>event.target).result;
    }
    var fd = new FormData();
    fd.append('video', this.selectedFile, this.selectedFile.name);
    this.subscription = this.http.post(this.server + "/uploadFilmContent", fd, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      (events: any) => {
        this.video_stop = true;
        if (events.type === HttpEventType.UploadProgress) {
          this.video_width = 100 - (events.loaded / events.total * 100) + '%';
          this.loaded = Math.floor(events.loaded / events.total * 100 - 1);
          this.volumn = parseFloat(String(events.total / 100 / 1024 / 1024 * 100)).toFixed(2);
          this.volumn_loaded = parseFloat(String(events.loaded / 100 / 1024 / 1024 * 100)).toFixed(2);
        }
        else if (events.type === HttpEventType.Response) {
          if (events && events.status == 200) {
            events.body.result.site = this.serverService.get_site_upload_video_main();
            events.body.result.path = "object/movie_content";
            this.video.push(events.body.result);
            this.loaded = 100;
            this.type_file_uploaded = events.body.result.fieldname;
            var x: any = document.getElementById(back_status_message)
            x.innerHTML = "ویدئوی شما آماده ی انتشار می باشد";
          }
        }
      }
    )
  }

  video_stop_upload() {
    this.subscription.unsubscribe();
    this.video_stop = false;
    this.loaded = 0;
    this.video_poster_list = [];
    this.video_poster_default = 0;
    this.video = [];
    //this.duration = '00:00:00';
    this.form1_group.patchValue({
      video_title: '',
      video_keyword: ''
    })
  }

  //*************************************************************************************** */
  get_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1064 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_services(id: number) {
    var where = "";
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
  }

  //***************************************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
