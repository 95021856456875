import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';


@Component({
  selector: 'app-cash-detaile',
  templateUrl: './cash-detaile.component.html',
  styleUrls: ['./cash-detaile.component.scss']
})
export class CashDetaileComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public financial: number | undefined;
  public result: any = [];
  public change: boolean = false;
  public user: number | undefined;
  public close_after_save: string | undefined;
  public document: number | undefined;
  public document_type: number | undefined;
  public token: number | undefined;
  public ref: any | undefined;
  public sum: number = 0;
  public creator: number | undefined;
  public transfer: any = false;
  public obj: any | undefined;
  public cash: number = 0;
  public video: string | undefined;
  public video2: string | undefined;

  constructor(
    public serverService: ServerService
    , public messageService: MessageService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , private matDialogRef: MatDialogRef<CashDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.sum = dialog_data.sum;
      this.ref = dialog_data.ref;
      this.token = dialog_data.token;
      this.financial = dialog_data.financial;
      this.sum = dialog_data.sum;
      this.document = dialog_data.document;
      this.create_form1();
      this.transfer = dialog_data.transfer;
      this.id = dialog_data.id;
      this.title = 'امور مالی';
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.creator = dialog_data.creator;
      if (this.document) {
        this.get_document();
      }
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        if (dialog_data) {
          this.form1_group.patchValue({
            date3: dialog_data.search_array.date3,
            date4: dialog_data.search_array.date4,
          })
        }
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_cash();
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  create_form1() {
    if (this.type_task == 3) { }
    this.form1_group = new FormGroup({
      'date3': new FormControl(),
      'date4': new FormControl(),
    });
  }

  get_cash() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6353, creator: this.creator, code: 224 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.cash = res['remain'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_document() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1952, document: this.document }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.document_type = res['result'][0].document_type;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  save(type_task: number, excel: number, close: string) {
    this.close_after_save = close;
    this.check_access(type_task, excel);
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { }
            if (type_task == 2) { }
            if (type_task == 3) { this.search(); }
            if (type_task == 4) { }
            if (type_task == 5) {
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }



  search() {
    var date3;
    var date4;
    if (this.form1_group.value.date3) { date3 = `${this.form1_group.value.date3.toISOString()}`; }
    if (this.form1_group.value.date4) { date4 = `${this.form1_group.value.date4.toISOString()}`; }
    this.obj = { date1: date3, date2: date4, search: true };
    this.router.navigate(['/home/cash/224'], { queryParams: this.obj });
    this.search2()
  }

  search2() {
    this.matDialogRef.close({
      date3: this.form1_group.value.date3,
      date4: this.form1_group.value.date4,
      export_excel: 1
    })
  }


  load_services(id: number, type: any) {
    var code_id;
    var where = "";
    if (type == 2) {
      code_id = 6101;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '45rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, where: where }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
        }
      }
    )

  }


  reset_input(id: number, type: any) {

  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
