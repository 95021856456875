<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">هزینه</span>
        <span *ngIf="lang == 2">Expense</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان هزینه </span>
                    <span *ngIf="lang == 2"> Expense title </span>
                    <span (click)="reset_input(221)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_type_title" (click)="load_services(221) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cost_type" [hidden]="true" required readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت پایه : </span>
                    <span *ngIf="lang == 2"> Starting price </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask pattern="[0-9]{1,}"
                        required [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea style="height:10rem" class="wh-input" formControlName="comment"
                        [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2" [hidden]="readonly == true">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
    </div>
</form>