<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr'}" name="search_all" [(ngModel)]="search_all"
            (keyup)="check_access(4,1,'',1,2)" placeholder="{{ messageService.get_search(lang) }}" autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">
        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span *ngIf="lang == 1">ردیف</span>
                <span *ngIf="lang == 2">Row</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 40px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px;max-width: 250px;">
                <span *ngIf="lang == 1">نام و نام خانوادگی</span>
                <span *ngIf="lang == 2"> Name and surname </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 250px;max-width: 250px;">
                <div class="right fright" [ngClass]="{left:dir == 'ltr',fleft : dir == 'ltr'}"
                    (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)">
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <div style="text-align:left;padding-left: 6px;">
                    <span class="fright" [ngClass]="{fleft:dir == 'rtl'}" [matMenuTriggerFor]="menu"
                        (click)="get_menu(i,x)"><i class="fas fa-info-circle icon"></i></span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 200px;max-width: 200px;"></mat-footer-cell>
        </ng-container>
        <mat-menu #menu="matMenu" class="reception-mat-menu-panel" xPosition="before">
            <div class="div_loading">
                <div class="my-container" [hidden]="!loading2">
                    <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                </div>
            </div>
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr *ngIf="lang == 1">
                    <td class="cursor right" [ngClass]="{'left':dir == 'ltr'}"
                        (click)="check_charge_sms('reception1',i,x.repairman_reception_id,x.repairman_reception_id2,x.repairman_reception_code,x.user_cellPhone, x.repairman_rstatus_title  , true, x.user_store, x.user_phone)">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="far fa-comments"></i></span>
                        <span *ngIf="lang == 1">ارسال پیامک پذیرش دستگاه</span>
                        <span *ngIf="lang == 2">Send device acceptance SMS</span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="show_factor1()" [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span *ngIf="lang == 1">
                            <span *ngIf="rstatus == 3">چاپ قبض پذیرش دستگاه</span>
                            <span [hidden]="rstatus == 3"> چاپ قبض پیش فرض</span>
                        </span>
                        <span *ngIf="lang == 2">
                            <span *ngIf="rstatus == 3">Print the receipt of the device</span>
                            <span [hidden]="rstatus == 3">Default invoice printing</span>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="lang == 1">
                    <td class="cursor right" [ngClass]="{'left':dir == 'ltr'}"
                        (click)="check_charge_sms('reception2',i,x.repairman_reception_id,x.repairman_reception_id2,x.repairman_reception_code,x.user_cellPhone, x.repairman_rstatus_title  , true, x.user_store, x.user_phone)">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="far fa-comments"></i></span>
                        <span *ngIf="lang == 1">ارسال پیامک فاکتور تعمیرگاه</span>
                        <span *ngIf="lang == 2">Send SMS repair invoice</span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="show_factor2()" [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span *ngIf="lang == 1">
                            <span>چاپ فاکتور تعمیرگاه</span>
                        </span>
                        <span *ngIf="lang == 2">
                            <span>Print repair shop invoice</span>
                        </span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="check_cellphone_sms2()" [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span *ngIf="lang == 1">
                            <span>ارسال پیامک پذیرش به تعمیرکار</span>
                        </span>
                        <span *ngIf="lang == 2">
                            <span>Send Notification Sms </span>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="rstatus == 6">
                    <td class="cursor right" (click)="show_factor3()" [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="fa fa-eye icon"></i></span>
                        <span *ngIf="lang == 1">چاپ استیکر لیبل پرینتر</span>
                        <span *ngIf="lang == 2">Label printer sticker printing</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right" (click)="barcode()" *ngIf="code == 160 || rstatus == 3 || rstatus == 4"
                        [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="fas fa-print icon"></i></span>
                        <span *ngIf="lang == 1">بارکد</span>
                        <span *ngIf="lang == 2">Barcode</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right" (click)="open_delivery(i,x.repairman_reception_id)" [hidden]="rstatus != 6"
                        [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="fas fa-truck icon"></i></span>
                        <span *ngIf="lang == 1"> کد تحویل</span>
                        <span *ngIf="lang == 2">Delivery code</span>
                    </td>
                </tr>
            </table>
        </mat-menu>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">شماره همراه</span>
                <span *ngIf="lang == 2">Phone number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:100px">
                <div *ngIf="show_cellphone == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">فاکتور</span>
                <span *ngIf="lang == 2">Invoice</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px"
                (click)="open_exit(i,x.repairman_reception_id)">
                <span *ngIf="lang == 1">درج فاکتور</span>
                <span *ngIf="lang == 2">insert invoice</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id2">
            <mat-header-cell *matHeaderCellDef style="max-width:90px" (click)="show_id()">
                <span *ngIf="lang == 1">ش پذیرش</span>
                <span *ngIf="lang == 2">Reception no</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_id2 }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:110px" [hidden]="!status_show_id == true">
                <span *ngIf="lang == 1">ش پذیرش قدیم</span>
                <span *ngIf="lang == 2">Old Reception no</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:110px"
                [hidden]="!status_show_id == true" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_id }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:110px;"
                [hidden]="!status_show_id == true"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="shelf">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">شماره قفسه</span>
                <span *ngIf="lang == 2">Shelf no</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_shelf_title }}
                <span (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="float: left;"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="device">
            <mat-header-cell *matHeaderCellDef style="max-width:110px">
                <span *ngIf="lang == 1">دستگاه</span>
                <span *ngIf="lang == 2">Device</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:110px"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.wharehouse_device_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:110px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="brand">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span *ngIf="lang == 1">برند</span>
                <span *ngIf="lang == 2">Brand</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px"
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_brand_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="model">
            <mat-header-cell *matHeaderCellDef style="min-width:140px;max-width:140px">
                <span *ngIf="lang == 1">مدل</span>
                <span *ngIf="lang == 2">Model</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)"
                style="min-width:140px;max-width:140px" [ngClass]="{'en':dir == 'ltr'}">
                <div class="left font-en" style="direction: ltr;padding-right:0.3rem"> {{ x.repairman_model_title |
                    substr:0:10}}
                    <span style="float: right;height:0.8rem" [ngStyle]="{color:x.repairman_reception_color}">
                        <i class="fas fa-circle"></i>
                    </span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:140px;max-width:140px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="searial">
            <mat-header-cell *matHeaderCellDef style="min-width:150px">
                <span *ngIf="lang == 1">سریال</span>
                <span *ngIf="lang == 2">Serial no</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px" class="font-en"
                [ngClass]="{'en':dir == 'ltr'}">
                <div class="left" (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)"
                    [ngClass]="{'en':dir == 'ltr'}">
                    {{ x.repairman_reception_searial }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery == 1" style="max-width:110px">
                <span *ngIf="lang == 1">تاریخ ورود</span>
                <span *ngIf="lang == 2">Received date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery == 1"
                style="max-width:110px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_date }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 110px;" [hidden]="delivery == 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery == 1" style="max-width:110px">
                <span *ngIf="lang == 1">ساعت ورود</span>
                <span *ngIf="lang == 2">Received time</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery == 1"
                style="max-width:110px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 110px;" [hidden]="delivery == 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date2">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery != 1" style="max-width:90px">
                <span *ngIf="lang == 1">تاریخ خروج</span>
                <span *ngIf="lang == 2">Exit date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery != 1"
                style="max-width:90px" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_editor_date }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;" [hidden]="delivery != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="time2">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery != 1" style="max-width:90px"
                [ngClass]="{'en':dir == 'ltr'}">
                <span *ngIf="lang == 1">ساعت خروج</span>
                <span *ngIf="lang == 2">Exit time</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery != 1"
                style="max-width:90px">
                {{ x.repairman_reception_editor_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;" [hidden]="delivery != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="estimated_time">
            <mat-header-cell *matHeaderCellDef style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197">
                <span *ngIf="lang == 1">موعد تقریبی تحویل</span>
                <span *ngIf="lang == 2">Estimated delivery date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.repairman_reception_estimated_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef style="max-width: 200px;min-width: 150px;">
                <span *ngIf="lang == 1">وضعیت پذیرش</span>
                <span *ngIf="lang == 2">Reception status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)"
                style="max-width: 200px;min-width: 150px">
                {{ x.repairman_rstatus_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 200px;min-width: 150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="device_status">
            <mat-header-cell *matHeaderCellDef style="max-width: 120px;">
                <span *ngIf="lang == 1">وضعیت دستگاه</span>
                <span *ngIf="lang == 2">Device status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width: 120px;">
                {{ x.repairman_device_status_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 120px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sum_services">
            <mat-header-cell *matHeaderCellDef style="max-width:90px"
                [hidden]="rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="lang == 1"> مبلغ خدمات </span>
                <span *ngIf="lang == 2">Service price</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px" [matMenuTriggerFor]="menu"
                [hidden]="rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="x.sum_services >= 0">{{ x.sum_services |
                    number}}</span>
                <span *ngIf="x.sum_services == null ">0</span>
                <span (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="float: left;"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"
                [hidden]="rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">{{
                sum_services | number }}</mat-footer-cell>
        </ng-container>

        <mat-menu #menu="matMenu" class="reception-mat-menu-panel"
            style="max-width:32rem !important;width:32rem !important" xPosition="before">
            <mat-progress-bar mode="indeterminate" [hidden]="!loading1" class="detaile"></mat-progress-bar>

            <div class="row" (click)="$event.stopPropagation()">
                <table class="table table-bordered">
                    <tr>
                        <th>نام تعمیرکار</th>
                        <th>مبلغ خدمات</th>
                        <th>مبلغ پورسانت</th>
                    </tr>
                    <tr *ngFor="let x of list_services">
                        <td>{{ x.user_title }}</td>
                        <td><span style="color:blue">{{ x.cost | number }}</span></td>
                        <td>{{ x.porsant | number }}</td>
                    </tr>
                </table>
            </div>
        </mat-menu>

        <ng-container matColumnDef="sum_material">
            <mat-header-cell *matHeaderCellDef style="max-width:90px"
                [hidden]=" rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="lang == 1"> مبلغ کالا </span>
                <span *ngIf="lang == 2"> Product price</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px"
                [hidden]="rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="x.sum_material >= 0">{{ x.sum_material | number}}</span>
                <span *ngIf="x.sum_material == null">0</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px;"
                [hidden]="rstatus == 7 || code == 4 || code == 6  || code == 197 || code == 160">{{ sum_material |
                number }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sum">
            <mat-header-cell *matHeaderCellDef style="max-width:90px"
                [hidden]="code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="lang == 1"> مبلغ فاکتور </span>
                <span *ngIf="lang == 2"> Amount due </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px"
                [hidden]="code == 4 || code == 6  || code == 197 || code == 160 || creator != 1">
                <span *ngIf="x.sum_order >= 0">{{ x.sum_order | number}}</span>
                <span *ngIf="x.sum_order == null">0</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px;"
                [hidden]="code == 4 || code == 6  || code == 197 || code == 160">{{ sum_order | number
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:100px;min-width:100px'>
                <span *ngIf="lang == 1">عملیات</span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px;min-width:100px'>
                <mat-checkbox (change)="get_select_records(i)" name="selete_records[]"
                    [value]="x.repairman_reception_id" [checked]="x.checked" class="hidden-sm hidden-xs"
                    [hidden]="true"></mat-checkbox>
                <span style="font-size: 1rem;" class="icon"
                    (click)="open_payment(i,x.repairman_reception_id,x.repairman_reception_id2)"
                    *ngIf="rstatus == 4 || rstatus == 6" [title]="lang == 2 ? 'Pay' : 'پرداخت' ">
                    <mat-icon class="matIcon1">credit_card</mat-icon>
                </span>
                <span style="font-size: 1rem;margin:0 2px" class="icon" (click)="open_exit(i,x.repairman_reception_id)"
                    [title]="lang == 2 ? 'Invoice' : 'فاکتور' ">
                    <mat-icon class="matIcon1"> add_circle_outline </mat-icon>
                </span>

                <span class="icon" (click)='open_detaile(i,2,x.repairman_reception_id)'>
                    <i class="fas fa-desktop"></i>
                </span>
                <span class="icon" (click)="check_access(3,0,x.repairman_reception_id,0,1)">
                    <i class="far fa-trash-alt"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;min-width:100px"></mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            [ngStyle]="{'background':row.urgent <= 3 && row.repairman_rstatus_empty_shelf != 1 && row.repairman_rstatus_sub_ready_delivery != 1 ? 'rgb(237 202 206)' : '' }"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>