<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 2">Client bill</span>
        <span *ngIf="lang == 1">صورتحساب اشخاص</span>
    </span>
    <span (click)="close(true)">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="search()">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row">

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="user" class='wh-label'>
                        <span *ngIf="lang == 1">نام طرف حساب </span>
                        <span *ngIf="lang == 2">Account holder </span>
                        <span (click)="reset_customer()">
                            <mat-icon style="text-align: left;" class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class='wh-input' formControlName="user_title" (click)="open_user()" autocomplete="off"
                            required>
                        <input class='wh-input' formControlName="user" autocomplete="off" required readonly
                            [hidden]="true">
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="date1" class='wh-label'>
                        <span *ngIf="lang == 1"> از تاریخ : </span>
                        <span *ngIf="lang == 2"> Date from : </span>
                        <input class="wh-input" matInput [matDatepicker]="picker" formControlName="date1"
                            autocomplete="off" [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="date2" class='wh-label'>
                        <span *ngIf="lang == 1"> تا تاریخ : </span>
                        <span *ngIf="lang == 2"> Date to : </span>
                        <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="date2"
                            autocomplete="off" [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker"
                            [ngClass]="{datepicker1_en:dir == 'ltr','datepicker1' : dir == 'rtl'}"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </label>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [disabled]="!form1.valid">
            <span *ngIf="lang == 1">مشاهده</span>
            <span *ngIf="lang == 2">View</span>
        </button>
    </div>
</form>